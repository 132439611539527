import { Union, Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { string_type, union_type, record_type, lambda_type, list_type, int32_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { map, ofArray, singleton, sortByDescending, fold } from "../fable_modules/fable-library.3.7.12/List.js";
import { partialApply, curry, comparePrimitives } from "../fable_modules/fable-library.3.7.12/Util.js";
import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { TableOption, table } from "../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";

export class ListDisplayModification_ListDisplayModification$2 extends Record {
    constructor(Source, ModificationOrderIndex, Modifier) {
        super();
        this.Source = Source;
        this.ModificationOrderIndex = (ModificationOrderIndex | 0);
        this.Modifier = Modifier;
    }
}

export function ListDisplayModification_ListDisplayModification$2$reflection(gen0, gen1) {
    return record_type("ThreeDocs.SharedUI.Sort.ListDisplayModification.ListDisplayModification`2", [gen0, gen1], ListDisplayModification_ListDisplayModification$2, () => [["Source", gen1], ["ModificationOrderIndex", int32_type], ["Modifier", lambda_type(list_type(gen0), list_type(gen0))]]);
}

export function ListDisplayModification_ListDisplayModification$2_applyListModifications(modifications, items) {
    return fold((items_1, modification_1) => modification_1.Modifier(items_1), items, sortByDescending((modification) => modification.ModificationOrderIndex, modifications, {
        Compare: comparePrimitives,
    }));
}

export class Sort_SortDirection extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Ascending", "Descending"];
    }
}

export function Sort_SortDirection$reflection() {
    return union_type("ThreeDocs.SharedUI.Sort.Sort.SortDirection", [], Sort_SortDirection, () => [[], []]);
}

export function Sort_SortDirection_get_not() {
    return (_arg1) => ((_arg1.tag === 1) ? (new Sort_SortDirection(0)) : (new Sort_SortDirection(1)));
}

export class Sort_Compare$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Compare"];
    }
}

export function Sort_Compare$1$reflection(gen0) {
    return union_type("ThreeDocs.SharedUI.Sort.Sort.Compare`1", [gen0], Sort_Compare$1, () => [[["Item", lambda_type(gen0, lambda_type(gen0, int32_type))]]]);
}

export function Sort_Compare$1_reverseSort_497FE845(_arg1) {
    return new Sort_Compare$1(0, (itemA, itemB) => _arg1.fields[0](itemB, itemA));
}

export function Sort_Compare$1_getSort_497FE845(_arg2) {
    return curry(2, _arg2.fields[0]);
}

export class Sort_SortableColumn$1 extends Record {
    constructor(header, mkCellContent, comparer) {
        super();
        this.header = header;
        this.mkCellContent = mkCellContent;
        this.comparer = comparer;
    }
}

export function Sort_SortableColumn$1$reflection(gen0) {
    return record_type("ThreeDocs.SharedUI.Sort.Sort.SortableColumn`1", [gen0], Sort_SortableColumn$1, () => [["header", string_type], ["mkCellContent", lambda_type(gen0, string_type)], ["comparer", Sort_Compare$1$reflection(gen0)]]);
}

export class Sort_SortInfo$1 extends Record {
    constructor(lastClicked, sortDirection) {
        super();
        this.lastClicked = lastClicked;
        this.sortDirection = sortDirection;
    }
}

export function Sort_SortInfo$1$reflection(gen0) {
    return record_type("ThreeDocs.SharedUI.Sort.Sort.SortInfo`1", [gen0], Sort_SortInfo$1, () => [["lastClicked", Sort_SortableColumn$1$reflection(gen0)], ["sortDirection", Sort_SortDirection$reflection()]]);
}

export function Sort_SortInfo$1_getUpdatedSortInfo(currentClick, sortInfo) {
    return new Sort_SortInfo$1(currentClick, (sortInfo.lastClicked.header === currentClick.header) ? Sort_SortDirection_get_not()(sortInfo.sortDirection) : (new Sort_SortDirection(0)));
}

export function Sort_SortInfo$1_getSortComparison_4155F936(_arg1) {
    const comparer = _arg1.lastClicked.comparer;
    if (_arg1.sortDirection.tag === 1) {
        return Sort_Compare$1_getSort_497FE845(Sort_Compare$1_reverseSort_497FE845(comparer));
    }
    else {
        return Sort_Compare$1_getSort_497FE845(comparer);
    }
}

export function Sort_itemListSortable(itemType, columns, selectItem, items, extraItem, sortInfo, updateSortInfo) {
    let props_8, children_8, children_6, props_10, children_10;
    const mkId = (item_1) => (new HTMLAttr(99, `${itemType}${item_1}`));
    const children_12 = [table(ofArray([new TableOption(6, singleton(mkId("Table"))), new TableOption(1), new TableOption(2), new TableOption(4)]), ofArray([(props_8 = [mkId("TableHeader")], (children_8 = [(children_6 = toList(delay(() => map((column) => {
        const icon = (sortInfo.lastClicked.header === column.header) ? Fa_i(ofArray([(sortInfo.sortDirection.tag === 1) ? (new Fa_IconOption(11, "fas fa-chevron-down")) : (new Fa_IconOption(11, "fas fa-chevron-up")), new Fa_IconOption(14, singleton(["style", {
            paddingLeft: "10px",
            color: "unset",
        }]))]), []) : Helpers_nothing;
        const props_4 = [new HTMLAttr(64, "disable-select"), new DOMAttr(40, partialApply(1, updateSortInfo, [Sort_SortInfo$1_getUpdatedSortInfo(column, sortInfo)])), ["style", {
            cursor: "pointer",
            fontWeight: "bold",
        }]];
        return react.createElement("td", keyValueList(props_4, 1), column.header, icon);
    }, columns))), react.createElement("tr", {}, ...children_6))], react.createElement("thead", keyValueList(props_8, 1), ...children_8))), (props_10 = [mkId("TableBody")], (children_10 = map((item) => {
        const props_2 = toList(delay(() => append(singleton_1(new DOMAttr(40, partialApply(1, selectItem, [item]))), delay(() => append(singleton_1(["style", {
            cursor: "pointer",
        }]), delay(() => extraItem(item)))))));
        const children_2 = toList(delay(() => map((_arg1) => {
            const children = [_arg1.mkCellContent(item)];
            return react.createElement("td", {}, ...children);
        }, columns)));
        return react.createElement("tr", keyValueList(props_2, 1), ...children_2);
    }, items), react.createElement("tbody", keyValueList(props_10, 1), ...children_10)))]))];
    return react.createElement("div", {}, ...children_12);
}

