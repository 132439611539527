import { State_update, Types_Msg$4, State_init } from "../ThreeDocs.Client.StateMachine.Crud/CrudComponent.js";
import { CustomShared_get_currentRoles_, PracticesUpdateArgs, CustomShared, PracticesCrudConfig_practiceVmModelConfig, PracticesCrudConfig_practiceVmViewConfig, PracticesCrudConfig_generalViewLayoutConfig } from "./PracticesCrudConfig.js";
import { ofArray, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Msg, Model } from "./PracticesTypes.js";
import { Cmd_batch, Cmd_none, Cmd_map } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Permission, isAllowed } from "../ThreeDocs.Models/Security.js";
import { Cmd_OfFunc_result, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedApi } from "../Api.js";
import { error } from "../ThreeDocs.SharedUI/Toast.js";
import { ErrorMessage_get_describe } from "../ThreeDocs.Models/Api/Api.js";
import { Shared_CrudInbound$2 } from "../ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.3.0.5/navigation.fs.js";
import { Page, pageHash } from "../Pages.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";

export function init() {
    const patternInput = State_init(PracticesCrudConfig_generalViewLayoutConfig(PracticesCrudConfig_practiceVmViewConfig.viewListItemConfig.listColumns, PracticesCrudConfig_practiceVmModelConfig.isSameItem), PracticesCrudConfig_practiceVmViewConfig, PracticesCrudConfig_practiceVmModelConfig, new CustomShared(empty()));
    return [new Model(patternInput[0]), Cmd_map((arg0) => (new Msg(5, arg0)), patternInput[1])];
}

export function update(token, userInfo, msg, model) {
    if (msg.tag === 2) {
        if (isAllowed(new Permission(10))(userInfo.roles)) {
            return [model, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(token).getPractices, void 0, (arg0_4) => (new Msg(3, arg0_4)), (arg0_5) => (new Msg(0, arg0_5)))];
        }
        else {
            return [model, Cmd_none()];
        }
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            return [model, error(ErrorMessage_get_describe()(msg.fields[0].fields[0]))];
        }
        else {
            return [model, Cmd_OfFunc_result(new Msg(5, new Types_Msg$4(2, new Shared_CrudInbound$2(0, msg.fields[0].fields[0]))))];
        }
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            return [model, Cmd_OfFunc_result(new Msg(2))];
        }
        else {
            return [model, Cmd_none()];
        }
    }
    else if (msg.tag === 0) {
        console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
        return [model, Cmd_none()];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [model, error("An unexpected error occurred. Please try again.")];
            }
            else if (msg.fields[0].fields[0].tag === 2) {
                return [model, Navigation_newUrl(pageHash(new Page(6, msg.fields[0].fields[0].fields[0])))];
            }
            else if (msg.fields[0].fields[0].tag === 3) {
                return [model, Navigation_newUrl(pageHash(new Page(8, msg.fields[0].fields[0].fields[0])))];
            }
            else {
                return [model, error(ErrorMessage_get_describe()(msg.fields[0].fields[0].fields[0]))];
            }
        }
        else {
            const patternInput = State_update(new PracticesUpdateArgs(token), msg.fields[0], model.PracticeCrudModel);
            return [new Model(patternInput[0]), Cmd_map((arg0_9) => (new Msg(5, arg0_9)), patternInput[1])];
        }
    }
    else {
        return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(2)), Cmd_OfFunc_result(new Msg(5, new Types_Msg$4(2, new Shared_CrudInbound$2(4, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_currentRoles_())(userInfo.roles)))))]))];
    }
}

