import { Union, Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { tuple_type, list_type, record_type, lambda_type, class_type, union_type, unit_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.12/Choice.js";
import { StateResultMsg$6, StateMachineMsg$5$reflection } from "../../fable_modules/Webbler.StateMachine.Core.1.1.0/StateMachine.fs.js";
import { Cmd_map, Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { CrudControls_ButtonClick, CrudControls_itemWithBoldLabel, Shared_SharedCrudModel$4$reflection, CrudControls_DetailField$2$reflection, CrudControls_ButtonClick$reflection, Shared_updateInboundModification, Shared_SharedCrudModel$4 } from "../SharedCrud.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { partialApply } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { printf, toText } from "../../fable_modules/fable-library.3.7.12/String.js";
import { some } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { delay, toList } from "../../fable_modules/fable-library.3.7.12/Seq.js";
import { map } from "../../fable_modules/fable-library.3.7.12/List.js";
import * as react from "react";

export class Model$4 extends Record {
    constructor(selected, delete$) {
        super();
        this.selected = selected;
        this.delete = delete$;
    }
}

export function Model$4$reflection(gen0, gen1, gen2, gen3) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDeleteConfirmation.Model`4", [gen0, gen1, gen2, gen3], Model$4, () => [["selected", gen0], ["delete", lambda_type(gen2, lambda_type(gen0, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, gen1], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", gen1]]])])))]]);
}

export class InternalMsg$4 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Cancel", "Delete", "DeleteResult", "ExceptionError", "SendOutbound"];
    }
}

export function InternalMsg$4$reflection(gen0, gen1, gen2, gen3) {
    return union_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDeleteConfirmation.InternalMsg`4", [gen0, gen1, gen2, gen3], InternalMsg$4, () => [[], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, gen1], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", gen1]]])]], [["Item", class_type("System.Exception")]], [["Item", gen1]]]);
}

export class Transitions$8 extends Record {
    constructor(Cancel, ItemDeleted) {
        super();
        this.Cancel = Cancel;
        this.ItemDeleted = ItemDeleted;
    }
}

export function Transitions$8$reflection(gen0, gen1, gen2, gen3, gen4, gen5, gen6, gen7) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDeleteConfirmation.Transitions`8", [gen0, gen1, gen2, gen3, gen4, gen5, gen6, gen7], Transitions$8, () => [["Cancel", lambda_type(gen1, lambda_type(Model$4$reflection(gen7, gen4, gen5, gen6), tuple_type(gen0, list_type(lambda_type(lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), unit_type), unit_type)), gen1)))], ["ItemDeleted", lambda_type(gen1, lambda_type(Model$4$reflection(gen7, gen4, gen5, gen6), tuple_type(gen0, list_type(lambda_type(lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), unit_type), unit_type)), gen1)))]]);
}

export function init(shared, selected) {
    return [new Model$4(selected, shared.ItemConfig.serverCalls.deleteItem), Cmd_none(), shared];
}

export function updateInbound(transitions, token, msg, sharedModel, model) {
    switch (msg.tag) {
        case 3: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(sharedModel.ItemList, sharedModel.Search, sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, msg.fields[0])];
        }
        case 4: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(sharedModel.ItemList, sharedModel.Search, sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, msg.fields[0](sharedModel.CustomStorage))];
        }
        case 1: {
            return [model, Cmd_none(), Shared_updateInboundModification(msg.fields[0], sharedModel)];
        }
        case 2: {
            return [model, Cmd_none(), sharedModel];
        }
        default: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(msg.fields[0], sharedModel.Search, sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, sharedModel.CustomStorage)];
        }
    }
}

export function update(transitions, token, msg, sharedModel, model) {
    if (msg.tag === 0) {
        return [model, Cmd_OfFunc_result(new StateResultMsg$6(1, transitions.Cancel(sharedModel, model))), sharedModel];
    }
    else if (msg.tag === 1) {
        return [model, Cmd_map((arg0_4) => (new StateResultMsg$6(0, arg0_4)), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, partialApply(1, model.delete, [token]), model.selected, (arg0_2) => (new InternalMsg$4(2, arg0_2)), (arg0_3) => (new InternalMsg$4(3, arg0_3)))), sharedModel];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            return [model, Cmd_OfFunc_result(new StateResultMsg$6(2, msg.fields[0].fields[0])), sharedModel];
        }
        else {
            return [model, Cmd_OfFunc_result(new StateResultMsg$6(1, transitions.ItemDeleted(sharedModel, model))), sharedModel];
        }
    }
    else if (msg.tag === 4) {
        return [model, Cmd_OfFunc_result(new StateResultMsg$6(2, msg.fields[0])), sharedModel];
    }
    else {
        console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
        return [model, Cmd_OfFunc_result(new StateResultMsg$6(2, sharedModel.ItemConfig.exceptionToOutbound(msg.fields[0]))), sharedModel];
    }
}

export class Buttons extends Record {
    constructor(cancel, delete$) {
        super();
        this.cancel = cancel;
        this.delete = delete$;
    }
}

export function Buttons$reflection() {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDeleteConfirmation.Buttons", [], Buttons, () => [["cancel", CrudControls_ButtonClick$reflection()], ["delete", CrudControls_ButtonClick$reflection()]]);
}

export class FormViewConfig$2 extends Record {
    constructor(detailFields) {
        super();
        this.detailFields = detailFields;
    }
}

export function FormViewConfig$2$reflection(gen0, gen1) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDeleteConfirmation.FormViewConfig`2", [gen0, gen1], FormViewConfig$2, () => [["detailFields", list_type(CrudControls_DetailField$2$reflection(gen0, gen1))]]);
}

export class ViewLayoutConfig$4 extends Record {
    constructor(confirmationViewParentElement) {
        super();
        this.confirmationViewParentElement = confirmationViewParentElement;
    }
}

export function ViewLayoutConfig$4$reflection(gen0, gen1, gen2, gen3) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDeleteConfirmation.ViewLayoutConfig`4", [gen0, gen1, gen2, gen3], ViewLayoutConfig$4, () => [["confirmationViewParentElement", lambda_type(FormViewConfig$2$reflection(gen0, gen3), lambda_type(Shared_SharedCrudModel$4$reflection(gen0, gen1, gen2, gen3), lambda_type(Model$4$reflection(gen0, gen1, gen2, gen3), lambda_type(class_type("Fable.React.ReactElement"), lambda_type(Buttons$reflection(), lambda_type(lambda_type(gen1, unit_type), class_type("Fable.React.ReactElement")))))))]]);
}

export function makeConfirmationElement(config, activeItem, customStorage) {
    const children = toList(delay(() => map((field) => CrudControls_itemWithBoldLabel(field.label + ": ", field.mkDetailField(activeItem, customStorage)), config.detailFields)));
    return react.createElement("div", {}, ...children);
}

export function view(formConfig, viewLayoutConfig, sharedModel, model, dispatch) {
    const children = [viewLayoutConfig.confirmationViewParentElement(formConfig, sharedModel, model, makeConfirmationElement(formConfig, model.selected, sharedModel.CustomStorage), new Buttons(new CrudControls_ButtonClick("Cancel", (_arg1) => {
        dispatch(new InternalMsg$4(0));
    }), new CrudControls_ButtonClick("Delete", (_arg2) => {
        dispatch(new InternalMsg$4(1));
    })), (arg) => {
        dispatch(new InternalMsg$4(4, arg));
    })];
    return react.createElement("div", {}, ...children);
}

