import { Record } from "../fable-library.3.7.12/Types.js";
import { StateMachineMsg$5, StateResultMsg$6$reflection } from "./StateMachine.fs.js";
import { record_type, class_type, tuple_type, list_type, lambda_type, unit_type } from "../fable-library.3.7.12/Reflection.js";
import { Cmd_map } from "../Fable.Elmish.3.1.0/cmd.fs.js";

export class StateMachineSetup_StateCreation$10 extends Record {
    constructor(Init, Update, Inbound, Transitions, View, MakeTaggedStateModels, MakeStateMessage) {
        super();
        this.Init = Init;
        this.Update = Update;
        this.Inbound = Inbound;
        this.Transitions = Transitions;
        this.View = View;
        this.MakeTaggedStateModels = MakeTaggedStateModels;
        this.MakeStateMessage = MakeStateMessage;
    }
}

export function StateMachineSetup_StateCreation$10$reflection(gen0, gen1, gen2, gen3, gen4, gen5, gen6, gen7, gen8, gen9) {
    return record_type("Webbler.StateMachine.Core.StateMachineSetup.StateCreation`10", [gen0, gen1, gen2, gen3, gen4, gen5, gen6, gen7, gen8, gen9], StateMachineSetup_StateCreation$10, () => [["Init", lambda_type(gen1, lambda_type(gen9, tuple_type(gen8, list_type(lambda_type(lambda_type(StateResultMsg$6$reflection(gen0, gen1, gen2, gen3, gen4, gen6), unit_type), unit_type)), gen1)))], ["Update", lambda_type(gen7, lambda_type(gen5, lambda_type(gen6, lambda_type(gen1, lambda_type(gen8, tuple_type(gen8, list_type(lambda_type(lambda_type(StateResultMsg$6$reflection(gen0, gen1, gen2, gen3, gen4, gen6), unit_type), unit_type)), gen1))))))], ["Inbound", lambda_type(gen7, lambda_type(gen5, lambda_type(gen3, lambda_type(gen1, lambda_type(gen8, tuple_type(gen8, list_type(lambda_type(lambda_type(StateResultMsg$6$reflection(gen0, gen1, gen2, gen3, gen4, gen6), unit_type), unit_type)), gen1))))))], ["Transitions", gen7], ["View", lambda_type(gen1, lambda_type(gen8, lambda_type(lambda_type(gen6, unit_type), class_type("Fable.React.ReactElement"))))], ["MakeTaggedStateModels", lambda_type(gen8, gen0)], ["MakeStateMessage", lambda_type(gen6, gen2)]]);
}

function StateMachineSetup_wrapCommand(toMsg, toModel, stateModel, stateCommand, sharedModel) {
    const wrappedCommand = Cmd_map((commandHolder) => {
        switch (commandHolder.tag) {
            case 1: {
                return new StateMachineMsg$5(1, commandHolder.fields[0]);
            }
            case 2: {
                return new StateMachineMsg$5(3, commandHolder.fields[0]);
            }
            default: {
                return new StateMachineMsg$5(0, toMsg(commandHolder.fields[0]));
            }
        }
    }, stateCommand);
    return [toModel(stateModel), wrappedCommand, sharedModel];
}

export function StateMachineSetup_makeTransition(toMsg, toModel, initResults_0, initResults_1, initResults_2) {
    const initResults = [initResults_0, initResults_1, initResults_2];
    return StateMachineSetup_wrapCommand(toMsg, toModel, initResults[0], initResults[1], initResults[2]);
}

export function StateMachineSetup_runUpdate(state, model, msg, token, sharedModel) {
    const updated = state.Update(state.Transitions, token, msg, sharedModel, model);
    return StateMachineSetup_wrapCommand(state.MakeStateMessage, state.MakeTaggedStateModels, updated[0], updated[1], updated[2]);
}

export function StateMachineSetup_runInbound(state, model, msg, token, sharedModel) {
    const updated = state.Inbound(state.Transitions, token, msg, sharedModel, model);
    return StateMachineSetup_wrapCommand(state.MakeStateMessage, state.MakeTaggedStateModels, updated[0], updated[1], updated[2]);
}

