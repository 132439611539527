import { isEmpty, ofArray, singleton, empty, map } from "../fable_modules/fable-library.3.7.12/List.js";
import { Option, file as file_3, name as name_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/File.fs.js";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Button_visibilityControlled, ComponentVisibility, Button_visibilityControlledCustom } from "../ThreeDocs.SharedUI/ComponentVisibility.js";
import { DOMAttr, HTMLAttr, CSSProp } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { button, Option as Option_1 } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Modifier_IModifier, Text_span, Common_GenericOption, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { FileManagement, FileDownload, LocalMsg } from "./FileManagementTypes.js";
import { Option as Option_5, icon } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import * as react from "react";
import { Option as Option_2, progress } from "../fable_modules/Fulma.2.16.0/Elements/Progress.fs.js";
import { split, printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { panel, Block_a } from "../fable_modules/Fulma.2.16.0/Components/Panel.fs.js";
import { Item_Option, right, item, left, Level_Option, level } from "../fable_modules/Fulma.2.16.0/Layouts/Level.fs.js";
import { toString } from "../fable_modules/fable-library.3.7.12/Types.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_3, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { last } from "../fable_modules/fable-library.3.7.12/Array.js";
import { div as div_1, p } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option as Option_4, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { FSharpMap__get_IsEmpty, map as map_1, toList as toList_1 } from "../fable_modules/fable-library.3.7.12/Map.js";
import { InOut_wrapLocalMsg } from "../Common/Common.js";
import { h5 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { FileComponentVisibility_downloadOnly_Z3014299D } from "./Shared.js";

export function uploadControl(mkSelectorView, fileComponentVisibility, uploadSelection, maybeFileTypeRestrictions, dispatch) {
    let uploadSelectorElement_1, filesFormatted, children_2;
    const children_4 = [(uploadSelectorElement_1 = mkSelectorView(maybeFileTypeRestrictions), (filesFormatted = map((fileData) => name_1(empty(), singleton(fileData.file.name)), uploadSelection), file_3(ofArray([new Option(9), new Option(10)]), singleton((children_2 = toList(delay(() => append(singleton_1(uploadSelectorElement_1), delay(() => {
        let matchValue;
        return append(singleton_1(Button_visibilityControlledCustom(singleton(new CSSProp(221, "0")), (matchValue = fileComponentVisibility.uploadButtonVisibility, (matchValue.tag === 0) ? (isEmpty(uploadSelection) ? (new ComponentVisibility(2, "No files selected for upload")) : (new ComponentVisibility(0))) : matchValue), ofArray([new Option_1(2), new Option_1(0, new Color_IColor(6)), new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg1) => {
            dispatch(new LocalMsg(7, uploadSelection));
        })]))]), ofArray([icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-upload")), []))), react.createElement("span", {}, "Step 2: Upload")]))), delay(() => filesFormatted));
    })))), react.createElement("div", {
        className: "file-layout",
    }, ...children_2))))))];
    return react.createElement("div", {
        className: "block",
    }, ...children_4);
}

export function progressPanelItem(name, width) {
    const progressBar = (width === 0) ? "Waiting..." : progress(ofArray([new Option_2(4, 100), new Option_2(3, ~(~width))]), singleton(toText(printf("%f%%"))(width)));
    return Block_a(empty(), singleton(level(singleton(new Level_Option(0, singleton(["style", {
        flexGrow: 1,
    }]))), ofArray([left(empty(), singleton(item(empty(), singleton(toString(name))))), right(singleton(new Common_GenericOption(1, singleton(["style", {
        flexGrow: 0.75,
    }]))), singleton(item(singleton(new Item_Option(0, singleton(["style", {
        flexGrow: 1,
    }]))), singleton(progressBar))))]))));
}

export function deleteConfirmationModal(model, s3Vm, dispatchLocal) {
    return modal(singleton(new Option_3(1, true)), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton("Delete")))), Card_body(empty(), singleton(model.DisplayContent.DeleteWarning)), Card_foot(empty(), ofArray([button(singleton(new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg1) => {
        dispatchLocal(new LocalMsg(18));
    })]))), singleton("Cancel")), button(ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg2) => {
        dispatchLocal(new LocalMsg(19, s3Vm));
    })]))]), singleton("Delete"))]))]))]));
}

export function listOfFiles(model, fileUploads, fileDownloads_1, failedFiles, persistedFiles, _arg1, dispatch) {
    let failedFiles_1, files, fileList, matchValue, children_10;
    let deleteModal;
    const matchValue_1 = model.MaybeS3VmToDelete;
    deleteModal = ((matchValue_1 != null) ? deleteConfirmationModal(model, matchValue_1, dispatch) : Helpers_nothing);
    const children_12 = [(failedFiles_1 = failedFiles, (files = fileUploads, (fileList = ((matchValue = [files, failedFiles_1, fileDownloads_1, persistedFiles], isEmpty(matchValue[0]) ? (isEmpty(matchValue[1]) ? (isEmpty(matchValue[2]) ? (isEmpty(matchValue[3]) ? singleton((children_10 = [react.createElement("div", {}, "No files have been uploaded yet")], react.createElement("div", {}, ...children_10))) : toList(delay(() => append(map((file_2) => {
        let _arg2_1;
        return progressPanelItem(last(split(file_2.Name, ["/"], null, 0)), (_arg2_1 = file_2.Progress, (_arg2_1.tag === 1) ? 100 : _arg2_1.fields[0]));
    }, files), delay(() => append(map((file_1) => Block_a(empty(), singleton(level(singleton(new Level_Option(0, singleton(["style", {
        flexGrow: 1,
    }]))), ofArray([left(empty(), ofArray([item(empty(), singleton(last(split(file_1, ["/"], null, 0)))), item(empty(), singleton(Text_span(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(1, new Color_IColor(8))))), singleton("Failed to upload"))))])), right(empty(), ofArray([item(empty(), singleton(p(empty(), singleton(button(ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg3) => {
        dispatch(new LocalMsg(21, file_1));
    })]))]), singleton("Retry")))))), item(empty(), singleton(p(empty(), singleton(button(singleton(new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg4) => {
        dispatch(new LocalMsg(22, file_1));
    })]))), singleton("Cancel"))))))]))])))), failedFiles_1), delay(() => map((file) => {
        let children_4, props_6;
        return Block_a(empty(), ofArray([(children_4 = [div(ofArray([new Option_4(5), new Option_4(11, singleton(["style", {
            marginLeft: "1.5rem",
        }]))]), ofArray([Button_visibilityControlled(_arg1.downloadButtonVisibility, singleton(new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg1_1) => {
            let tupledArg;
            dispatch((tupledArg = [1, file.FilePath], new LocalMsg(15, tupledArg[0], tupledArg[1])));
        })]))), singleton(icon(singleton(new Option_5(3, "tooltip")), ofArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-download")), []), react.createElement("span", {
            className: "tooltip-text",
        }, "Download File")])))), Button_visibilityControlled(_arg1.deleteButtonVisibility, ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg2) => {
            dispatch(new LocalMsg(17, file));
        })]))]), singleton(icon(singleton(new Option_5(3, "tooltip")), ofArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-trash")), []), react.createElement("span", {
            className: "tooltip-text",
        }, "Delete File")]))))]))], react.createElement("div", {}, ...children_4)), (props_6 = [new HTMLAttr(65, ""), ["style", {
            marginLeft: "1.0rem",
            whiteSpace: "nowrap",
            overflowX: "hidden",
            textOverflow: "ellipsis",
        }]], react.createElement("div", keyValueList(props_6, 1), file.FileName))]));
    }, persistedFiles)))))))) : toList(delay(() => append(map((file_2) => {
        let _arg2_1;
        return progressPanelItem(last(split(file_2.Name, ["/"], null, 0)), (_arg2_1 = file_2.Progress, (_arg2_1.tag === 1) ? 100 : _arg2_1.fields[0]));
    }, files), delay(() => append(map((file_1) => Block_a(empty(), singleton(level(singleton(new Level_Option(0, singleton(["style", {
        flexGrow: 1,
    }]))), ofArray([left(empty(), ofArray([item(empty(), singleton(last(split(file_1, ["/"], null, 0)))), item(empty(), singleton(Text_span(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(1, new Color_IColor(8))))), singleton("Failed to upload"))))])), right(empty(), ofArray([item(empty(), singleton(p(empty(), singleton(button(ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg3) => {
        dispatch(new LocalMsg(21, file_1));
    })]))]), singleton("Retry")))))), item(empty(), singleton(p(empty(), singleton(button(singleton(new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg4) => {
        dispatch(new LocalMsg(22, file_1));
    })]))), singleton("Cancel"))))))]))])))), failedFiles_1), delay(() => map((file) => {
        let children_4, props_6;
        return Block_a(empty(), ofArray([(children_4 = [div(ofArray([new Option_4(5), new Option_4(11, singleton(["style", {
            marginLeft: "1.5rem",
        }]))]), ofArray([Button_visibilityControlled(_arg1.downloadButtonVisibility, singleton(new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg1_1) => {
            let tupledArg;
            dispatch((tupledArg = [1, file.FilePath], new LocalMsg(15, tupledArg[0], tupledArg[1])));
        })]))), singleton(icon(singleton(new Option_5(3, "tooltip")), ofArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-download")), []), react.createElement("span", {
            className: "tooltip-text",
        }, "Download File")])))), Button_visibilityControlled(_arg1.deleteButtonVisibility, ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg2) => {
            dispatch(new LocalMsg(17, file));
        })]))]), singleton(icon(singleton(new Option_5(3, "tooltip")), ofArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-trash")), []), react.createElement("span", {
            className: "tooltip-text",
        }, "Delete File")]))))]))], react.createElement("div", {}, ...children_4)), (props_6 = [new HTMLAttr(65, ""), ["style", {
            marginLeft: "1.0rem",
            whiteSpace: "nowrap",
            overflowX: "hidden",
            textOverflow: "ellipsis",
        }]], react.createElement("div", keyValueList(props_6, 1), file.FileName))]));
    }, persistedFiles)))))))) : toList(delay(() => append(map((file_2) => {
        let _arg2_1;
        return progressPanelItem(last(split(file_2.Name, ["/"], null, 0)), (_arg2_1 = file_2.Progress, (_arg2_1.tag === 1) ? 100 : _arg2_1.fields[0]));
    }, files), delay(() => append(map((file_1) => Block_a(empty(), singleton(level(singleton(new Level_Option(0, singleton(["style", {
        flexGrow: 1,
    }]))), ofArray([left(empty(), ofArray([item(empty(), singleton(last(split(file_1, ["/"], null, 0)))), item(empty(), singleton(Text_span(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(1, new Color_IColor(8))))), singleton("Failed to upload"))))])), right(empty(), ofArray([item(empty(), singleton(p(empty(), singleton(button(ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg3) => {
        dispatch(new LocalMsg(21, file_1));
    })]))]), singleton("Retry")))))), item(empty(), singleton(p(empty(), singleton(button(singleton(new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg4) => {
        dispatch(new LocalMsg(22, file_1));
    })]))), singleton("Cancel"))))))]))])))), failedFiles_1), delay(() => map((file) => {
        let children_4, props_6;
        return Block_a(empty(), ofArray([(children_4 = [div(ofArray([new Option_4(5), new Option_4(11, singleton(["style", {
            marginLeft: "1.5rem",
        }]))]), ofArray([Button_visibilityControlled(_arg1.downloadButtonVisibility, singleton(new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg1_1) => {
            let tupledArg;
            dispatch((tupledArg = [1, file.FilePath], new LocalMsg(15, tupledArg[0], tupledArg[1])));
        })]))), singleton(icon(singleton(new Option_5(3, "tooltip")), ofArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-download")), []), react.createElement("span", {
            className: "tooltip-text",
        }, "Download File")])))), Button_visibilityControlled(_arg1.deleteButtonVisibility, ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg2) => {
            dispatch(new LocalMsg(17, file));
        })]))]), singleton(icon(singleton(new Option_5(3, "tooltip")), ofArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-trash")), []), react.createElement("span", {
            className: "tooltip-text",
        }, "Delete File")]))))]))], react.createElement("div", {}, ...children_4)), (props_6 = [new HTMLAttr(65, ""), ["style", {
            marginLeft: "1.0rem",
            whiteSpace: "nowrap",
            overflowX: "hidden",
            textOverflow: "ellipsis",
        }]], react.createElement("div", keyValueList(props_6, 1), file.FileName))]));
    }, persistedFiles)))))))) : toList(delay(() => append(map((file_2) => {
        let _arg2_1;
        return progressPanelItem(last(split(file_2.Name, ["/"], null, 0)), (_arg2_1 = file_2.Progress, (_arg2_1.tag === 1) ? 100 : _arg2_1.fields[0]));
    }, files), delay(() => append(map((file_1) => Block_a(empty(), singleton(level(singleton(new Level_Option(0, singleton(["style", {
        flexGrow: 1,
    }]))), ofArray([left(empty(), ofArray([item(empty(), singleton(last(split(file_1, ["/"], null, 0)))), item(empty(), singleton(Text_span(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(1, new Color_IColor(8))))), singleton("Failed to upload"))))])), right(empty(), ofArray([item(empty(), singleton(p(empty(), singleton(button(ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg3) => {
        dispatch(new LocalMsg(21, file_1));
    })]))]), singleton("Retry")))))), item(empty(), singleton(p(empty(), singleton(button(singleton(new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg4) => {
        dispatch(new LocalMsg(22, file_1));
    })]))), singleton("Cancel"))))))]))])))), failedFiles_1), delay(() => map((file) => {
        let children_4, props_6;
        return Block_a(empty(), ofArray([(children_4 = [div(ofArray([new Option_4(5), new Option_4(11, singleton(["style", {
            marginLeft: "1.5rem",
        }]))]), ofArray([Button_visibilityControlled(_arg1.downloadButtonVisibility, singleton(new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg1_1) => {
            let tupledArg;
            dispatch((tupledArg = [1, file.FilePath], new LocalMsg(15, tupledArg[0], tupledArg[1])));
        })]))), singleton(icon(singleton(new Option_5(3, "tooltip")), ofArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-download")), []), react.createElement("span", {
            className: "tooltip-text",
        }, "Download File")])))), Button_visibilityControlled(_arg1.deleteButtonVisibility, ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(17, ofArray([new HTMLAttr(159, "button"), new DOMAttr(40, (_arg2) => {
            dispatch(new LocalMsg(17, file));
        })]))]), singleton(icon(singleton(new Option_5(3, "tooltip")), ofArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-trash")), []), react.createElement("span", {
            className: "tooltip-text",
        }, "Delete File")]))))]))], react.createElement("div", {}, ...children_4)), (props_6 = [new HTMLAttr(65, ""), ["style", {
            marginLeft: "1.0rem",
            whiteSpace: "nowrap",
            overflowX: "hidden",
            textOverflow: "ellipsis",
        }]], react.createElement("div", keyValueList(props_6, 1), file.FileName))]));
    }, persistedFiles))))))))), panel(empty(), toList(delay(() => fileList)))))), deleteModal];
    return react.createElement("div", {}, ...children_12);
}

export function fileDownloads(files) {
    return map((tuple) => tuple[1], toList_1(map_1((_arg1, x) => (new FileDownload(x.Name)), files)));
}

export function downloadAndUpload(model, downloadableFiles, dispatch, fileComponentVisibility) {
    let files_1;
    return listOfFiles(model, map((tuple) => tuple[1], toList_1(map_1((_arg1, x) => (new FileManagement(x.Name, x.Progress)), model.FileManagementsInProgress))), downloadableFiles, (files_1 = model.FailedUploads, FSharpMap__get_IsEmpty(files_1) ? empty() : map((tuple_1) => tuple_1[0], toList_1(files_1))), model.PathTestFiles, fileComponentVisibility, dispatch);
}

export function view(mkUploadSelector, fileComponentVisibility, model, dispatch, maybeMkExtraElements) {
    const downloadableFiles = fileDownloads(model.DownloadableFiles);
    const dispatchLocal = (arg) => {
        dispatch(InOut_wrapLocalMsg(arg));
    };
    const extraElements = (maybeMkExtraElements == null) ? empty() : maybeMkExtraElements(model);
    const mainDisplay = (fileComponentVisibility.uploadComponentVisibility.tag === 1) ? listOfFiles(model, empty(), downloadableFiles, empty(), model.PathTestFiles, fileComponentVisibility, dispatchLocal) : div_1(empty(), toList(delay(() => append(extraElements, delay(() => append(singleton_1(uploadControl(mkUploadSelector, fileComponentVisibility, model.UploadSelection, model.Specs.maybeFileTypeRestrictions, dispatchLocal)), delay(() => append(singleton_1(h5(empty())(singleton("Files"))), delay(() => singleton_1(downloadAndUpload(model, downloadableFiles, dispatchLocal, fileComponentVisibility)))))))))));
    return react.createElement("div", {}, mainDisplay);
}

export function viewDownloadOnly(model, dispatch) {
    return listOfFiles(model, empty(), fileDownloads(model.DownloadableFiles), empty(), model.PathTestFiles, FileComponentVisibility_downloadOnly_Z3014299D(new ComponentVisibility(0)), (arg) => {
        dispatch(InOut_wrapLocalMsg(arg));
    });
}

export function splitViewDownloadAndUpload(mkUploadSelectors, fileComponentVisibility, model, dispatch) {
    const downloadableFiles = fileDownloads(model.DownloadableFiles);
    const dispatchLocal = (arg) => {
        dispatch(InOut_wrapLocalMsg(arg));
    };
    return [uploadControl(mkUploadSelectors, fileComponentVisibility, model.UploadSelection, model.Specs.maybeFileTypeRestrictions, dispatchLocal), downloadAndUpload(model, downloadableFiles, dispatchLocal, fileComponentVisibility)];
}

