import { Common_EmailAddress_value_Z39D6D3C1, Security_UserInfo$2, Common_Name_create, Common_EmailAddress_create_Z721C83C5, Security_UserViewModel$2, Security_UserViewModel$2_empty_1505 } from "../../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { ofArray, singleton, tail, head, contains, isEmpty, empty } from "../../fable_modules/fable-library.3.7.12/List.js";
import { UserRole, UserData, PermissionRestriction } from "../Security.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, Result_ofOption, Result_mapError, Result_bind, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Validate_required, Validate_String_notNullOrWhiteSpace, Operators_op_GreaterEqualsGreater, FormValidationError } from "../../ThreeDocs.SharedUI/FormValidation.js";
import { ofNullable } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.12/Choice.js";
import { safeHash, equals } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { Specialty } from "../Domain/Specialty.js";
import { List_containsOneOf } from "../../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";

export const UserViewModelModule_empty = Security_UserViewModel$2_empty_1505(new UserData(empty(), empty(), void 0, new PermissionRestriction(0), false));

export const UserViewModelModule_userData_ = [(m) => m.userData, (v) => ((m_1) => (new Security_UserViewModel$2(m_1.id, m_1.email, m_1.name, m_1.roles, v)))];

export function UserViewModelModule_toDomain(uvm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Result_bind()((email) => Result_mapError()(() => (new FormValidationError(0, "Login Email Email", "be a valid email address")))(Common_EmailAddress_create_Z721C83C5(email)))(Operators_op_GreaterEqualsGreater(Validate_String_notNullOrWhiteSpace, Validate_required)("Login Email address")(ofNullable(uvm.email))), (_arg1_1) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Operators_op_GreaterEqualsGreater(Validate_String_notNullOrWhiteSpace, Validate_required)("First name")(ofNullable(uvm.name.given)), (_arg2) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Operators_op_GreaterEqualsGreater(Validate_String_notNullOrWhiteSpace, Validate_required)("Last name")(ofNullable(uvm.name.family)), (_arg3) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Result_ofOption(new FormValidationError(0, "Reply-To Email", "be a valid email address"), uvm.userData.ReplyToEmail), (_arg4) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Operators_op_GreaterEqualsGreater((f, _arg5) => (isEmpty(_arg5) ? (new FSharpResult$2(1, new FormValidationError(0, f, "not be null or empty"))) : (new FSharpResult$2(0, _arg5))), Validate_required)("Role")(uvm.roles), (_arg6) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Operators_op_GreaterEqualsGreater((f_1, specialties) => (contains(new UserRole(0), uvm.roles, {
        Equals: equals,
        GetHashCode: safeHash,
    }) ? ((!isEmpty(specialties)) ? ((head(specialties).tag === 82) ? (isEmpty(tail(specialties)) ? (new FSharpResult$2(0, singleton(new Specialty(82)))) : (new FSharpResult$2(1, new FormValidationError(0, f_1, "be Other for 3Docs Admins")))) : (new FSharpResult$2(1, new FormValidationError(0, f_1, "be Other for 3Docs Admins")))) : (new FSharpResult$2(1, new FormValidationError(0, f_1, "not be empty")))) : (isEmpty(specialties) ? (new FSharpResult$2(1, new FormValidationError(0, f_1, "not be empty"))) : (contains(new Specialty(82), specialties, {
        Equals: equals,
        GetHashCode: safeHash,
    }) ? (new FSharpResult$2(1, new FormValidationError(0, f_1, "not contain Other"))) : (new FSharpResult$2(0, specialties))))), Validate_required)("Specialty")(uvm.userData.Specialty), (_arg7) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Operators_op_GreaterEqualsGreater((f_2, practiceIds) => (List_containsOneOf(ofArray([new UserRole(1), new UserRole(2), new UserRole(3)]), uvm.roles) ? (isEmpty(practiceIds) ? (new FSharpResult$2(1, new FormValidationError(0, f_2, "not be empty"))) : (new FSharpResult$2(0, practiceIds))) : (new FSharpResult$2(0, practiceIds))), Validate_required)("Practices")(uvm.userData.Practices), (_arg8) => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, new Security_UserInfo$2(uvm.id, _arg1_1, Common_Name_create(_arg2, _arg3), _arg6, uvm.userData)))))))))));
}

export function UserViewModelModule_ofDomain(userInfo) {
    return new Security_UserViewModel$2(userInfo.id, Common_EmailAddress_value_Z39D6D3C1(userInfo.email), userInfo.name, userInfo.roles, userInfo.userData);
}

