import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { MediaViewModel$reflection } from "../ThreeDocs.Models/View/MediaViewModel.js";
import { lambda_type, list_type, unit_type, record_type, option_type, class_type, string_type, union_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { S3Context$reflection } from "../Common/AwsCommon.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Sort_SortInfo$1$reflection } from "../ThreeDocs.SharedUI/Sort.js";
import { UserRole$reflection } from "../ThreeDocs.Models/Security.js";
import { OutboundMsg$reflection, InboundMsg$reflection, LocalMsg$reflection, Model$reflection as Model$reflection_1 } from "../FileManagement/FileManagementTypes.js";
import { ChainedChildMsg$2$reflection, ChildAbsentStrategy$1$reflection, DefaultActions_Types_ChildModel$2$reflection } from "../Common/Terminate.js";
import { OutboundMsg$reflection as OutboundMsg$reflection_1, InboundMsg$reflection as InboundMsg$reflection_1, LocalMsg$reflection as LocalMsg$reflection_1, Model$reflection as Model$reflection_2 } from "../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropTypes.js";
import { Notification$reflection, ErrorMessage$reflection } from "../ThreeDocs.Models/Api/Api.js";
import { InOut_Msg$3$reflection } from "../Common/Common.js";

export class ViewMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["List", "Detail", "Create", "Edit", "Delete"];
    }
}

export function ViewMode$reflection() {
    return union_type("ThreeDocs.Client.Media.Types.ViewMode", [], ViewMode, () => [[], [["Item", MediaViewModel$reflection()]], [], [["Item", MediaViewModel$reflection()]], []]);
}

export class MediaFilter extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GeneralMedia", "PracticeMedia", "AllMedia"];
    }
}

export function MediaFilter$reflection() {
    return union_type("ThreeDocs.Client.Media.Types.MediaFilter", [], MediaFilter, () => [[], [], []]);
}

export function MediaFilter_get_all() {
    return ofArray([new MediaFilter(0), new MediaFilter(1), new MediaFilter(2)]);
}

export function MediaFilter_get_describe() {
    return (_arg1) => ((_arg1.tag === 1) ? "Practice Media" : ((_arg1.tag === 2) ? "All Media" : "3Docs Media"));
}

export class MediaUpdateArgs extends Record {
    constructor(Token, MaybeCredentials, MaybeS3Context) {
        super();
        this.Token = Token;
        this.MaybeCredentials = MaybeCredentials;
        this.MaybeS3Context = MaybeS3Context;
    }
}

export function MediaUpdateArgs$reflection() {
    return record_type("ThreeDocs.Client.Media.Types.MediaUpdateArgs", [], MediaUpdateArgs, () => [["Token", string_type], ["MaybeCredentials", option_type(class_type("Fable.Import.Aws.Credentials.Credentials"))], ["MaybeS3Context", option_type(S3Context$reflection())]]);
}

export class Model extends Record {
    constructor(ViewMode, MediaFilter, SelectedMedia, IsSelectedMediaValid, SortInfo, CurrentRoles, PracticeMedia, PublicMedia, dragDropFileManagementModel, dragDropModel) {
        super();
        this.ViewMode = ViewMode;
        this.MediaFilter = MediaFilter;
        this.SelectedMedia = SelectedMedia;
        this.IsSelectedMediaValid = IsSelectedMediaValid;
        this.SortInfo = SortInfo;
        this.CurrentRoles = CurrentRoles;
        this.PracticeMedia = PracticeMedia;
        this.PublicMedia = PublicMedia;
        this.dragDropFileManagementModel = dragDropFileManagementModel;
        this.dragDropModel = dragDropModel;
    }
}

export function Model$reflection() {
    return record_type("ThreeDocs.Client.Media.Types.Model", [], Model, () => [["ViewMode", ViewMode$reflection()], ["MediaFilter", MediaFilter$reflection()], ["SelectedMedia", MediaViewModel$reflection()], ["IsSelectedMediaValid", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, option_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", option_type(string_type)]]])], ["SortInfo", Sort_SortInfo$1$reflection(MediaViewModel$reflection())], ["CurrentRoles", list_type(UserRole$reflection())], ["PracticeMedia", list_type(MediaViewModel$reflection())], ["PublicMedia", list_type(MediaViewModel$reflection())], ["dragDropFileManagementModel", DefaultActions_Types_ChildModel$2$reflection(Model$reflection_1(), Model$reflection())], ["dragDropModel", Model$reflection_2()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "SetMediaVm", "SetViewMode", "SetMediaFilter", "UpdateSortInfo", "ValidateSelectedMedia", "CreateMedia", "CreateMediaResult", "UpdateMedia", "UpdateMediaResult", "DeleteMedia", "DeleteMediaResult", "GetPublicMedia", "GetPublicMediaResult", "GetMediaForPractice", "GetMediaForPracticeResult", "Notification", "CreateFileManagement", "TerminateFileManagement", "ClearDragDropChildrenLists", "DragDropFileManagementMsg", "DragDropMsg"];
    }
}

export function Msg$reflection() {
    return union_type("ThreeDocs.Client.Media.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [["Item", lambda_type(MediaViewModel$reflection(), MediaViewModel$reflection())]], [["Item", ViewMode$reflection()]], [["Item", MediaFilter$reflection()]], [["Item", Sort_SortInfo$1$reflection(MediaViewModel$reflection())]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [MediaViewModel$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", MediaViewModel$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [MediaViewModel$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", MediaViewModel$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ErrorMessage$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(MediaViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(MediaViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(MediaViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(MediaViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", Notification$reflection()]], [], [], [], [["Item", ChainedChildMsg$2$reflection(InOut_Msg$3$reflection(LocalMsg$reflection(), InboundMsg$reflection(), OutboundMsg$reflection()), ChildAbsentStrategy$1$reflection(Model$reflection()))]], [["Item", InOut_Msg$3$reflection(LocalMsg$reflection_1(), InboundMsg$reflection_1(), OutboundMsg$reflection_1())]]]);
}

