import { Union, Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { UserData$reflection, UserRole$reflection } from "../ThreeDocs.Models/Security.js";
import { Security_UserViewModel$2$reflection } from "../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { union_type, lambda_type, class_type, string_type, record_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";

export class InitArgs extends Record {
    constructor(user) {
        super();
        this.user = user;
    }
}

export function InitArgs$reflection() {
    return record_type("ThreeDocs.Client.EmailChange.Types.InitArgs", [], InitArgs, () => [["user", Security_UserViewModel$2$reflection(UserRole$reflection(), UserData$reflection())]]);
}

export class Model extends Record {
    constructor(currentEmail, newEmail) {
        super();
        this.currentEmail = currentEmail;
        this.newEmail = newEmail;
    }
}

export function Model$reflection() {
    return record_type("ThreeDocs.Client.EmailChange.Types.Model", [], Model, () => [["currentEmail", string_type], ["newEmail", string_type]]);
}

export function Model_get_currentEmail_() {
    return [(m) => m.currentEmail, (v) => ((m_1) => (new Model(v, m_1.newEmail)))];
}

export function Model_get_newEmail_() {
    return [(m) => m.newEmail, (v) => ((m_1) => (new Model(m_1.currentEmail, v)))];
}

export class LocalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "SetVm"];
    }
}

export function LocalMsg$reflection() {
    return union_type("ThreeDocs.Client.EmailChange.Types.LocalMsg", [], LocalMsg, () => [[["Item", class_type("System.Exception")]], [["Item", lambda_type(Model$reflection(), Model$reflection())]]]);
}

export class InboundMsg {
    constructor() {
    }
}

export function InboundMsg$reflection() {
    return class_type("ThreeDocs.Client.EmailChange.Types.InboundMsg", void 0, InboundMsg);
}

export class OutboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CancelEmailChange"];
    }
}

export function OutboundMsg$reflection() {
    return union_type("ThreeDocs.Client.EmailChange.Types.OutboundMsg", [], OutboundMsg, () => [[]]);
}

