import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { View_view } from "../ThreeDocs.Client.StateMachine.Crud/CrudComponent.js";
import { Msg } from "./InvitationsTypes.js";
import * as react from "react";

export function view(model, dispatch) {
    let children;
    return content(empty(), singleton((children = [View_view(model.InvitationCrudModel, (arg) => {
        dispatch(new Msg(8, arg));
    })], react.createElement("div", {}, ...children))));
}

