import { Record, FSharpRef, toString, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { Prop, HTMLAttr, DOMAttr, DOMAttr$reflection } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { record_type, string_type, class_type, lambda_type, unit_type, option_type, union_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { structuralHash, curry, equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { String_toOption } from "../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import { map as map_2, defaultArg, toNullable, bind, some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { toUniversalTime, tryParse, minValue, getUtcTicks, fromTicks } from "../fable_modules/fable-library.3.7.12/DateOffset.js";
import { Option as Option_2, Label_Option, label as label_1, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { contains, filter, map, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Naming_UIElement, Naming_UIElement__get_asString, Naming_mkIdFromStrings } from "../fable_modules/Webbler.Models.1.1.0/Common.fs.js";
import { isEmpty, cons, empty, singleton, ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { GroupType$1, SelectOptions_value, SelectOption$1, SelectPropsMulti$1 } from "./bindings/ReactSelectBind.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option, IInputType, input } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option as Option_1, textarea } from "../fable_modules/Fulma.2.16.0/Elements/Form/Textarea.fs.js";
import { fromDateTimeOffset, today, toString as toString_1 } from "../fable_modules/fable-library.3.7.12/Date.js";
import { map as map_1, tryFind, tryPick, equalsWith, tryFindIndex } from "../fable_modules/fable-library.3.7.12/Array.js";
import { isNullOrEmpty, printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import * as react from "react";
import react_select from "react-select";
import { EasyEditProps$1 } from "./bindings/ReactEasyEditBind.js";
import react_easy_edit from "react-easy-edit";

export class FormField_OnChangeField extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnChangeField"];
    }
}

export function FormField_OnChangeField$reflection() {
    return union_type("ThreeDocs.SharedUI.Forms.FormField.OnChangeField", [], FormField_OnChangeField, () => [[["Item", DOMAttr$reflection()]]]);
}

export function FormField_OnChangeField_get_getDomAttr() {
    return (_arg1) => _arg1.fields[0];
}

export function FormField_handleStringUpdate(e) {
    if (equals(String_toOption(e.target.value), void 0)) {
        return void 0;
    }
    else {
        return some(e.target.value);
    }
}

export function FormField_onChangeStringOptic(dispatchChange, optic_0, optic_1) {
    const optic = [optic_0, curry(2, optic_1)];
    return new FormField_OnChangeField(0, new DOMAttr(9, (e) => {
        dispatchChange((svm) => {
            let value;
            return ((value = FormField_handleStringUpdate(e), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value)))(svm);
        });
    }));
}

export function FormField_onChangeStringOtherOptic(dispatchChange, optic_0, optic_1) {
    const optic = [optic_0, curry(2, optic_1)];
    return new FormField_OnChangeField(0, new DOMAttr(9, (e) => {
        dispatchChange((svm) => {
            let value;
            return ((value = e.target.value, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value)))(svm);
        });
    }));
}

export function FormField_onChangeDateOptic(dispatchChange, optic_0, optic_1) {
    const optic = [optic_0, curry(2, optic_1)];
    return new FormField_OnChangeField(0, new DOMAttr(9, (e) => {
        dispatchChange((svm) => {
            let value_2, lastValue;
            return ((value_2 = (e.target.validity.badInput ? ((lastValue = Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(svm), (e.target.value = lastValue, lastValue))) : (equals(String_toOption(toString(e.target.value)), void 0) ? (void 0) : fromTicks(getUtcTicks(e.target.valueAsDate), 0))), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value_2)))(svm);
        });
    }));
}

export function FormField_onChangeNumberOptic(dispatchChange, optic_0, optic_1) {
    const optic = [optic_0, curry(2, optic_1)];
    return new FormField_OnChangeField(0, new DOMAttr(9, (e) => {
        dispatchChange((svm) => {
            let value_2, lastValue;
            return ((value_2 = (e.target.validity.badInput ? ((lastValue = Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(svm), (e.target.value = lastValue, lastValue))) : (equals(String_toOption(toString(e.target.value)), void 0) ? (void 0) : some(e.target.valueAsNumber))), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value_2)))(svm);
        });
    }));
}

export function FormField_onChangeSet(dispatchChange, overWrite) {
    return new FormField_OnChangeField(0, new DOMAttr(9, (e) => {
        dispatchChange((svm) => overWrite(svm, e.target.value));
    }));
}

export function FormField_parseDate(date) {
    let matchValue;
    let outArg = minValue();
    matchValue = [tryParse(date, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return toUniversalTime(matchValue[1]);
    }
    else {
        return minValue();
    }
}

export function FormField_onEnterKeyPress(disabled, dispatchMsg, e) {
    if ((!disabled) && ((e.char === "13") ? true : (e.which === 13))) {
        e.preventDefault();
        dispatchMsg();
    }
}

export function FormField_labeledField(modelName, label, fieldElement, props) {
    return div(toList(delay(() => props)), ofArray([label_1(singleton(new Label_Option(3, singleton(new SelectPropsMulti$1(11, Naming_mkIdFromStrings(ofArray([modelName, label, Naming_UIElement__get_asString(new Naming_UIElement(1, new Naming_UIElement(0)))])))))), singleton(label)), div_1(empty(), singleton(fieldElement))]));
}

export function FormField_textWithHint(hint, initialValue, extraProps, extraInputProps, _arg1, modelName, label) {
    const fieldElement = input(toList(delay(() => append(singleton_1(new Option(1, new IInputType(0))), delay(() => append(singleton_1(new Option(10, initialValue)), delay(() => append(singleton_1(new Option(12, hint)), delay(() => append(singleton_1(new Option(15, cons(_arg1.fields[0], extraProps))), delay(() => extraInputProps)))))))))));
    return (props) => FormField_labeledField(modelName, label, fieldElement, props);
}

export function FormField_text(initialValue, extraProps, extraInputProps, _arg1, modelName, label) {
    return FormField_textWithHint(label, initialValue, extraProps, extraInputProps, new FormField_OnChangeField(0, _arg1.fields[0]), modelName, label);
}

export function FormField_textArea(description, initialValue, extraProps, extraTextAreaProps, _arg1, modelName, label) {
    const fieldElement = textarea(toList(delay(() => append(singleton_1(new Option_1(13, description)), delay(() => append(singleton_1(new Option_1(11, initialValue)), delay(() => append(singleton_1(new Option_1(14, cons(_arg1.fields[0], extraProps))), delay(() => extraTextAreaProps)))))))), empty());
    return (props) => FormField_labeledField(modelName, label, fieldElement, props);
}

export function FormField_date(initialValue, extraProps, extraInputProps, _arg1, modelName, label) {
    const formatDate = (d) => toString_1(d, "yyyy-MM-dd");
    const maxDate = formatDate(today());
    const initDate = (initialValue == null) ? "" : formatDate(fromDateTimeOffset(initialValue, 0));
    const fieldElement = div_1(empty(), singleton(input(toList(delay(() => append(singleton_1(new Option(1, new IInputType(3))), delay(() => append(singleton_1(new Option(10, initDate)), delay(() => append(singleton_1(new Option(12, label)), delay(() => append(singleton_1(new Option(15, toList(delay(() => append(singleton_1(_arg1.fields[0]), delay(() => append(extraProps, delay(() => append(singleton_1(new HTMLAttr(114, maxDate)), delay(() => singleton_1(new HTMLAttr(119, "1900-01-01")))))))))))), delay(() => extraInputProps)))))))))))));
    return (props) => FormField_labeledField(modelName, label, fieldElement, props);
}

export function FormField_number(initialValue, extraProps, extraInputProps, _arg1, modelName, label) {
    const fieldElement = input(toList(delay(() => append(singleton_1(new Option(1, new IInputType(7))), delay(() => append(singleton_1(new Option(10, initialValue)), delay(() => append(singleton_1(new Option(12, label)), delay(() => append(singleton_1(new Option(15, cons(_arg1.fields[0], extraProps))), delay(() => extraInputProps)))))))))));
    return (props) => FormField_labeledField(modelName, label, fieldElement, props);
}

export class FormField_ReactSelect_CallbackSingle$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CallbackSingle"];
    }
}

export function FormField_ReactSelect_CallbackSingle$1$reflection(gen0) {
    return union_type("ThreeDocs.SharedUI.Forms.FormField.ReactSelect.CallbackSingle`1", [gen0], FormField_ReactSelect_CallbackSingle$1, () => [[["Item", lambda_type(option_type(gen0), unit_type)]]]);
}

export class FormField_ReactSelect_CallbackMulti$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CallbackMulti"];
    }
}

export function FormField_ReactSelect_CallbackMulti$1$reflection(gen0) {
    return union_type("ThreeDocs.SharedUI.Forms.FormField.ReactSelect.CallbackMulti`1", [gen0], FormField_ReactSelect_CallbackMulti$1, () => [[["Item", lambda_type(class_type("System.Collections.Generic.IEnumerable`1", [gen0]), unit_type)]]]);
}

export function FormField_ReactSelect_mkReactSelectIdString(modelName, label, element) {
    return Naming_mkIdFromStrings(ofArray([modelName, label, Naming_UIElement__get_asString(element)]));
}

export function FormField_ReactSelect_mkReactSelectId(modelName, label, element) {
    return new SelectPropsMulti$1(11, FormField_ReactSelect_mkReactSelectIdString(modelName, label, element));
}

export function FormField_ReactSelect_onChangeSingle(dispatchChange, optic_0, optic_1) {
    const optic = [optic_0, curry(2, optic_1)];
    return new FormField_ReactSelect_CallbackSingle$1(0, (newValue) => {
        dispatchChange((svm) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(newValue)(svm));
    });
}

export function FormField_ReactSelect_onChangeMulti(dispatchChange, optic_0, optic_1) {
    const optic = [optic_0, curry(2, optic_1)];
    return new FormField_ReactSelect_CallbackMulti$1(0, (newValue) => {
        dispatchChange((svm) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(newValue)(svm));
    });
}

export function FormField_ReactSelect_deselectable(availableToSelect, extraSelectProps, optionLabel, currentlySelected, _arg1, fieldLabel, modelName, fieldProps) {
    const changeCallback = _arg1.fields[0];
    const canSelect = Array.from(map((selection) => (new SelectOption$1(optionLabel(selection), selection)), availableToSelect));
    let defaultSelection;
    const matchValue = bind((selected) => tryFindIndex((select) => equals(SelectOptions_value(select), selected), canSelect), currentlySelected);
    if (matchValue == null) {
        defaultSelection = empty();
    }
    else {
        const index = matchValue | 0;
        defaultSelection = singleton(new SelectPropsMulti$1(3, [canSelect[index]]));
    }
    let key;
    const arg20 = isEmpty(defaultSelection) ? "empty" : "full";
    key = toText(printf("%s-%s"))(fieldLabel)(arg20);
    let reactSelect;
    const props = toList(delay(() => append(defaultSelection, delay(() => append(extraSelectProps, delay(() => append(singleton_1(new SelectPropsMulti$1(1, canSelect)), delay(() => append(singleton_1(new SelectPropsMulti$1(11, FormField_ReactSelect_mkReactSelectIdString(modelName, fieldLabel, new Naming_UIElement(4)))), delay(() => append(singleton_1(new SelectPropsMulti$1(8, false)), delay(() => append(singleton_1(new SelectPropsMulti$1(9, true)), delay(() => singleton_1(new SelectPropsMulti$1(4, (value) => {
        if (equalsWith(equals, value, null)) {
            changeCallback(void 0);
        }
        else {
            changeCallback(some(value.value));
        }
    }))))))))))))))));
    const props_1 = keyValueList(props, 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(toList(delay(() => append(singleton_1(new Option_2(11, singleton(new Prop(0, key)))), delay(() => fieldProps)))), ofArray([label_1(singleton(new Label_Option(3, singleton(FormField_ReactSelect_mkReactSelectId(modelName, fieldLabel, new Naming_UIElement(1, new Naming_UIElement(4)))))), singleton(fieldLabel)), reactSelect]));
}

export function FormField_ReactSelect_single(availableToSelect, extraSelectProps, optionLabel, currentlySelected, _arg1, fieldLabel, modelName, fieldProps) {
    const canSelect = Array.from(map((selection) => (new SelectOption$1(optionLabel(selection), selection)), availableToSelect));
    let defaultSelection;
    const matchValue = bind((selected) => tryFindIndex((select) => (optionLabel(SelectOptions_value(select)) === optionLabel(selected)), canSelect), currentlySelected);
    if (matchValue == null) {
        defaultSelection = empty();
    }
    else {
        const index = matchValue | 0;
        defaultSelection = singleton(new SelectPropsMulti$1(3, [canSelect[index]]));
    }
    let key;
    const arg20 = isEmpty(defaultSelection) ? "empty" : "full";
    key = toText(printf("%s-%s"))(fieldLabel)(arg20);
    let reactSelect;
    const props = toList(delay(() => append(defaultSelection, delay(() => append(extraSelectProps, delay(() => append(singleton_1(new SelectPropsMulti$1(1, canSelect)), delay(() => append(singleton_1(new SelectPropsMulti$1(8, false)), delay(() => singleton_1(new SelectPropsMulti$1(4, (filledItem) => {
        _arg1.fields[0](some(filledItem.value));
    }))))))))))));
    const props_1 = keyValueList(props, 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(toList(delay(() => append(singleton_1(new Option_2(11, singleton(new Prop(0, key)))), delay(() => fieldProps)))), ofArray([label_1(singleton(new Label_Option(3, singleton(FormField_ReactSelect_mkReactSelectId(modelName, fieldLabel, new Naming_UIElement(1, new Naming_UIElement(4)))))), singleton(fieldLabel)), reactSelect]));
}

export class FormField_ReactSelect_LabelledGroup$1 extends Record {
    constructor(groupLabel, values) {
        super();
        this.groupLabel = groupLabel;
        this.values = values;
    }
}

export function FormField_ReactSelect_LabelledGroup$1$reflection(gen0) {
    return record_type("ThreeDocs.SharedUI.Forms.FormField.ReactSelect.LabelledGroup`1", [gen0], FormField_ReactSelect_LabelledGroup$1, () => [["groupLabel", string_type], ["values", class_type("System.Collections.Generic.IEnumerable`1", [gen0])]]);
}

export function FormField_ReactSelect_singleGrouped(availableToSelect, extraSelectProps, optionLabel, currentlySelected, _arg1, fieldLabel, modelName, fieldProps) {
    const canSelect = Array.from(map((groupInfo) => (new GroupType$1(groupInfo.groupLabel, Array.from(map((selection) => (new SelectOption$1(optionLabel(selection), selection)), groupInfo.values)))), availableToSelect));
    let defaultSelection;
    const matchValue = bind((selected) => tryPick((group) => tryFind((select) => (optionLabel(SelectOptions_value(select)) === optionLabel(selected)), group.options), canSelect), currentlySelected);
    defaultSelection = ((matchValue == null) ? empty() : singleton(new SelectPropsMulti$1(3, [matchValue])));
    let key;
    const arg20 = isEmpty(defaultSelection) ? "empty" : "full";
    key = toText(printf("%s-%s"))(fieldLabel)(arg20);
    let reactSelect;
    const props = toList(delay(() => append(defaultSelection, delay(() => append(extraSelectProps, delay(() => append(singleton_1(new SelectPropsMulti$1(2, canSelect)), delay(() => append(singleton_1(new SelectPropsMulti$1(8, false)), delay(() => singleton_1(new SelectPropsMulti$1(4, (filledItem) => {
        _arg1.fields[0](some(filledItem.value));
    }))))))))))));
    const props_1 = keyValueList(props, 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(toList(delay(() => append(singleton_1(new Option_2(11, singleton(new Prop(0, key)))), delay(() => fieldProps)))), ofArray([label_1(singleton(new Label_Option(3, singleton(FormField_ReactSelect_mkReactSelectId(modelName, fieldLabel, new Naming_UIElement(1, new Naming_UIElement(4)))))), singleton(fieldLabel)), reactSelect]));
}

export function FormField_ReactSelect_multi(availableToSelect, extraSelectProps, optionLabel, currentlySelected, _arg1, fieldLabel, modelName, fieldProps) {
    const toOptions = (values) => Array.from(map((selection) => (new SelectOption$1(optionLabel(selection), selection)), values));
    const notAlreadySelected = filter((item) => (!contains(item, currentlySelected, {
        Equals: equals,
        GetHashCode: structuralHash,
    })), availableToSelect);
    let reactSelect;
    const props = toList(delay(() => append(singleton_1(new SelectPropsMulti$1(0, toOptions(currentlySelected))), delay(() => append(singleton_1(new SelectPropsMulti$1(1, toOptions(notAlreadySelected))), delay(() => append(singleton_1(new SelectPropsMulti$1(11, FormField_ReactSelect_mkReactSelectIdString(modelName, fieldLabel, new Naming_UIElement(4)))), delay(() => append(singleton_1(new SelectPropsMulti$1(8, true)), delay(() => append(singleton_1(new SelectPropsMulti$1(4, (selected) => {
        _arg1.fields[0](equalsWith(equals, selected, null) ? empty() : ofArray(map_1((filled) => filled.value, selected)));
    })), delay(() => extraSelectProps))))))))))));
    const props_1 = keyValueList(props, 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(singleton(new Option_2(11, toList(delay(() => fieldProps)))), ofArray([label_1(singleton(new Label_Option(3, singleton(FormField_ReactSelect_mkReactSelectId(modelName, fieldLabel, new Naming_UIElement(1, new Naming_UIElement(4)))))), singleton(fieldLabel)), reactSelect]));
}

export function FormField_ReactSelect_multiGrouped(availableToSelect, extraSelectProps, optionLabel, currentlySelected, _arg1, fieldLabel, modelName, fieldProps) {
    const toOptions = (values) => Array.from(map((selection) => (new SelectOption$1(optionLabel(selection), selection)), values));
    const notAlreadySelected = map_1((group) => (new GroupType$1(group.label, group.options.filter((x) => (!contains(x.value, currentlySelected, {
        Equals: equals,
        GetHashCode: structuralHash,
    }))))), Array.from(map((groupInfo) => (new GroupType$1(groupInfo.groupLabel, toOptions(groupInfo.values))), availableToSelect)));
    let reactSelect;
    const props = toList(delay(() => append(singleton_1(new SelectPropsMulti$1(11, FormField_ReactSelect_mkReactSelectIdString(modelName, fieldLabel, new Naming_UIElement(4)))), delay(() => append(singleton_1(new SelectPropsMulti$1(0, toOptions(currentlySelected))), delay(() => append(singleton_1(new SelectPropsMulti$1(2, notAlreadySelected)), delay(() => append(singleton_1(new SelectPropsMulti$1(8, true)), delay(() => append(singleton_1(new SelectPropsMulti$1(4, (selected) => {
        _arg1.fields[0](equalsWith(equals, selected, null) ? empty() : ofArray(map_1((filled) => filled.value, selected)));
    })), delay(() => extraSelectProps))))))))))));
    const props_1 = keyValueList(props, 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(singleton(new Option_2(11, toList(delay(() => fieldProps)))), ofArray([label_1(singleton(new Label_Option(3, singleton(FormField_ReactSelect_mkReactSelectId(modelName, fieldLabel, new Naming_UIElement(1, new Naming_UIElement(4)))))), singleton(fieldLabel)), reactSelect]));
}

export function FormField_ReactEasyEdit_mkReactEasyEdit(editType, initialValue, value, onSave, modelName, label, maybeCustomDisplay, canEdit) {
    let fieldElement;
    const props = ofArray([new EasyEditProps$1(0, editType), new EasyEditProps$1(1, initialValue), new EasyEditProps$1(2, value), new EasyEditProps$1(7, onSave), new EasyEditProps$1(3, "Save"), new EasyEditProps$1(4, "button is-success is-small"), new EasyEditProps$1(5, "Cancel"), new EasyEditProps$1(6, "button is-small"), new EasyEditProps$1(9, toNullable(canEdit ? maybeCustomDisplay : (void 0))), new EasyEditProps$1(10, "test"), new EasyEditProps$1(11, (value_2) => defaultArg(map_2((arg) => (!isNullOrEmpty(arg)), value_2), false)), new EasyEditProps$1(13, canEdit)]);
    const props_1 = keyValueList(props, 1);
    fieldElement = react.createElement(react_easy_edit, props_1);
    return (props_2) => FormField_labeledField(modelName, label, fieldElement, props_2);
}

