import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Model } from "./EmailChangeTypes.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { InOut_update } from "../Common/Common.js";

export function tryTerminate(_model) {
    return new FSharpResult$2(0, () => {
    });
}

export function init(args) {
    return [new Model(args.user.email, ""), Cmd_none()];
}

export function updateInbound(_args, _msg, model) {
    return [model, Cmd_none(), Cmd_none()];
}

export function updateLocal(_args, msg, model) {
    if (msg.tag === 1) {
        return [msg.fields[0](model), Cmd_none(), Cmd_none()];
    }
    else {
        console.error(some(msg.fields[0]));
        return [model, Cmd_none(), Cmd_none()];
    }
}

export function update(args, msg, model) {
    return InOut_update((_args, msg_1, model_1) => updateLocal(void 0, msg_1, model_1), (_args_1, _msg, model_2) => updateInbound(void 0, _msg, model_2), void 0, msg, model);
}

