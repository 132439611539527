import { value as value_3, some, defaultArg, map, ofNullable, bind } from "./fable_modules/fable-library.3.7.12/Option.js";
import { printf, toText, isNullOrEmpty } from "./fable_modules/fable-library.3.7.12/String.js";
import { Union, Record } from "./fable_modules/fable-library.3.7.12/Types.js";
import { bool_type, option_type, union_type, class_type, int32_type, record_type, string_type, float64_type } from "./fable_modules/fable-library.3.7.12/Reflection.js";
import jwt_decode from "jwt-decode";
import { Security_AuthErrorMessage$reflection } from "./fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.12/Choice.js";
import { utcNow, op_Subtraction, create } from "./fable_modules/fable-library.3.7.12/Date.js";
import { totalSeconds } from "./fable_modules/fable-library.3.7.12/TimeSpan.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_ofSub, Cmd_batch, Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { comparePrimitives, max } from "./fable_modules/fable-library.3.7.12/Util.js";
import { ofArray, iterate } from "./fable_modules/fable-library.3.7.12/List.js";
import { publicAuthApi } from "./Api.js";

export function SessionStorage_storeApiToken(apiToken) {
    sessionStorage["api_token"]=apiToken;
}

export function SessionStorage_tryGetApiToken() {
    return bind((t) => {
        if (isNullOrEmpty(t)) {
            return void 0;
        }
        else {
            return t;
        }
    }, ofNullable(sessionStorage["api_token"]));
}

export function SessionStorage_clear() {
    sessionStorage.clear();
}

export class Jwt_DecodedJwt extends Record {
    constructor(exp, iat, email) {
        super();
        this.exp = exp;
        this.iat = iat;
        this.email = email;
    }
}

export function Jwt_DecodedJwt$reflection() {
    return record_type("Client.Jwt.DecodedJwt", [], Jwt_DecodedJwt, () => [["exp", float64_type], ["iat", float64_type], ["email", string_type]]);
}

export function Jwt_decode(token) {
    return jwt_decode(token);
}

export class AccessToken extends Record {
    constructor(rawToken, decoded) {
        super();
        this.rawToken = rawToken;
        this.decoded = decoded;
    }
}

export function AccessToken$reflection() {
    return record_type("Client.AccessToken", [], AccessToken, () => [["rawToken", string_type], ["decoded", Jwt_DecodedJwt$reflection()]]);
}

export function AccessToken_create_Z721C83C5(t) {
    return new AccessToken(t, Jwt_decode(t));
}

export function AccessToken_rawToken__Z58A6E8F3(t) {
    return t.rawToken;
}

export class InactivityConfig extends Record {
    constructor(inactivityWarningAfter, inactivityLogoutAfter) {
        super();
        this.inactivityWarningAfter = (inactivityWarningAfter | 0);
        this.inactivityLogoutAfter = (inactivityLogoutAfter | 0);
    }
}

export function InactivityConfig$reflection() {
    return record_type("Client.InactivityConfig", [], InactivityConfig, () => [["inactivityWarningAfter", int32_type], ["inactivityLogoutAfter", int32_type]]);
}

export function InactivityConfig_create_ZBA264C0(warnAfter, logoutAfter) {
    return new InactivityConfig(warnAfter, logoutAfter);
}

export class SessionManagementMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SessionError", "StoreToken", "ClearToken", "Tick", "RefreshAccessToken", "GotRefreshTokenResponse", "ResetInactivityTime", "InactivityElapsedWarning", "InactivityElapsedNoTurningBack"];
    }
}

export function SessionManagementMsg$reflection() {
    return union_type("Client.SessionManagementMsg", [], SessionManagementMsg, () => [[["Item", class_type("System.Exception")]], [["Item", string_type]], [], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, Security_AuthErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", Security_AuthErrorMessage$reflection()]]])]], [], [], []]);
}

export class SessionManagement_SessionManagementModel extends Record {
    constructor(token$0027, lastActiveAt, inactiveWarning, inactivityConfig) {
        super();
        this["token\u0027"] = token$0027;
        this.lastActiveAt = lastActiveAt;
        this.inactiveWarning = inactiveWarning;
        this.inactivityConfig = inactivityConfig;
    }
}

export function SessionManagement_SessionManagementModel$reflection() {
    return record_type("Client.SessionManagement.SessionManagementModel", [], SessionManagement_SessionManagementModel, () => [["token\u0027", option_type(AccessToken$reflection())], ["lastActiveAt", class_type("System.DateTime")], ["inactiveWarning", bool_type], ["inactivityConfig", option_type(InactivityConfig$reflection())]]);
}

export function SessionManagement_SessionManagementModel_token__39A60A33(t) {
    return map(AccessToken_rawToken__Z58A6E8F3, t["token\u0027"]);
}

export function SessionManagement_SessionManagementModel__get_token(x) {
    return x["token\u0027"];
}

const SessionManagement_epoch = create(1970, 1, 1, 0, 0, 0, 0, 1);

export const SessionManagement_tryGetAccessToken = () => map(AccessToken_create_Z721C83C5, SessionStorage_tryGetApiToken());

export function SessionManagement_create(token) {
    SessionStorage_storeApiToken(token);
    return AccessToken_create_Z721C83C5(token);
}

const SessionManagement_expiryThreshold = 0.2;

function SessionManagement_getUnixTime(dt) {
    let copyOfStruct;
    return (copyOfStruct = op_Subtraction(dt, SessionManagement_epoch), totalSeconds(copyOfStruct));
}

function SessionManagement_checkExpiryThreshold(token) {
    if ((token.decoded.exp - SessionManagement_getUnixTime(utcNow())) < ((token.decoded.exp - token.decoded.iat) * SessionManagement_expiryThreshold)) {
        return Cmd_OfFunc_result(new SessionManagementMsg(4));
    }
    else {
        return Cmd_none();
    }
}

function SessionManagement_checkInactivity(lastActiveAt, isCurrentlyWarning, cfg) {
    const matchValue = [isCurrentlyWarning, SessionManagement_getUnixTime(utcNow()) - SessionManagement_getUnixTime(lastActiveAt)];
    let pattern_matching_result;
    if (matchValue[0]) {
        if (matchValue[1] > (cfg.inactivityWarningAfter + cfg.inactivityLogoutAfter)) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return Cmd_OfFunc_result(new SessionManagementMsg(8));
        }
        case 1: {
            let pattern_matching_result_1;
            if (matchValue[0]) {
                pattern_matching_result_1 = 1;
            }
            else if (matchValue[1] > cfg.inactivityWarningAfter) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return Cmd_OfFunc_result(new SessionManagementMsg(7));
                }
                case 1: {
                    return Cmd_none();
                }
            }
        }
    }
}

export function SessionManagement_init(refreshIntervalOverride, inactivityConfig) {
    const maybeToken = SessionManagement_tryGetAccessToken();
    let refreshInterval;
    const tickGoal = 10;
    refreshInterval = defaultArg(refreshIntervalOverride, (inactivityConfig == null) ? defaultArg(map((t) => ((~(~((~(~(t.decoded.exp - t.decoded.iat))) / tickGoal))) * 1), maybeToken), 1) : max(comparePrimitives, 1, ~(~(inactivityConfig.inactivityWarningAfter / tickGoal))));
    return [new SessionManagement_SessionManagementModel(maybeToken, utcNow(), false, inactivityConfig), Cmd_batch(ofArray([Cmd_ofSub((dispatch) => {
        window.setInterval((_arg1) => dispatch(new SessionManagementMsg(3)), refreshInterval * 1000);
    }), Cmd_ofSub((dispatch_1) => {
        if (inactivityConfig != null) {
            iterate((ev) => {
                window.addEventListener(ev, (_arg1_1) => {
                    dispatch_1(new SessionManagementMsg(6));
                });
            }, ofArray(["click", "keypress", "mousedown", "touchstart"]));
        }
    })]))];
}

export function SessionManagement_update(isInactivityDisabled, msg, model) {
    let pattern_matching_result;
    if (msg.tag === 0) {
        pattern_matching_result = 0;
    }
    else if (msg.tag === 3) {
        if (SessionManagement_SessionManagementModel__get_token(model) != null) {
            pattern_matching_result = 1;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else {
        pattern_matching_result = 2;
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
            return [model, Cmd_none()];
        }
        case 1: {
            const refreshCmd = SessionManagement_checkExpiryThreshold(value_3(SessionManagement_SessionManagementModel__get_token(model)));
            const model_1 = isInactivityDisabled() ? (new SessionManagement_SessionManagementModel(model["token\u0027"], utcNow(), model.inactiveWarning, model.inactivityConfig)) : model;
            return [model_1, Cmd_batch(ofArray([refreshCmd, defaultArg(map((cfg) => SessionManagement_checkInactivity(model_1.lastActiveAt, model_1.inactiveWarning, cfg), model_1.inactivityConfig), Cmd_none())]))];
        }
        case 2: {
            switch (msg.tag) {
                case 3: {
                    return [model, Cmd_none()];
                }
                case 1: {
                    return [new SessionManagement_SessionManagementModel(SessionManagement_create(msg.fields[0]), model.lastActiveAt, model.inactiveWarning, model.inactivityConfig), Cmd_none()];
                }
                case 2: {
                    SessionStorage_clear();
                    return [new SessionManagement_SessionManagementModel(void 0, model.lastActiveAt, model.inactiveWarning, model.inactivityConfig), Cmd_none()];
                }
                case 7: {
                    return [new SessionManagement_SessionManagementModel(model["token\u0027"], model.lastActiveAt, true, model.inactivityConfig), Cmd_none()];
                }
                case 8: {
                    return [new SessionManagement_SessionManagementModel(model["token\u0027"], model.lastActiveAt, false, model.inactivityConfig), Cmd_none()];
                }
                case 4: {
                    const matchValue = SessionManagement_SessionManagementModel__get_token(model);
                    if (matchValue != null) {
                        return [model, Cmd_OfAsyncWith_either((x) => {
                            Cmd_OfAsync_start(x);
                        }, publicAuthApi.refreshToken, matchValue.decoded.email, (arg0) => (new SessionManagementMsg(5, arg0)), (arg0_1) => (new SessionManagementMsg(0, arg0_1)))];
                    }
                    else {
                        return [model, Cmd_none()];
                    }
                }
                case 5: {
                    const r = msg.fields[0];
                    if (r.tag === 1) {
                        return [model, Cmd_none()];
                    }
                    else {
                        return [model, Cmd_OfFunc_result(new SessionManagementMsg(1, r.fields[0]))];
                    }
                }
                case 6: {
                    return [new SessionManagement_SessionManagementModel(model["token\u0027"], utcNow(), false, model.inactivityConfig), Cmd_none()];
                }
                default: {
                    throw (new Error("Match failure: Client.SessionManagementMsg"));
                }
            }
        }
    }
}

