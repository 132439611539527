import { Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, option_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { ofArray, cons, empty, singleton } from "../fable_modules/fable-library.3.7.12/List.js";
import { String_fromOption } from "../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import { Option as Option_1, p } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import * as react from "react";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";

export class ComponentVisibility extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Visible", "Hidden", "Disabled"];
    }
}

export function ComponentVisibility$reflection() {
    return union_type("ThreeDocs.SharedUI.ComponentVisibility.Visibility.ComponentVisibility", [], ComponentVisibility, () => [[], [], [["tooltip", option_type(string_type)]]]);
}

export function mapVis(vis) {
    switch (vis.tag) {
        case 1: {
            return singleton(["data-sub_du-hidden", ""]);
        }
        case 2: {
            return singleton(["data-sub_du-disabled", String_fromOption(vis.fields[0])]);
        }
        default: {
            return singleton(["data-sub_du-visible", ""]);
        }
    }
}

export function Button_visibilityControlled(visibility, options, children) {
    if (visibility.tag === 2) {
        if (visibility.fields[0] == null) {
            return p(empty(), singleton(button(cons(new Option(16, true), options), children)));
        }
        else {
            const tooltip = visibility.fields[0];
            return p(singleton(new Option_1(4, "tooltip")), ofArray([button(cons(new Option(16, true), options), children), react.createElement("span", {
                className: "tooltip-text",
            }, tooltip)]));
        }
    }
    else if (visibility.tag === 0) {
        return p(empty(), singleton(button(options, children)));
    }
    else {
        return Helpers_nothing;
    }
}

export function Button_visibilityControlledCustom(customControlStyle, visibility, options, children) {
    if (visibility.tag === 2) {
        if (visibility.fields[0] == null) {
            return p(singleton(new Option_1(5, singleton(["style", keyValueList(customControlStyle, 1)]))), singleton(button(cons(new Option(16, true), options), children)));
        }
        else {
            const tooltip = visibility.fields[0];
            return p(ofArray([new Option_1(4, "tooltip"), new Option_1(5, singleton(["style", keyValueList(customControlStyle, 1)]))]), ofArray([button(cons(new Option(16, true), options), children), react.createElement("span", {
                className: "tooltip-text",
            }, tooltip)]));
        }
    }
    else if (visibility.tag === 0) {
        return p(singleton(new Option_1(5, singleton(["style", keyValueList(customControlStyle, 1)]))), singleton(button(options, children)));
    }
    else {
        return Helpers_nothing;
    }
}

