import { State_update, Types_Msg$4, State_init } from "../ThreeDocs.Client.StateMachine.Crud/CrudComponent.js";
import { InvitationsUpdateArgs, CustomShared_get_replyToEmail_, CustomShared_get_currentRoles_, CustomShared_get_packages_, CustomShared, InvitationsCrudConfig_invitationVmModelConfig, InvitationsCrudConfig_invitationVmViewConfig, InvitationsCrudConfig_generalViewLayoutConfig } from "./InvitationsCrudConfig.js";
import { ofArray, tryHead, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Msg, Model } from "./InvitationsTypes.js";
import { Cmd_batch, Cmd_none, Cmd_map } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Permission, isAllowed } from "../ThreeDocs.Models/Security.js";
import { Cmd_OfFunc_result, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedAuthApi, securedApi } from "../Api.js";
import { some, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { error } from "../ThreeDocs.SharedUI/Toast.js";
import { ErrorMessage_get_describe } from "../ThreeDocs.Models/Api/Api.js";
import { Shared_CrudInbound$2 } from "../ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";

export function init() {
    const patternInput = State_init(InvitationsCrudConfig_generalViewLayoutConfig(InvitationsCrudConfig_invitationVmViewConfig.viewListItemConfig.listColumns, InvitationsCrudConfig_invitationVmModelConfig.isSameItem), InvitationsCrudConfig_invitationVmViewConfig, InvitationsCrudConfig_invitationVmModelConfig, new CustomShared(empty(), empty(), void 0));
    return [new Model(patternInput[0]), Cmd_map((arg0) => (new Msg(8, arg0)), patternInput[1])];
}

export function update(token, userInfo, msg, model) {
    let matchValue_1, pId_1;
    if (msg.tag === 2) {
        if (isAllowed(new Permission(25))(userInfo.roles)) {
            return [model, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(token).getAllInvitationsForPractice, defaultArg(tryHead(userInfo.userData.Practices), "00000000-0000-0000-0000-000000000000"), (arg0_8) => (new Msg(3, arg0_8)), (arg0_9) => (new Msg(0, arg0_9)))];
        }
        else {
            return [model, Cmd_none()];
        }
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            return [model, error(ErrorMessage_get_describe()(msg.fields[0].fields[0]))];
        }
        else {
            return [model, Cmd_OfFunc_result(new Msg(8, new Types_Msg$4(2, new Shared_CrudInbound$2(0, msg.fields[0].fields[0]))))];
        }
    }
    else if (msg.tag === 4) {
        return [model, Cmd_OfAsyncWith_either((x_1) => {
            Cmd_OfAsync_start(x_1);
        }, securedApi(token).getAllPackagesForPractice, msg.fields[0], (arg0_13) => (new Msg(5, arg0_13)), (arg0_14) => (new Msg(0, arg0_14)))];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            return [model, error(ErrorMessage_get_describe()(msg.fields[0].fields[0]))];
        }
        else {
            return [model, Cmd_OfFunc_result(new Msg(8, new Types_Msg$4(2, new Shared_CrudInbound$2(4, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_packages_())(msg.fields[0].fields[0])))))];
        }
    }
    else if (msg.tag === 6) {
        if (msg.fields[0].tag === 0) {
            return [model, Cmd_OfFunc_result(new Msg(2))];
        }
        else if (msg.fields[0].tag === 3) {
            return [model, (matchValue_1 = tryHead(userInfo.userData.Practices), (matchValue_1 != null) ? ((matchValue_1 === msg.fields[0].fields[0]) ? ((pId_1 = matchValue_1, Cmd_OfFunc_result(new Msg(4, msg.fields[0].fields[0])))) : Cmd_none()) : Cmd_none())];
        }
        else if (msg.fields[0].tag === 6) {
            return [model, (userInfo.id === msg.fields[0].fields[0]) ? Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedAuthApi(token).getUserInfo, void 0, (arg0_19) => (new Msg(7, arg0_19)), (arg0_20) => (new Msg(0, arg0_20))) : Cmd_none()];
        }
        else {
            return [model, Cmd_none()];
        }
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 0) {
            return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(8, new Types_Msg$4(2, new Shared_CrudInbound$2(4, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_currentRoles_())(msg.fields[0].fields[0].roles))))), Cmd_OfFunc_result(new Msg(8, new Types_Msg$4(2, new Shared_CrudInbound$2(4, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_replyToEmail_())(msg.fields[0].fields[0].userData.ReplyToEmail)))))]))];
        }
        else {
            throw (new Error("Match failure: Client.Invitations.Types.Msg"));
        }
    }
    else if (msg.tag === 0) {
        console.error(some(`Error: ${msg.fields[0]}`));
        return [model, Cmd_none()];
    }
    else if (msg.tag === 8) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [model, error("An unexpected error occurred. Please try again.")];
            }
            else {
                return [model, error(ErrorMessage_get_describe()(msg.fields[0].fields[0].fields[0]))];
            }
        }
        else {
            const patternInput = State_update(new InvitationsUpdateArgs(token, defaultArg(tryHead(userInfo.userData.Practices), "00000000-0000-0000-0000-000000000000")), msg.fields[0], model.InvitationCrudModel);
            return [new Model(patternInput[0]), Cmd_map((arg0_29) => (new Msg(8, arg0_29)), patternInput[1])];
        }
    }
    else {
        return [model, Cmd_batch(toList(delay(() => append(singleton(Cmd_OfFunc_result(new Msg(2))), delay(() => append(singleton(Cmd_OfFunc_result(new Msg(8, new Types_Msg$4(2, new Shared_CrudInbound$2(4, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_currentRoles_())(userInfo.roles)))))), delay(() => append(singleton(Cmd_OfFunc_result(new Msg(8, new Types_Msg$4(2, new Shared_CrudInbound$2(4, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_replyToEmail_())(userInfo.userData.ReplyToEmail)))))), delay(() => {
            const matchValue = tryHead(userInfo.userData.Practices);
            return (matchValue == null) ? singleton(Cmd_none()) : singleton(Cmd_OfFunc_result(new Msg(4, matchValue)));
        })))))))))];
    }
}

