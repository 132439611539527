import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, bool_type, option_type, union_type, list_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { choose, filter, contains, exists, map, empty, tryFind, ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { List_except } from "../fable_modules/fable-library.3.7.12/Seq2.js";
import { safeHash, equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { Specialty$reflection } from "./Domain/Specialty.js";
import { Common_EmailAddress_create_Z721C83C5, Common_EmailAddress_value_Z39D6D3C1, Common_EmailAddress$reflection } from "../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { Result_toOption } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { tryFind as tryFind_1, ofList } from "../fable_modules/fable-library.3.7.12/Map.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";

export class PermissionRestriction extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NoPermissions", "ByPractices", "Unrestricted"];
    }
}

export function PermissionRestriction$reflection() {
    return union_type("ThreeDocs.Models.Api.Security.PermissionRestriction", [], PermissionRestriction, () => [[], [["assignedPracticeIds", list_type(class_type("System.Guid"))]], []]);
}

export class UserRole extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ThreeDocsAdmin", "PracticeAdmin", "Editor", "Viewer"];
    }
    toString() {
        const x = this;
        return UserRole_get_describe()(x);
    }
}

export function UserRole$reflection() {
    return union_type("ThreeDocs.Models.Api.Security.UserRole", [], UserRole, () => [[], [], [], []]);
}

export function UserRole_get_all() {
    return ofArray([new UserRole(0), new UserRole(1), new UserRole(2), new UserRole(3)]);
}

export function UserRole_get_describe() {
    return (_arg1) => ((_arg1.tag === 1) ? "Practice Admin" : ((_arg1.tag === 2) ? "Editor" : ((_arg1.tag === 3) ? "Viewer" : "3DOCS Admin")));
}

export function UserRole_tryParse_Z721C83C5(role) {
    return tryFind((r) => (UserRole_get_describe()(r).toLowerCase() === role.toLowerCase()), UserRole_get_all());
}

export function UserRole_get_selectableRoles() {
    return (_arg2) => ((_arg2.tag === 0) ? UserRole_get_all() : ((_arg2.tag === 1) ? List_except([new UserRole(0)], UserRole_get_all(), {
        Equals: equals,
        GetHashCode: safeHash,
    }) : empty()));
}

export class UserData extends Record {
    constructor(Practices, Specialty, ReplyToEmail, Restriction, IsLockedOut) {
        super();
        this.Practices = Practices;
        this.Specialty = Specialty;
        this.ReplyToEmail = ReplyToEmail;
        this.Restriction = Restriction;
        this.IsLockedOut = IsLockedOut;
    }
}

export function UserData$reflection() {
    return record_type("ThreeDocs.Models.Api.Security.UserData", [], UserData, () => [["Practices", list_type(class_type("System.Guid"))], ["Specialty", list_type(Specialty$reflection())], ["ReplyToEmail", option_type(Common_EmailAddress$reflection())], ["Restriction", PermissionRestriction$reflection()], ["IsLockedOut", bool_type]]);
}

export function UserData_get_practices_() {
    return [(m) => m.Practices, (v) => ((m_1) => (new UserData(v, m_1.Specialty, m_1.ReplyToEmail, m_1.Restriction, m_1.IsLockedOut)))];
}

export function UserData_get_replyToEmail_() {
    return [(m) => m.ReplyToEmail, (v) => ((m_1) => (new UserData(m_1.Practices, m_1.Specialty, v, m_1.Restriction, m_1.IsLockedOut)))];
}

export function UserData_get_maybeEmailString_() {
    return [(_arg1) => ((_arg1 != null) ? Common_EmailAddress_value_Z39D6D3C1(_arg1) : ""), (arg) => Result_toOption(Common_EmailAddress_create_Z721C83C5(arg))];
}

export function UserData_get_specialty_() {
    return [(m) => m.Specialty, (v) => ((m_1) => (new UserData(m_1.Practices, v, m_1.ReplyToEmail, m_1.Restriction, m_1.IsLockedOut)))];
}

export class Permission extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CreateUser", "EditUser", "DeleteUser", "ViewUsers", "ViewOwnProfile", "LockoutUsers", "ViewUsersPage", "CreatePractice", "EditPractice", "DeletePractice", "ViewPractices", "ViewPracticesPage", "ViewMedia", "ViewPublicMediaPage", "UploadMedia", "EditMedia", "DeleteMedia", "UploadPublicMedia", "EditPublicMedia", "DeletePublicMedia", "CreatePackage", "EditPackage", "DeletePackage", "ViewPackages", "SendInvitation", "ViewInvitations"];
    }
}

export function Permission$reflection() {
    return union_type("ThreeDocs.Models.Api.Security.Permission", [], Permission, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

const permissionsByRole = ofList(map((r) => [r, (r.tag === 1) ? ofArray([new Permission(0), new Permission(1), new Permission(2), new Permission(3), new Permission(4), new Permission(5), new Permission(6), new Permission(8), new Permission(10), new Permission(11), new Permission(14), new Permission(16), new Permission(15), new Permission(12), new Permission(20), new Permission(21), new Permission(22), new Permission(23), new Permission(25), new Permission(24)]) : ((r.tag === 2) ? ofArray([new Permission(4), new Permission(10), new Permission(14), new Permission(16), new Permission(15), new Permission(12), new Permission(20), new Permission(21), new Permission(22), new Permission(23), new Permission(24), new Permission(25)]) : ((r.tag === 3) ? ofArray([new Permission(4), new Permission(10), new Permission(12), new Permission(23), new Permission(25)]) : ofArray([new Permission(0), new Permission(1), new Permission(2), new Permission(3), new Permission(4), new Permission(5), new Permission(6), new Permission(7), new Permission(8), new Permission(9), new Permission(10), new Permission(11), new Permission(17), new Permission(19), new Permission(18), new Permission(13), new Permission(12), new Permission(23)])))], UserRole_get_all()));

export function isAllowed(permission) {
    return (list) => exists((role) => defaultArg(map_1((source) => contains(permission, source, {
        Equals: equals,
        GetHashCode: safeHash,
    }), tryFind_1(role, permissionsByRole)), false), list);
}

export function rolesWithPermission(permission) {
    return filter((role) => defaultArg(map_1((source) => contains(permission, source, {
        Equals: equals,
        GetHashCode: safeHash,
    }), tryFind_1(role, permissionsByRole)), false), UserRole_get_all());
}

export function collectPermissions(permissionChooser, role) {
    return defaultArg(map_1((list) => choose(permissionChooser, list), tryFind_1(role, permissionsByRole)), empty());
}

