import { Union, Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { UserData$reflection, UserRole$reflection } from "../ThreeDocs.Models/Security.js";
import { Security_AuthErrorMessage$reflection, Security_UserInfo$2$reflection, Security_UserViewModel$2$reflection } from "../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { CustomShared$reflection, UsersUpdateArgs$reflection, UsersCrudOutbound$reflection } from "./UsersCrudConfig.js";
import { Types_Msg$4$reflection, Types_Model$4$reflection } from "../ThreeDocs.Client.StateMachine.Crud/CrudComponent.js";
import { OutboundMsg$reflection, InboundMsg$reflection, LocalMsg$reflection, Model$reflection as Model$reflection_1 } from "../EmailChange/EmailChangeTypes.js";
import { ChildAbsentStrategy$1, Child_absentChildCheck, ChainedChildMsg$2$reflection, ChildAbsentStrategy$1$reflection, DefaultActions_Types_ChildModel$2$reflection } from "../Common/Terminate.js";
import { unit_type, list_type, union_type, array_type, class_type, record_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { PracticeViewModel$reflection } from "../ThreeDocs.Models/View/PracticeViewModel.js";
import { Notification$reflection } from "../ThreeDocs.Models/Api/Api.js";
import { InOut_Msg$3$reflection } from "../Common/Common.js";

export class Model extends Record {
    constructor(UserCrudModel, emailChange) {
        super();
        this.UserCrudModel = UserCrudModel;
        this.emailChange = emailChange;
    }
}

export function Model$reflection() {
    return record_type("Client.Users.Types.Model", [], Model, () => [["UserCrudModel", Types_Model$4$reflection(Security_UserViewModel$2$reflection(UserRole$reflection(), UserData$reflection()), UsersCrudOutbound$reflection(), UsersUpdateArgs$reflection(), CustomShared$reflection())], ["emailChange", DefaultActions_Types_ChildModel$2$reflection(Model$reflection_1(), Model$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "GetUsers", "GetUsersResult", "AllPracticesUpdated", "Notification", "SendEmailChangeRequest", "SendEmailChangeRequestResult", "UserCrudMsg", "EmailChangeMsg", "TerminateEmailChange"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Users.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection())), Security_AuthErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection()))]], [["ErrorValue", Security_AuthErrorMessage$reflection()]]])]], [["practices", list_type(PracticeViewModel$reflection())]], [["Item", Notification$reflection()]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, Security_AuthErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", Security_AuthErrorMessage$reflection()]]])]], [["Item", Types_Msg$4$reflection(Security_UserViewModel$2$reflection(UserRole$reflection(), UserData$reflection()), UsersCrudOutbound$reflection(), UsersUpdateArgs$reflection(), CustomShared$reflection())]], [["Item", ChainedChildMsg$2$reflection(InOut_Msg$3$reflection(LocalMsg$reflection(), InboundMsg$reflection(), OutboundMsg$reflection()), ChildAbsentStrategy$1$reflection(Model$reflection()))]], []]);
}

export const childAbsentStrategy = new ChildAbsentStrategy$1(0, (model) => Child_absentChildCheck(model.emailChange.child));

