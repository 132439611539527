import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, list_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { Specialty$reflection } from "./Specialty.js";
import { Language$reflection } from "./Language.js";
import { Section$reflection } from "./Section.js";

export class Package extends Record {
    constructor(PackageId, PracticeId, Name, Description, Procedure, Specialty, Language, Title, Intro, Sections, Outro) {
        super();
        this.PackageId = PackageId;
        this.PracticeId = PracticeId;
        this.Name = Name;
        this.Description = Description;
        this.Procedure = Procedure;
        this.Specialty = Specialty;
        this.Language = Language;
        this.Title = Title;
        this.Intro = Intro;
        this.Sections = Sections;
        this.Outro = Outro;
    }
}

export function Package$reflection() {
    return record_type("ThreeDocs.Models.Domain.Package.Package", [], Package, () => [["PackageId", class_type("System.Guid")], ["PracticeId", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["Procedure", string_type], ["Specialty", Specialty$reflection()], ["Language", Language$reflection()], ["Title", string_type], ["Intro", string_type], ["Sections", list_type(Section$reflection())], ["Outro", string_type]]);
}

