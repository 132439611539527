import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { InvitationViewModel_get_empty, InvitationViewModel$reflection } from "./InvitationViewModel.js";
import { PackageViewModel_get_empty, PackageViewModel$reflection } from "./PackageViewModel.js";
import { PracticeViewModel_get_empty, PracticeViewModel$reflection } from "./PracticeViewModel.js";
import { MediaViewModel$reflection } from "./MediaViewModel.js";
import { record_type, list_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { empty } from "../../fable_modules/fable-library.3.7.12/List.js";

export class PatientPackageViewModel extends Record {
    constructor(invitationViewModel, packageViewModel, practiceViewModel, mediaViewModels) {
        super();
        this.invitationViewModel = invitationViewModel;
        this.packageViewModel = packageViewModel;
        this.practiceViewModel = practiceViewModel;
        this.mediaViewModels = mediaViewModels;
    }
}

export function PatientPackageViewModel$reflection() {
    return record_type("ThreeDocs.ViewModels.PatientPackageViewModel.PatientPackageViewModel", [], PatientPackageViewModel, () => [["invitationViewModel", InvitationViewModel$reflection()], ["packageViewModel", PackageViewModel$reflection()], ["practiceViewModel", PracticeViewModel$reflection()], ["mediaViewModels", list_type(MediaViewModel$reflection())]]);
}

export function PatientPackageViewModel_get_empty() {
    return new PatientPackageViewModel(InvitationViewModel_get_empty(), PackageViewModel_get_empty(), PracticeViewModel_get_empty(), empty());
}

export function PatientPackageViewModel_get_packageViewModel_() {
    return [(m) => m.packageViewModel, (v) => ((m_1) => (new PatientPackageViewModel(m_1.invitationViewModel, v, m_1.practiceViewModel, m_1.mediaViewModels)))];
}

export function PatientPackageViewModel_get_practiceViewModel_() {
    return [(m) => m.practiceViewModel, (v) => ((m_1) => (new PatientPackageViewModel(m_1.invitationViewModel, m_1.packageViewModel, v, m_1.mediaViewModels)))];
}

export function PatientPackageViewModel_get_invitationViewModel_() {
    return [(m) => m.invitationViewModel, (v) => ((m_1) => (new PatientPackageViewModel(v, m_1.packageViewModel, m_1.practiceViewModel, m_1.mediaViewModels)))];
}

export function PatientPackageViewModel_get_mediaViewModels_() {
    return [(m) => m.mediaViewModels, (v) => ((m_1) => (new PatientPackageViewModel(m_1.invitationViewModel, m_1.packageViewModel, m_1.practiceViewModel, v)))];
}

