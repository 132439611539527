import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, option_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { Common_EmailAddress$reflection } from "../../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { PracticeType$reflection } from "./PracticeType.js";

export class Practice extends Record {
    constructor(Id, Name, Address, City, Country, Subdivision, GeneralEnquiryEmail, Website, PhoneNumber, PracticeType) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Address = Address;
        this.City = City;
        this.Country = Country;
        this.Subdivision = Subdivision;
        this.GeneralEnquiryEmail = GeneralEnquiryEmail;
        this.Website = Website;
        this.PhoneNumber = PhoneNumber;
        this.PracticeType = PracticeType;
    }
}

export function Practice$reflection() {
    return record_type("ThreeDocs.Models.Domain.Practice.Practice", [], Practice, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Address", option_type(string_type)], ["City", option_type(string_type)], ["Country", option_type(string_type)], ["Subdivision", option_type(string_type)], ["GeneralEnquiryEmail", Common_EmailAddress$reflection()], ["Website", option_type(string_type)], ["PhoneNumber", option_type(string_type)], ["PracticeType", option_type(PracticeType$reflection())]]);
}

