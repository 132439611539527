import { Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, option_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";

export class TableSearch extends Record {
    constructor(search, identifier) {
        super();
        this.search = search;
        this.identifier = identifier;
    }
}

export function TableSearch$reflection() {
    return record_type("ThreeDocs.SharedUI.Search.TableSearch", [], TableSearch, () => [["search", option_type(string_type)], ["identifier", string_type]]);
}

export function TableSearch_get_search_() {
    return [(m) => m.search, (v) => ((m_1) => (new TableSearch(v, m_1.identifier)))];
}

