import { State_update, Types_Msg$4, State_init } from "../ThreeDocs.Client.StateMachine.Crud/CrudComponent.js";
import { SectionsUpdateArgs, CustomShared_get_practiceMedia_, CustomShared_get_publicMedia_, CustomShared_get_currentRoles_, CustomShared, SectionsCrudConfig_SectionVmModelConfig, SectionsCrudConfig_SectionVmViewConfig, SectionsCrudConfig_generalViewLayoutConfig } from "./Sections/SectionsCrudConfig.js";
import { filter, singleton as singleton_1, append as append_1, cons, ofArray, map, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { emptyPackageWithDefaults, Model, ViewMode as ViewMode_1, Msg } from "./PackageTypes.js";
import { Sort_SortInfo$1, Sort_SortDirection, Sort_SortableColumn$1, Sort_Compare$1 } from "../ThreeDocs.SharedUI/Sort.js";
import { PackageViewModel, PackageViewModel_get_empty } from "../ThreeDocs.Models/View/PackageViewModel.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Shared_CrudInbound$2 } from "../ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { securedApi } from "../Api.js";
import { newGuid } from "../fable_modules/fable-library.3.7.12/Guid.js";
import { success, error } from "../ThreeDocs.SharedUI/Toast.js";
import { ErrorMessage_get_describe } from "../ThreeDocs.Models/Api/Api.js";
import { SectionViewModel } from "../ThreeDocs.Models/View/SectionViewModel.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { InternalMsg$4 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDataEntryState.js";
import { CrudStateMachine_CrudTaggedStateMessages$4 } from "../ThreeDocs.Client.StateMachine.Crud/CrudMachine.js";
import { StateMachineMsg$5 } from "../fable_modules/Webbler.StateMachine.Core.1.1.0/StateMachine.fs.js";
import { InternalMsg$4 as InternalMsg$4_1 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDeleteConfirmationState.js";

export function init(maybePracticeId, currentRoles) {
    const patternInput = State_init(SectionsCrudConfig_generalViewLayoutConfig(SectionsCrudConfig_SectionVmViewConfig.viewListItemConfig.listColumns, SectionsCrudConfig_SectionVmModelConfig.isSameItem), SectionsCrudConfig_SectionVmViewConfig, SectionsCrudConfig_SectionVmModelConfig, new CustomShared(currentRoles, empty(), empty()));
    let packagesCommands;
    if (maybePracticeId == null) {
        packagesCommands = empty();
    }
    else {
        const practiceId = maybePracticeId;
        packagesCommands = map(Cmd_OfFunc_result, ofArray([new Msg(2, practiceId), new Msg(4, practiceId), new Msg(6), new Msg(8, practiceId)]));
    }
    return [new Model(empty(), void 0, new Sort_SortInfo$1(new Sort_SortableColumn$1("", (_arg1) => "", new Sort_Compare$1(0, (_arg3, _arg2) => 0)), new Sort_SortDirection(1)), PackageViewModel_get_empty(), new ViewMode_1(0), currentRoles, patternInput[0]), Cmd_batch(toList(delay(() => append(singleton(Cmd_map((arg0_1) => (new Msg(18, arg0_1)), patternInput[1])), delay(() => packagesCommands)))))];
}

export function update(token, userInfo, practiceId, msg, model) {
    let inputRecord_1, inputRecord_2, inputRecord, inputRecord_3, inputRecord_4, inputRecord_5;
    let pattern_matching_result, ex, practiceId_1, packages, practiceId_2, practice, media, practiceId_3, media_1, package$, updatedPackage, e, mode, setter, si, pid, crudOutbound, msg_22;
    if (msg.tag === 1) {
        pattern_matching_result = 1;
    }
    else if (msg.tag === 2) {
        pattern_matching_result = 2;
        practiceId_1 = msg.fields[0];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 14;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
            packages = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 4) {
        pattern_matching_result = 4;
        practiceId_2 = msg.fields[0];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 14;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 5;
            practice = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 6) {
        pattern_matching_result = 6;
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 14;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 7;
            media = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 8) {
        pattern_matching_result = 8;
        practiceId_3 = msg.fields[0];
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 14;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 9;
            media_1 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 10) {
        pattern_matching_result = 10;
    }
    else if (msg.tag === 11) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 14;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 11;
            package$ = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 12) {
        pattern_matching_result = 12;
    }
    else if (msg.tag === 13) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 14;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 13;
            updatedPackage = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 14) {
        pattern_matching_result = 15;
        mode = msg.fields[0];
    }
    else if (msg.tag === 15) {
        pattern_matching_result = 16;
        setter = msg.fields[0];
    }
    else if (msg.tag === 16) {
        pattern_matching_result = 17;
        si = msg.fields[0];
    }
    else if (msg.tag === 17) {
        if (msg.fields[0].tag === 3) {
            pattern_matching_result = 18;
            pid = msg.fields[0].fields[0];
        }
        else if (msg.fields[0].tag === 1) {
            pattern_matching_result = 19;
        }
        else {
            pattern_matching_result = 20;
        }
    }
    else if (msg.tag === 18) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 21;
            crudOutbound = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 22;
            msg_22 = msg.fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(`Error: ${ex}`));
            return [model, Cmd_none()];
        }
        case 1: {
            return [new Model(model.Packages, model.ParentPractice, model.SortInfo, model.SelectedPackage, model.ViewMode, userInfo.roles, model.SectionsCrudModel), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(18, new Types_Msg$4(2, new Shared_CrudInbound$2(4, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_currentRoles_())(userInfo.roles))))), Cmd_OfFunc_result(new Msg(2, practiceId)), Cmd_OfFunc_result(new Msg(4, practiceId)), Cmd_OfFunc_result(new Msg(6)), Cmd_OfFunc_result(new Msg(8, practiceId))]))];
        }
        case 2: {
            return [model, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(token).getAllPackagesForPractice, practiceId_1, (arg0_4) => (new Msg(3, arg0_4)), (arg0_5) => (new Msg(0, arg0_5)))];
        }
        case 3: {
            return [new Model(packages, model.ParentPractice, model.SortInfo, model.SelectedPackage, model.ViewMode, model.CurrentRoles, model.SectionsCrudModel), Cmd_none()];
        }
        case 4: {
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(token).getPractice, practiceId_2, (arg0_6) => (new Msg(5, arg0_6)), (arg0_7) => (new Msg(0, arg0_7)))];
        }
        case 5: {
            return [new Model(model.Packages, practice, model.SortInfo, model.SelectedPackage, model.ViewMode, model.CurrentRoles, model.SectionsCrudModel), Cmd_none()];
        }
        case 6: {
            return [model, Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedApi(token).getAllPublicMedia, void 0, (arg0_8) => (new Msg(7, arg0_8)), (arg0_9) => (new Msg(0, arg0_9)))];
        }
        case 7: {
            return [model, Cmd_OfFunc_result(new Msg(18, new Types_Msg$4(2, new Shared_CrudInbound$2(4, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_publicMedia_())(media)))))];
        }
        case 8: {
            return [model, Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, securedApi(token).getAllMediaForPractice, practiceId_3, (arg0_14) => (new Msg(9, arg0_14)), (arg0_15) => (new Msg(0, arg0_15)))];
        }
        case 9: {
            return [model, Cmd_OfFunc_result(new Msg(18, new Types_Msg$4(2, new Shared_CrudInbound$2(4, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_practiceMedia_())(media_1)))))];
        }
        case 10: {
            return [model, (model.ViewMode.tag === 7) ? Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, securedApi(token).createPackage, (inputRecord_1 = model.SelectedPackage, new PackageViewModel(newGuid(), inputRecord_1.practiceId, inputRecord_1.name, inputRecord_1.description, inputRecord_1.procedure, inputRecord_1.specialty, inputRecord_1.language, inputRecord_1.title, inputRecord_1.intro, inputRecord_1.sections, inputRecord_1.outro)), (arg0_20) => (new Msg(11, arg0_20)), (arg0_21) => (new Msg(0, arg0_21))) : error("Must be on the Create view to create")];
        }
        case 11: {
            return [new Model(cons(package$, model.Packages), model.ParentPractice, model.SortInfo, model.SelectedPackage, model.ViewMode, model.CurrentRoles, model.SectionsCrudModel), Cmd_OfFunc_result(new Msg(14, new ViewMode_1(0)))];
        }
        case 12: {
            return [model, Cmd_OfAsyncWith_either((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, securedApi(token).editPackage, model.SelectedPackage, (arg0_22) => (new Msg(13, arg0_22)), (arg0_23) => (new Msg(0, arg0_23)))];
        }
        case 13: {
            return [new Model(map((package$_1) => {
                if (package$_1.packageId === updatedPackage.packageId) {
                    return updatedPackage;
                }
                else {
                    return package$_1;
                }
            }, model.Packages), model.ParentPractice, model.SortInfo, model.SelectedPackage, model.ViewMode, model.CurrentRoles, model.SectionsCrudModel), Cmd_batch(ofArray([success("Successfully updated package"), Cmd_OfFunc_result(new Msg(2, updatedPackage.practiceId))]))];
        }
        case 14: {
            return [model, error(ErrorMessage_get_describe()(e))];
        }
        case 15: {
            switch (mode.tag) {
                case 3: {
                    return [new Model(model.Packages, model.ParentPractice, model.SortInfo, (inputRecord_2 = emptyPackageWithDefaults(model.ParentPractice), new PackageViewModel(inputRecord_2.packageId, practiceId, inputRecord_2.name, inputRecord_2.description, inputRecord_2.procedure, inputRecord_2.specialty, inputRecord_2.language, inputRecord_2.title, inputRecord_2.intro, inputRecord_2.sections, inputRecord_2.outro)), mode, model.CurrentRoles, model.SectionsCrudModel), Cmd_none()];
                }
                case 2: {
                    return [new Model(model.Packages, model.ParentPractice, model.SortInfo, model.SelectedPackage, mode, model.CurrentRoles, model.SectionsCrudModel), Cmd_none()];
                }
                case 6:
                case 7:
                case 4:
                case 5: {
                    return [new Model(model.Packages, model.ParentPractice, model.SortInfo, model.SelectedPackage, mode, model.CurrentRoles, model.SectionsCrudModel), Cmd_OfFunc_result(new Msg(18, new Types_Msg$4(2, new Shared_CrudInbound$2(0, model.SelectedPackage.sections))))];
                }
                case 0: {
                    return [new Model(model.Packages, model.ParentPractice, model.SortInfo, (inputRecord = emptyPackageWithDefaults(model.ParentPractice), new PackageViewModel(inputRecord.packageId, practiceId, inputRecord.name, inputRecord.description, inputRecord.procedure, inputRecord.specialty, inputRecord.language, inputRecord.title, inputRecord.intro, inputRecord.sections, inputRecord.outro)), new ViewMode_1(0), model.CurrentRoles, model.SectionsCrudModel), Cmd_none()];
                }
                default: {
                    return [new Model(model.Packages, model.ParentPractice, model.SortInfo, mode.fields[0], mode, model.CurrentRoles, model.SectionsCrudModel), Cmd_none()];
                }
            }
        }
        case 16: {
            return [new Model(model.Packages, model.ParentPractice, model.SortInfo, setter(model.SelectedPackage), model.ViewMode, model.CurrentRoles, model.SectionsCrudModel), Cmd_none()];
        }
        case 17: {
            return [new Model(model.Packages, model.ParentPractice, si, model.SelectedPackage, model.ViewMode, model.CurrentRoles, model.SectionsCrudModel), Cmd_none()];
        }
        case 18: {
            return [model, (practiceId === pid) ? Cmd_OfFunc_result(new Msg(2, practiceId)) : Cmd_none()];
        }
        case 19: {
            return [model, Cmd_OfFunc_result(new Msg(4, practiceId))];
        }
        case 20: {
            return [model, Cmd_none()];
        }
        case 21: {
            switch (crudOutbound.tag) {
                case 1: {
                    return [model, error("An unexpected error occurred. Please try again.")];
                }
                case 2: {
                    const section = crudOutbound.fields[0];
                    const section_1 = new SectionViewModel(newGuid(), section.mediaId, section.mediaSpecialtyFilter, section.sectionHeading, section.sectionContents);
                    return [new Model(model.Packages, model.ParentPractice, model.SortInfo, (inputRecord_3 = model.SelectedPackage, new PackageViewModel(inputRecord_3.packageId, inputRecord_3.practiceId, inputRecord_3.name, inputRecord_3.description, inputRecord_3.procedure, inputRecord_3.specialty, inputRecord_3.language, inputRecord_3.title, inputRecord_3.intro, append_1(model.SelectedPackage.sections, singleton_1(section_1)), inputRecord_3.outro)), model.ViewMode, model.CurrentRoles, model.SectionsCrudModel), Cmd_OfFunc_result(new Msg(18, new Types_Msg$4(0, new StateMachineMsg$5(0, new CrudStateMachine_CrudTaggedStateMessages$4(0, new InternalMsg$4(3, new FSharpResult$2(0, section_1)))))))];
                }
                case 3: {
                    const section_2 = crudOutbound.fields[0];
                    return [new Model(model.Packages, model.ParentPractice, model.SortInfo, (inputRecord_4 = model.SelectedPackage, new PackageViewModel(inputRecord_4.packageId, inputRecord_4.practiceId, inputRecord_4.name, inputRecord_4.description, inputRecord_4.procedure, inputRecord_4.specialty, inputRecord_4.language, inputRecord_4.title, inputRecord_4.intro, map((s) => {
                        if (s.sectionId === section_2.sectionId) {
                            return section_2;
                        }
                        else {
                            return s;
                        }
                    }, model.SelectedPackage.sections), inputRecord_4.outro)), model.ViewMode, model.CurrentRoles, model.SectionsCrudModel), Cmd_OfFunc_result(new Msg(18, new Types_Msg$4(0, new StateMachineMsg$5(0, new CrudStateMachine_CrudTaggedStateMessages$4(1, new InternalMsg$4(3, new FSharpResult$2(0, section_2)))))))];
                }
                case 4: {
                    return [new Model(model.Packages, model.ParentPractice, model.SortInfo, (inputRecord_5 = model.SelectedPackage, new PackageViewModel(inputRecord_5.packageId, inputRecord_5.practiceId, inputRecord_5.name, inputRecord_5.description, inputRecord_5.procedure, inputRecord_5.specialty, inputRecord_5.language, inputRecord_5.title, inputRecord_5.intro, filter((s_1) => (s_1.sectionId !== crudOutbound.fields[0].sectionId), model.SelectedPackage.sections), inputRecord_5.outro)), model.ViewMode, model.CurrentRoles, model.SectionsCrudModel), Cmd_OfFunc_result(new Msg(18, new Types_Msg$4(0, new StateMachineMsg$5(0, new CrudStateMachine_CrudTaggedStateMessages$4(4, new InternalMsg$4_1(2, new FSharpResult$2(0, void 0)))))))];
                }
                default: {
                    return [model, error(ErrorMessage_get_describe()(crudOutbound.fields[0]))];
                }
            }
        }
        case 22: {
            const patternInput = State_update(new SectionsUpdateArgs(token), msg_22, model.SectionsCrudModel);
            return [new Model(model.Packages, model.ParentPractice, model.SortInfo, model.SelectedPackage, model.ViewMode, model.CurrentRoles, patternInput[0]), Cmd_map((arg0_39) => (new Msg(18, arg0_39)), patternInput[1])];
        }
    }
}

