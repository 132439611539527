import { MediaViewModel_get_mediaType_, MediaViewModel_get_language_, MediaViewModel_get_procedure_, MediaViewModel_get_specialty_, MediaViewModel_get_description_, MediaViewModel_get_name_ } from "../ThreeDocs.Models/View/MediaViewModel.js";
import { CrudControls_mkId, CrudControls_makeItemElementOptionUnion, CrudControls_makeItemElementOptionOtherSelect, CrudControls_makeItemElementUnionList, CrudControls_ItemElement$2, CrudControls_CrudFormField$2, CrudControls_makeItemElementOptionString } from "../ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { safeHash, equals, compare, uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { FormField_ReactSelect_single, FormField_ReactSelect_multiGrouped, FormField_textArea, FormField_textWithHint, FormField_onChangeStringOptic, FormField_text } from "../ThreeDocs.SharedUI/Forms.js";
import { String_fromOption } from "../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { contains, choose, map, append, ofArray, singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { CSSProp, Prop, DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Option } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Specialty_get_describe, Specialty_get_groupedList } from "../ThreeDocs.Models/Domain/Specialty.js";
import { Language_get_otherSelected, Language_dropdownDisplay_Z72E66594, Language_viewDisplay_Z72E66594, Language_get_all, Language_get_other_ } from "../ThreeDocs.Models/Domain/Language.js";
import { MediaType_get_describe, MediaType_get_all } from "../ThreeDocs.Models/Domain/MediaType.js";
import { Sort_itemListSortable, Sort_SortableColumn$1, Sort_Compare$1 } from "../ThreeDocs.SharedUI/Sort.js";
import { Permission, isAllowed, UserRole } from "../ThreeDocs.Models/Security.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Input_Option, input, radio } from "../fable_modules/Fulma.2.16.0/Elements/Form/Radio.fs.js";
import { ViewMode, MediaFilter_get_all, MediaFilter_get_describe, Msg } from "./MediaTypes.js";
import { Button_visibilityControlledCustom, Button_visibilityControlled, ComponentVisibility } from "../ThreeDocs.SharedUI/ComponentVisibility.js";
import { content, Header_icon, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { button, list as list_4, Option as Option_1 } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import * as react from "react";
import { makeItemDetailElement, FormViewConfig$2 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDetailState.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_2, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Naming_UIElement } from "../fable_modules/Webbler.Models.1.1.0/Common.fs.js";
import { FormViewConfig$2 as FormViewConfig$2_1 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDataEntryState.js";
import { view as view_1 } from "../FileManagement/FileManagementView.js";
import { uploadControl } from "../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropView.js";
import { FileComponentVisibility_get_fileSystemUpload } from "../FileManagement/Shared.js";
import { ChildMsg_ofMsg } from "../Common/Terminate.js";
import { dragDropChildAbsentStrategy } from "./MediaState.js";
import { isEmpty } from "../fable_modules/fable-library.3.7.12/Map.js";
import { content as content_1 } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";

export const itemFields = ofArray([(() => {
    let inputRecord;
    const tupledArg = MediaViewModel_get_name_();
    inputRecord = CrudControls_makeItemElementOptionString("Media", "Name", tupledArg[0], uncurry(2, tupledArg[1]), uncurry(7, (initialValue) => ((extraProps) => ((extraInputProps) => ((arg30$0040) => ((modelName_1) => ((label_1) => {
        const clo6 = FormField_text(initialValue, extraProps, extraInputProps, arg30$0040, modelName_1, label_1);
        return clo6;
    })))))));
    return new CrudControls_ItemElement$2(inputRecord.maybeList, inputRecord.maybeDetail, new CrudControls_CrudFormField$2("Name", (mvm, _arg1, dispatchChange) => {
        let onChange;
        const tupledArg_1 = MediaViewModel_get_name_();
        onChange = FormField_onChangeStringOptic(dispatchChange, tupledArg_1[0], uncurry(2, tupledArg_1[1]));
        return FormField_textWithHint("e.g. Pre Colonoscopy Video", String_fromOption(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), MediaViewModel_get_name_())(mvm)), empty(), [new Option(15, singleton(new HTMLAttr(115, 50)))], onChange, "Media", "Name")([]);
    }));
})(), (() => {
    const tupledArg_2 = MediaViewModel_get_description_();
    return CrudControls_makeItemElementOptionString("Media", "Description", tupledArg_2[0], uncurry(2, tupledArg_2[1]), uncurry(7, (initialValue_1) => ((extraProps_1) => ((extraTextAreaProps) => ((arg40$0040) => ((modelName_3) => ((label_3) => {
        const clo7_1 = FormField_textArea("e.g. This video explains how to prepare for a colonoscopy...", initialValue_1, extraProps_1, extraTextAreaProps, arg40$0040, modelName_3, label_3);
        return clo7_1;
    })))))));
})(), (() => {
    const tupledArg_3 = MediaViewModel_get_specialty_();
    return CrudControls_makeItemElementUnionList("Media", "Specialty", tupledArg_3[0], uncurry(2, tupledArg_3[1]), Specialty_get_groupedList(), Specialty_get_describe(), FormField_ReactSelect_multiGrouped);
})(), (() => {
    const tupledArg_4 = MediaViewModel_get_procedure_();
    return CrudControls_makeItemElementOptionString("Media", "Procedure", tupledArg_4[0], uncurry(2, tupledArg_4[1]), uncurry(7, (initialValue_2) => ((extraProps_2) => ((extraInputProps_1) => ((arg40$0040_2) => ((modelName_7) => ((label_6) => {
        const clo7_2 = FormField_textWithHint("e.g. Colonoscopy", initialValue_2, extraProps_2, extraInputProps_1, arg40$0040_2, modelName_7, label_6);
        return clo7_2;
    })))))));
})(), (() => {
    const tupledArg_5 = MediaViewModel_get_language_();
    const tupledArg_6 = Language_get_other_();
    return CrudControls_makeItemElementOptionOtherSelect("Media", "Language", tupledArg_5[0], uncurry(2, tupledArg_5[1]), tupledArg_6[0], tupledArg_6[1], Language_get_all(), Language_viewDisplay_Z72E66594, Language_dropdownDisplay_Z72E66594, Language_get_otherSelected(), FormField_ReactSelect_single);
})(), (() => {
    let inputRecord_1;
    const tupledArg_7 = MediaViewModel_get_mediaType_();
    inputRecord_1 = CrudControls_makeItemElementOptionUnion("Media", "Media Type", tupledArg_7[0], uncurry(2, tupledArg_7[1]), MediaType_get_all(), MediaType_get_describe(), FormField_ReactSelect_single);
    return new CrudControls_ItemElement$2(inputRecord_1.maybeList, inputRecord_1.maybeDetail, void 0);
})()]);

export function listView(model, dispatch) {
    let children;
    let allMedia;
    const matchValue = model.MediaFilter;
    switch (matchValue.tag) {
        case 1: {
            allMedia = model.PracticeMedia;
            break;
        }
        case 0: {
            allMedia = model.PublicMedia;
            break;
        }
        default: {
            allMedia = append(model.PublicMedia, model.PracticeMedia);
        }
    }
    const mediaOwnershipColumn = new Sort_SortableColumn$1("Media Ownership", (mediaVm) => {
        if (mediaVm.practiceId == null) {
            return "3Docs Media";
        }
        else {
            return "Practice Media";
        }
    }, new Sort_Compare$1(0, compare));
    const listColumns = map((field) => (new Sort_SortableColumn$1(field.label, (vm) => field.mkCellContent(vm, void 0), field.compare)), choose((_arg1) => _arg1.maybeList, itemFields));
    const mediaFilterButtons = contains(new UserRole(0), model.CurrentRoles, {
        Equals: equals,
        GetHashCode: safeHash,
    }) ? Helpers_nothing : div(empty(), singleton(div_1(empty(), map((filter) => radio(empty(), ofArray([input(ofArray([new Input_Option(2, "media-filter"), new Input_Option(1, ofArray([new DOMAttr(40, (_arg4) => {
        dispatch(new Msg(4, filter));
    }), new HTMLAttr(0, equals(model.MediaFilter, filter))]))])), MediaFilter_get_describe()(filter)])), MediaFilter_get_all()))));
    const createButtonVisibility = (isAllowed(new Permission(14))(model.CurrentRoles) ? true : isAllowed(new Permission(17))(model.CurrentRoles)) ? (new ComponentVisibility(0)) : (new ComponentVisibility(1));
    return card(empty(), ofArray([header(empty(), ofArray([Header_title(empty(), singleton("Media")), Header_icon(empty(), ofArray([mediaFilterButtons, Button_visibilityControlled(createButtonVisibility, singleton(new Option_1(18, (_arg5) => {
        dispatch(new Msg(3, new ViewMode(2)));
    })), singleton("Create Media"))]))])), content(empty(), singleton((children = [Sort_itemListSortable("Media", append(listColumns, singleton(mediaOwnershipColumn)), (media, _arg3) => {
        dispatch(new Msg(3, new ViewMode(1, media)));
    }, allMedia, (_media) => empty(), model.SortInfo, (updatedInfo, _arg2) => {
        dispatch(new Msg(5, updatedInfo));
    })], react.createElement("div", {
        className: "table-container",
        id: "MediaTable",
    }, ...children))))]));
}

export function detailView(model, dispatch) {
    const mkDetailId = (label, element) => CrudControls_mkId("Media", "Detail", label, element);
    const formViewConfig = new FormViewConfig$2(choose((_arg1) => _arg1.maybeDetail, itemFields));
    return modal(singleton(new Option_2(1, true)), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton(toText(printf("Details")))))), Card_body(empty(), singleton(makeItemDetailElement(formViewConfig, model.SelectedMedia, void 0))), Card_foot(empty(), singleton(list_4(empty(), toList(delay(() => append_1(((model.SelectedMedia.practiceId == null) ? isAllowed(new Permission(18))(model.CurrentRoles) : isAllowed(new Permission(15))(model.CurrentRoles)) ? singleton_1(button(ofArray([new Option_1(0, new Color_IColor(6)), new Option_1(17, singleton(new Prop(0, "Edit"))), new Option_1(4), new Option_1(17, ofArray([mkDetailId("Edit", new Naming_UIElement(2)), new DOMAttr(40, (_arg2) => {
        dispatch(new Msg(3, new ViewMode(3, model.SelectedMedia)));
    })]))]), singleton("Edit"))) : empty_1(), delay(() => singleton_1(button(singleton(new Option_1(17, ofArray([mkDetailId("Cancel", new Naming_UIElement(2)), new DOMAttr(40, (_arg3) => {
        dispatch(new Msg(3, new ViewMode(0)));
    })]))), singleton("Close"))))))))))]))]));
}

export function dataEntryView(model, dispatch) {
    let children_2, matchValue_2, matchValue_3;
    const dataEntryFormViewConfig = new FormViewConfig$2_1(choose((_arg1) => _arg1.maybeForm, itemFields));
    let fileUploadComponent;
    const matchValue = model.dragDropFileManagementModel.child;
    if (matchValue.tag === 0) {
        const children = [view_1((maybeFileTypeRestrictions) => uploadControl((arg) => {
            dispatch(new Msg(22, arg));
        }, maybeFileTypeRestrictions), FileComponentVisibility_get_fileSystemUpload(), matchValue.fields[0], (arg_2) => {
            dispatch(new Msg(21, ChildMsg_ofMsg(dragDropChildAbsentStrategy, arg_2)));
        }, void 0)];
        fileUploadComponent = react.createElement("div", {
            className: "block",
        }, ...children);
    }
    else {
        fileUploadComponent = Helpers_nothing;
    }
    let patternInput;
    const matchValue_1 = model.ViewMode;
    switch (matchValue_1.tag) {
        case 2: {
            patternInput = ["Create", (_arg2) => {
                dispatch(new Msg(3, new ViewMode(0)));
            }, (_arg3) => {
                dispatch(new Msg(7));
            }];
            break;
        }
        case 3: {
            patternInput = ["Edit", (_arg4) => {
                dispatch(new Msg(3, new ViewMode(1, matchValue_1.fields[0])));
            }, (_arg5) => {
                dispatch(new Msg(9));
            }];
            break;
        }
        default: {
            patternInput = ["", (value) => {
            }, (value_1) => {
            }];
        }
    }
    const action = patternInput[0];
    const mkEditId = (label, element) => CrudControls_mkId("Media", action, label, element);
    return modal(singleton(new Option_2(1, true)), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton(action)))), Card_body(empty(), singleton((children_2 = toList(delay(() => append_1(map((field) => field.mkFormField(model.SelectedMedia, void 0, (arg_3) => {
        dispatch(new Msg(2, arg_3));
    }), dataEntryFormViewConfig.formFields), delay(() => singleton_1(fileUploadComponent))))), react.createElement("form", {}, ...children_2)))), Card_foot(empty(), singleton(list_4(empty(), ofArray([Button_visibilityControlledCustom(singleton(new CSSProp(221, "0")), (matchValue_2 = model.IsSelectedMediaValid, (matchValue_2.tag === 1) ? (new ComponentVisibility(2, matchValue_2.fields[0])) : (new ComponentVisibility(0))), ofArray([new Option_1(0, new Color_IColor(6)), new Option_1(17, ofArray([mkEditId("Save", new Naming_UIElement(2)), ["style", {
        marginRight: "0.5em",
    }], new DOMAttr(40, patternInput[2])]))]), singleton("Save")), Button_visibilityControlledCustom(singleton(new CSSProp(221, "0")), (matchValue_3 = [model.dragDropFileManagementModel.child, model.ViewMode], (matchValue_3[0].tag === 0) ? ((matchValue_3[1].tag === 3) ? (isEmpty(matchValue_3[0].fields[0].DownloadableFiles) ? (new ComponentVisibility(2, "A media file must be uploaded before canceling")) : ((matchValue_3[0].tag === 0) ? ((matchValue_3[1].tag === 2) ? ((!isEmpty(matchValue_3[0].fields[0].DownloadableFiles)) ? (new ComponentVisibility(2, "Please delete the media file before canceling")) : (new ComponentVisibility(0))) : (new ComponentVisibility(0))) : (new ComponentVisibility(0)))) : ((matchValue_3[0].tag === 0) ? ((matchValue_3[1].tag === 2) ? ((!isEmpty(matchValue_3[0].fields[0].DownloadableFiles)) ? (new ComponentVisibility(2, "Please delete the media file before canceling")) : (new ComponentVisibility(0))) : (new ComponentVisibility(0))) : (new ComponentVisibility(0)))) : ((matchValue_3[0].tag === 0) ? ((matchValue_3[1].tag === 2) ? ((!isEmpty(matchValue_3[0].fields[0].DownloadableFiles)) ? (new ComponentVisibility(2, "Please delete the media file before canceling")) : (new ComponentVisibility(0))) : (new ComponentVisibility(0))) : (new ComponentVisibility(0)))), singleton(new Option_1(17, ofArray([mkEditId("Cancel", new Naming_UIElement(2)), new DOMAttr(40, patternInput[1])]))), singleton("Cancel"))]))))]))]));
}

export function view(model, dispatch) {
    let children;
    return content_1(empty(), singleton((children = toList(delay(() => append_1(singleton_1(listView(model, dispatch)), delay(() => {
        const matchValue = model.ViewMode;
        switch (matchValue.tag) {
            case 3: {
                return singleton_1(dataEntryView(model, dispatch));
            }
            case 2: {
                return singleton_1(dataEntryView(model, dispatch));
            }
            case 4:
            case 0: {
                return singleton_1(Helpers_nothing);
            }
            default: {
                return singleton_1(detailView(model, dispatch));
            }
        }
    })))), react.createElement("div", {}, ...children))));
}

