import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, list_type, string_type, option_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { Specialty$reflection } from "./Specialty.js";
import { Language$reflection } from "./Language.js";
import { MediaType$reflection } from "./MediaType.js";

export class Media extends Record {
    constructor(MediaId, PracticeId, Name, Description, S3Key, S3Url, Specialty, Procedure, Language, MediaType) {
        super();
        this.MediaId = MediaId;
        this.PracticeId = PracticeId;
        this.Name = Name;
        this.Description = Description;
        this.S3Key = S3Key;
        this.S3Url = S3Url;
        this.Specialty = Specialty;
        this.Procedure = Procedure;
        this.Language = Language;
        this.MediaType = MediaType;
    }
}

export function Media$reflection() {
    return record_type("ThreeDocs.Models.Domain.Media.Media", [], Media, () => [["MediaId", class_type("System.Guid")], ["PracticeId", option_type(class_type("System.Guid"))], ["Name", string_type], ["Description", string_type], ["S3Key", string_type], ["S3Url", string_type], ["Specialty", list_type(Specialty$reflection())], ["Procedure", string_type], ["Language", Language$reflection()], ["MediaType", MediaType$reflection()]]);
}

