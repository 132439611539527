import { SessionManagementMsg, SessionManagement_update, SessionManagement_SessionManagementModel_token__39A60A33, InactivityConfig_create_ZBA264C0, SessionManagement_init } from "../Session.js";
import { Common_EmailAddress_value_Z39D6D3C1, Common_EmailAddress_create_Z721C83C5, Security_ResetPasswordViewModel, Security_LoginViewModel, Security_AuthErrorMessage_get_describe, Security_EmailChangeViewModel_get_empty, Security_ResetPasswordViewModel_get_empty, Security_RegisterViewModel_get_empty, Security_LoginViewModel_get_empty } from "../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { InactivityModel_get_isActive_, Model_get_inactivity_, InactivityModel_get_loggedOut, InactivityModel_get_warning, Msg, Model, InactivityModel_get_init } from "./AuthTypes.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { success, error } from "../ThreeDocs.SharedUI/Toast.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { securedAuthApi, publicAuthApi } from "../Api.js";
import { Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.3.0.5/navigation.fs.js";
import { Page, AuthPage, pageHash } from "../Pages.js";

export function init() {
    const patternInput = SessionManagement_init(void 0, InactivityConfig_create_ZBA264C0(10 * 60, 60));
    return [new Model(Security_LoginViewModel_get_empty(), Security_RegisterViewModel_get_empty(), Security_ResetPasswordViewModel_get_empty(), Security_EmailChangeViewModel_get_empty(), "", void 0, patternInput[0], false, InactivityModel_get_init(), false), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(7)), Cmd_map((arg0) => (new Msg(19, arg0)), patternInput[1])]))];
}

const errorToast = (() => {
    const f1 = Security_AuthErrorMessage_get_describe();
    return (arg) => error(f1(arg));
})();

function maybeWithToken(f) {
    let f2_1;
    const value = Cmd_none();
    f2_1 = ((option_1) => defaultArg(option_1, value));
    return (arg_1) => f2_1(map(f, SessionManagement_SessionManagementModel_token__39A60A33(arg_1)));
}

export function update(msg, model) {
    let l, inputRecord_1;
    switch (msg.tag) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
            return [model, Cmd_none()];
        }
        case 19: {
            const msg_1 = msg.fields[0];
            const patternInput = SessionManagement_update(() => false, msg_1, model.sessionManagement);
            const patternInput_1 = (msg_1.tag === 7) ? [InactivityModel_get_warning(), Cmd_OfFunc_result(new Msg(20, true))] : ((msg_1.tag === 8) ? [InactivityModel_get_loggedOut(), Cmd_OfFunc_result(new Msg(6))] : [model.inactivity, Cmd_none()]);
            return [new Model(model.loginVM, model.registerVM, model.resetVM, model.emailChangeVm, model.resetRequestEmail, model.userInfo, patternInput[0], model.hideResetEmail, patternInput_1[0], model.showPassword), Cmd_batch(ofArray([Cmd_map((arg0) => (new Msg(19, arg0)), patternInput[1]), patternInput_1[1]]))];
        }
        case 20: {
            return [Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l = Model_get_inactivity_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), InactivityModel_get_isActive_())(l)))(msg.fields[0])(model), Cmd_none()];
        }
        case 1: {
            return [new Model(model.loginVM, msg.fields[0](model.registerVM), model.resetVM, model.emailChangeVm, model.resetRequestEmail, model.userInfo, model.sessionManagement, model.hideResetEmail, model.inactivity, model.showPassword), Cmd_none()];
        }
        case 2: {
            return [new Model(msg.fields[0](model.loginVM), model.registerVM, model.resetVM, model.emailChangeVm, model.resetRequestEmail, model.userInfo, model.sessionManagement, model.hideResetEmail, model.inactivity, model.showPassword), Cmd_none()];
        }
        case 3: {
            return [new Model(model.loginVM, model.registerVM, msg.fields[0](model.resetVM), model.emailChangeVm, model.resetRequestEmail, model.userInfo, model.sessionManagement, model.hideResetEmail, model.inactivity, model.showPassword), Cmd_none()];
        }
        case 4: {
            return [new Model(new Security_LoginViewModel(model.loginVM.email, ""), model.registerVM, model.resetVM, model.emailChangeVm, model.resetRequestEmail, model.userInfo, model.sessionManagement, model.hideResetEmail, model.inactivity, model.showPassword), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, publicAuthApi.login, model.loginVM, (arg0_3) => (new Msg(5, arg0_3)), (arg0_4) => (new Msg(0, arg0_4)))];
        }
        case 5: {
            const r = msg.fields[0];
            if (r.tag === 1) {
                return [model, errorToast(r.fields[0])];
            }
            else if (r.fields[0].tag === 1) {
                return [new Model(model.loginVM, model.registerVM, (inputRecord_1 = model.resetVM, new Security_ResetPasswordViewModel(model.loginVM.email, r.fields[0].fields[0], inputRecord_1.newPassword, inputRecord_1.confirmPassword)), model.emailChangeVm, model.resetRequestEmail, void 0, model.sessionManagement, true, model.inactivity, model.showPassword), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(19, new SessionManagementMsg(2))), Navigation_newUrl(pageHash(new Page(0, new AuthPage(1, void 0))))]))];
            }
            else {
                return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(19, new SessionManagementMsg(1, r.fields[0].fields[0]))), Cmd_OfFunc_result(new Msg(7))]))];
            }
        }
        case 7: {
            return [model, maybeWithToken((t_2) => Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedAuthApi(t_2).getUserInfo, void 0, (arg0_6) => (new Msg(8, arg0_6)), (arg0_7) => (new Msg(0, arg0_7))))(model.sessionManagement)];
        }
        case 8: {
            const r_1 = msg.fields[0];
            if (r_1.tag === 1) {
                return [model, errorToast(r_1.fields[0])];
            }
            else {
                return [new Model(model.loginVM, model.registerVM, model.resetVM, model.emailChangeVm, model.resetRequestEmail, r_1.fields[0], model.sessionManagement, model.hideResetEmail, model.inactivity, model.showPassword), Cmd_OfFunc_result(new Msg(18))];
            }
        }
        case 6: {
            return [new Model(model.loginVM, model.registerVM, model.resetVM, model.emailChangeVm, model.resetRequestEmail, void 0, model.sessionManagement, model.hideResetEmail, model.inactivity, model.showPassword), Cmd_batch(ofArray([maybeWithToken((t_3) => Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedAuthApi(t_3).logout, void 0, (arg0_8) => (new Msg(9, arg0_8)), (arg0_9) => (new Msg(0, arg0_9))))(model.sessionManagement), Cmd_OfFunc_result(new Msg(19, new SessionManagementMsg(2))), Navigation_newUrl(pageHash(new Page(0, new AuthPage(0))))]))];
        }
        case 9: {
            if (msg.fields[0].tag === 1) {
                return [model, Cmd_none()];
            }
            else {
                return [new Model(model.loginVM, model.registerVM, model.resetVM, model.emailChangeVm, model.resetRequestEmail, void 0, model.sessionManagement, model.hideResetEmail, model.inactivity, model.showPassword), Cmd_none()];
            }
        }
        case 10: {
            return [model, Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, publicAuthApi.register, model.registerVM, (arg0_10) => (new Msg(11, arg0_10)), (arg0_11) => (new Msg(0, arg0_11)))];
        }
        case 11: {
            const r_3 = msg.fields[0];
            if (r_3.tag === 1) {
                return [model, errorToast(r_3.fields[0])];
            }
            else {
                return [model, Cmd_batch(ofArray([success("Success"), Navigation_newUrl(pageHash(new Page(0, new AuthPage(0))))]))];
            }
        }
        case 12: {
            return [model, Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, publicAuthApi.resetPasswordWithToken, model.resetVM, (arg0_12) => (new Msg(13, arg0_12)), (arg0_13) => (new Msg(0, arg0_13)))];
        }
        case 13: {
            const r_4 = msg.fields[0];
            if (r_4.tag === 1) {
                return [model, errorToast(r_4.fields[0])];
            }
            else {
                return [model, Cmd_batch(ofArray([success("Success, please log in with your new password"), Navigation_newUrl(pageHash(new Page(0, new AuthPage(0))))]))];
            }
        }
        case 14: {
            return [new Model(model.loginVM, model.registerVM, model.resetVM, model.emailChangeVm, msg.fields[0], model.userInfo, model.sessionManagement, model.hideResetEmail, model.inactivity, model.showPassword), Cmd_none()];
        }
        case 15: {
            const matchValue = Common_EmailAddress_create_Z721C83C5(model.resetRequestEmail);
            if (matchValue.tag === 1) {
                return [model, error("Invalid e-mail address entered")];
            }
            else {
                return [new Model(model.loginVM, model.registerVM, model.resetVM, model.emailChangeVm, "", model.userInfo, model.sessionManagement, model.hideResetEmail, model.inactivity, model.showPassword), Cmd_batch(ofArray([Cmd_OfAsyncWith_attempt((x_5) => {
                    Cmd_OfAsync_start(x_5);
                }, publicAuthApi.requestPasswordResetTokenEmail, Common_EmailAddress_value_Z39D6D3C1(matchValue.fields[0]), (arg0_14) => (new Msg(0, arg0_14))), success("An email has been sent to the specified address"), Navigation_newUrl(pageHash(new Page(0, new AuthPage(0))))]))];
            }
        }
        case 16: {
            const matchValue_1 = [Common_EmailAddress_create_Z721C83C5(model.emailChangeVm.currentEmail), Common_EmailAddress_create_Z721C83C5(model.emailChangeVm.newEmail)];
            const copyOfStruct = matchValue_1[0];
            if (copyOfStruct.tag === 1) {
                return [model, error("Invalid current email address")];
            }
            else {
                const copyOfStruct_1 = matchValue_1[1];
                if (copyOfStruct_1.tag === 1) {
                    return [model, error("Invalid new email address")];
                }
                else {
                    return [model, Cmd_OfAsyncWith_either((x_6) => {
                        Cmd_OfAsync_start(x_6);
                    }, publicAuthApi.emailChangeWithToken, model.emailChangeVm, (arg0_15) => (new Msg(17, arg0_15)), (arg0_16) => (new Msg(0, arg0_16)))];
                }
            }
        }
        case 17: {
            const r_5 = msg.fields[0];
            return [model, (r_5.tag === 1) ? errorToast(r_5.fields[0]) : Cmd_batch(ofArray([success("Email successfully changed"), Navigation_newUrl(pageHash(new Page(0, new AuthPage(0))))]))];
        }
        case 21: {
            return [new Model(model.loginVM, model.registerVM, model.resetVM, model.emailChangeVm, model.resetRequestEmail, model.userInfo, model.sessionManagement, model.hideResetEmail, model.inactivity, msg.fields[0]), Cmd_none()];
        }
        default: {
            return [model, Cmd_none()];
        }
    }
}

