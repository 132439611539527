import { Union } from "../../fable-library.3.7.12/Types.js";
import { Common_parseOptions, Color_ofColor, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection, Color_IColor$reflection } from "../Common.fs.js";
import { bool_type, union_type, string_type, list_type, class_type } from "../../fable-library.3.7.12/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.7.12/MapUtil.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Color", "Props", "CustomClass", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Panel.Option", [], Option, () => [[["Item", Color_IColor$reflection()]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export class Tab_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-active", "Props", "CustomClass", "Modifiers"];
    }
}

export function Tab_Option$reflection() {
    return union_type("Fulma.Panel.Tab.Option", [], Tab_Option, () => [[["Item", bool_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export class Block_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-active", "Props", "CustomClass", "Modifiers"];
    }
}

export function Block_Option$reflection() {
    return union_type("Fulma.Panel.Block.Option", [], Block_Option, () => [[["Item", bool_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function Block_panel(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
        }
    }, "panel-block"), element, children);
}

export function Block_div(options, children) {
    return Block_panel((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

export function Block_a(options, children) {
    return Block_panel((props, children_1) => react.createElement("a", keyValueList(props, 1), ...children_1), options, children);
}

export function Block_label(options, children) {
    return Block_panel((props, children_1) => react.createElement("label", keyValueList(props, 1), ...children_1), options, children);
}

export function Block_p(options, children) {
    return Block_panel((props, children_1) => react.createElement("p", keyValueList(props, 1), ...children_1), options, children);
}

export function checkbox(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
        }
    }, "panel-block"), (props_1, children_1) => react.createElement("label", keyValueList(props_1, 1), ...children_1), children);
}

export function panel(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
            }
        }
    }, "panel"), (props_1, children_1) => react.createElement("nav", keyValueList(props_1, 1), ...children_1), children);
}

export function heading(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "panel-heading"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function tabs(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "panel-tabs"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function tab(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
        }
    }), (props_1, children_1) => react.createElement("a", keyValueList(props_1, 1), ...children_1), children);
}

export function icon(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "panel-icon"), (props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), children);
}

