import { Union } from "../../../fable-library.3.7.12/Types.js";
import { union_type, list_type, class_type, string_type } from "../../../fable-library.3.7.12/Reflection.js";
import { Common_GenericOptions__AddProp_7BFEDA81, Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions__ToReactElement_Z46A53D36, Common_parseOptions, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection } from "../../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library.3.7.12/MapUtil.js";
import { HTMLAttr } from "../../../Fable.React.7.4.3/Fable.React.Props.fs.js";
import { singleton } from "../../../fable-library.3.7.12/List.js";

export class Input_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CustomClass", "Props", "Name", "Modifiers"];
    }
}

export function Input_Option$reflection() {
    return union_type("Fulma.Radio.Input.Option", [], Input_Option, () => [[["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function radio(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "radio"), (props, children_1) => react.createElement("label", keyValueList(props, 1), ...children_1), children);
}

export function input(options) {
    return Common_GenericOptions__ToReactElement_Z46A53D36(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 0: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(123, option.fields[0]));
            }
        }
    }, "radio", singleton(new HTMLAttr(159, "radio"))), (props_1) => react.createElement("input", keyValueList(props_1, 1)));
}

