import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, union_type, list_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { PracticeViewModel$reflection } from "../ThreeDocs.Models/View/PracticeViewModel.js";
import { Notification$reflection, ErrorMessage$reflection } from "../ThreeDocs.Models/Api/Api.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { CustomShared$reflection, PracticesUpdateArgs$reflection, PracticesCrudOutbound$reflection } from "./PracticesCrudConfig.js";
import { Types_Model$4$reflection, Types_Msg$4$reflection } from "../ThreeDocs.Client.StateMachine.Crud/CrudComponent.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "GetPractices", "GetPracticesResult", "Notification", "PracticeCrudMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Practices.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(PracticeViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(PracticeViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", Notification$reflection()]], [["Item", Types_Msg$4$reflection(PracticeViewModel$reflection(), PracticesCrudOutbound$reflection(), PracticesUpdateArgs$reflection(), CustomShared$reflection())]]]);
}

export class Model extends Record {
    constructor(PracticeCrudModel) {
        super();
        this.PracticeCrudModel = PracticeCrudModel;
    }
}

export function Model$reflection() {
    return record_type("Client.Practices.Types.Model", [], Model, () => [["PracticeCrudModel", Types_Model$4$reflection(PracticeViewModel$reflection(), PracticesCrudOutbound$reflection(), PracticesUpdateArgs$reflection(), CustomShared$reflection())]]);
}

