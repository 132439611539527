import { ChildMsg_ofMsg, DefaultActions_create, DefaultActions_Types_UpdateArgs$3, DefaultActions_runUpdate, DefaultActions_Types_ChildModel$2, Child$1, ChildAbsentStrategy$1, Child_absentChildCheck } from "../Common/Terminate.js";
import { FileComponentSpecs, MaximumFiles, FileType_get_all } from "../FileManagement/Shared.js";
import { value as value_4, some, map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { update as update_2, init as init_1 } from "../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropState.js";
import { Msg, Model, MediaFilter as MediaFilter_1, ViewMode as ViewMode_1 } from "./MediaTypes.js";
import { MediaViewModel_toDomain_Z7357511, MediaViewModel, MediaViewModel_get_empty } from "../ThreeDocs.Models/View/MediaViewModel.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { ListDisplayModification_ListDisplayModification$2, Sort_SortInfo$1, Sort_SortDirection, Sort_SortableColumn$1, Sort_Compare$1 } from "../ThreeDocs.SharedUI/Sort.js";
import { length, ofArray, map as map_1, cons, tryHead, filter, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { exists, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Result_map, Result_mapError, Result_bind, ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__ReturnFrom_1505, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Model_getNumberOfAssociatedFiles_Z23C80908, InboundMsg as InboundMsg_1, FileManagementUpdateArgs, Model_tryTerminate_Z23C80908 } from "../FileManagement/FileManagementTypes.js";
import { toList as toList_1, isEmpty } from "../fable_modules/fable-library.3.7.12/Map.js";
import { FileOperationContext, ErrorMessage, ErrorMessage_get_describe } from "../ThreeDocs.Models/Api/Api.js";
import { FormValidationError_get_describe, FormValidationError } from "../ThreeDocs.SharedUI/FormValidation.js";
import { newGuid } from "../fable_modules/fable-library.3.7.12/Guid.js";
import { securedApi } from "../Api.js";
import { error } from "../ThreeDocs.SharedUI/Toast.js";
import { equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { split, printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { InOut_wrapInboundMsg } from "../Common/Common.js";
import { DragDropFileSelectorUpdateArgs, InboundMsg } from "../FileManagement/FileUploadSelectors/DragDropFileSelector/DragDropTypes.js";
import { MediaType_tryParseFromExtension_Z721C83C5 } from "../ThreeDocs.Models/Domain/MediaType.js";
import { last } from "../fable_modules/fable-library.3.7.12/Array.js";
import { init as init_2, update as update_1 } from "../FileManagement/FileManagementState.js";
import { S3Context } from "../Common/AwsCommon.js";
import { Terminate_TerminateError_get_describe } from "../ThreeDocs.Models/Shared.js";

export const dragDropChildAbsentStrategy = new ChildAbsentStrategy$1(0, (model) => Child_absentChildCheck(model.dragDropFileManagementModel.child));

export function dragDropFileSpecs(maybePracticeId) {
    return new FileComponentSpecs(FileType_get_all(), defaultArg(map((practiceId) => (`${practiceId}/`), maybePracticeId), "3docsGeneralMedia/"), new MaximumFiles(1, 1));
}

export function init(maybePracticeId, currentRoles) {
    const patternInput = init_1(dragDropFileSpecs(maybePracticeId));
    return [new Model(new ViewMode_1(0), new MediaFilter_1(2), MediaViewModel_get_empty(), new FSharpResult$2(0, void 0), new Sort_SortInfo$1(new Sort_SortableColumn$1("", (_arg1) => "", new Sort_Compare$1(0, (_arg3, _arg2) => 0)), new Sort_SortDirection(1)), currentRoles, empty(), empty(), new DefaultActions_Types_ChildModel$2(new Child$1(1), dragDropChildAbsentStrategy, "DragDrop File Management component"), patternInput[0]), Cmd_batch(toList(delay(() => append(singleton(Cmd_map((arg0_1) => (new Msg(22, arg0_1)), patternInput[1])), delay(() => ((maybePracticeId == null) ? singleton(Cmd_none()) : singleton(Cmd_OfFunc_result(new Msg(15, maybePracticeId)))))))))];
}

export function tryTerminate(model) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => {
        let child;
        return ResultComputationExpression_ResultBuilder__ReturnFrom_1505(ResultComputationExpression_result, (child = model.dragDropFileManagementModel.child, (child.tag === 1) ? (new FSharpResult$2(0, (value) => {
        })) : ((child.tag === 2) ? (new FSharpResult$2(0, (value) => {
        })) : (Model_tryTerminate_Z23C80908)(child.fields[0]))));
    }));
}

export function update(args, userInfo, maybePracticeId, msg, model) {
    let inputRecord, inputRecord_2, matchValue_2, inputRecord_4, inputRecord_5, matchValue_6, arg10_1, inputRecord_6;
    let pattern_matching_result, setter, viewMode, mediaFilter, sortInfo, mediaVm, mediaVm_1, e, practiceId, media_2, media_3, e_1, pid, ex, fileName_1;
    if (msg.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (msg.tag === 2) {
        pattern_matching_result = 1;
        setter = msg.fields[0];
    }
    else if (msg.tag === 3) {
        pattern_matching_result = 2;
        viewMode = msg.fields[0];
    }
    else if (msg.tag === 4) {
        pattern_matching_result = 3;
        mediaFilter = msg.fields[0];
    }
    else if (msg.tag === 5) {
        pattern_matching_result = 4;
        sortInfo = msg.fields[0];
    }
    else if (msg.tag === 6) {
        pattern_matching_result = 5;
    }
    else if (msg.tag === 7) {
        pattern_matching_result = 6;
    }
    else if (msg.tag === 8) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 12;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 7;
            mediaVm = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 10) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 12;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 8;
            mediaVm_1 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 9) {
        pattern_matching_result = 9;
    }
    else if (msg.tag === 11) {
        pattern_matching_result = 10;
    }
    else if (msg.tag === 12) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 12;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (msg.tag === 15) {
        pattern_matching_result = 13;
        practiceId = msg.fields[0];
    }
    else if (msg.tag === 13) {
        pattern_matching_result = 14;
    }
    else if (msg.tag === 16) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 17;
            e_1 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 15;
            media_2 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 14) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 17;
            e_1 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 16;
            media_3 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 17) {
        if (msg.fields[0].tag === 4) {
            pattern_matching_result = 18;
            pid = msg.fields[0].fields[0];
        }
        else if (msg.fields[0].tag === 5) {
            pattern_matching_result = 19;
        }
        else {
            pattern_matching_result = 20;
        }
    }
    else if (msg.tag === 0) {
        pattern_matching_result = 21;
        ex = msg.fields[0];
    }
    else if (msg.tag === 20) {
        pattern_matching_result = 22;
    }
    else if (msg.tag === 21) {
        if (msg.fields[0].chainedMsg.tag === 1) {
            if (msg.fields[0].chainedMsg.fields[0].tag === 3) {
                if (args.MaybeS3Context != null) {
                    pattern_matching_result = 23;
                    fileName_1 = msg.fields[0].chainedMsg.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 24;
                }
            }
            else {
                pattern_matching_result = 24;
            }
        }
        else {
            pattern_matching_result = 24;
        }
    }
    else {
        pattern_matching_result = 24;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, userInfo.roles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel), defaultArg(map((arg) => Cmd_OfFunc_result(new Msg(15, arg)), maybePracticeId), Cmd_OfFunc_result(new Msg(13)))];
        }
        case 1: {
            return [new Model(model.ViewMode, model.MediaFilter, setter(model.SelectedMedia), model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel), Cmd_OfFunc_result(new Msg(6))];
        }
        case 2: {
            switch (viewMode.tag) {
                case 4:
                case 0: {
                    return [new Model(viewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel), Cmd_OfFunc_result(new Msg(19))];
                }
                case 1: {
                    return [new Model(viewMode, model.MediaFilter, viewMode.fields[0], model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel), Cmd_OfFunc_result(new Msg(19))];
                }
                case 3: {
                    return [new Model(viewMode, model.MediaFilter, viewMode.fields[0], model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel), Cmd_OfFunc_result(new Msg(18))];
                }
                default: {
                    return [new Model(viewMode, model.MediaFilter, (inputRecord = MediaViewModel_get_empty(), new MediaViewModel(inputRecord.mediaId, maybePracticeId, inputRecord.name, inputRecord.description, inputRecord.s3Key, inputRecord.s3Url, inputRecord.specialty, inputRecord.procedure, inputRecord.language, inputRecord.mediaType)), model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel), Cmd_OfFunc_result(new Msg(18))];
                }
            }
        }
        case 3: {
            const _listModification = new ListDisplayModification_ListDisplayModification$2("MediaFilter", 1, (itemList) => filter((item) => {
                switch (mediaFilter.tag) {
                    case 1: {
                        return item.practiceId != null;
                    }
                    case 2: {
                        return true;
                    }
                    default: {
                        return item.practiceId == null;
                    }
                }
            }, itemList));
            return [new Model(model.ViewMode, mediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel), Cmd_none()];
        }
        case 4: {
            return [new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, sortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel), Cmd_none()];
        }
        case 5: {
            return [new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, Result_bind()(() => {
                const matchValue_1 = model.dragDropFileManagementModel.child;
                return (matchValue_1.tag === 0) ? (isEmpty(matchValue_1.fields[0].DownloadableFiles) ? (new FSharpResult$2(1, ErrorMessage_get_describe()(new ErrorMessage(4, new FormValidationError(0, "A Media File", "be uploaded"))))) : (new FSharpResult$2(0, void 0))) : (new FSharpResult$2(0, void 0));
            })(Result_mapError()((_arg1) => ((_arg1.tag === 1) ? ((_arg1.fields[0] === "S3 Key") ? "A Media File must be uploaded" : FormValidationError_get_describe()(_arg1)) : ((_arg1.tag === 2) ? ((_arg1.fields[0] === "S3 Key") ? "A Media File must be uploaded" : FormValidationError_get_describe()(_arg1)) : FormValidationError_get_describe()(_arg1))))(Result_map()((value_1) => {
            })(MediaViewModel_toDomain_Z7357511((inputRecord_2 = model.SelectedMedia, new MediaViewModel(newGuid(), inputRecord_2.practiceId, inputRecord_2.name, inputRecord_2.description, inputRecord_2.s3Key, inputRecord_2.s3Url, inputRecord_2.specialty, inputRecord_2.procedure, inputRecord_2.language, inputRecord_2.mediaType)))))), model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel), Cmd_none()];
        }
        case 6: {
            let updatedSelected;
            const inputRecord_3 = model.SelectedMedia;
            updatedSelected = (new MediaViewModel(inputRecord_3.mediaId, inputRecord_3.practiceId, inputRecord_3.name, inputRecord_3.description, (matchValue_2 = model.dragDropFileManagementModel.child, (matchValue_2.tag === 0) ? map((tuple) => tuple[0], tryHead(toList_1(matchValue_2.fields[0].DownloadableFiles))) : (void 0)), inputRecord_3.s3Url, inputRecord_3.specialty, inputRecord_3.procedure, inputRecord_3.language, inputRecord_3.mediaType));
            return [new Model(model.ViewMode, model.MediaFilter, updatedSelected, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedApi(args.Token).createMedia, updatedSelected, (arg0_6) => (new Msg(8, arg0_6)), (arg0_7) => (new Msg(0, arg0_7)))];
        }
        case 7: {
            return [(mediaVm.practiceId == null) ? (new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, cons(mediaVm, model.PublicMedia), model.dragDropFileManagementModel, model.dragDropModel)) : (new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, cons(mediaVm, model.PracticeMedia), model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel)), Cmd_OfFunc_result(new Msg(3, new ViewMode_1(1, mediaVm)))];
        }
        case 8: {
            return [(mediaVm_1.practiceId == null) ? (new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, map_1((media_1) => {
                if (media_1.mediaId === mediaVm_1.mediaId) {
                    return mediaVm_1;
                }
                else {
                    return media_1;
                }
            }, model.PublicMedia), model.dragDropFileManagementModel, model.dragDropModel)) : (new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, map_1((media) => {
                if (media.mediaId === mediaVm_1.mediaId) {
                    return mediaVm_1;
                }
                else {
                    return media;
                }
            }, model.PracticeMedia), model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel)), Cmd_OfFunc_result(new Msg(3, new ViewMode_1(1, mediaVm_1)))];
        }
        case 9: {
            let s3Key_1;
            const matchValue_5 = model.dragDropFileManagementModel.child;
            s3Key_1 = ((matchValue_5.tag === 0) ? map((tuple_1) => tuple_1[0], tryHead(toList_1(matchValue_5.fields[0].DownloadableFiles))) : (void 0));
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(args.Token).editMedia, (inputRecord_4 = model.SelectedMedia, new MediaViewModel(inputRecord_4.mediaId, inputRecord_4.practiceId, inputRecord_4.name, inputRecord_4.description, s3Key_1, inputRecord_4.s3Url, inputRecord_4.specialty, inputRecord_4.procedure, inputRecord_4.language, inputRecord_4.mediaType)), (arg0_8) => (new Msg(10, arg0_8)), (arg0_9) => (new Msg(0, arg0_9)))];
        }
        case 10: {
            return [model, Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedApi(args.Token).deleteMedia, model.SelectedMedia.mediaId, (arg0_10) => (new Msg(12, arg0_10)), (arg0_11) => (new Msg(0, arg0_11)))];
        }
        case 11: {
            return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(3, new ViewMode_1(0))), Cmd_OfFunc_result(defaultArg(map((arg0_12) => (new Msg(15, arg0_12)), maybePracticeId), new Msg(13)))]))];
        }
        case 12: {
            return [model, error(ErrorMessage_get_describe()(e))];
        }
        case 13: {
            return [model, Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, securedApi(args.Token).getAllMediaForPractice, practiceId, (arg0_13) => (new Msg(16, arg0_13)), (arg0_14) => (new Msg(0, arg0_14)))];
        }
        case 14: {
            return [model, Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, securedApi(args.Token).getAllPublicMedia, void 0, (arg0_15) => (new Msg(14, arg0_15)), (arg0_16) => (new Msg(0, arg0_16)))];
        }
        case 15: {
            return [new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, media_2, model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel), Cmd_OfFunc_result(new Msg(13))];
        }
        case 16: {
            return [new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, media_3, model.dragDropFileManagementModel, model.dragDropModel), Cmd_none()];
        }
        case 17: {
            return [model, error(ErrorMessage_get_describe()(e_1))];
        }
        case 18: {
            return [model, equals(maybePracticeId, pid) ? Cmd_OfFunc_result(new Msg(15, pid)) : Cmd_none()];
        }
        case 19: {
            return [model, Cmd_OfFunc_result(new Msg(13))];
        }
        case 20: {
            return [model, Cmd_none()];
        }
        case 21: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none()];
        }
        case 22: {
            return [model, Cmd_OfFunc_result(new Msg(22, InOut_wrapInboundMsg(new InboundMsg(0))))];
        }
        case 23: {
            return [new Model(model.ViewMode, model.MediaFilter, (inputRecord_5 = model.SelectedMedia, new MediaViewModel(inputRecord_5.mediaId, inputRecord_5.practiceId, inputRecord_5.name, inputRecord_5.description, fileName_1, `https://${value_4(args.MaybeS3Context).ActiveBucket}.s3.${value_4(args.MaybeS3Context).S3Config.Region}.amazonaws.com/`, inputRecord_5.specialty, inputRecord_5.procedure, inputRecord_5.language, MediaType_tryParseFromExtension_Z721C83C5((matchValue_6 = exists((y) => ("." === y), fileName_1.split("")), matchValue_6 ? last(split(fileName_1, ["."], null, 0)) : "")))), model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, model.dragDropModel), Cmd_OfFunc_result(new Msg(20))];
        }
        case 24: {
            if (msg.tag === 21) {
                let patternInput_1;
                const patternInput = DefaultActions_runUpdate(new DefaultActions_Types_UpdateArgs$3((msg_1, model_1) => {
                    let inputRecord_1;
                    const matchValue = [args.MaybeCredentials, args.MaybeS3Context];
                    let pattern_matching_result_1, context, credentials;
                    if (matchValue[0] != null) {
                        if (matchValue[1] != null) {
                            pattern_matching_result_1 = 0;
                            context = matchValue[1];
                            credentials = matchValue[0];
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            const args_1 = new FileManagementUpdateArgs(args.Token, credentials, context);
                            if (msg_1.tag === 1) {
                                return [model_1, Cmd_none()];
                            }
                            else {
                                return update_1(model.SelectedMedia.s3Key, new FileManagementUpdateArgs(args_1.Token, args_1.Credentials, (inputRecord_1 = args_1.Context, new S3Context(inputRecord_1.S3Config, inputRecord_1.S3Client, inputRecord_1.S3Expiry, args_1.Context.ActiveBucket))), msg_1.fields[0], model_1);
                            }
                        }
                        case 1: {
                            return [model_1, error("Missing Credentials or S3 Context")];
                        }
                    }
                }, model), model.dragDropFileManagementModel, msg.fields[0]);
                patternInput_1 = [patternInput[0], patternInput[1]];
                return [new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, patternInput_1[0], model.dragDropModel), Cmd_batch(ofArray([Cmd_map((arg0_18) => (new Msg(21, arg0_18)), patternInput_1[1]), Cmd_OfFunc_result(new Msg(6))]))];
            }
            else if (msg.tag === 19) {
                const matchValue_7 = tryTerminate(model);
                if (matchValue_7.tag === 1) {
                    return [model, error((arg10_1 = Terminate_TerminateError_get_describe()(matchValue_7.fields[0]), toText(printf("Termination failed: %O"))(arg10_1)))];
                }
                else {
                    matchValue_7.fields[0]();
                    return [new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, (inputRecord_6 = model.dragDropFileManagementModel, new DefaultActions_Types_ChildModel$2(new Child$1(2), inputRecord_6.childAbsentStrategy, inputRecord_6.childComponentName)), model.dragDropModel), Cmd_none()];
                }
            }
            else if (msg.tag === 18) {
                const fileOperationContext = defaultArg(map((arg0_19) => (new FileOperationContext(0, arg0_19)), maybePracticeId), new FileOperationContext(1));
                const createdDragDropFileManagement = init_2(dragDropFileSpecs(maybePracticeId), fileOperationContext, model.SelectedMedia.s3Key);
                const patternInput_2 = DefaultActions_create(createdDragDropFileManagement[0], createdDragDropFileManagement[1], model.dragDropFileManagementModel);
                const patternInput_3 = init_1(dragDropFileSpecs(maybePracticeId));
                return [new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, patternInput_2[0], patternInput_3[0]), Cmd_batch(ofArray([Cmd_map((arg0_20) => (new Msg(21, arg0_20)), patternInput_2[1]), Cmd_map((arg0_21) => (new Msg(22, arg0_21)), patternInput_3[1])]))];
            }
            else if (msg.tag === 22) {
                if (msg.fields[0].tag === 1) {
                    return [model, Cmd_OfFunc_result(new Msg(21, ChildMsg_ofMsg(dragDropChildAbsentStrategy, InOut_wrapInboundMsg(new InboundMsg_1(1, msg.fields[0].fields[0].fields[0])))))];
                }
                else {
                    const matchValue_8 = [args.MaybeCredentials, args.MaybeS3Context];
                    let pattern_matching_result_2, context_1, credentials_1;
                    if (matchValue_8[0] != null) {
                        if (matchValue_8[1] != null) {
                            pattern_matching_result_2 = 0;
                            context_1 = matchValue_8[1];
                            credentials_1 = matchValue_8[0];
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            if (model.dragDropFileManagementModel.child.tag === 0) {
                                const fileManagementModel = model.dragDropFileManagementModel.child.fields[0];
                                const patternInput_4 = update_2(new DragDropFileSelectorUpdateArgs(args.Token, Model_getNumberOfAssociatedFiles_Z23C80908(fileManagementModel) + length(fileManagementModel.UploadSelection), credentials_1, context_1), msg.fields[0].fields[0], model.dragDropModel);
                                return [new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, patternInput_4[0]), Cmd_batch(ofArray([Cmd_map((arg0_22) => (new Msg(22, arg0_22)), patternInput_4[1]), Cmd_OfFunc_result(new Msg(6))]))];
                            }
                            else {
                                console.debug("FileParentState::DragDropMsg while child is not active");
                                const patternInput_5 = update_2(new DragDropFileSelectorUpdateArgs(args.Token, 0, credentials_1, context_1), msg.fields[0].fields[0], model.dragDropModel);
                                return [new Model(model.ViewMode, model.MediaFilter, model.SelectedMedia, model.IsSelectedMediaValid, model.SortInfo, model.CurrentRoles, model.PracticeMedia, model.PublicMedia, model.dragDropFileManagementModel, patternInput_5[0]), Cmd_map((arg0_23) => (new Msg(22, arg0_23)), patternInput_5[1])];
                            }
                        }
                        case 1: {
                            return [model, error("Missing Credentials or S3 Context")];
                        }
                    }
                }
            }
            else {
                throw (new Error("Match failure: ThreeDocs.Client.Media.Types.Msg"));
            }
        }
    }
}

