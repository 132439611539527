import { find, remove, add, tryFind, toSeq, ofList, ofSeq, toList, empty } from "../fable_modules/fable-library.3.7.12/Map.js";
import { FileManagementViewModel_abortUpload_21D2AD00, Model_getNumberOfNovelSelections_Z23C80908, Model_getNumberOfAssociatedFiles_Z23C80908, OutboundMsg, FileManagementViewModel, LocalMsg, Model, StringDisplays_get_defaults } from "./FileManagementTypes.js";
import { singleton, length, map as map_2, ofArray, exists, empty as empty_1 } from "../fable_modules/fable-library.3.7.12/List.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_perform, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { InOut_update, InOut_wrapLocalMsg } from "../Common/Common.js";
import { value as value_5, bind, map, defaultArg, some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { FileManagementProgress } from "./Shared.js";
import { tryLast, last } from "../fable_modules/fable-library.3.7.12/Array.js";
import { split } from "../fable_modules/fable-library.3.7.12/String.js";
import { S3_DeleteObjectRequestParams, S3_DeleteObjectRequestParams_get_create, S3_ListObjectsV2RequestParams, S3_ListObjectsV2RequestParams_get_create, S3_PutObjectRequestParams, S3_PutObjectRequestParams_get_create } from "../bindings/Aws/Fable.Helpers.Aws.js";
import { Cmd_OfFunc_either, Cmd_ofSub, Cmd_batch, Cmd_OfPromise_either, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { error } from "../ThreeDocs.SharedUI/Toast.js";
import { concat, map as map_1, toList as toList_1 } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { S3ObjectViewModel } from "../ThreeDocs.Models/Api/AwsApi.js";
import { validateFiles } from "./Validation.js";
import { compare } from "../fable_modules/fable-library.3.7.12/Util.js";
import { String_pluralize } from "../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import { securedApi } from "../Api.js";
import { TelemetryAction } from "../ThreeDocs.Models/Api/Api.js";
import { getSignedUrlCmd } from "../Common/AwsCommon.js";

export function init(specs, operationContext, maybeKey) {
    let FailedUploads, FileManagementsInProgress, DownloadableFiles, SignedUrls;
    return [(FailedUploads = empty(), (FileManagementsInProgress = empty(), (DownloadableFiles = empty(), (SignedUrls = empty(), new Model(specs, StringDisplays_get_defaults(), "", empty_1(), FailedUploads, FileManagementsInProgress, empty_1(), DownloadableFiles, void 0, SignedUrls, operationContext))))), Cmd_OfFunc_result(InOut_wrapLocalMsg(new LocalMsg(3, maybeKey)))];
}

export function mkManagedUpload(fileName, s3Client, req, dispatch) {
    const managedUpload = s3Client.upload(req);
    managedUpload.on('httpUploadProgress',((progress) => {
        const matchValue = progress.total == null;
        if (matchValue) {
            console.log(some("Total size undefined, calculating..."));
        }
        else {
            dispatch(new LocalMsg(14, fileName, new FileManagementProgress(0, (progress.loaded * 100) / progress.total)));
        }
    }));
    dispatch(new LocalMsg(9, managedUpload, fileName));
}

export function hasFileWithExtension(extension, model) {
    return exists((tupledArg) => {
        const fileExtension = last(split(tupledArg[1].Name, ["."], null, 0));
        if (fileExtension === extension) {
            return true;
        }
        else {
            return ("." + fileExtension) === extension;
        }
    }, toList(model.DownloadableFiles));
}

function mkPutObjectRequest(model, bucket, fileData) {
    return S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, bucket), new S3_PutObjectRequestParams(3, model.Specs.uploadDestinationPath + fileData.file.name), new S3_PutObjectRequestParams(1, fileData.file), new S3_PutObjectRequestParams(0, "public-read")]));
}

export function updateLocal(args, msg, model) {
    let FileManagementsInProgress, tupledArg, req_1;
    switch (msg.tag) {
        case 1: {
            console.error(some(`Error: ${msg.fields[0]}`));
            return [model, Cmd_none(), Cmd_none()];
        }
        case 2: {
            return [model, error(msg.fields[0]), Cmd_none()];
        }
        case 3: {
            const prefix = defaultArg(msg.fields[0], `${model.Specs.uploadDestinationPath}/`);
            const listParams = S3_ListObjectsV2RequestParams_get_create()(new S3_ListObjectsV2RequestParams(args.Context.ActiveBucket, "/", `${prefix}`, model.Specs.uploadDestinationPath));
            const listRequest = args.Context.S3Client.listObjectsV2(listParams);
            return [model, Cmd_OfPromise_either(() => listRequest.promise(), void 0, (arg0) => (new LocalMsg(4, arg0)), (arg0_1) => (new LocalMsg(1, arg0_1))), Cmd_none()];
        }
        case 4: {
            const output = msg.fields[0];
            return [new Model(model.Specs, model.DisplayContent, model.Message, model.UploadSelection, model.FailedUploads, model.FileManagementsInProgress, defaultArg(map((arg) => toList_1(map_1((file) => (new S3ObjectViewModel(defaultArg(bind((k) => tryLast(split(k, ["/"], null, 0)), file.Key), "Unknown File"), 0, value_5(file.LastModified), value_5(file.Key))), arg)), output.Contents), empty_1()), defaultArg(map((arg_1) => ofSeq(map_1((file_1) => {
                const fileName_1 = defaultArg(map((x) => x, file_1.Key), "");
                return [fileName_1, new FileManagementViewModel(void 0, fileName_1, void 0, new FileManagementProgress(0, 0))];
            }, arg_1)), output.Contents), empty()), model.MaybeS3VmToDelete, model.SignedUrls, model.OperationContext), Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(0))];
        }
        case 5: {
            return validateFiles(msg.fields[0], msg.fields[1], model, model.Specs, (model_1) => (Model_getNumberOfAssociatedFiles_Z23C80908(model_1) + Model_getNumberOfNovelSelections_Z23C80908(model_1)));
        }
        case 6: {
            const fileDataList_1 = msg.fields[0];
            return [model, Cmd_OfFunc_result(new LocalMsg(5, fileDataList_1, (model_2) => [new Model(model_2.Specs, model_2.DisplayContent, model_2.Message, fileDataList_1, model_2.FailedUploads, model_2.FileManagementsInProgress, model_2.PathTestFiles, model_2.DownloadableFiles, model_2.MaybeS3VmToDelete, model_2.SignedUrls, model_2.OperationContext), msg.fields[1] ? Cmd_OfFunc_result(new LocalMsg(8, fileDataList_1)) : Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(1))])), Cmd_none()];
        }
        case 7: {
            const fileDataList_2 = msg.fields[0];
            return [model, Cmd_OfFunc_result(new LocalMsg(5, fileDataList_2, (model_3) => [model_3, Cmd_OfFunc_result(new LocalMsg(8, fileDataList_2)), Cmd_none()])), Cmd_none()];
        }
        case 8: {
            const fileDataList_3 = msg.fields[0];
            const fileMap = ofList(map_2((fileData) => {
                const name = model.Specs.uploadDestinationPath + fileData.file.name;
                return [name, new FileManagementViewModel(fileData, name, void 0, new FileManagementProgress(0, 0))];
            }, fileDataList_3));
            const uploadCmds = Cmd_batch(map_2((fileData_1) => {
                let fileName_2, req;
                return Cmd_ofSub((fileName_2 = (model.Specs.uploadDestinationPath + fileData_1.file.name), (req = mkPutObjectRequest(model, args.Context.ActiveBucket, fileData_1), (dispatch) => {
                    mkManagedUpload(fileName_2, args.Context.S3Client, req, dispatch);
                })));
            }, fileDataList_3));
            const numFiles = length(fileDataList_3) | 0;
            return [(FileManagementsInProgress = ofSeq(concat([toSeq(model.FileManagementsInProgress), toSeq(fileMap)]), {
                Compare: compare,
            }), new Model(model.Specs, model.DisplayContent, `Uploading ${numFiles} file${String_pluralize(numFiles)}...`, model.UploadSelection, model.FailedUploads, FileManagementsInProgress, model.PathTestFiles, model.DownloadableFiles, model.MaybeS3VmToDelete, model.SignedUrls, model.OperationContext)), Cmd_batch(ofArray([uploadCmds, Cmd_OfFunc_result((tupledArg = [empty_1(), false], new LocalMsg(6, tupledArg[0], tupledArg[1])))])), Cmd_none()];
        }
        case 9: {
            const upload = msg.fields[0];
            const fileName_3 = msg.fields[1];
            const matchValue = tryFind(fileName_3, model.FileManagementsInProgress);
            if (matchValue != null) {
                const file_2 = matchValue;
                return [new Model(model.Specs, model.DisplayContent, model.Message, model.UploadSelection, model.FailedUploads, add(fileName_3, new FileManagementViewModel(file_2.MaybeFileData, file_2.Name, upload, file_2.Progress), model.FileManagementsInProgress), model.PathTestFiles, model.DownloadableFiles, model.MaybeS3VmToDelete, model.SignedUrls, model.OperationContext), Cmd_OfPromise_either(() => upload.promise(), void 0, (sendData) => (new LocalMsg(12, fileName_3, sendData)), (e_1) => (new LocalMsg(13, fileName_3, e_1))), Cmd_OfFunc_result(new OutboundMsg(2))];
            }
            else {
                return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 10: {
            const matchValue_1 = tryFind(msg.fields[0], model.FileManagementsInProgress);
            if (matchValue_1 != null) {
                const file_3 = matchValue_1;
                return [new Model(model.Specs, model.DisplayContent, model.Message, model.UploadSelection, model.FailedUploads, remove(file_3.Name, model.FileManagementsInProgress), model.PathTestFiles, model.DownloadableFiles, model.MaybeS3VmToDelete, model.SignedUrls, model.OperationContext), Cmd_OfFunc_either((arg00) => {
                    FileManagementViewModel_abortUpload_21D2AD00(arg00);
                }, file_3, () => (new LocalMsg(11, void 0)), (arg0_4) => (new LocalMsg(1, arg0_4))), Cmd_none()];
            }
            else {
                return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 11: {
            return [model, Cmd_none(), Cmd_none()];
        }
        case 12: {
            const fileName_5 = msg.fields[0];
            return [new Model(model.Specs, model.DisplayContent, `${fileName_5} uploaded`, model.UploadSelection, model.FailedUploads, remove(fileName_5, model.FileManagementsInProgress), model.PathTestFiles, model.DownloadableFiles, model.MaybeS3VmToDelete, model.SignedUrls, model.OperationContext), Cmd_batch(ofArray([Cmd_OfFunc_result(new LocalMsg(3, fileName_5)), Cmd_OfFunc_result(new LocalMsg(6, empty_1(), false)), Cmd_OfAsyncWith_perform((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, securedApi(args.Token).telemetry, new TelemetryAction(0, model.OperationContext, singleton(fileName_5)), (_arg1) => (new LocalMsg(0)))])), Cmd_OfFunc_result(new OutboundMsg(3, fileName_5))];
        }
        case 13: {
            const fileName_6 = msg.fields[0];
            console.error(some(msg.fields[1]));
            const uploadInfo = find(fileName_6, model.FileManagementsInProgress);
            return [new Model(model.Specs, model.DisplayContent, `Failed to upload ${fileName_6}`, model.UploadSelection, add(fileName_6, uploadInfo, model.FailedUploads), remove(fileName_6, model.FileManagementsInProgress), model.PathTestFiles, model.DownloadableFiles, model.MaybeS3VmToDelete, model.SignedUrls, model.OperationContext), Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(4))];
        }
        case 14: {
            const fileName_7 = msg.fields[0];
            const matchValue_2 = tryFind(fileName_7, model.FileManagementsInProgress);
            if (matchValue_2 != null) {
                const file_4 = matchValue_2;
                return [new Model(model.Specs, model.DisplayContent, model.Message, model.UploadSelection, model.FailedUploads, add(fileName_7, new FileManagementViewModel(file_4.MaybeFileData, file_4.Name, file_4.MaybeManagedUpload, msg.fields[1]), model.FileManagementsInProgress), model.PathTestFiles, model.DownloadableFiles, model.MaybeS3VmToDelete, model.SignedUrls, model.OperationContext), Cmd_none(), Cmd_none()];
            }
            else {
                return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 15: {
            const fileName_8 = msg.fields[1];
            const cmd_1 = getSignedUrlCmd(args.Context, msg.fields[0], fileName_8, (tupledArg_1) => (new LocalMsg(16, tupledArg_1[0], tupledArg_1[1])), (arg0_6) => (new LocalMsg(1, arg0_6)));
            return [new Model(model.Specs, model.DisplayContent, `${fileName_8} uploaded`, model.UploadSelection, model.FailedUploads, model.FileManagementsInProgress, model.PathTestFiles, model.DownloadableFiles, model.MaybeS3VmToDelete, model.SignedUrls, model.OperationContext), cmd_1, Cmd_none()];
        }
        case 16: {
            const fileName_9 = msg.fields[0];
            return [new Model(model.Specs, model.DisplayContent, model.Message, model.UploadSelection, model.FailedUploads, remove(fileName_9, model.FileManagementsInProgress), model.PathTestFiles, model.DownloadableFiles, model.MaybeS3VmToDelete, add(fileName_9, msg.fields[1], model.SignedUrls), model.OperationContext), Cmd_batch(ofArray([Cmd_OfFunc_result(new LocalMsg(3, fileName_9)), Cmd_OfAsyncWith_perform((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, securedApi(args.Token).telemetry, new TelemetryAction(1, model.OperationContext, singleton(fileName_9)), (_arg2) => (new LocalMsg(0)))])), Cmd_none()];
        }
        case 19: {
            const s3Vm = msg.fields[0];
            const deleteParams = S3_DeleteObjectRequestParams_get_create()(new S3_DeleteObjectRequestParams(args.Context.ActiveBucket, s3Vm.FilePath));
            const deleteRequest = args.Context.S3Client.deleteObject(deleteParams);
            return [model, Cmd_OfPromise_either(() => deleteRequest.promise(), void 0, (output_1) => (new LocalMsg(20, s3Vm.FilePath, output_1)), (arg0_7) => (new LocalMsg(1, arg0_7))), Cmd_none()];
        }
        case 20: {
            const fileName_10 = msg.fields[0];
            return [new Model(model.Specs, model.DisplayContent, model.Message, model.UploadSelection, model.FailedUploads, model.FileManagementsInProgress, model.PathTestFiles, model.DownloadableFiles, void 0, model.SignedUrls, model.OperationContext), Cmd_batch(ofArray([Cmd_OfFunc_result(new LocalMsg(3, fileName_10)), Cmd_OfAsyncWith_perform((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, securedApi(args.Token).telemetry, new TelemetryAction(2, model.OperationContext, singleton(fileName_10)), (_arg3) => (new LocalMsg(0)))])), Cmd_OfFunc_result(new OutboundMsg(5))];
        }
        case 21: {
            const fileName_11 = msg.fields[0];
            const failedUploadInfo = find(fileName_11, model.FailedUploads);
            const newUploadInfo = new FileManagementViewModel(failedUploadInfo.MaybeFileData, failedUploadInfo.Name, void 0, new FileManagementProgress(0, 0));
            const matchValue_3 = failedUploadInfo.MaybeFileData;
            if (matchValue_3 != null) {
                const fileData_2 = matchValue_3;
                return [new Model(model.Specs, model.DisplayContent, model.Message, model.UploadSelection, remove(fileName_11, model.FailedUploads), add(fileName_11, newUploadInfo, model.FileManagementsInProgress), model.PathTestFiles, model.DownloadableFiles, model.MaybeS3VmToDelete, model.SignedUrls, model.OperationContext), Cmd_ofSub((req_1 = mkPutObjectRequest(model, args.Context.ActiveBucket, fileData_2), (dispatch_1) => {
                    mkManagedUpload(fileName_11, args.Context.S3Client, req_1, dispatch_1);
                })), Cmd_none()];
            }
            else {
                return [model, Cmd_none(), Cmd_none()];
            }
        }
        case 22: {
            return [new Model(model.Specs, model.DisplayContent, model.Message, model.UploadSelection, remove(msg.fields[0], model.FailedUploads), model.FileManagementsInProgress, model.PathTestFiles, model.DownloadableFiles, model.MaybeS3VmToDelete, model.SignedUrls, model.OperationContext), Cmd_none(), Cmd_none()];
        }
        case 17: {
            return [new Model(model.Specs, model.DisplayContent, model.Message, model.UploadSelection, model.FailedUploads, model.FileManagementsInProgress, model.PathTestFiles, model.DownloadableFiles, msg.fields[0], model.SignedUrls, model.OperationContext), Cmd_none(), Cmd_none()];
        }
        case 18: {
            return [new Model(model.Specs, model.DisplayContent, model.Message, model.UploadSelection, model.FailedUploads, model.FileManagementsInProgress, model.PathTestFiles, model.DownloadableFiles, void 0, model.SignedUrls, model.OperationContext), Cmd_none(), Cmd_none()];
        }
        default: {
            return [model, Cmd_none(), Cmd_none()];
        }
    }
}

export function updateInbound(maybeKeyFilter, _args, msg, model) {
    if (msg.tag === 1) {
        return [model, Cmd_OfFunc_result(new LocalMsg(6, msg.fields[0].files, msg.fields[0].triggerUpload)), Cmd_none()];
    }
    else {
        return [model, Cmd_OfFunc_result(new LocalMsg(3, maybeKeyFilter)), Cmd_none()];
    }
}

export function update(maybeKeyFilter, args, msg, model) {
    return InOut_update(updateLocal, (_args, msg_2, model_2) => updateInbound(maybeKeyFilter, _args, msg_2, model_2), args, msg, model);
}

