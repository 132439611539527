import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { ErrorMessage, ErrorMessage$reflection } from "../ThreeDocs.Models/Api/Api.js";
import { option_type, list_type, record_type, string_type, union_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { Permission, isAllowed, UserRole$reflection } from "../ThreeDocs.Models/Security.js";
import { PackageViewModel_get_name_, PackageViewModel_get_packageId_, PackageViewModel$reflection } from "../ThreeDocs.Models/View/PackageViewModel.js";
import { Common_EmailAddress_value_Z39D6D3C1, Common_EmailAddress$reflection } from "../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { InvitationViewModel_toDomain_3D45F2EF, InvitationViewModel, InvitationViewModel_get_empty, InvitationViewModel_get_packageId_, InvitationViewModel_get_viewed_, InvitationViewModel_get_createdAt_, InvitationViewModel_get_replyToEmail_, InvitationViewModel_get_patientEmail_, InvitationViewModel_get_patientName_ } from "../ThreeDocs.Models/View/InvitationViewModel.js";
import { Shared_CrudMachineItemConfig$4, Shared_CrudMachineServerCalls$4, CrudControls_DetailField$2, CrudControls_CrudFormField$2, CrudControls_ListColumn$2, CrudControls_makeItemElement, CrudControls_makeItemElementOptionDate, CrudControls_ItemElement$2, CrudControls_makeItemElementOptionString } from "../ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { compare, uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { FormField_ReactSelect_onChangeSingle, FormField_ReactSelect_single, FormField_text } from "../ThreeDocs.SharedUI/Forms.js";
import { choose, append as append_1, tryHead, map, empty, ofArray, singleton } from "../fable_modules/fable-library.3.7.12/List.js";
import { deleteConfirmationViewLayoutConfig, detailViewLayoutConfig, defaultSearchComponent, listViewLayoutConfig, dataEntryViewLayoutConfig, defaultListComponents, makeList, ListViewHeaderInfo } from "../ModalCrudConfigLayout.js";
import { ViewConfig$2, SearchModule_allColumnSearch, columnListTable } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudListState.js";
import { Sort_Compare$1, Sort_itemListSortable } from "../ThreeDocs.SharedUI/Sort.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Option, button as button_1 } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { flatten, defaultArg, map as map_1, toArray } from "../fable_modules/fable-library.3.7.12/Option.js";
import { DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { CrudStateMachine_CrudMachineItemViewConfig$4, CrudStateMachine_CrudMachineViewLayoutConfig$4 } from "../ThreeDocs.Client.StateMachine.Crud/CrudMachine.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61, Compose_Lens, Compose_Lens_op_GreaterMinusGreater_2536FC39 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { String_fromOption_, List_toOption_, List_mapLens_ } from "../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import { FormViewConfig$2 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDataEntryState.js";
import { FormViewConfig$2 as FormViewConfig$2_1 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDetailState.js";
import { FormViewConfig$2 as FormViewConfig$2_2 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDeleteConfirmationState.js";
import { Result_MapError, Result_Map } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { newGuid } from "../fable_modules/fable-library.3.7.12/Guid.js";
import { utcNow } from "../fable_modules/fable-library.3.7.12/DateOffset.js";
import { FormValidationError_get_describe } from "../ThreeDocs.SharedUI/FormValidation.js";
import { AsyncResult_ofError, AsyncResult_map, AsyncResult_mapError } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { securedApi } from "../Api.js";

export class InvitationsCrudOutbound extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ApiError", "CrudExceptionError"];
    }
}

export function InvitationsCrudOutbound$reflection() {
    return union_type("Client.Invitations.InvitationsCrudOutbound", [], InvitationsCrudOutbound, () => [[["Item", ErrorMessage$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export class InvitationsUpdateArgs extends Record {
    constructor(token, practiceId) {
        super();
        this.token = token;
        this.practiceId = practiceId;
    }
}

export function InvitationsUpdateArgs$reflection() {
    return record_type("Client.Invitations.InvitationsUpdateArgs", [], InvitationsUpdateArgs, () => [["token", string_type], ["practiceId", class_type("System.Guid")]]);
}

export class CustomShared extends Record {
    constructor(currentRoles, packages, replyToEmail) {
        super();
        this.currentRoles = currentRoles;
        this.packages = packages;
        this.replyToEmail = replyToEmail;
    }
}

export function CustomShared$reflection() {
    return record_type("Client.Invitations.CustomShared", [], CustomShared, () => [["currentRoles", list_type(UserRole$reflection())], ["packages", list_type(PackageViewModel$reflection())], ["replyToEmail", option_type(Common_EmailAddress$reflection())]]);
}

export function CustomShared_get_currentRoles_() {
    return [(m) => m.currentRoles, (v) => ((m_1) => (new CustomShared(v, m_1.packages, m_1.replyToEmail)))];
}

export function CustomShared_get_packages_() {
    return [(m) => m.packages, (v) => ((m_1) => (new CustomShared(m_1.currentRoles, v, m_1.replyToEmail)))];
}

export function CustomShared_get_replyToEmail_() {
    return [(m) => m.replyToEmail, (v) => ((m_1) => (new CustomShared(m_1.currentRoles, m_1.packages, v)))];
}

export const InvitationsCrudConfig_itemFields = ofArray([(() => {
    const tupledArg = InvitationViewModel_get_patientName_();
    return CrudControls_makeItemElementOptionString("Invitation", "Name", tupledArg[0], uncurry(2, tupledArg[1]), uncurry(7, (initialValue) => ((extraProps) => ((extraInputProps) => ((arg30$0040) => ((modelName_1) => ((label_1) => {
        const clo6 = FormField_text(initialValue, extraProps, extraInputProps, arg30$0040, modelName_1, label_1);
        return clo6;
    })))))));
})(), (() => {
    const tupledArg_1 = InvitationViewModel_get_patientEmail_();
    return CrudControls_makeItemElementOptionString("Invitation", "Patient Email", tupledArg_1[0], uncurry(2, tupledArg_1[1]), uncurry(7, (initialValue_1) => ((extraProps_1) => ((extraInputProps_1) => ((arg30$0040_1) => ((modelName_3) => ((label_3) => {
        const clo6_1 = FormField_text(initialValue_1, extraProps_1, extraInputProps_1, arg30$0040_1, modelName_3, label_3);
        return clo6_1;
    })))))));
})(), (() => {
    let inputRecord;
    const tupledArg_2 = InvitationViewModel_get_replyToEmail_();
    inputRecord = CrudControls_makeItemElementOptionString("Invitation", "Reply-To Email", tupledArg_2[0], uncurry(2, tupledArg_2[1]), uncurry(7, (initialValue_2) => ((extraProps_2) => ((extraInputProps_2) => ((arg30$0040_2) => ((modelName_5) => ((label_5) => {
        const clo6_2 = FormField_text(initialValue_2, extraProps_2, extraInputProps_2, arg30$0040_2, modelName_5, label_5);
        return clo6_2;
    })))))));
    return new CrudControls_ItemElement$2(void 0, inputRecord.maybeDetail, inputRecord.maybeForm);
})(), (() => {
    let inputRecord_1;
    const tupledArg_3 = InvitationViewModel_get_createdAt_();
    inputRecord_1 = CrudControls_makeItemElementOptionDate("Invitation", "Date Sent", tupledArg_3[0], uncurry(2, tupledArg_3[1]));
    return new CrudControls_ItemElement$2(inputRecord_1.maybeList, inputRecord_1.maybeDetail, void 0);
})(), CrudControls_makeItemElement("Viewed", [InvitationViewModel_get_viewed_(), (vm) => (vm.viewed ? "Yes" : "No")], (model) => singleton(model.viewed ? "Yes" : "No"), uncurry(3, void 0), uncurry(2, void 0))]);

export function InvitationsCrudConfig_generalViewLayoutConfig(columns, isSameItem) {
    const listViewHeaderInfo = new ListViewHeaderInfo("Invitations", "Create Invitation");
    const canEdit = (_arg1, sharedModel) => isAllowed(new Permission(24))(sharedModel.CustomStorage.currentRoles);
    const extraListButtons = empty();
    const listView = (selected_1, shared_1) => makeList(defaultListComponents, shared_1, columnListTable((columns_1, selectItem, items, extraItem, sortInfo, updateSortInfo) => Sort_itemListSortable(listViewHeaderInfo.title, columns_1, uncurry(2, selectItem), items, extraItem, sortInfo, uncurry(2, updateSortInfo)), shared_1, columns, selected_1, (value) => {
    }), listViewHeaderInfo, void 0, map((func) => func((value_1) => {
    }), extraListButtons));
    return new CrudStateMachine_CrudMachineViewLayoutConfig$4(dataEntryViewLayoutConfig(empty(), listView, void 0, listViewHeaderInfo.title, "Invitation", "Edit"), dataEntryViewLayoutConfig(empty(), listView, void 0, listViewHeaderInfo.title, "Invitation", "Create"), listViewLayoutConfig((sharedModel_1, listViewHeaderInfo_1, buttons) => toList(delay(() => append(singleton_1(defaultSearchComponent(sharedModel_1, listViewHeaderInfo_1, buttons)), delay(() => (canEdit(void 0, sharedModel_1) ? singleton_1(button_1(toList(delay(() => append(singleton_1(new Option(4)), delay(() => ofArray(toArray(map_1((button) => (new Option(17, singleton(new DOMAttr(40, button.createItem.action)))), buttons))))))), singleton(listViewHeaderInfo_1.createButtonText))) : empty_1()))))), listViewHeaderInfo, extraListButtons), detailViewLayoutConfig((_arg2, _arg1_1) => false, canEdit, empty(), listView, listViewHeaderInfo.title), deleteConfirmationViewLayoutConfig(empty(), listView, void 0, listViewHeaderInfo.title));
}

export const InvitationsCrudConfig_invitationVmViewConfig = (() => {
    const packageLens = (sharedStorage) => {
        let tupledArg;
        let l_4;
        let l_2;
        const l = InvitationViewModel_get_packageId_();
        l_2 = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), [singleton, (arg) => defaultArg(tryHead(arg), "00000000-0000-0000-0000-000000000000")])(l);
        l_4 = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), (tupledArg = PackageViewModel_get_packageId_(), List_mapLens_(tupledArg[0], uncurry(2, tupledArg[1]), sharedStorage.packages)))(l_2);
        return Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), List_toOption_())(l_4);
    };
    const listViewConfig = new ViewConfig$2(append_1(choose((_arg1) => _arg1.maybeList, InvitationsCrudConfig_itemFields), singleton(new CrudControls_ListColumn$2("Package", (invitationVm_2, sharedStorage_3) => defaultArg(flatten(map_1((x_1) => x_1.name, Optic_Get_op_HatDot_21762A61(new Optic_Get(0), packageLens(sharedStorage_3))(invitationVm_2))), ""), new Sort_Compare$1(0, compare)))), SearchModule_allColumnSearch);
    const editFormViewConfig = new FormViewConfig$2(append_1(choose((_arg2) => _arg2.maybeForm, InvitationsCrudConfig_itemFields), singleton(new CrudControls_CrudFormField$2("Package", (invitationVm, sharedStorage_1, dispatchChange) => {
        let optic, l_6;
        const packageLens_1 = packageLens(sharedStorage_1);
        return FormField_ReactSelect_single(sharedStorage_1.packages, [], (optic = ((l_6 = PackageViewModel_get_name_(), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), String_fromOption_)(l_6))), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(target)), Optic_Get_op_HatDot_21762A61(new Optic_Get(0), packageLens_1)(invitationVm), FormField_ReactSelect_onChangeSingle(dispatchChange, packageLens_1[0], uncurry(2, packageLens_1[1])), "Package", "User", []);
    }))));
    return new CrudStateMachine_CrudMachineItemViewConfig$4(listViewConfig, editFormViewConfig, editFormViewConfig, new FormViewConfig$2_1(append_1(choose((_arg3) => _arg3.maybeDetail, InvitationsCrudConfig_itemFields), singleton(new CrudControls_DetailField$2("Package", (invitationVm_1, sharedStorage_2) => singleton(defaultArg(flatten(map_1((x) => x.name, Optic_Get_op_HatDot_21762A61(new Optic_Get(0), packageLens(sharedStorage_2))(invitationVm_1))), "")))))), new FormViewConfig$2_2(empty()));
})();

export const InvitationsCrudConfig_invitationVmModelConfig = new Shared_CrudMachineItemConfig$4((customShared) => {
    const inputRecord = InvitationViewModel_get_empty();
    return new InvitationViewModel(inputRecord.invitationId, inputRecord.patientName, inputRecord.patientEmail, map_1(Common_EmailAddress_value_Z39D6D3C1, customShared.replyToEmail), inputRecord.packageId, inputRecord.practiceId, inputRecord.createdAt, inputRecord.viewed);
}, (updateArgs_2, _arg3, item_2) => {
    let f1;
    const result_1 = Result_Map((value) => {
    }, InvitationViewModel_toDomain_3D45F2EF(new InvitationViewModel(newGuid(), item_2.patientName, item_2.patientEmail, item_2.replyToEmail, item_2.packageId, updateArgs_2.practiceId, utcNow(), item_2.viewed)));
    return Result_MapError((f1 = FormValidationError_get_describe(), f1), result_1);
}, (invitationVm1, invitationVm2) => (invitationVm1.invitationId === invitationVm2.invitationId), new Shared_CrudMachineServerCalls$4((updateArgs, item) => AsyncResult_mapError((arg0) => (new InvitationsCrudOutbound(0, arg0)), securedApi(updateArgs.token).createInvitation(new InvitationViewModel(item.invitationId, item.patientName, item.patientEmail, item.replyToEmail, item.packageId, updateArgs.practiceId, item.createdAt, item.viewed))), (updateArgs_1, item_1) => AsyncResult_map(() => item_1, AsyncResult_mapError((arg0_1) => (new InvitationsCrudOutbound(0, arg0_1)), securedApi(updateArgs_1.token).editInvitation(item_1))), (_arg2, _arg1) => AsyncResult_ofError(new InvitationsCrudOutbound(0, new ErrorMessage(0)))), (arg0_5) => (new InvitationsCrudOutbound(1, arg0_5)));

