import { Record, Union } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { ErrorMessage, ErrorMessage$reflection } from "../../ThreeDocs.Models/Api/Api.js";
import { list_type, record_type, string_type, union_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { SectionViewModel_toDomain_4C64BA8F, SectionViewModel_get_empty, SectionViewModel, SectionViewModel_get_mediaId_, SectionViewModel_get_sectionContents_, SectionViewModel_get_sectionHeading_, SectionViewModel$reflection } from "../../ThreeDocs.Models/View/SectionViewModel.js";
import { Permission, isAllowed, UserRole$reflection } from "../../ThreeDocs.Models/Security.js";
import { MediaViewModel_get_language_, MediaViewModel_get_name_, MediaViewModel_get_mediaId_, MediaViewModel$reflection } from "../../ThreeDocs.Models/View/MediaViewModel.js";
import { Shared_CrudMachineItemConfig$4, Shared_CrudMachineServerCalls$4, CrudControls_DetailField$2, CrudControls_CrudFormField$2, CrudControls_ListColumn$2, CrudControls_makeItemElementOptionString } from "../../ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { compare, uncurry } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { FormField_ReactSelect_CallbackMulti$1, FormField_ReactSelect_multiGrouped, FormField_ReactSelect_onChangeSingle, FormField_ReactSelect_single, FormField_textArea, FormField_text } from "../../ThreeDocs.SharedUI/Forms.js";
import { ofSeq, tryHead, isEmpty, filter, tryFind, choose, append as append_1, map as map_1, empty, singleton as singleton_1, ofArray } from "../../fable_modules/fable-library.3.7.12/List.js";
import { deleteConfirmationViewLayoutConfig, detailViewLayoutConfig, listViewLayoutConfig, dataEntryViewLayoutConfig, makeList, defaultSearchComponent, ListViewHeaderInfo } from "../../ModalCrudConfigLayout.js";
import { ComponentVisibility, Button_visibilityControlled } from "../../ThreeDocs.SharedUI/ComponentVisibility.js";
import { singleton, append, delay, toList } from "../../fable_modules/fable-library.3.7.12/Seq.js";
import { Option } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { defaultArg, flatten, map, toArray } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { DOMAttr } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { ViewConfig$2, SearchModule_allColumnSearch, columnListTable } from "../../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudListState.js";
import { Sort_Compare$1, Sort_itemListSortable } from "../../ThreeDocs.SharedUI/Sort.js";
import { CrudStateMachine_CrudMachineItemViewConfig$4, CrudStateMachine_CrudMachineViewLayoutConfig$4 } from "../../ThreeDocs.Client.StateMachine.Crud/CrudMachine.js";
import { String_fromOption_, List_toOption_, List_mapLens_, List_overlaps, String_fromOption } from "../../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import { MediaType_get_describe } from "../../ThreeDocs.Models/Domain/MediaType.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61, Compose_Lens, Compose_Lens_op_GreaterMinusGreater_2536FC39 } from "../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Language_viewDisplay_Z72E66594 } from "../../ThreeDocs.Models/Domain/Language.js";
import { Specialty_get_describe, Specialty_get_groupedList } from "../../ThreeDocs.Models/Domain/Specialty.js";
import * as react from "react";
import { FormViewConfig$2 } from "../../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDataEntryState.js";
import { FormViewConfig$2 as FormViewConfig$2_1 } from "../../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDetailState.js";
import { FormViewConfig$2 as FormViewConfig$2_2 } from "../../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDeleteConfirmationState.js";
import { AsyncResult_ofError, Result_map, Result_mapError } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { FormValidationError_get_describe } from "../../ThreeDocs.SharedUI/FormValidation.js";
import { newGuid } from "../../fable_modules/fable-library.3.7.12/Guid.js";

export class SectionsCrudOutbound extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ApiError", "CrudExceptionError", "CreateSection", "EditSection", "DeleteSection"];
    }
}

export function SectionsCrudOutbound$reflection() {
    return union_type("ThreeDocs.Client.Sections.SectionsCrudOutbound", [], SectionsCrudOutbound, () => [[["Item", ErrorMessage$reflection()]], [["Item", class_type("System.Exception")]], [["Item", SectionViewModel$reflection()]], [["Item", SectionViewModel$reflection()]], [["Item", SectionViewModel$reflection()]]]);
}

export class SectionsUpdateArgs extends Record {
    constructor(token) {
        super();
        this.token = token;
    }
}

export function SectionsUpdateArgs$reflection() {
    return record_type("ThreeDocs.Client.Sections.SectionsUpdateArgs", [], SectionsUpdateArgs, () => [["token", string_type]]);
}

export class CustomShared extends Record {
    constructor(currentRoles, publicMedia, practiceMedia) {
        super();
        this.currentRoles = currentRoles;
        this.publicMedia = publicMedia;
        this.practiceMedia = practiceMedia;
    }
}

export function CustomShared$reflection() {
    return record_type("ThreeDocs.Client.Sections.CustomShared", [], CustomShared, () => [["currentRoles", list_type(UserRole$reflection())], ["publicMedia", list_type(MediaViewModel$reflection())], ["practiceMedia", list_type(MediaViewModel$reflection())]]);
}

export function CustomShared_get_currentRoles_() {
    return [(m) => m.currentRoles, (v) => ((m_1) => (new CustomShared(v, m_1.publicMedia, m_1.practiceMedia)))];
}

export function CustomShared_get_publicMedia_() {
    return [(m) => m.publicMedia, (v) => ((m_1) => (new CustomShared(m_1.currentRoles, v, m_1.practiceMedia)))];
}

export function CustomShared_get_practiceMedia_() {
    return [(m) => m.practiceMedia, (v) => ((m_1) => (new CustomShared(m_1.currentRoles, m_1.publicMedia, v)))];
}

export const SectionsCrudConfig_itemFields = ofArray([(() => {
    const tupledArg = SectionViewModel_get_sectionHeading_();
    return CrudControls_makeItemElementOptionString("Section", "Media Title", tupledArg[0], uncurry(2, tupledArg[1]), uncurry(7, (initialValue) => ((extraProps) => ((extraInputProps) => ((arg30$0040) => ((modelName_1) => ((label_1) => {
        const clo6 = FormField_text(initialValue, extraProps, extraInputProps, arg30$0040, modelName_1, label_1);
        return clo6;
    })))))));
})(), (() => {
    const tupledArg_1 = SectionViewModel_get_sectionContents_();
    return CrudControls_makeItemElementOptionString("Section", "Media Description", tupledArg_1[0], uncurry(2, tupledArg_1[1]), uncurry(7, (initialValue_1) => ((extraProps_1) => ((extraTextAreaProps) => ((arg40$0040) => ((modelName_3) => ((label_3) => {
        const clo7 = FormField_textArea("Media Description", initialValue_1, extraProps_1, extraTextAreaProps, arg40$0040, modelName_3, label_3);
        return clo7;
    })))))));
})()]);

export function SectionsCrudConfig_generalViewLayoutConfig(columns, isSameItem) {
    const listViewHeaderInfo = new ListViewHeaderInfo("Media", "Add Media");
    const createSectionVisibilityButton = (sharedModel, listViewHeaderInfo_1, buttons) => ofArray([defaultSearchComponent(sharedModel, listViewHeaderInfo_1, buttons), Button_visibilityControlled(isAllowed(new Permission(20))(sharedModel.CustomStorage.currentRoles) ? (new ComponentVisibility(0)) : (new ComponentVisibility(1)), toList(delay(() => append(singleton(new Option(4)), delay(() => ofArray(toArray(map((button) => (new Option(17, singleton_1(new DOMAttr(40, button.createItem.action)))), buttons))))))), singleton_1(listViewHeaderInfo_1.createButtonText))]);
    const extraListButtons = empty();
    const listView = (selected_1, shared_1) => makeList(createSectionVisibilityButton, shared_1, columnListTable((columns_1, selectItem, items, extraItem, sortInfo, updateSortInfo) => Sort_itemListSortable(listViewHeaderInfo.title, columns_1, uncurry(2, selectItem), items, extraItem, sortInfo, uncurry(2, updateSortInfo)), shared_1, columns, selected_1, (value) => {
    }), listViewHeaderInfo, void 0, map_1((func) => func((value_1) => {
    }), extraListButtons));
    return new CrudStateMachine_CrudMachineViewLayoutConfig$4(dataEntryViewLayoutConfig(empty(), listView, (model_1) => (new SectionsCrudOutbound(3, model_1.activeItem)), listViewHeaderInfo.title, "Media", "Edit"), dataEntryViewLayoutConfig(empty(), listView, (model) => (new SectionsCrudOutbound(2, model.activeItem)), listViewHeaderInfo.title, "Media", "Create"), listViewLayoutConfig(createSectionVisibilityButton, listViewHeaderInfo, extraListButtons), detailViewLayoutConfig((_arg2, sharedModel_2) => isAllowed(new Permission(22))(sharedModel_2.CustomStorage.currentRoles), (_arg1, sharedModel_1) => isAllowed(new Permission(21))(sharedModel_1.CustomStorage.currentRoles), empty(), listView, listViewHeaderInfo.title), deleteConfirmationViewLayoutConfig(empty(), listView, (model_2) => (new SectionsCrudOutbound(4, model_2.selected)), listViewHeaderInfo.title));
}

export const SectionsCrudConfig_SectionVmViewConfig = (() => {
    const listViewConfig = new ViewConfig$2(append_1(choose((_arg1_3) => _arg1_3.maybeList, SectionsCrudConfig_itemFields), singleton_1(new CrudControls_ListColumn$2("Media Type", (sectionVm_2, sharedStorage_2) => String_fromOption(flatten(map((media_5) => map(MediaType_get_describe(), media_5.mediaType), tryFind((media_4) => (media_4.mediaId === sectionVm_2.mediaId), append_1(sharedStorage_2.publicMedia, sharedStorage_2.practiceMedia))))), new Sort_Compare$1(0, compare)))), SearchModule_allColumnSearch);
    const editFormViewConfig = new FormViewConfig$2(append_1(choose((_arg2) => _arg2.maybeForm, SectionsCrudConfig_itemFields), singleton_1(new CrudControls_CrudFormField$2("Select Media File", (sectionVm, sharedStorage, dispatchChange) => {
        let tupledArg;
        const setVmDispatch = dispatchChange;
        const availableMedia = filter((media) => {
            const matchValue = sectionVm.mediaSpecialtyFilter;
            if (isEmpty(matchValue)) {
                return true;
            }
            else {
                return List_overlaps(matchValue, media.specialty);
            }
        }, append_1(sharedStorage.practiceMedia, sharedStorage.publicMedia));
        let mediaLens;
        let l_4;
        let l_2;
        const l = SectionViewModel_get_mediaId_();
        l_2 = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), [singleton_1, (arg) => defaultArg(tryHead(arg), "00000000-0000-0000-0000-000000000000")])(l);
        l_4 = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), (tupledArg = MediaViewModel_get_mediaId_(), List_mapLens_(tupledArg[0], uncurry(2, tupledArg[1]), availableMedia)))(l_2);
        mediaLens = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), List_toOption_())(l_4);
        const mediaSelector = FormField_ReactSelect_single(availableMedia, [], (media_1) => {
            let l_6;
            const mediaName = Optic_Get_op_HatDot_21762A61(new Optic_Get(0), (l_6 = MediaViewModel_get_name_(), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), String_fromOption_)(l_6)))(media_1);
            const mediaLanguage = defaultArg(map(Language_viewDisplay_Z72E66594, Optic_Get_op_HatDot_21762A61(new Optic_Get(0), MediaViewModel_get_language_())(media_1)), "");
            return `${mediaName} (${mediaLanguage}) `;
        }, Optic_Get_op_HatDot_21762A61(new Optic_Get(0), mediaLens)(sectionVm), FormField_ReactSelect_onChangeSingle(setVmDispatch, mediaLens[0], uncurry(2, mediaLens[1])), "Available Media", "Sections", []);
        const children = [FormField_ReactSelect_multiGrouped(Specialty_get_groupedList(), [], Specialty_get_describe(), sectionVm.mediaSpecialtyFilter, new FormField_ReactSelect_CallbackMulti$1(0, (newValue) => {
            setVmDispatch((item) => (new SectionViewModel(item.sectionId, item.mediaId, ofSeq(newValue), item.sectionHeading, item.sectionContents)));
        }), "Filter by Media Specialty", "Sections", []), mediaSelector];
        return react.createElement("div", {}, ...children);
    }))));
    return new CrudStateMachine_CrudMachineItemViewConfig$4(listViewConfig, editFormViewConfig, editFormViewConfig, new FormViewConfig$2_1(append_1(choose((_arg3) => _arg3.maybeDetail, SectionsCrudConfig_itemFields), singleton_1(new CrudControls_DetailField$2("Media File Name", (sectionVm_1, sharedStorage_1) => singleton_1(String_fromOption(map((media_3) => {
        const name = String_fromOption(media_3.name);
        const language = defaultArg(map(Language_viewDisplay_Z72E66594, media_3.language), "");
        return `${name} (${language})`;
    }, tryFind((media_2) => (media_2.mediaId === sectionVm_1.mediaId), append_1(sharedStorage_1.publicMedia, sharedStorage_1.practiceMedia))))))))), new FormViewConfig$2_2(empty()));
})();

export const SectionsCrudConfig_SectionVmModelConfig = new Shared_CrudMachineItemConfig$4((_arg1) => SectionViewModel_get_empty(), (_arg3, _arg2, item_3) => {
    let f1;
    return Result_mapError()((f1 = FormValidationError_get_describe(), f1))(Result_map()((value) => {
    })(SectionViewModel_toDomain_4C64BA8F(new SectionViewModel(newGuid(), item_3.mediaId, item_3.mediaSpecialtyFilter, item_3.sectionHeading, item_3.sectionContents))));
}, (sectionVm1, sectionVm2) => (sectionVm1.sectionId === sectionVm2.sectionId), new Shared_CrudMachineServerCalls$4((updateArgs, item) => AsyncResult_ofError(new SectionsCrudOutbound(0, new ErrorMessage(0))), (updateArgs_1, item_1) => AsyncResult_ofError(new SectionsCrudOutbound(0, new ErrorMessage(0))), (updateArgs_2, item_2) => AsyncResult_ofError(new SectionsCrudOutbound(0, new ErrorMessage(0)))), (arg0_4) => (new SectionsCrudOutbound(1, arg0_4)));

