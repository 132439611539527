import { empty } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { LocalMsg, OutboundMsg, Model } from "./DragDropTypes.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { validateFiles } from "../../Validation.js";
import { Cmd_OfFunc_result } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { UploadSelection } from "../../Shared.js";
import { error } from "../../../ThreeDocs.SharedUI/Toast.js";
import { InOut_update } from "../../../Common/Common.js";

export function init(specs) {
    return [new Model(specs, empty(), empty()), Cmd_none()];
}

export function updateLocal(args, msg, model) {
    switch (msg.tag) {
        case 2: {
            return validateFiles(msg.fields[0], msg.fields[1], model, model.Specs, (_model) => args.numberOfAssociatedFiles);
        }
        case 1: {
            const files = msg.fields[0];
            return [model, Cmd_OfFunc_result(new LocalMsg(2, files, (model_1) => [new Model(model_1.Specs, model_1.PathTestFiles, files), Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(0, new UploadSelection(files, false)))])), Cmd_none()];
        }
        default: {
            return [model, error(msg.fields[0]), Cmd_none()];
        }
    }
}

export function updateInbound(_args, msg, model) {
    return [new Model(model.Specs, model.PathTestFiles, empty()), Cmd_none(), Cmd_none()];
}

export function update(args, msg, model) {
    return InOut_update(updateLocal, updateInbound, args, msg, model);
}

