import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class Section extends Record {
    constructor(SectionId, MediaId, SectionHeading, SectionContents) {
        super();
        this.SectionId = SectionId;
        this.MediaId = MediaId;
        this.SectionHeading = SectionHeading;
        this.SectionContents = SectionContents;
    }
}

export function Section$reflection() {
    return record_type("ThreeDocs.Models.Domain.Section.Section", [], Section, () => [["SectionId", class_type("System.Guid")], ["MediaId", class_type("System.Guid")], ["SectionHeading", string_type], ["SectionContents", string_type]]);
}

