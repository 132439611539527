import { CrudControls_mkId } from "../ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { length, map as map_1, ofArray, singleton as singleton_1, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { emailChangeModal } from "../EmailChange/EmailChangeView.js";
import { ChildMsg_ofMsg } from "../Common/Terminate.js";
import { Msg, childAbsentStrategy } from "./UserProfileTypes.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { UserViewModelModule_toDomain, UserViewModelModule_empty } from "../ThreeDocs.Models/View/UserViewModel.js";
import { columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option, ISize, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Color_IColor, Screen } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option as Option_5, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option as Option_6, div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option as Option_1, image } from "../fable_modules/Fulma.2.16.0/Elements/Image.fs.js";
import * as react from "react";
import { h5 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { Option as Option_2, label as label_2 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { Naming_UIElement } from "../fable_modules/Webbler.Models.1.1.0/Common.fs.js";
import { input } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option as Option_3, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Common_EmailAddress_create_Z721C83C5, Common_EmailAddress_value_Z39D6D3C1, Security_UserViewModel$2, Common_Name_create } from "../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { Result_isOk, Result_toOption } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { UserRole_get_describe, UserData } from "../ThreeDocs.Models/Security.js";
import { join } from "../fable_modules/fable-library.3.7.12/String.js";
import { Specialty_get_describe } from "../ThreeDocs.Models/Domain/Specialty.js";
import { Option as Option_4, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";

export function view(model, dispatch) {
    let children_2;
    const mkId = (label, element) => CrudControls_mkId("", "Profile", label, element);
    return content(empty(), singleton_1((children_2 = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.emailChange.child, (matchValue.tag === 0) ? singleton(emailChangeModal(matchValue.fields[0], (arg_1) => {
            dispatch(new Msg(12, ChildMsg_ofMsg(childAbsentStrategy, arg_1)));
        }, (_arg1) => {
            dispatch(new Msg(10));
        }, (_arg2) => {
            dispatch(new Msg(13));
        })) : singleton(Helpers_nothing)), delay(() => {
            let children;
            return (!equals(model.userInfo, UserViewModelModule_empty)) ? singleton((children = [columns(empty(), ofArray([column(singleton_1(new Option(0, new Screen(1), new ISize(5))), singleton_1(div(empty(), singleton_1(div_1(empty(), singleton_1(image(singleton_1(new Option_1(4)), singleton_1(react.createElement("img", {
                className: "is-rounded",
                src: "blank-profile-picture.png",
            }))))))))), column(empty(), ofArray([div(empty(), ofArray([h5(empty())(singleton_1("Personal Information")), label_2(singleton_1(new Option_2(3, singleton_1(mkId("First Name", new Naming_UIElement(1, new Naming_UIElement(0)))))), singleton_1("First Name")), div_1(empty(), singleton_1(input(ofArray([new Option_3(1, new IInputType(0)), new Option_3(10, model.userInfo.name.given), new Option_3(15, ofArray([["style", {
                width: "50%",
            }], mkId("First Name", new Naming_UIElement(0))])), new Option_3(13, (e) => {
                const name = Common_Name_create(e.currentTarget.value, model.userInfo.name.family);
                dispatch(new Msg(4, (vm) => (new Security_UserViewModel$2(vm.id, vm.email, name, vm.roles, vm.userData))));
            })]))))])), div(empty(), ofArray([label_2(singleton_1(new Option_2(3, singleton_1(mkId("Last Name", new Naming_UIElement(1, new Naming_UIElement(0)))))), singleton_1("Last Name")), div_1(empty(), singleton_1(input(ofArray([new Option_3(1, new IInputType(0)), new Option_3(10, model.userInfo.name.family), new Option_3(15, ofArray([["style", {
                width: "50%",
            }], mkId("Last Name", new Naming_UIElement(0))])), new Option_3(13, (e_1) => {
                const name_1 = Common_Name_create(model.userInfo.name.given, e_1.currentTarget.value);
                dispatch(new Msg(4, (vm_1) => (new Security_UserViewModel$2(vm_1.id, vm_1.email, name_1, vm_1.roles, vm_1.userData))));
            })]))))])), div(empty(), ofArray([label_2(singleton_1(new Option_2(3, singleton_1(mkId("Reply-To Email", new Naming_UIElement(1, new Naming_UIElement(0)))))), singleton_1("Reply-To Email")), div_1(empty(), singleton_1(input(ofArray([new Option_3(1, new IInputType(0)), new Option_3(10, defaultArg(map(Common_EmailAddress_value_Z39D6D3C1, model.userInfo.userData.ReplyToEmail), "")), new Option_3(15, ofArray([["style", {
                width: "50%",
            }], mkId("Reply-To Email", new Naming_UIElement(0))])), new Option_3(13, (e_2) => {
                const email = Result_toOption(Common_EmailAddress_create_Z721C83C5(e_2.currentTarget.value));
                dispatch(new Msg(4, (vm_2) => {
                    let inputRecord;
                    return new Security_UserViewModel$2(vm_2.id, vm_2.email, vm_2.name, vm_2.roles, (inputRecord = vm_2.userData, new UserData(inputRecord.Practices, inputRecord.Specialty, email, inputRecord.Restriction, inputRecord.IsLockedOut)));
                }));
            })]))))])), div(empty(), ofArray([label_2(empty(), singleton_1("Specialty")), join(", ", map_1(Specialty_get_describe(), model.userInfo.userData.Specialty))])), div(empty(), toList(delay(() => append(singleton(label_2(empty(), singleton_1((length(model.userInfo.roles) > 1) ? "Roles" : "Role"))), delay(() => singleton(join(", ", map_1(UserRole_get_describe(), model.userInfo.roles)))))))), react.createElement("hr", {}), div(empty(), ofArray([h5(empty())(singleton_1("Email and Password")), label_2(singleton_1(new Option_2(3, singleton_1(mkId("Change Login Email Address", new Naming_UIElement(1, new Naming_UIElement(2)))))), singleton_1("Login Email")), model.userInfo.email, react.createElement("br", {}), button(ofArray([new Option_4(18, (_arg3) => {
                dispatch(new Msg(9, model.userInfo));
            }), new Option_4(17, singleton_1(mkId("Change Login Email Address", new Naming_UIElement(2))))]), singleton_1("Change Login Email Address"))])), div(singleton_1(new Option_5(4)), singleton_1(div_1(empty(), ofArray([label_2(singleton_1(new Option_2(3, singleton_1(mkId("Password Change", new Naming_UIElement(1, new Naming_UIElement(2)))))), singleton_1("Password")), button(ofArray([new Option_4(17, singleton_1(mkId("Password Change", new Naming_UIElement(2)))), new Option_4(18, (_arg4) => {
                dispatch(new Msg(7));
            })]), singleton_1("Send Change Password Email"))])))), div(empty(), singleton_1(div_1(singleton_1(new Option_6(5, singleton_1(["style", {
                width: "50%",
                float: "right",
            }]))), singleton_1(button(ofArray([new Option_4(16, !(Result_isOk(UserViewModelModule_toDomain(model.userInfo)) && model.hasChanged)), new Option_4(0, new Color_IColor(4)), new Option_4(17, singleton_1(mkId("Save", new Naming_UIElement(2)))), new Option_4(18, (_arg5) => {
                dispatch(new Msg(5));
            })]), singleton_1("Save"))))))]))]))], react.createElement("div", {}, ...children))) : singleton(Helpers_nothing);
        }));
    })), react.createElement("div", {}, ...children_2))));
}

