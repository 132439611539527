import { Record, Union } from "./fable_modules/fable-library.3.7.12/Types.js";
import { record_type, option_type, union_type, bool_type, class_type } from "./fable_modules/fable-library.3.7.12/Reflection.js";
import { Page$reflection } from "./Pages.js";
import { Model$reflection as Model$reflection_2, Msg$reflection as Msg$reflection_1 } from "./Auth/AuthTypes.js";
import { Model$reflection as Model$reflection_5, Msg$reflection as Msg$reflection_2 } from "./Practices/PracticesTypes.js";
import { Model$reflection as Model$reflection_3, Msg$reflection as Msg$reflection_3 } from "./Users/UsersTypes.js";
import { Model$reflection as Model$reflection_4, Msg$reflection as Msg$reflection_4 } from "./Invitations/InvitationsTypes.js";
import { Model$reflection as Model$reflection_6, Msg$reflection as Msg$reflection_5 } from "./Demonstration/DemonstrationPage.js";
import { Model$reflection as Model$reflection_7, Msg$reflection as Msg$reflection_6 } from "./Landing/LandingPage.js";
import { Model$reflection as Model$reflection_1, Msg$reflection as Msg$reflection_7 } from "./Notifications/Types.js";
import { Model$reflection as Model$reflection_8, Msg$reflection as Msg$reflection_8 } from "./UserProfile/UserProfileTypes.js";
import { Model$reflection as Model$reflection_9, Msg$reflection as Msg$reflection_9 } from "./Package/PackageTypes.js";
import { Model$reflection as Model$reflection_10, Msg$reflection as Msg$reflection_10 } from "./PatientPackages/PatientPackagesTypes.js";
import { Model$reflection as Model$reflection_11, Msg$reflection as Msg$reflection_11 } from "./Media/MediaTypes.js";
import { DeveloperIdentity$reflection, S3Configuration$reflection } from "./ThreeDocs.Models/Api/AwsApi.js";
import { ErrorMessage$reflection } from "./ThreeDocs.Models/Api/Api.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.12/Choice.js";
import { S3Context$reflection } from "./Common/AwsCommon.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Navigate", "AuthMsg", "PracticeMsg", "UsersMsg", "InvitationMsg", "DemonstrationMsg", "LandingMsg", "NotificationsMsg", "UserProfileMsg", "PackagesMsg", "PatientPackagesMsg", "MediaMsg", "MenuToggled", "GetS3Configuration", "GotS3Configuration", "CreateIdentityResult", "SetCredentialExpiration"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [["Item", Page$reflection()]], [["Item", Msg$reflection_1()]], [["Item", Msg$reflection_2()]], [["Item", Msg$reflection_3()]], [["Item", Msg$reflection_4()]], [["Item", Msg$reflection_5()]], [["Item", Msg$reflection_6()]], [["Item", Msg$reflection_7()]], [["Item", Msg$reflection_8()]], [["Item", Msg$reflection_9()]], [["Item", Msg$reflection_10()]], [["Item", Msg$reflection_11()]], [["Item", bool_type]], [], [["Item", S3Configuration$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [DeveloperIdentity$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", DeveloperIdentity$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item1", class_type("Fable.Import.Aws.CognitoCredentials.CognitoIdentityCredentials")], ["Item2", S3Context$reflection()]]]);
}

export class Model extends Record {
    constructor(notificationsModel, currentPage, redirectPage, authModel, usersModel, invitationsModel, practicesModel, demonstrationModel, landingModel, userProfileModel, packagesModel, patientPackagesModel, mediaModel, menuIsExpanded, s3Context, awsToken, s3Configuration, credentials) {
        super();
        this.notificationsModel = notificationsModel;
        this.currentPage = currentPage;
        this.redirectPage = redirectPage;
        this.authModel = authModel;
        this.usersModel = usersModel;
        this.invitationsModel = invitationsModel;
        this.practicesModel = practicesModel;
        this.demonstrationModel = demonstrationModel;
        this.landingModel = landingModel;
        this.userProfileModel = userProfileModel;
        this.packagesModel = packagesModel;
        this.patientPackagesModel = patientPackagesModel;
        this.mediaModel = mediaModel;
        this.menuIsExpanded = menuIsExpanded;
        this.s3Context = s3Context;
        this.awsToken = awsToken;
        this.s3Configuration = s3Configuration;
        this.credentials = credentials;
    }
}

export function Model$reflection() {
    return record_type("Client.Types.Model", [], Model, () => [["notificationsModel", Model$reflection_1()], ["currentPage", Page$reflection()], ["redirectPage", option_type(Page$reflection())], ["authModel", Model$reflection_2()], ["usersModel", Model$reflection_3()], ["invitationsModel", Model$reflection_4()], ["practicesModel", Model$reflection_5()], ["demonstrationModel", Model$reflection_6()], ["landingModel", Model$reflection_7()], ["userProfileModel", Model$reflection_8()], ["packagesModel", Model$reflection_9()], ["patientPackagesModel", Model$reflection_10()], ["mediaModel", Model$reflection_11()], ["menuIsExpanded", bool_type], ["s3Context", option_type(S3Context$reflection())], ["awsToken", option_type(DeveloperIdentity$reflection())], ["s3Configuration", option_type(S3Configuration$reflection())], ["credentials", option_type(class_type("Fable.Import.Aws.Credentials.Credentials"))]]);
}

