import { section as section_1 } from "../fable_modules/Fulma.2.16.0/Layouts/Section.fs.js";
import { append as append_1, choose, map, exists, ofArray, singleton as singleton_1, tryFind, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { String_fromOption } from "../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import * as react from "react";
import { append, singleton, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { List_Option, button, list as list_6, Option, a } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { CSSProp, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { image } from "../fable_modules/Fulma.2.16.0/Elements/Image.fs.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { footer, Header_icon, content, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { content as content_1 } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { icon } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { PackageViewModel, PackageViewModel_toDomain_4DF7C8EF, PackageViewModel_get_outro_, PackageViewModel_get_intro_, PackageViewModel_get_title_, PackageViewModel_validatePreCreateFields_4DF7C8EF, PackageViewModel_get_language_, PackageViewModel_get_specialty_, PackageViewModel_get_procedure_, PackageViewModel_get_description_, PackageViewModel_get_name_ } from "../ThreeDocs.Models/View/PackageViewModel.js";
import { CrudControls_itemWithBoldLabel, CrudControls_makeItemElementOptionOtherSelect, CrudControls_makeItemElementOptionUnion, CrudControls_makeItemElementOptionString } from "../ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { equals, uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { FormField_ReactEasyEdit_mkReactEasyEdit, FormField_ReactSelect_single, FormField_ReactSelect_singleGrouped, FormField_text } from "../ThreeDocs.SharedUI/Forms.js";
import { Specialty_get_describe, Specialty_get_groupedList } from "../ThreeDocs.Models/Domain/Specialty.js";
import { Language_get_otherSelected, Language_dropdownDisplay_Z72E66594, Language_viewDisplay_Z72E66594, Language_get_all, Language_get_other_ } from "../ThreeDocs.Models/Domain/Language.js";
import { Button_visibilityControlled, Button_visibilityControlledCustom, ComponentVisibility } from "../ThreeDocs.SharedUI/ComponentVisibility.js";
import { FormValidationError_get_describe } from "../ThreeDocs.SharedUI/FormValidation.js";
import { Msg, ViewMode } from "./PackageTypes.js";
import { Permission, isAllowed } from "../ThreeDocs.Models/Security.js";
import { Screen, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_1, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { Sort_itemListSortable, Sort_SortableColumn$1 } from "../ThreeDocs.SharedUI/Sort.js";
import { label as label_8, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option as Option_2, IInputType, input } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { View_view } from "../ThreeDocs.Client.StateMachine.Crud/CrudComponent.js";
import { newGuid } from "../fable_modules/fable-library.3.7.12/Guid.js";
import { columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option as Option_3, ISize, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";

export function generateSectionView(availableMedia, section) {
    let children, children_2, children_14;
    return section_1(empty(), ofArray([(children = [String_fromOption(section.sectionHeading)], react.createElement("h2", {}, ...children)), (children_2 = [String_fromOption(section.sectionContents)], react.createElement("p", {}, ...children_2)), (children_14 = toList(delay(() => {
        let children_12, props_8, children_4, props_6, children_10, props_12, children_8, children_6, props_4;
        const maybeMedia = tryFind((media) => (media.mediaId === section.mediaId), availableMedia);
        if (maybeMedia == null) {
            return singleton("Unable to find Media");
        }
        else {
            const media_1 = maybeMedia;
            const s3MediaLink = `${String_fromOption(media_1.s3Url)}${String_fromOption(media_1.s3Key)}`;
            const matchValue = media_1.mediaType;
            return (matchValue == null) ? singleton((children_12 = ["Unable to determine media type. Please download the file using the button below", a(singleton_1(new Option(17, singleton_1(new HTMLAttr(94, `${s3MediaLink}`)))), singleton_1("Download Media"))], react.createElement("div", {}, ...children_12))) : ((matchValue.tag === 1) ? singleton((props_8 = [new HTMLAttr(71, true), ["style", {
                height: "320",
                width: "240",
            }]], (children_4 = [(props_6 = [new HTMLAttr(149, `${s3MediaLink}`)], react.createElement("source", keyValueList(props_6, 1)))], react.createElement("video", keyValueList(props_8, 1), ...children_4)))) : ((matchValue.tag === 2) ? singleton((children_10 = [(props_12 = [["data", `https://drive.google.com/viewerng/viewer?embedded=true&url=${s3MediaLink}`], new HTMLAttr(159, "application/pdf"), ["style", {
                width: "100%",
                height: "50rem",
                border: "none",
            }]], (children_8 = [(children_6 = ["Unable to render PDF. Please download the file using the button below", a(singleton_1(new Option(17, singleton_1(new HTMLAttr(94, `${s3MediaLink}`)))), singleton_1("Download PDF"))], react.createElement("div", {}, ...children_6))], react.createElement("object", keyValueList(props_12, 1), ...children_8)))], react.createElement("div", {}, ...children_10))) : singleton(image(empty(), singleton_1((props_4 = [new HTMLAttr(149, `${s3MediaLink}`)], react.createElement("img", keyValueList(props_4, 1))))))));
        }
    })), react.createElement("div", {}, ...children_14))]));
}

export function stringDisplay(s) {
    return defaultArg(s, "");
}

export function formatPracticeAddress(practice) {
    return `${practice.address} ${practice.city}, ${practice.subdivision} `;
}

export function practiceInfo(practice) {
    let children, children_2, children_4, children_6, children_8;
    return card(empty(), ofArray([header(empty(), singleton_1(Header_title(empty(), singleton_1((children = [stringDisplay(practice.name)], react.createElement("h1", {}, ...children)))))), content(empty(), singleton_1(content_1(empty(), ofArray([(children_2 = [icon(empty(), singleton_1(Fa_i(singleton_1(new Fa_IconOption(15, "fa-solid fa-location-dot")), []))), formatPracticeAddress(practice)], react.createElement("p", {}, ...children_2)), (children_4 = [icon(empty(), singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-phone")), []))), stringDisplay(practice.phoneNumber)], react.createElement("p", {}, ...children_4)), (children_6 = [icon(empty(), singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-envelope")), []))), stringDisplay(practice.generalEnquiryEmail)], react.createElement("p", {}, ...children_6)), (children_8 = [icon(empty(), singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-globe")), []))), stringDisplay(practice.website)], react.createElement("p", {}, ...children_8))]))))]));
}

export const metaItemFields = ofArray([(() => {
    const tupledArg = PackageViewModel_get_name_();
    return CrudControls_makeItemElementOptionString("Package", "Package Name", tupledArg[0], uncurry(2, tupledArg[1]), uncurry(7, (initialValue) => ((extraProps) => ((extraInputProps) => ((arg30$0040) => ((modelName_1) => ((label_1) => {
        const clo6 = FormField_text(initialValue, extraProps, extraInputProps, arg30$0040, modelName_1, label_1);
        return clo6;
    })))))));
})(), (() => {
    const tupledArg_1 = PackageViewModel_get_description_();
    return CrudControls_makeItemElementOptionString("Package", "Description", tupledArg_1[0], uncurry(2, tupledArg_1[1]), uncurry(7, (initialValue_1) => ((extraProps_1) => ((extraInputProps_1) => ((arg30$0040_1) => ((modelName_3) => ((label_3) => {
        const clo6_1 = FormField_text(initialValue_1, extraProps_1, extraInputProps_1, arg30$0040_1, modelName_3, label_3);
        return clo6_1;
    })))))));
})(), (() => {
    const tupledArg_2 = PackageViewModel_get_procedure_();
    return CrudControls_makeItemElementOptionString("Package", "Procedure", tupledArg_2[0], uncurry(2, tupledArg_2[1]), uncurry(7, (initialValue_2) => ((extraProps_2) => ((extraInputProps_2) => ((arg30$0040_2) => ((modelName_5) => ((label_5) => {
        const clo6_2 = FormField_text(initialValue_2, extraProps_2, extraInputProps_2, arg30$0040_2, modelName_5, label_5);
        return clo6_2;
    })))))));
})(), (() => {
    const tupledArg_3 = PackageViewModel_get_specialty_();
    return CrudControls_makeItemElementOptionUnion("Package", "Specialty", tupledArg_3[0], uncurry(2, tupledArg_3[1]), Specialty_get_groupedList(), Specialty_get_describe(), FormField_ReactSelect_singleGrouped);
})(), (() => {
    const tupledArg_4 = PackageViewModel_get_language_();
    const tupledArg_5 = Language_get_other_();
    return CrudControls_makeItemElementOptionOtherSelect("Package", "Language", tupledArg_4[0], uncurry(2, tupledArg_4[1]), tupledArg_5[0], tupledArg_5[1], Language_get_all(), Language_viewDisplay_Z72E66594, Language_dropdownDisplay_Z72E66594, Language_get_otherSelected(), FormField_ReactSelect_single);
})()]);

export function metaFormModal(model, dispatch) {
    let matchValue_3, children;
    const hasSelectedChanged = !exists((package$) => equals(package$, model.SelectedPackage), model.Packages);
    let packageContentsButtonVisibility;
    const matchValue = PackageViewModel_validatePreCreateFields_4DF7C8EF(model.SelectedPackage);
    let pattern_matching_result;
    if (matchValue.tag === 0) {
        if (equals(model.ViewMode, new ViewMode(2)) && hasSelectedChanged) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            packageContentsButtonVisibility = (new ComponentVisibility(2, "You have pending changes. Please save or cancel"));
            break;
        }
        case 1: {
            packageContentsButtonVisibility = ((matchValue.tag === 1) ? (new ComponentVisibility(2, FormValidationError_get_describe()(matchValue.fields[0]))) : (new ComponentVisibility(0)));
            break;
        }
    }
    let editSaveButtonVisibility;
    if (isAllowed(new Permission(21))(model.CurrentRoles)) {
        const matchValue_1 = PackageViewModel_validatePreCreateFields_4DF7C8EF(model.SelectedPackage);
        let pattern_matching_result_1;
        if (matchValue_1.tag === 0) {
            if (!hasSelectedChanged) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                editSaveButtonVisibility = (new ComponentVisibility(2, void 0));
                break;
            }
            case 1: {
                editSaveButtonVisibility = ((matchValue_1.tag === 1) ? (new ComponentVisibility(2, FormValidationError_get_describe()(matchValue_1.fields[0]))) : (new ComponentVisibility(0)));
                break;
            }
        }
    }
    else {
        editSaveButtonVisibility = (new ComponentVisibility(1));
    }
    let patternInput;
    const matchValue_2 = model.ViewMode;
    switch (matchValue_2.tag) {
        case 2: {
            patternInput = [Button_visibilityControlledCustom(singleton_1(new CSSProp(221, "0")), editSaveButtonVisibility, ofArray([new Option(18, (_arg1) => {
                dispatch(new Msg(12));
            }), new Option(0, new Color_IColor(6)), new Option(17, singleton_1(["style", {
                marginRight: "0.5em",
            }]))]), singleton_1("Save")), new Msg(14, new ViewMode(1, defaultArg(tryFind((package$_1) => (package$_1.packageId === model.SelectedPackage.packageId), model.Packages), model.SelectedPackage)))];
            break;
        }
        case 1: {
            patternInput = [Button_visibilityControlledCustom(singleton_1(new CSSProp(221, "0")), isAllowed(new Permission(21))(model.CurrentRoles) ? (new ComponentVisibility(0)) : (new ComponentVisibility(1)), ofArray([new Option(18, (_arg2) => {
                dispatch(new Msg(14, new ViewMode(2)));
            }), new Option(0, new Color_IColor(6)), new Option(17, singleton_1(["style", {
                marginRight: "0.5em",
            }]))]), singleton_1("Edit")), new Msg(14, new ViewMode(0))];
            break;
        }
        default: {
            patternInput = [Helpers_nothing, new Msg(14, new ViewMode(0))];
        }
    }
    const editFields = map((field) => field.mkFormField(model.SelectedPackage, void 0, (arg) => {
        dispatch(new Msg(15, arg));
    }), choose((_arg3) => _arg3.maybeForm, metaItemFields));
    const displayFields = map((field_1) => CrudControls_itemWithBoldLabel(field_1.label + ": ", field_1.mkDetailField(model.SelectedPackage, void 0)), choose((_arg4) => _arg4.maybeDetail, metaItemFields));
    return modal(singleton_1(new Option_1(1, true)), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton_1(Card_title(empty(), singleton_1((matchValue_3 = model.ViewMode, (matchValue_3.tag === 2) ? "Edit Package" : ((matchValue_3.tag === 3) ? "Create Package" : ((matchValue_3.tag === 1) ? "Package Details" : "Package Details"))))))), Card_body(empty(), singleton_1((children = toList(delay(() => {
        const matchValue_4 = model.ViewMode;
        switch (matchValue_4.tag) {
            case 1: {
                return displayFields;
            }
            case 3:
            case 2: {
                return editFields;
            }
            default: {
                return singleton(Helpers_nothing);
            }
        }
    })), react.createElement("form", {}, ...children)))), Card_foot(empty(), singleton_1(list_6(empty(), ofArray([patternInput[0], Button_visibilityControlledCustom(singleton_1(new CSSProp(221, "0")), packageContentsButtonVisibility, ofArray([new Option(18, (_arg5) => {
        let matchValue_5;
        dispatch(new Msg(14, (matchValue_5 = model.ViewMode, (matchValue_5.tag === 2) ? (new ViewMode(6)) : ((matchValue_5.tag === 3) ? (new ViewMode(7)) : (new ViewMode(5))))));
    }), new Option(0, new Color_IColor(9)), new Option(17, singleton_1(["style", {
        marginRight: "0.5em",
    }]))]), singleton_1("Package Contents")), Button_visibilityControlledCustom(singleton_1(new CSSProp(221, "0")), new ComponentVisibility(0), singleton_1(new Option(18, (_arg6) => {
        dispatch(patternInput[1]);
    })), singleton_1("Cancel"))]))))]))]));
}

export function tableView(model, dispatch) {
    let children;
    const listColumns = map((field) => (new Sort_SortableColumn$1(field.label, (vm) => field.mkCellContent(vm, void 0), field.compare)), choose((_arg1) => _arg1.maybeList, metaItemFields));
    const createButtonVisibility = isAllowed(new Permission(20))(model.CurrentRoles) ? (new ComponentVisibility(0)) : (new ComponentVisibility(1));
    return card(empty(), ofArray([header(empty(), ofArray([Header_title(empty(), singleton_1("Packages")), Header_icon(empty(), singleton_1(Button_visibilityControlled(createButtonVisibility, singleton_1(new Option(18, (_arg4) => {
        dispatch(new Msg(14, new ViewMode(3)));
    })), singleton_1("Create Package"))))])), content(empty(), singleton_1((children = [Sort_itemListSortable("Packages", listColumns, (package$, _arg3) => {
        dispatch(new Msg(14, new ViewMode(1, package$)));
    }, model.Packages, (package$_1) => empty(), model.SortInfo, (updatedInfo, _arg2) => {
        dispatch(new Msg(16, updatedInfo));
    })], react.createElement("div", {
        className: "table-container",
        id: "PackagesTable",
    }, ...children))))]));
}

export function patientPreview(selectedPackage, availableMedia, dispatch) {
    let children;
    return ofArray([card(empty(), ofArray([header(empty(), singleton_1(Header_title(empty(), singleton_1((children = [String_fromOption(selectedPackage.title)], react.createElement("h1", {}, ...children)))))), content(empty(), toList(delay(() => {
        let props_2, children_2;
        return append(singleton((props_2 = [["style", {
            whiteSpace: "pre-wrap",
        }]], (children_2 = ["Dear -PatientName-\n", String_fromOption(selectedPackage.intro)], react.createElement("p", keyValueList(props_2, 1), ...children_2)))), delay(() => append(map((section) => generateSectionView(availableMedia, section), selectedPackage.sections), delay(() => {
            let props_4, children_4;
            return singleton((props_4 = [["style", {
                whiteSpace: "pre-wrap",
            }]], (children_4 = [String_fromOption(selectedPackage.outro)], react.createElement("p", keyValueList(props_4, 1), ...children_4))));
        }))));
    })))])), footer(empty(), singleton_1(button(ofArray([new Option(18, (_arg1_3) => {
        dispatch(new Msg(14, new ViewMode(5)));
    }), new Option(17, singleton_1(["style", {
        marginLeft: "auto",
        marginTop: "0.5em",
    }]))]), singleton_1("Back"))))]);
}

export function packageDetail(model, dispatch) {
    let props_2, children_2, props_4, children_4;
    const selectedPackage = model.SelectedPackage;
    const optionStringWithIcon = (arg_1) => {
        let s, children;
        return (s = String_fromOption(arg_1), (children = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-edit")), []), ` ${s}`], react.createElement("div", {
            className: "easy-edit-display",
        }, ...children)));
    };
    const canEdit = isAllowed(new Permission(21))(model.CurrentRoles);
    return ofArray([card(empty(), ofArray([header(empty(), singleton_1(Header_title(empty(), singleton_1(div(empty(), ofArray([label_8(empty(), singleton_1("Package Title")), input(ofArray([new Option_2(1, new IInputType(0)), new Option_2(12, "Package Title"), new Option_2(8, String_fromOption(selectedPackage.title)), new Option_2(13, (e) => {
        let optic, value;
        dispatch(new Msg(15, (optic = PackageViewModel_get_title_(), (value = e.currentTarget.value, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value)))));
    })]))])))))), content(empty(), singleton_1((props_2 = [["style", {
        whiteSpace: "pre-wrap",
    }]], (children_2 = [FormField_ReactEasyEdit_mkReactEasyEdit("textarea", "", selectedPackage.intro, (value_1) => {
        dispatch(new Msg(15, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), PackageViewModel_get_intro_())(value_1)));
    }, "Package", "Intro", optionStringWithIcon(selectedPackage.intro), canEdit)([])], react.createElement("span", keyValueList(props_2, 1), ...children_2))))), View_view(model.SectionsCrudModel, (arg_2) => {
        dispatch(new Msg(18, arg_2));
    }), content(empty(), singleton_1((props_4 = [["style", {
        whiteSpace: "pre-wrap",
    }]], (children_4 = [FormField_ReactEasyEdit_mkReactEasyEdit("textarea", "", selectedPackage.outro, (value_3) => {
        dispatch(new Msg(15, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), PackageViewModel_get_outro_())(value_3)));
    }, "Package", "Conclusion", optionStringWithIcon(selectedPackage.outro), canEdit)([])], react.createElement("span", keyValueList(props_4, 1), ...children_4)))))])), footer(empty(), toList(delay(() => {
        const hasSelectedChanged = !exists((package$) => equals(package$, selectedPackage), model.Packages);
        let createButtonVisibility;
        const matchValue = PackageViewModel_toDomain_4DF7C8EF(new PackageViewModel(newGuid(), newGuid(), selectedPackage.name, selectedPackage.description, selectedPackage.procedure, selectedPackage.specialty, selectedPackage.language, selectedPackage.title, selectedPackage.intro, selectedPackage.sections, selectedPackage.outro));
        let pattern_matching_result;
        if (matchValue.tag === 0) {
            if (isAllowed(new Permission(20))(model.CurrentRoles)) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                createButtonVisibility = (new ComponentVisibility(0));
                break;
            }
            case 1: {
                createButtonVisibility = ((matchValue.tag === 1) ? (new ComponentVisibility(2, FormValidationError_get_describe()(matchValue.fields[0]))) : (new ComponentVisibility(1)));
                break;
            }
        }
        let saveButtonVisibility;
        if (isAllowed(new Permission(21))(model.CurrentRoles)) {
            const matchValue_1 = PackageViewModel_toDomain_4DF7C8EF(new PackageViewModel(newGuid(), newGuid(), selectedPackage.name, selectedPackage.description, selectedPackage.procedure, selectedPackage.specialty, selectedPackage.language, selectedPackage.title, selectedPackage.intro, selectedPackage.sections, selectedPackage.outro));
            saveButtonVisibility = ((!hasSelectedChanged) ? (new ComponentVisibility(2, void 0)) : ((matchValue_1.tag === 1) ? (new ComponentVisibility(2, FormValidationError_get_describe()(matchValue_1.fields[0]))) : (new ComponentVisibility(0))));
        }
        else {
            saveButtonVisibility = (new ComponentVisibility(1));
        }
        return singleton(list_6(singleton_1(new List_Option(6, singleton_1(["style", {
            marginLeft: "auto",
            marginTop: "0.5em",
        }]))), toList(delay(() => {
            let matchValue_2;
            return append((matchValue_2 = model.ViewMode, (matchValue_2.tag === 5) ? append(singleton(Button_visibilityControlledCustom(singleton_1(new CSSProp(221, "0")), saveButtonVisibility, ofArray([new Option(18, (_arg1_2) => {
                dispatch(new Msg(12));
            }), new Option(0, new Color_IColor(6)), new Option(17, singleton_1(["style", {
                marginRight: "0.5em",
            }]))]), singleton_1("Save"))), delay(() => singleton(button(singleton_1(new Option(18, (_arg2) => {
                dispatch(new Msg(14, new ViewMode(4)));
            })), singleton_1("View Patient Preview"))))) : ((matchValue_2.tag === 6) ? append(singleton(Button_visibilityControlledCustom(singleton_1(new CSSProp(221, "0")), saveButtonVisibility, ofArray([new Option(18, (_arg1_2) => {
                dispatch(new Msg(12));
            }), new Option(0, new Color_IColor(6)), new Option(17, singleton_1(["style", {
                marginRight: "0.5em",
            }]))]), singleton_1("Save"))), delay(() => singleton(button(singleton_1(new Option(18, (_arg2) => {
                dispatch(new Msg(14, new ViewMode(4)));
            })), singleton_1("View Patient Preview"))))) : ((matchValue_2.tag === 7) ? singleton(Button_visibilityControlledCustom(singleton_1(new CSSProp(221, "0")), createButtonVisibility, ofArray([new Option(18, (_arg3) => {
                dispatch(new Msg(10));
            }), new Option(0, new Color_IColor(6)), new Option(17, singleton_1(["style", {
                marginRight: "0.5em",
            }]))]), singleton_1("Create"))) : singleton(Helpers_nothing)))), delay(() => singleton(button(singleton_1(new Option(18, (_arg4) => {
                dispatch(new Msg(14, new ViewMode(0)));
            })), singleton_1("Back")))));
        }))));
    })))]);
}

export function detailView(model, dispatch) {
    const availableMedia = append_1(model.SectionsCrudModel.CrudMachineModel.SharedModel.CustomStorage.practiceMedia, model.SectionsCrudModel.CrudMachineModel.SharedModel.CustomStorage.publicMedia);
    const children = [columns(empty(), ofArray([column(empty(), toList(delay(() => ((model.ViewMode.tag === 4) ? patientPreview(model.SelectedPackage, availableMedia, dispatch) : packageDetail(model, dispatch))))), column(singleton_1(new Option_3(0, new Screen(0), new ISize(7))), singleton_1(defaultArg(map_1(practiceInfo, model.ParentPractice), Helpers_nothing)))]))];
    return react.createElement("div", {}, ...children);
}

export function view(model, dispatch) {
    let children;
    return content_1(empty(), singleton_1((children = toList(delay(() => {
        const matchValue = model.ViewMode;
        switch (matchValue.tag) {
            case 7:
            case 4:
            case 5: {
                return singleton(detailView(model, dispatch));
            }
            case 2:
            case 3: {
                return singleton(metaFormModal(model, dispatch));
            }
            case 1: {
                return singleton(metaFormModal(model, dispatch));
            }
            case 0: {
                return singleton(tableView(model, dispatch));
            }
            default: {
                return singleton(detailView(model, dispatch));
            }
        }
    })), react.createElement("div", {}, ...children))));
}

