import { Union, Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { UserData$reflection, UserRole$reflection } from "../ThreeDocs.Models/Security.js";
import { Security_AuthErrorMessage$reflection, Security_UserInfo$2$reflection, Security_UserViewModel$2$reflection } from "../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { OutboundMsg$reflection, InboundMsg$reflection, LocalMsg$reflection, Model$reflection as Model$reflection_1 } from "../EmailChange/EmailChangeTypes.js";
import { ChildAbsentStrategy$1, Child_absentChildCheck, ChainedChildMsg$2$reflection, ChildAbsentStrategy$1$reflection, DefaultActions_Types_ChildModel$2$reflection } from "../Common/Terminate.js";
import { unit_type, lambda_type, union_type, option_type, class_type, record_type, bool_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Notification$reflection } from "../ThreeDocs.Models/Api/Api.js";
import { InOut_Msg$3$reflection } from "../Common/Common.js";

export class Model extends Record {
    constructor(userInfo, emailChange, hasChanged) {
        super();
        this.userInfo = userInfo;
        this.emailChange = emailChange;
        this.hasChanged = hasChanged;
    }
}

export function Model$reflection() {
    return record_type("Client.UserProfile.Types.Model", [], Model, () => [["userInfo", Security_UserViewModel$2$reflection(UserRole$reflection(), UserData$reflection())], ["emailChange", DefaultActions_Types_ChildModel$2$reflection(Model$reflection_1(), Model$reflection())], ["hasChanged", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "GetUserInfo", "GetUserInfoResult", "SetModelInfo", "UpdateUserInfo", "UpdateUserInfoResult", "SendResetEmail", "Notification", "StartEmailChange", "SendEmailChangeRequest", "SendEmailChangeRequestResult", "EmailChangeMsg", "TerminateEmailChange"];
    }
}

export function Msg$reflection() {
    return union_type("Client.UserProfile.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection())), Security_AuthErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", option_type(Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection()))]], [["ErrorValue", Security_AuthErrorMessage$reflection()]]])]], [["Item", lambda_type(Security_UserViewModel$2$reflection(UserRole$reflection(), UserData$reflection()), Security_UserViewModel$2$reflection(UserRole$reflection(), UserData$reflection()))]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, Security_AuthErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", Security_AuthErrorMessage$reflection()]]])]], [], [["Item", Notification$reflection()]], [["Item", Security_UserViewModel$2$reflection(UserRole$reflection(), UserData$reflection())]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, Security_AuthErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", Security_AuthErrorMessage$reflection()]]])]], [["Item", ChainedChildMsg$2$reflection(InOut_Msg$3$reflection(LocalMsg$reflection(), InboundMsg$reflection(), OutboundMsg$reflection()), ChildAbsentStrategy$1$reflection(Model$reflection()))]], []]);
}

export const childAbsentStrategy = new ChildAbsentStrategy$1(0, (model) => Child_absentChildCheck(model.emailChange.child));

