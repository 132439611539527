import { Union } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, string_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { printf, toText } from "../../fable_modules/fable-library.3.7.12/String.js";
import { ofArray } from "../../fable_modules/fable-library.3.7.12/List.js";
import { map } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { ComponentVisibility } from "../../ThreeDocs.SharedUI/ComponentVisibility.js";
import { FormData_tryParse, Extensions_String_$007CPrefix$007C_$007C } from "../Shared.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Validate_String_standardValidate } from "../../ThreeDocs.SharedUI/FormValidation.js";

export class Language extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Albanian", "Arabic", "Armenian", "BanglaBengali", "Bosnian", "Bulgarian", "CantoneseYueChinese", "Croatian", "Czech", "Danish", "Dutch", "English", "FarsiPersian", "Finnish", "French", "German", "Greek", "Gujarati", "Hebrew", "Hindi", "Hungarian", "Indonesian", "Italian", "Japanese", "Javanese", "Korean", "Latvian", "Malay", "MandarinChinese", "Mongolian", "Norwegian", "Polish", "Portuguese", "Punjabi", "Romanian", "Russian", "RwandaRundi", "Serbian", "SinhalaSriLankan", "Slovenian", "Somali", "Spanish", "Swahili", "Swedish", "TagalogPhilippino", "Tamil", "Thai", "Turkish", "Ukrainian", "Urdu", "Vietnamese", "Zulu", "Other_specify_string"];
    }
}

export function Language$reflection() {
    return union_type("ThreeDocs.Models.Domain.Language.Language", [], Language, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [["Item", string_type]]]);
}

export function Language_viewDisplay_Z72E66594(languages) {
    switch (languages.tag) {
        case 1: {
            return "Arabic";
        }
        case 2: {
            return "Armenian";
        }
        case 3: {
            return "Bangla / Bengali";
        }
        case 4: {
            return "Bosnian";
        }
        case 5: {
            return "Bulgarian";
        }
        case 6: {
            return "Cantonese / Yue Chinese";
        }
        case 7: {
            return "Croatian";
        }
        case 8: {
            return "Czech";
        }
        case 9: {
            return "Danish";
        }
        case 10: {
            return "Dutch";
        }
        case 11: {
            return "English";
        }
        case 12: {
            return "Farsi / Persian";
        }
        case 13: {
            return "Finnish";
        }
        case 14: {
            return "French";
        }
        case 15: {
            return "German";
        }
        case 16: {
            return "Greek";
        }
        case 17: {
            return "Gujarati";
        }
        case 18: {
            return "Hebrew";
        }
        case 19: {
            return "Hindi";
        }
        case 20: {
            return "Hungarian";
        }
        case 21: {
            return "Indonesian";
        }
        case 22: {
            return "Italian";
        }
        case 23: {
            return "Japanese";
        }
        case 24: {
            return "Javanese";
        }
        case 25: {
            return "Korean";
        }
        case 26: {
            return "Latvian";
        }
        case 27: {
            return "Malay";
        }
        case 28: {
            return "Mandarin Chinese";
        }
        case 29: {
            return "Mongolian";
        }
        case 30: {
            return "Norwegian";
        }
        case 31: {
            return "Polish";
        }
        case 32: {
            return "Portuguese";
        }
        case 33: {
            return "Punjabi";
        }
        case 34: {
            return "Romanian";
        }
        case 35: {
            return "Russian";
        }
        case 36: {
            return "Rwanda-Rundi";
        }
        case 37: {
            return "Serbian";
        }
        case 38: {
            return "Sinhala / Sri Lankan";
        }
        case 39: {
            return "Slovenian";
        }
        case 40: {
            return "Somali";
        }
        case 41: {
            return "Spanish";
        }
        case 42: {
            return "Swahili";
        }
        case 43: {
            return "Swedish";
        }
        case 44: {
            return "Tagalog / Philippino";
        }
        case 45: {
            return "Tamil";
        }
        case 46: {
            return "Thai";
        }
        case 47: {
            return "Turkish";
        }
        case 48: {
            return "Ukrainian";
        }
        case 49: {
            return "Urdu";
        }
        case 50: {
            return "Vietnamese";
        }
        case 51: {
            return "Zulu";
        }
        case 52: {
            return toText(printf("Other (%s)"))(languages.fields[0]);
        }
        default: {
            return "Albanian";
        }
    }
}

export function Language_dropdownDisplay_Z72E66594(languages) {
    if (languages.tag === 52) {
        return "Other (Specify)";
    }
    else {
        return Language_viewDisplay_Z72E66594(languages);
    }
}

export function Language_internalDescribe_Z72E66594(languages) {
    switch (languages.tag) {
        case 1: {
            return "Arabic";
        }
        case 2: {
            return "Armenian";
        }
        case 3: {
            return "BanglaBengali";
        }
        case 4: {
            return "Bosnian";
        }
        case 5: {
            return "Bulgarian";
        }
        case 6: {
            return "CantoneseYueChinese";
        }
        case 7: {
            return "Croatian";
        }
        case 8: {
            return "Czech";
        }
        case 9: {
            return "Danish";
        }
        case 10: {
            return "Dutch";
        }
        case 11: {
            return "English";
        }
        case 12: {
            return "FarsiPersian";
        }
        case 13: {
            return "Finnish";
        }
        case 14: {
            return "French";
        }
        case 15: {
            return "German";
        }
        case 16: {
            return "Greek";
        }
        case 17: {
            return "Gujarati";
        }
        case 18: {
            return "Hebrew";
        }
        case 19: {
            return "Hindi";
        }
        case 20: {
            return "Hungarian";
        }
        case 21: {
            return "Indonesian";
        }
        case 22: {
            return "Italian";
        }
        case 23: {
            return "Japanese";
        }
        case 24: {
            return "Javanese";
        }
        case 25: {
            return "Korean";
        }
        case 26: {
            return "Latvian";
        }
        case 27: {
            return "Malay";
        }
        case 28: {
            return "MandarinChinese";
        }
        case 29: {
            return "Mongolian";
        }
        case 30: {
            return "Norwegian";
        }
        case 31: {
            return "Polish";
        }
        case 32: {
            return "Portuguese";
        }
        case 33: {
            return "Punjabi";
        }
        case 34: {
            return "Romanian";
        }
        case 35: {
            return "Russian";
        }
        case 36: {
            return "RwandaRundi";
        }
        case 37: {
            return "Serbian";
        }
        case 38: {
            return "SinhalaSriLankan";
        }
        case 39: {
            return "Slovenian";
        }
        case 40: {
            return "Somali";
        }
        case 41: {
            return "Spanish";
        }
        case 42: {
            return "Swahili";
        }
        case 43: {
            return "Swedish";
        }
        case 44: {
            return "TagalogPhilippino";
        }
        case 45: {
            return "Tamil";
        }
        case 46: {
            return "Thai";
        }
        case 47: {
            return "Turkish";
        }
        case 48: {
            return "Ukrainian";
        }
        case 49: {
            return "Urdu";
        }
        case 50: {
            return "Vietnamese";
        }
        case 51: {
            return "Zulu";
        }
        case 52: {
            return toText(printf("Other_specify_string %s"))(languages.fields[0]);
        }
        default: {
            return "Albanian";
        }
    }
}

export function Language_get_all() {
    return ofArray([new Language(0), new Language(1), new Language(2), new Language(3), new Language(4), new Language(5), new Language(6), new Language(7), new Language(8), new Language(9), new Language(10), new Language(11), new Language(12), new Language(13), new Language(14), new Language(15), new Language(16), new Language(17), new Language(18), new Language(19), new Language(20), new Language(21), new Language(22), new Language(23), new Language(24), new Language(25), new Language(26), new Language(27), new Language(28), new Language(29), new Language(30), new Language(31), new Language(32), new Language(33), new Language(34), new Language(35), new Language(36), new Language(37), new Language(38), new Language(39), new Language(40), new Language(41), new Language(42), new Language(43), new Language(44), new Language(45), new Language(46), new Language(47), new Language(48), new Language(49), new Language(50), new Language(51), new Language(52, "")]);
}

export function Language_get_other_() {
    return [(_arg1) => {
        let other;
        return (_arg1 != null) ? ((_arg1.tag === 52) ? ((other = _arg1.fields[0], other)) : (void 0)) : (void 0);
    }, (option) => map((arg0) => (new Language(52, arg0)), option)];
}

export function Language_get_otherSelected() {
    return (_arg2) => ((_arg2 != null) ? ((_arg2.tag === 52) ? (new ComponentVisibility(0)) : (new ComponentVisibility(1))) : (new ComponentVisibility(1)));
}

export function Language_tryParse_Z721C83C5(languages) {
    const activePatternResult17772 = Extensions_String_$007CPrefix$007C_$007C(Language_internalDescribe_Z72E66594(new Language(52, "")), languages);
    if (activePatternResult17772 != null) {
        const other = activePatternResult17772;
        return new Language(52, other);
    }
    else {
        return FormData_tryParse(Language_internalDescribe_Z72E66594, Language_get_all(), languages);
    }
}

export function Language_get_toLanguage_() {
    return [Language_tryParse_Z721C83C5, Language_internalDescribe_Z72E66594];
}

export function Language_validate_Z72E66594(language) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ((language.tag === 52) ? ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Validate_String_standardValidate("Language Other (Specify)")(language.fields[0]), (_arg3) => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, language)) : ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, language))));
}

