import { Union, Record } from "../../../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, tuple_type, lambda_type, unit_type, list_type, record_type, class_type, int32_type, string_type } from "../../../fable_modules/fable-library.3.7.12/Reflection.js";
import { S3Context$reflection } from "../../../Common/AwsCommon.js";
import { UploadSelection$reflection, FileComponentSpecs$reflection } from "../../Shared.js";
import { S3ObjectViewModel$reflection } from "../../../ThreeDocs.Models/Api/AwsApi.js";
import { FileData$reflection } from "../../../Common/FileData.js";

export class DragDropFileSelectorUpdateArgs extends Record {
    constructor(token, numberOfAssociatedFiles, credentials, context) {
        super();
        this.token = token;
        this.numberOfAssociatedFiles = (numberOfAssociatedFiles | 0);
        this.credentials = credentials;
        this.context = context;
    }
}

export function DragDropFileSelectorUpdateArgs$reflection() {
    return record_type("ThreeDocs.Client.FileManagement.FileUploadSelectors.DragDropFileSelector.Types.DragDropFileSelectorUpdateArgs", [], DragDropFileSelectorUpdateArgs, () => [["token", string_type], ["numberOfAssociatedFiles", int32_type], ["credentials", class_type("Fable.Import.Aws.Credentials.Credentials")], ["context", S3Context$reflection()]]);
}

export class Model extends Record {
    constructor(Specs, PathTestFiles, SelectedFiles) {
        super();
        this.Specs = Specs;
        this.PathTestFiles = PathTestFiles;
        this.SelectedFiles = SelectedFiles;
    }
}

export function Model$reflection() {
    return record_type("ThreeDocs.Client.FileManagement.FileUploadSelectors.DragDropFileSelector.Types.Model", [], Model, () => [["Specs", FileComponentSpecs$reflection()], ["PathTestFiles", list_type(S3ObjectViewModel$reflection())], ["SelectedFiles", list_type(FileData$reflection())]]);
}

export class LocalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ShowErrorToast", "UploadSelectionChanged", "ValidateFiles"];
    }
}

export function LocalMsg$reflection() {
    return union_type("ThreeDocs.Client.FileManagement.FileUploadSelectors.DragDropFileSelector.Types.LocalMsg", [], LocalMsg, () => [[["Item", string_type]], [["Item", list_type(FileData$reflection())]], [["fileDataList", list_type(FileData$reflection())], ["onSuccess", lambda_type(Model$reflection(), tuple_type(Model$reflection(), list_type(lambda_type(lambda_type(LocalMsg$reflection(), unit_type), unit_type)), list_type(lambda_type(lambda_type(OutboundMsg$reflection(), unit_type), unit_type))))]]]);
}

export class OutboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SelectionMade"];
    }
}

export function OutboundMsg$reflection() {
    return union_type("ThreeDocs.Client.FileManagement.FileUploadSelectors.DragDropFileSelector.Types.OutboundMsg", [], OutboundMsg, () => [[["Item", UploadSelection$reflection()]]]);
}

export class InboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ClearFileList"];
    }
}

export function InboundMsg$reflection() {
    return union_type("ThreeDocs.Client.FileManagement.FileUploadSelectors.DragDropFileSelector.Types.InboundMsg", [], InboundMsg, () => [[]]);
}

