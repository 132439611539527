import { length, tryHead } from "../fable_modules/fable-library.3.7.12/List.js";
import { error } from "../ThreeDocs.SharedUI/Toast.js";
import { String_ofListWithOxfordComma, String_pluralize } from "../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { FileComponentSpecs__isFileExtensionAllowed_Z721C83C5, FileType_asExtensions_4FA3D7C1 } from "./Shared.js";

export function validateFiles(fileDataList, onSuccessfulValidation, model, specs, getNumberOfFiles) {
    let fileData, allowedFileTypes;
    const matchValue = [tryHead(fileDataList), specs];
    let pattern_matching_result, maxNum_1;
    if (matchValue[1].maxFiles.tag === 1) {
        if (length(fileDataList) > matchValue[1].maxFiles.fields[0]) {
            pattern_matching_result = 0;
            maxNum_1 = matchValue[1].maxFiles.fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [model, error(`Only ${maxNum_1} item${String_pluralize(maxNum_1)} can be uploaded at a time.`), Cmd_none()];
        }
        case 1: {
            let pattern_matching_result_1, allowedFileTypes_1, fileData_1;
            if (matchValue[0] != null) {
                if (matchValue[1].maybeFileTypeRestrictions != null) {
                    if ((fileData = matchValue[0], (allowedFileTypes = matchValue[1].maybeFileTypeRestrictions, !FileComponentSpecs__isFileExtensionAllowed_Z721C83C5(specs, fileData.file.name)))) {
                        pattern_matching_result_1 = 0;
                        allowedFileTypes_1 = matchValue[1].maybeFileTypeRestrictions;
                        fileData_1 = matchValue[0];
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const allowedExtensions = String_ofListWithOxfordComma(FileType_asExtensions_4FA3D7C1(allowedFileTypes_1));
                    return [model, error(`Unsupported file type. Please select a ${allowedExtensions} file.`), Cmd_none()];
                }
                case 1: {
                    let pattern_matching_result_2, maxNum_3;
                    if (matchValue[1].maxFiles.tag === 1) {
                        if (getNumberOfFiles(model) > matchValue[1].maxFiles.fields[0]) {
                            pattern_matching_result_2 = 0;
                            maxNum_3 = matchValue[1].maxFiles.fields[0];
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return [model, error(`Can only have ${maxNum_3} file${String_pluralize(maxNum_3)} uploaded. Please delete unneeded files.`), Cmd_none()];
                        }
                        case 1: {
                            return onSuccessfulValidation(model);
                        }
                    }
                }
            }
        }
    }
}

