import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, option_type, union_type, string_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { PatientPackageViewModel_get_empty, PatientPackageViewModel$reflection } from "../ThreeDocs.Models/View/PatientPackageViewModel.js";
import { ErrorMessage$reflection } from "../ThreeDocs.Models/Api/Api.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "GetPatientPackage", "GetPatientPackageResult"];
    }
}

export function Msg$reflection() {
    return union_type("ThreeDocs.Client.PatientPackages.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [["token", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [PatientPackageViewModel$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", PatientPackageViewModel$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]]]);
}

export class Model extends Record {
    constructor(token, patientPackageVm) {
        super();
        this.token = token;
        this.patientPackageVm = patientPackageVm;
    }
}

export function Model$reflection() {
    return record_type("ThreeDocs.Client.PatientPackages.Types.Model", [], Model, () => [["token", option_type(string_type)], ["patientPackageVm", union_type("Microsoft.FSharp.Core.FSharpResult`2", [PatientPackageViewModel$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", PatientPackageViewModel$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]]);
}

export function Model_get_empty() {
    return new Model(void 0, new FSharpResult$2(0, PatientPackageViewModel_get_empty()));
}

export function Model_get_token_() {
    return [(m) => m.token, (v) => ((m_1) => (new Model(v, m_1.patientPackageVm)))];
}

export function Model_get_patientPackageVm_() {
    return [(m) => m.patientPackageVm, (v) => ((m_1) => (new Model(m_1.token, v)))];
}

