import { toNumber, fromNumber } from "../fable-library.3.7.12/Long.js";
import { addSeconds, create } from "../fable-library.3.7.12/DateOffset.js";
import { Async_retn } from "../AsyncResult.0.3.0/Result.fs.js";
import { defaultArg } from "../fable-library.3.7.12/Option.js";
import { Union } from "../fable-library.3.7.12/Types.js";
import { union_type } from "../fable-library.3.7.12/Reflection.js";
import { split, join } from "../fable-library.3.7.12/String.js";
import { map } from "../fable-library.3.7.12/Array.js";
import { map as map_1, empty, cons, tail as tail_1, head as head_1, isEmpty, ofSeq, toArray } from "../fable-library.3.7.12/List.js";

export function toUnixSeconds(dto) {
    return fromNumber(dto.getTime() / 1000);
}

export function fromUnixSeconds(secs) {
    let copyOfStruct = create(1970, 1, 1, 0, 0, 0, 0);
    return addSeconds(copyOfStruct, toNumber(secs));
}

export function Functions_flip(f, b, a) {
    return f(a, b);
}

export function Option_defaultValueAsync(d) {
    const value = Async_retn(d);
    return (option) => defaultArg(option, value);
}

export class Naming_UIElement extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Input", "Label", "Button", "Table", "ReactSelect", "Tab"];
    }
}

export function Naming_UIElement$reflection() {
    return union_type("Webbler.Models.Common.Naming.UIElement", [], Naming_UIElement, () => [[], [["Item", Naming_UIElement$reflection()]], [], [], [], []]);
}

export function Naming_UIElement__get_asString(this$) {
    switch (this$.tag) {
        case 1: {
            return `${Naming_UIElement__get_asString(this$.fields[0])}Label`;
        }
        case 2: {
            return "Button";
        }
        case 3: {
            return "Table";
        }
        case 4: {
            return "React Select";
        }
        case 5: {
            return "Tab";
        }
        default: {
            return "Input";
        }
    }
}

export function Naming_toPascalCase(string) {
    return join("", map((s) => {
        let matchValue;
        const arg00 = toArray((matchValue = ofSeq(s.split("")), (!isEmpty(matchValue)) ? cons(head_1(matchValue).toLocaleUpperCase(), tail_1(matchValue)) : empty()));
        return arg00.join('');
    }, split(string, [" "], null, 0)));
}

export function Naming_mkIdFromStrings(stringList) {
    return join("", map_1(Naming_toPascalCase, stringList));
}

