import { S3_PutObjectRequestParams, S3_PutObjectRequestParams_get_create, S3_ApiParams, S3_ApiParams_get_create, Config, Config_get_create, CredentialsParams, CredentialsParams_get_create } from "../bindings/Aws/Fable.Helpers.Aws.js";
import { ofMap } from "../ThreeDocs.SharedUI/SharedJsInterop.js";
import * as aws from "../../../node_modules/aws-sdk/lib/aws.js";
import { ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { S3Configuration$reflection } from "../ThreeDocs.Models/Api/AwsApi.js";
import { record_type, string_type, option_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { some } from "../fable_modules/fable-library.3.7.12/Option.js";

export function mkCredentials(identityPoolId, identityId, region, token) {
    const arg00 = CredentialsParams_get_create()(new CredentialsParams(identityPoolId, identityId, ofMap()(token), region));
    const objectArg = aws.CognitoIdentityCredentials;
    return new objectArg(arg00);
}

export function updateGlobalConfig(region, credentials) {
    const arg00 = Config_get_create()(ofArray([new Config(0, region), new Config(1, credentials)]));
    const objectArg = aws.config;
    objectArg.update(arg00);
}

export function mkS3Client(bucket) {
    const arg00 = S3_ApiParams_get_create()(new S3_ApiParams("2012-10-17", bucket));
    const objectArg = aws.S3;
    return new objectArg(arg00);
}

export class S3Context extends Record {
    constructor(S3Config, S3Client, S3Expiry, ActiveBucket) {
        super();
        this.S3Config = S3Config;
        this.S3Client = S3Client;
        this.S3Expiry = S3Expiry;
        this.ActiveBucket = ActiveBucket;
    }
}

export function S3Context$reflection() {
    return record_type("ThreeDocs.Client.Common.AwsCommon.S3Context", [], S3Context, () => [["S3Config", S3Configuration$reflection()], ["S3Client", class_type("Fable.Import.Aws.S3.S3Client")], ["S3Expiry", option_type(class_type("System.DateTime"))], ["ActiveBucket", string_type]]);
}

export function getSignedUrlCmd(ctx, downloadBehavior, fileName, gotPresignedUrl, onError) {
    return Cmd_OfPromise_either((tupledArg) => ctx.S3Client.getSignedUrlPromise(tupledArg[0], tupledArg[1]), ["getObject", some(S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, ctx.ActiveBucket), new S3_PutObjectRequestParams(3, fileName)])))], (url) => {
        if (downloadBehavior === 1) {
            window.open(url);
        }
        return gotPresignedUrl([fileName, url]);
    }, onError);
}

export function getSignedUrlPromise(ctx, fileName) {
    const tupledArg = ["getObject", some(S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, ctx.ActiveBucket), new S3_PutObjectRequestParams(3, fileName)])))];
    return ctx.S3Client.getSignedUrlPromise(tupledArg[0], tupledArg[1]);
}

