import { CrudControls_mkId } from "./ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option, modal } from "./fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { map, ofArray, empty, singleton } from "./fable_modules/fable-library.3.7.12/List.js";
import { empty as empty_1, append, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.7.12/Seq.js";
import { map as map_1, some, defaultArg } from "./fable_modules/fable-library.3.7.12/Option.js";
import { button as button_1, Option as Option_1, list as list_3 } from "./fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { ComponentVisibility, Button_visibilityControlledCustom } from "./ThreeDocs.SharedUI/ComponentVisibility.js";
import { HTMLAttr, Prop, DOMAttr, CSSProp } from "./fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Color_IColor } from "./fable_modules/Fulma.2.16.0/Common.fs.js";
import { Naming_UIElement__get_asString, Naming_mkIdFromStrings, Naming_UIElement } from "./fable_modules/Webbler.Models.1.1.0/Common.fs.js";
import * as react from "react";
import { ViewLayoutConfig$4 } from "./ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDataEntryState.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.12/String.js";
import { ViewLayoutConfig$4 as ViewLayoutConfig$4_1 } from "./ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDetailState.js";
import { content } from "./fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { ViewLayoutConfig$4 as ViewLayoutConfig$4_2 } from "./ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDeleteConfirmationState.js";
import { Record } from "./fable_modules/fable-library.3.7.12/Types.js";
import { record_type, string_type } from "./fable_modules/fable-library.3.7.12/Reflection.js";
import { Option as Option_2, div } from "./fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option as Option_3, div as div_1 } from "./fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option as Option_4, IInputType, input } from "./fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { String_fromOption } from "./fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import { FormField_handleStringUpdate } from "./ThreeDocs.SharedUI/Forms.js";
import { Option as Option_5, icon } from "./fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "./fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { content as content_1, Header_icon, Header_title, header, card } from "./fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.12/MapUtil.js";
import { ViewLayoutConfig$4 as ViewLayoutConfig$4_3, columnListTable } from "./ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudListState.js";
import { Sort_itemListSortable } from "./ThreeDocs.SharedUI/Sort.js";
import { uncurry } from "./fable_modules/fable-library.3.7.12/Util.js";

export function dataEntryViewLayoutConfig(extraButtons, listView, customSaveAction, viewName, maybeCardTitle, action) {
    const mkEditId = (label, element) => CrudControls_mkId(viewName, action, label, element);
    return new ViewLayoutConfig$4((formConfig, sharedModel, model, formElement, buttons, dispatch) => {
        let saveOnClick;
        if (customSaveAction == null) {
            saveOnClick = buttons.save.action;
        }
        else {
            const action_1 = customSaveAction;
            saveOnClick = ((_arg1) => {
                dispatch(action_1(model));
            });
        }
        const children = [listView(void 0, sharedModel), modal(singleton(new Option(1, true)), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), toList(delay(() => {
            const additionalCardTitle = defaultArg(maybeCardTitle, "");
            return singleton_1(`${action} ${additionalCardTitle}`);
        }))))), Card_body(empty(), singleton(formElement)), Card_foot(empty(), singleton(list_3(empty(), toList(delay(() => {
            let matchValue;
            return append(singleton_1(Button_visibilityControlledCustom(singleton(new CSSProp(221, "0")), (matchValue = model.isValid, (matchValue.tag === 1) ? (new ComponentVisibility(2, matchValue.fields[0])) : (new ComponentVisibility(0))), ofArray([new Option_1(0, new Color_IColor(6)), new Option_1(17, ofArray([mkEditId("Save", new Naming_UIElement(2)), ["style", {
                marginRight: "0.5em",
            }], new DOMAttr(40, saveOnClick)]))]), singleton("Save"))), delay(() => append(singleton_1(button_1(singleton(new Option_1(17, ofArray([mkEditId("Cancel", new Naming_UIElement(2)), new DOMAttr(40, buttons.cancel.action)]))), singleton("Cancel"))), delay(() => map((button) => button(dispatch), extraButtons)))));
        })))))]))]))];
        return react.createElement("div", {}, ...children);
    });
}

export function detailViewLayoutConfig(canDelete, canEdit, extraButtons, listView, viewName) {
    const mkDetailId = (label, element) => CrudControls_mkId(viewName, "Detail", label, element);
    return new ViewLayoutConfig$4_1((formConfig, sharedModel, model, detailElement, buttons, dispatch) => {
        const children = [listView(some(model.selected), sharedModel), modal(singleton(new Option(1, true)), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), toList(delay(() => append(singleton_1(Card_title(empty(), singleton(toText(printf("Details"))))), delay(() => (canDelete(model.selected, sharedModel) ? singleton_1(button_1(ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(17, ofArray([mkDetailId("Delete", new Naming_UIElement(2)), new DOMAttr(40, buttons.delete.action)]))]), singleton("Delete"))) : empty_1())))))), Card_body(empty(), singleton(detailElement)), Card_foot(empty(), singleton(list_3(empty(), toList(delay(() => append(canEdit(model.selected, sharedModel) ? singleton_1(button_1(ofArray([new Option_1(0, new Color_IColor(6)), new Option_1(17, singleton(new Prop(0, "Edit"))), new Option_1(4), new Option_1(17, ofArray([mkDetailId("Edit", new Naming_UIElement(2)), new DOMAttr(40, buttons.edit.action)]))]), singleton("Edit"))) : empty_1(), delay(() => append(singleton_1(button_1(singleton(new Option_1(17, ofArray([mkDetailId("Cancel", new Naming_UIElement(2)), new DOMAttr(40, buttons.cancel.action)]))), singleton("Close"))), delay(() => map((button) => button(model)(sharedModel)(dispatch), extraButtons))))))))))]))]))];
        return react.createElement("div", {}, ...children);
    });
}

export function deleteConfirmationViewLayoutConfig(extraButtons, listView, customDeleteAction, viewName) {
    const mkDeleteId = (label, element) => CrudControls_mkId(viewName, "Delete", label, element);
    return new ViewLayoutConfig$4_2((formConfig, sharedModel, model, confirmationElement, buttons, dispatch) => {
        let deleteAction;
        if (customDeleteAction == null) {
            deleteAction = buttons.delete.action;
        }
        else {
            const action = customDeleteAction;
            deleteAction = ((_arg1) => {
                dispatch(action(model));
            });
        }
        const children_2 = [listView(some(model.selected), sharedModel), modal(singleton(new Option(1, true)), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton(toText(printf("Confirm Deletion")))))), Card_body(empty(), singleton(content(empty(), ofArray([react.createElement("p", {}, "Are you sure you want to delete the following item? This operation cannot be undone."), react.createElement("hr", {}), confirmationElement])))), Card_foot(empty(), toList(delay(() => append(singleton_1(button_1(singleton(new Option_1(17, ofArray([mkDeleteId("Cancel", new Naming_UIElement(2)), new DOMAttr(40, deleteAction)]))), singleton("Cancel"))), delay(() => append(map((button) => button(model)(dispatch), extraButtons), delay(() => singleton_1(button_1(ofArray([new Option_1(0, new Color_IColor(8)), new Option_1(15), new Option_1(4), new Option_1(17, ofArray([mkDeleteId("Confirm", new Naming_UIElement(2)), new DOMAttr(40, deleteAction)]))]), singleton("Delete"))))))))))]))]))];
        return react.createElement("div", {}, ...children_2);
    });
}

export class ListViewHeaderInfo extends Record {
    constructor(title, createButtonText) {
        super();
        this.title = title;
        this.createButtonText = createButtonText;
    }
}

export function ListViewHeaderInfo$reflection() {
    return record_type("Client.ModalCrudConfigLayout.ListViewHeaderInfo", [], ListViewHeaderInfo, () => [["title", string_type], ["createButtonText", string_type]]);
}

export function defaultSearchComponent(_arg1, listViewHeaderInfo, buttons) {
    const search = _arg1.Search;
    const searchBarId = Naming_mkIdFromStrings(ofArray(["", listViewHeaderInfo.title, "Search Bar", Naming_UIElement__get_asString(new Naming_UIElement(0))]));
    return div(singleton(new Option_2(0)), ofArray([div_1(singleton(new Option_3(0)), singleton(input(ofArray([new Option_4(1, new IInputType(0)), new Option_4(10, String_fromOption(search.search)), new Option_4(12, "Search"), new Option_4(15, toList(delay(() => append(singleton_1(new DOMAttr(17, (e) => {
        defaultArg(map_1((buttons_2) => {
            buttons_2.searchTable.triggerSearch(search.search);
        }, buttons), void 0);
    })), delay(() => append(singleton_1(CrudControls_mkId("", listViewHeaderInfo.title, "Search Bar", new Naming_UIElement(0))), delay(() => defaultArg(map_1((buttons_3) => singleton(new DOMAttr(9, (arg) => {
        buttons_3.searchTable.updateSearch(FormField_handleStringUpdate(arg));
    })), buttons), empty()))))))))])))), div_1(empty(), singleton(button_1(singleton(new Option_1(17, ofArray([CrudControls_mkId("", listViewHeaderInfo.title, "Search Bar Cancel", new Naming_UIElement(2)), new DOMAttr(40, (e_2) => {
        defaultArg(map_1((buttons_1) => {
            const searchBar = document.getElementById(searchBarId);
            searchBar.value = null;
            buttons_1.searchTable.updateSearch(void 0);
            buttons_1.searchTable.triggerSearch(void 0);
        }, buttons), void 0);
    })]))), singleton(icon(singleton(new Option_5(4, singleton(new HTMLAttr(64, "has-text-link")))), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-times")), [])))))))]));
}

export function defaultCreateListItemButton(customStorage, listViewHeaderInfo, buttons) {
    return button_1(toList(delay(() => append(singleton_1(new Option_1(4)), delay(() => [defaultArg(map_1((button) => (new Option_1(17, ofArray([CrudControls_mkId("", listViewHeaderInfo.title, "Create", new Naming_UIElement(2)), new DOMAttr(40, button.createItem.action)]))), buttons), new Option_1(16, true))])))), singleton(listViewHeaderInfo.createButtonText));
}

export function defaultListComponents(shared, listViewHeaderInfo, buttons) {
    return ofArray([defaultSearchComponent(shared, listViewHeaderInfo, buttons), defaultCreateListItemButton(shared, listViewHeaderInfo, buttons)]);
}

export function makeList(createButton, shared, formElement, listViewHeaderInfo, buttons, extraButtons) {
    let props;
    return card(empty(), ofArray([header(empty(), toList(delay(() => append(singleton_1(Header_title(empty(), singleton(listViewHeaderInfo.title))), delay(() => append(extraButtons, delay(() => map((arg) => Header_icon(empty(), singleton(arg)), createButton(shared, listViewHeaderInfo, buttons))))))))), content_1(empty(), singleton((props = [new HTMLAttr(64, "table-container"), new HTMLAttr(99, toText(printf("%sTable"))(listViewHeaderInfo.title))], react.createElement("div", keyValueList(props, 1), formElement))))]));
}

export function disableInactiveListButton(standardButton, maybeListModel, sharedModel, dispatch) {
    const visibility = (maybeListModel == null) ? (new ComponentVisibility(2, void 0)) : (new ComponentVisibility(0));
    const tupledArg = standardButton(maybeListModel, sharedModel, dispatch);
    return Button_visibilityControlledCustom(singleton(new CSSProp(221, "0")), visibility, tupledArg[0], tupledArg[1]);
}

export function listViewLayoutConfig(createButton, listViewHeaderInfo, extraButtons) {
    return new ViewLayoutConfig$4_3((formConfig, sharedModel, model, formElement, buttons, dispatch) => makeList(createButton, sharedModel, formElement, listViewHeaderInfo, buttons, map((children) => Header_icon(empty(), children), map(singleton, map((button) => button(model)(sharedModel)(dispatch), extraButtons)))), (arg10$0040, columnConfig, selectedItem, dispatch_1) => columnListTable((columns, selectItem, items, extraItem, sortInfo, updateSortInfo) => Sort_itemListSortable(listViewHeaderInfo.title, columns, uncurry(2, selectItem), items, extraItem, sortInfo, uncurry(2, updateSortInfo)), arg10$0040, columnConfig, selectedItem, dispatch_1));
}

export function listViewLayoutConfigWithDefaultCreateButton(listViewHeaderInfo, extraButtons) {
    return listViewLayoutConfig(defaultListComponents, listViewHeaderInfo, extraButtons);
}

