import { Common_passwordRequirementTracker, Common_mkField, Common_mkId } from "../Common/Common.js";
import { HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { ofArray, tryHead, cons, singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { h3 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option as Option_3, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { input as input_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Security_RegisterViewModel } from "../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { Msg } from "./AuthTypes.js";
import * as react from "react";
import { defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { Option as Option_1, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option as Option_2, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Naming_UIElement } from "../fable_modules/Webbler.Models.1.1.0/Common.fs.js";
import { Modifier_IModifier, Screen, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";

export function mkRegisterViewId(label, element) {
    return new HTMLAttr(99, Common_mkId("Register", label, element));
}

export function view(model, dispatch) {
    let children_2;
    const mkField = (input, label, value, onChange) => Common_mkField("Register", input, label, value, onChange);
    const patternInput = Common_passwordRequirementTracker(model.registerVM.password);
    const passwordTracker = patternInput[0];
    return container(empty(), singleton((children_2 = [h3(empty())(singleton("Registration")), columns(empty(), ofArray([column(empty(), ofArray([mkField((options) => input_1(cons(new Option(1, new IInputType(0)), options)), "Login Email", model.registerVM.email, (v) => {
        dispatch(new Msg(1, (rvm) => (new Security_RegisterViewModel(v, rvm.password, rvm.givenName, rvm.familyName, rvm.roles))));
    }), mkField((options_2) => input_1(cons(new Option(1, new IInputType(1)), options_2)), "Password", model.registerVM.password, (v_1) => {
        dispatch(new Msg(1, (rvm_1) => (new Security_RegisterViewModel(rvm_1.email, v_1, rvm_1.givenName, rvm_1.familyName, rvm_1.roles))));
    }), react.createElement("div", {
        className: "is-hidden-desktop",
    }, passwordTracker), mkField((options_4) => input_1(cons(new Option(1, new IInputType(0)), options_4)), "Role", defaultArg(tryHead(model.registerVM.roles), ""), (v_2) => {
        dispatch(new Msg(1, (rvm_2) => (new Security_RegisterViewModel(rvm_2.email, rvm_2.password, rvm_2.givenName, rvm_2.familyName, (v_2 === "") ? empty() : singleton(v_2)))));
    }), div(ofArray([new Option_1(4), new Option_1(11, singleton(["style", {
        justifyContent: "flex-end",
    }]))]), singleton(div_1(empty(), singleton(button(ofArray([new Option_2(17, singleton(mkRegisterViewId("Submit", new Naming_UIElement(2)))), new Option_2(0, new Color_IColor(4)), new Option_2(18, (_arg1) => {
        dispatch(new Msg(10));
    }), new Option_2(16, !patternInput[1])]), singleton("Submit"))))))])), column(ofArray([new Option_3(3, singleton(["style", {
        paddingTop: "7rem",
    }])), new Option_3(4, singleton(new Modifier_IModifier(24, new Screen(5), true)))]), singleton(passwordTracker))]))], react.createElement("div", {}, ...children_2))));
}

