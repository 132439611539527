import { State_update, Types_Msg$4, State_init } from "../ThreeDocs.Client.StateMachine.Crud/CrudComponent.js";
import { CustomShared_get_currentUserInfo_, CustomShared_get_currentRoles_, UsersUpdateArgs, UsersCrudOutbound, CustomShared_get_practices_, CustomShared_get_canCreateUserWithoutAssignedPractices_, CustomShared_get_availableRolesToSelect_, CustomShared, UsersCrudConfig_userVmModelConfig, UsersCrudConfig_userVmViewConfig, UsersCrudConfig_generalViewLayoutConfig } from "./UsersCrudConfig.js";
import { ofArray, map as map_1, contains, tryHead, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { DefaultActions_terminate, DefaultActions_Types_UpdateArgs$3, DefaultActions_runUpdate, DefaultActions_create, DefaultActions_Types_ChildModel$2, Child$1 } from "../Common/Terminate.js";
import { Msg, Model, childAbsentStrategy } from "./UsersTypes.js";
import { Cmd_batch, Cmd_none, Cmd_map } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { UserRole, UserRole_get_selectableRoles, Permission, isAllowed } from "../ThreeDocs.Models/Security.js";
import { Cmd_OfFunc_result, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { publicAuthApi, securedUserManagementApi } from "../Api.js";
import { success, error } from "../ThreeDocs.SharedUI/Toast.js";
import { Common_EmailAddress_create_Z721C83C5, Security_UserInfo$2_toViewModel_Z3F1C2E28, Security_AuthErrorMessage_get_describe } from "../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { safeHash, equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Shared_CrudInbound$2 } from "../ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { tryTerminate, update as update_1, init as init_1 } from "../EmailChange/EmailChangeState.js";
import { InitArgs } from "../EmailChange/EmailChangeTypes.js";

export function init() {
    const patternInput = State_init(UsersCrudConfig_generalViewLayoutConfig(UsersCrudConfig_userVmViewConfig.viewListItemConfig.listColumns, UsersCrudConfig_userVmModelConfig.isSameItem), UsersCrudConfig_userVmViewConfig, UsersCrudConfig_userVmModelConfig, new CustomShared(empty(), empty(), false, empty(), void 0));
    return [new Model(patternInput[0], new DefaultActions_Types_ChildModel$2(new Child$1(1), childAbsentStrategy, "Email Change")), Cmd_map((arg0) => (new Msg(8, arg0)), patternInput[1])];
}

export function update(token, userInfo, msg, model) {
    let option, f1, f2, matchValue, emailChange, matchValue_1;
    if (msg.tag === 2) {
        if (isAllowed(new Permission(3))(userInfo.roles)) {
            return [model, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedUserManagementApi(token).getUsers, void 0, (arg0_8) => (new Msg(3, arg0_8)), (arg0_9) => (new Msg(0, arg0_9)))];
        }
        else {
            return [model, Cmd_none()];
        }
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            return [model, error(Security_AuthErrorMessage_get_describe()(msg.fields[0].fields[0]))];
        }
        else {
            let updateStorageCmd;
            const availableRoles = defaultArg((option = tryHead(msg.fields[0].fields[0].find((user) => (user.id === userInfo.id)).roles), map(UserRole_get_selectableRoles(), option)), empty());
            const canCreateEmpty = contains(new UserRole(0), availableRoles, {
                Equals: equals,
                GetHashCode: safeHash,
            });
            updateStorageCmd = Cmd_OfFunc_result(new Msg(8, new Types_Msg$4(2, new Shared_CrudInbound$2(4, (f1 = Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_availableRolesToSelect_())(availableRoles), (f2 = Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_canCreateUserWithoutAssignedPractices_())(canCreateEmpty), (arg_1) => f2(f1(arg_1))))))));
            return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(8, new Types_Msg$4(2, new Shared_CrudInbound$2(0, map_1(Security_UserInfo$2_toViewModel_Z3F1C2E28, ofArray(msg.fields[0].fields[0])))))), updateStorageCmd]))];
        }
    }
    else if (msg.tag === 4) {
        return [model, Cmd_OfFunc_result(new Msg(8, new Types_Msg$4(2, new Shared_CrudInbound$2(4, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_practices_())(msg.fields[0])))))];
    }
    else if (msg.tag === 6) {
        return [model, (matchValue = model.emailChange.child, (matchValue.tag === 0) ? ((emailChange = matchValue.fields[0], (matchValue_1 = [Common_EmailAddress_create_Z721C83C5(emailChange.currentEmail), Common_EmailAddress_create_Z721C83C5(emailChange.newEmail)], (matchValue_1[0].tag === 1) ? error("The user\u0027s current email is invalid. Please try reloading the page") : ((matchValue_1[1].tag === 1) ? error("The new email is invalid. Please enter a valid email address.") : Cmd_OfAsyncWith_either((x_2) => {
            Cmd_OfAsync_start(x_2);
        }, publicAuthApi.requestEmailChangeTokenEmail, [emailChange.currentEmail, emailChange.newEmail], (arg0_22) => (new Msg(7, arg0_22)), (arg0_23) => (new Msg(0, arg0_23))))))) : error("An email change component has not been created yet. Please try reloading the page."))];
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 1) {
            return [model, error(Security_AuthErrorMessage_get_describe()(msg.fields[0].fields[0]))];
        }
        else {
            return [model, Cmd_batch(ofArray([success("A confirmation email has been sent to the user\u0027s current email address"), Cmd_OfFunc_result(new Msg(10))]))];
        }
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 2) {
            return [model, Cmd_OfFunc_result(new Msg(2))];
        }
        else {
            return [model, Cmd_none()];
        }
    }
    else if (msg.tag === 0) {
        console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
        return [model, Cmd_none()];
    }
    else if (msg.tag === 8) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [model, error("An unexpected error occurred. Please try again.")];
            }
            else if (msg.fields[0].fields[0].tag === 2) {
                const createdEmailChange = init_1(new InitArgs(msg.fields[0].fields[0].fields[0]));
                const patternInput = DefaultActions_create(createdEmailChange[0], createdEmailChange[1], model.emailChange);
                return [new Model(model.UserCrudModel, patternInput[0]), Cmd_map((arg0_24) => (new Msg(9, arg0_24)), patternInput[1])];
            }
            else if (msg.fields[0].fields[0].tag === 3) {
                return [model, Cmd_OfAsyncWith_either((x_3) => {
                    Cmd_OfAsync_start(x_3);
                }, securedUserManagementApi(token).changeUserLockoutStatus, [msg.fields[0].fields[0].fields[0], msg.fields[0].fields[0].fields[1]], (arg_4) => (new Msg(8, new Types_Msg$4(1, new UsersCrudOutbound(4, arg_4)))), (arg0_28) => (new Msg(0, arg0_28)))];
            }
            else if (msg.fields[0].fields[0].tag === 4) {
                if (msg.fields[0].fields[0].fields[0].tag === 1) {
                    return [model, error(Security_AuthErrorMessage_get_describe()(msg.fields[0].fields[0].fields[0].fields[0]))];
                }
                else {
                    return [model, Cmd_batch(ofArray([success("Successfully changed user lockout status"), Cmd_OfFunc_result(new Msg(2)), Cmd_OfFunc_result(new Msg(8, new Types_Msg$4(2, new Shared_CrudInbound$2(2, (_arg1) => (void 0)))))]))];
                }
            }
            else {
                return [model, error(Security_AuthErrorMessage_get_describe()(msg.fields[0].fields[0].fields[0]))];
            }
        }
        else {
            const patternInput_1 = State_update(new UsersUpdateArgs(token), msg.fields[0], model.UserCrudModel);
            return [new Model(patternInput_1[0], model.emailChange), Cmd_map((arg0_32) => (new Msg(8, arg0_32)), patternInput_1[1])];
        }
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].chainedMsg.tag === 0) {
            const patternInput_2 = DefaultActions_runUpdate(new DefaultActions_Types_UpdateArgs$3((msg_15, model_2) => {
                if (msg_15.tag === 1) {
                    return [model_2, Cmd_none()];
                }
                else {
                    return update_1(void 0, msg_15.fields[0], model_2);
                }
            }, model), model.emailChange, msg.fields[0]);
            return [new Model(model.UserCrudModel, patternInput_2[0]), Cmd_map((arg0_33) => (new Msg(9, arg0_33)), patternInput_2[1])];
        }
        else {
            return [model, Cmd_none()];
        }
    }
    else if (msg.tag === 10) {
        const patternInput_3 = DefaultActions_terminate(tryTerminate, model.emailChange);
        return [new Model(model.UserCrudModel, patternInput_3[0]), Cmd_batch(ofArray([Cmd_map((arg0_34) => (new Msg(9, arg0_34)), patternInput_3[1]), Cmd_OfFunc_result(new Msg(2))]))];
    }
    else {
        return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(2)), Cmd_OfFunc_result(new Msg(8, new Types_Msg$4(2, new Shared_CrudInbound$2(4, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_currentRoles_())(userInfo.roles))))), Cmd_OfFunc_result(new Msg(8, new Types_Msg$4(2, new Shared_CrudInbound$2(4, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), CustomShared_get_currentUserInfo_())(userInfo)))))]))];
    }
}

