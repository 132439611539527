import { Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { tryFind } from "../fable_modules/fable-library.3.7.12/List.js";
import { substring } from "../fable_modules/fable-library.3.7.12/String.js";

export class Terminate_TerminateError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Generic", "Custom", "UncancellableOperation"];
    }
}

export function Terminate_TerminateError$reflection() {
    return union_type("ThreeDocs.Models.Shared.Terminate.TerminateError", [], Terminate_TerminateError, () => [[], [["errorMessage", string_type]], [["operation", string_type]]]);
}

export function Terminate_TerminateError_get_describe() {
    return (_arg1) => ((_arg1.tag === 1) ? _arg1.fields[0] : ((_arg1.tag === 2) ? (`Unable to cancel ${_arg1.fields[0]} operation`) : "Generic termination error"));
}

export function FormData_tryParse(toString, all, v) {
    return tryFind((x) => (toString(x) === v), all);
}

export function Extensions_String_$007CPrefix$007C_$007C(prefix, s) {
    if (s.indexOf(prefix) === 0) {
        return substring(s, prefix.length);
    }
    else {
        return void 0;
    }
}

