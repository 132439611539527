import { Common_hasRole, Common_mkId, Common_isAllowed, Common_containerWithContent } from "./Common/Common.js";
import { view } from "./Auth/LoginView.js";
import { Msg } from "./Types.js";
import { viewRequestResetEmail, view as view_1 } from "./Auth/ResetView.js";
import { view as view_2 } from "./Auth/RegisterView.js";
import { view as view_3 } from "./Auth/EmailChangeView.js";
import { view as view_4 } from "./Users/UsersView.js";
import { view as view_5 } from "./Demonstration/DemonstrationPage.js";
import { view as view_6 } from "./UserProfile/UserProfileView.js";
import { view as view_7 } from "./Practices/PracticesView.js";
import { view as view_8 } from "./Invitations/InvitationsView.js";
import { view as view_9 } from "./Package/PackageView.js";
import { view as view_10 } from "./PatientPackages/PatientPackagesView.js";
import { view as view_11 } from "./Media/MediaView.js";
import { view as view_12 } from "./Landing/LandingPage.js";
import { TextAlignment_Option, Common_GenericOption, Color_IColor, Modifier_IModifier, Screen, Text_div } from "./fable_modules/Fulma.2.16.0/Common.fs.js";
import { map, tryHead, ofArray, collect, singleton, empty } from "./fable_modules/fable-library.3.7.12/List.js";
import { UserRole_get_describe, UserRole, Permission } from "./ThreeDocs.Models/Security.js";
import * as react from "react";
import { panel as panel_1, icon as icon_1, Block_Option, Block_a } from "./fable_modules/Fulma.2.16.0/Components/Panel.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.12/Seq.js";
import { Naming_UIElement } from "./fable_modules/Webbler.Models.1.1.0/Common.fs.js";
import { DOMAttr, HTMLAttr } from "./fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { equals } from "./fable_modules/fable-library.3.7.12/Util.js";
import { Option, icon as icon_2 } from "./fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "./fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Page } from "./Pages.js";
import { map as map_1, defaultArg } from "./fable_modules/fable-library.3.7.12/Option.js";
import { Option as Option_1, ISize, column } from "./fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Option as Option_2, content as content_18 } from "./fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { End_div, menu as menu_1, Burger_Option, burger, Item_div, Item_Option, Item_a, Brand_div, Option as Option_3, navbar as navbar_1 } from "./fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { Common_EmailAddress_value_Z39D6D3C1 } from "./fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.12/MapUtil.js";
import { join } from "./fable_modules/fable-library.3.7.12/String.js";
import { Option as Option_4, image } from "./fable_modules/Fulma.2.16.0/Elements/Image.fs.js";
import { right, Item_Option as Item_Option_1, item, Level_Option, level } from "./fable_modules/Fulma.2.16.0/Layouts/Level.fs.js";
import { sessionExpiryModal, view as view_13 } from "./Auth/AuthView.js";
import { clientVersion } from "./Version.js";
import { Option as Option_5, columns } from "./fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";

export function root(model, dispatch) {
    let props_2, children_2, children_4, children_18, children_20;
    let content_16;
    const matchValue = model.currentPage;
    let pattern_matching_result;
    if (matchValue.tag === 0) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 1;
        }
        else if (matchValue.fields[0].tag === 3) {
            pattern_matching_result = 2;
        }
        else if (matchValue.fields[0].tag === 4) {
            pattern_matching_result = 3;
        }
        else if (matchValue.fields[0].tag === 2) {
            pattern_matching_result = 4;
        }
        else {
            pattern_matching_result = 0;
        }
    }
    else if (matchValue.tag === 2) {
        if (Common_isAllowed(new Permission(6))(model.authModel.userInfo)) {
            pattern_matching_result = 5;
        }
        else {
            pattern_matching_result = 6;
        }
    }
    else {
        pattern_matching_result = 6;
    }
    switch (pattern_matching_result) {
        case 0: {
            content_16 = Common_containerWithContent("Login", view(model.authModel, (arg) => {
                dispatch(new Msg(2, arg));
            }));
            break;
        }
        case 1: {
            content_16 = Common_containerWithContent("Reset Password", view_1(model.authModel, (arg_1) => {
                dispatch(new Msg(2, arg_1));
            }));
            break;
        }
        case 2: {
            content_16 = Common_containerWithContent("Reset Password", viewRequestResetEmail(model.authModel.resetRequestEmail, (arg_2) => {
                dispatch(new Msg(2, arg_2));
            }));
            break;
        }
        case 3: {
            content_16 = Common_containerWithContent("Register", view_2(model.authModel, (arg_3) => {
                dispatch(new Msg(2, arg_3));
            }));
            break;
        }
        case 4: {
            content_16 = Common_containerWithContent("Change Email", view_3(model.authModel, (arg_4) => {
                dispatch(new Msg(2, arg_4));
            }));
            break;
        }
        case 5: {
            content_16 = Common_containerWithContent("Users", view_4(model.usersModel, (arg_5) => {
                dispatch(new Msg(4, arg_5));
            }));
            break;
        }
        case 6: {
            let pattern_matching_result_1;
            if (matchValue.tag === 4) {
                if (Common_isAllowed(new Permission(3))(model.authModel.userInfo)) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    content_16 = Common_containerWithContent("Demonstration", view_5(model.demonstrationModel, (arg_6) => {
                        dispatch(new Msg(6, arg_6));
                    }));
                    break;
                }
                case 1: {
                    let pattern_matching_result_2;
                    if (matchValue.tag === 5) {
                        if (Common_isAllowed(new Permission(4))(model.authModel.userInfo)) {
                            pattern_matching_result_2 = 0;
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            content_16 = Common_containerWithContent("User Profile", view_6(model.userProfileModel, (arg_7) => {
                                dispatch(new Msg(9, arg_7));
                            }));
                            break;
                        }
                        case 1: {
                            let pattern_matching_result_3;
                            if (matchValue.tag === 1) {
                                if (Common_isAllowed(new Permission(11))(model.authModel.userInfo)) {
                                    pattern_matching_result_3 = 0;
                                }
                                else {
                                    pattern_matching_result_3 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_3 = 1;
                            }
                            switch (pattern_matching_result_3) {
                                case 0: {
                                    content_16 = Common_containerWithContent("Practices", view_7(model.practicesModel, (arg_8) => {
                                        dispatch(new Msg(3, arg_8));
                                    }));
                                    break;
                                }
                                case 1: {
                                    let pattern_matching_result_4;
                                    if (matchValue.tag === 3) {
                                        if (Common_isAllowed(new Permission(25))(model.authModel.userInfo)) {
                                            pattern_matching_result_4 = 0;
                                        }
                                        else {
                                            pattern_matching_result_4 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_4 = 1;
                                    }
                                    switch (pattern_matching_result_4) {
                                        case 0: {
                                            content_16 = Common_containerWithContent("Invitations", view_8(model.invitationsModel, (arg_9) => {
                                                dispatch(new Msg(5, arg_9));
                                            }));
                                            break;
                                        }
                                        case 1: {
                                            let pattern_matching_result_5;
                                            if (matchValue.tag === 6) {
                                                if (Common_isAllowed(new Permission(23))(model.authModel.userInfo)) {
                                                    pattern_matching_result_5 = 0;
                                                }
                                                else {
                                                    pattern_matching_result_5 = 1;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_5 = 1;
                                            }
                                            switch (pattern_matching_result_5) {
                                                case 0: {
                                                    content_16 = Common_containerWithContent("Packages", view_9(model.packagesModel, (arg_10) => {
                                                        dispatch(new Msg(10, arg_10));
                                                    }));
                                                    break;
                                                }
                                                case 1: {
                                                    let pattern_matching_result_6;
                                                    if (matchValue.tag === 7) {
                                                        pattern_matching_result_6 = 0;
                                                    }
                                                    else if (matchValue.tag === 8) {
                                                        if (Common_isAllowed(new Permission(12))(model.authModel.userInfo)) {
                                                            pattern_matching_result_6 = 1;
                                                        }
                                                        else {
                                                            pattern_matching_result_6 = 2;
                                                        }
                                                    }
                                                    else {
                                                        pattern_matching_result_6 = 2;
                                                    }
                                                    switch (pattern_matching_result_6) {
                                                        case 0: {
                                                            content_16 = Common_containerWithContent("Patient Packages", view_10(model.patientPackagesModel, (arg_11) => {
                                                                dispatch(new Msg(11, arg_11));
                                                            }));
                                                            break;
                                                        }
                                                        case 1: {
                                                            content_16 = Common_containerWithContent("Media", view_11(model.mediaModel, (arg_12) => {
                                                                dispatch(new Msg(12, arg_12));
                                                            }));
                                                            break;
                                                        }
                                                        case 2: {
                                                            let pattern_matching_result_7;
                                                            if (matchValue.tag === 9) {
                                                                if (Common_isAllowed(new Permission(13))(model.authModel.userInfo)) {
                                                                    pattern_matching_result_7 = 0;
                                                                }
                                                                else {
                                                                    pattern_matching_result_7 = 1;
                                                                }
                                                            }
                                                            else {
                                                                pattern_matching_result_7 = 1;
                                                            }
                                                            switch (pattern_matching_result_7) {
                                                                case 0: {
                                                                    content_16 = Common_containerWithContent("Public Media", view_11(model.mediaModel, (arg_13) => {
                                                                        dispatch(new Msg(12, arg_13));
                                                                    }));
                                                                    break;
                                                                }
                                                                case 1: {
                                                                    content_16 = ((matchValue.tag === 10) ? Common_containerWithContent("Landing", view_12(model.landingModel, (arg_14) => {
                                                                        dispatch(new Msg(7, arg_14));
                                                                    })) : Common_containerWithContent("Default", Text_div(empty(), singleton("Default View"))));
                                                                    break;
                                                                }
                                                            }
                                                            break;
                                                        }
                                                    }
                                                    break;
                                                }
                                            }
                                            break;
                                        }
                                    }
                                    break;
                                }
                            }
                            break;
                        }
                    }
                    break;
                }
            }
            break;
        }
    }
    let menu;
    const matchValue_1 = model.authModel.userInfo;
    if (matchValue_1 == null) {
        menu = react.createElement("div", {});
    }
    else {
        const ui = matchValue_1;
        const pages = collect((page) => {
            let linkedPage, label;
            return singleton((linkedPage = page.Page, (label = page.Label, Block_a(toList(delay(() => append(singleton_1(new Block_Option(1, ofArray([new HTMLAttr(99, Common_mkId("NavMenu", label, new Naming_UIElement(5))), new DOMAttr(40, (_arg1) => {
                dispatch(new Msg(1, linkedPage));
            }), ["style", {
                paddingRight: "2rem",
            }]]))), delay(() => (equals(linkedPage, model.currentPage) ? singleton_1(new Block_Option(0, true)) : empty_1()))))), ofArray([icon_1(empty(), singleton(icon_2(singleton(new Option(4, singleton(["style", {
                justifyContent: "revert",
                alignItems: "baseline",
            }]))), singleton(Fa_i(singleton(page.Icon), []))))), label])))));
        }, toList(delay(() => append(Common_isAllowed(new Permission(6))(model.authModel.userInfo) ? singleton_1({
            Icon: new Fa_IconOption(11, "fas fa-users"),
            Label: "Users",
            Page: new Page(2),
        }) : empty_1(), delay(() => append(Common_isAllowed(new Permission(11))(model.authModel.userInfo) ? singleton_1({
            Icon: new Fa_IconOption(11, "fas fa-file"),
            Label: "Practices",
            Page: new Page(1),
        }) : empty_1(), delay(() => append(Common_hasRole(new UserRole(0))(model.authModel.userInfo) ? singleton_1({
            Icon: new Fa_IconOption(11, "fas fa-book"),
            Label: "Media Library",
            Page: new Page(9),
        }) : append(singleton_1({
            Icon: new Fa_IconOption(11, "fas fa-book"),
            Label: "Media Library",
            Page: new Page(8, defaultArg(tryHead(ui.userData.Practices), "00000000-0000-0000-0000-000000000000")),
        }), delay(() => singleton_1({
            Icon: new Fa_IconOption(11, "fas fa-box"),
            Label: "Packages",
            Page: new Page(6, defaultArg(tryHead(ui.userData.Practices), "00000000-0000-0000-0000-000000000000")),
        }))), delay(() => (Common_isAllowed(new Permission(25))(model.authModel.userInfo) ? singleton_1({
            Icon: new Fa_IconOption(11, "fas fa-paper-plane"),
            Label: "Invitations",
            Page: new Page(3),
        }) : empty_1()))))))))));
        const panel = (content_17) => panel_1(empty(), content_17);
        menu = column(singleton(new Option_1(0, new Screen(0), new ISize(17))), ofArray([content_18(singleton(new Option_2(3, singleton(new Modifier_IModifier(24, new Screen(5), true)))), singleton(panel(pages))), content_18(singleton(new Option_2(3, singleton(new Modifier_IModifier(24, new Screen(1), true)))), toList(delay(() => (model.menuIsExpanded ? singleton_1(panel(pages)) : empty_1()))))]));
    }
    let navbar;
    const matchValue_2 = model.authModel.userInfo;
    if (matchValue_2 == null) {
        navbar = navbar_1(singleton(new Option_3(0, new Color_IColor(4))), singleton(Brand_div(empty(), singleton(Item_a(singleton(new Item_Option(6, "banner-logo")), singleton(react.createElement("img", {
            src: "3docs-banner-logo.png",
        })))))));
    }
    else {
        const ui_1 = matchValue_2;
        let describeUser;
        const children_6 = [(props_2 = [["style", {
            marginRight: 10,
        }]], (children_2 = [Common_EmailAddress_value_Z39D6D3C1(ui_1.email)], react.createElement("span", keyValueList(props_2, 1), ...children_2))), (children_4 = [join(",", map(UserRole_get_describe(), ui_1.roles))], react.createElement("span", {}, ...children_4))];
        describeUser = react.createElement("div", {}, ...children_6);
        navbar = navbar_1(singleton(new Option_3(0, new Color_IColor(4))), ofArray([Brand_div(singleton(new Common_GenericOption(1, singleton(["style", {
            flexWrap: "Wrap",
        }]))), ofArray([Item_div(empty(), singleton(burger(ofArray([new Burger_Option(0, model.menuIsExpanded), new Burger_Option(2, ofArray([new HTMLAttr(99, "NavbarBurger"), new DOMAttr(40, (e) => {
            dispatch(new Msg(13, !model.menuIsExpanded));
        }), new HTMLAttr(135, "button"), ["style", {
            marginLeft: "0px",
        }]]))]), ofArray([react.createElement("span", {}), react.createElement("span", {}), react.createElement("span", {})])))), Item_a(singleton(new Item_Option(6, "banner-logo")), singleton(image(singleton(new Option_4(25, singleton(new DOMAttr(40, (_arg2) => {
            dispatch(new Msg(1, new Page(10)));
        })))), singleton(react.createElement("img", {
            src: "3docs-banner-logo.png",
        }))))), Item_a(ofArray([new Item_Option(7, singleton(new Modifier_IModifier(24, new Screen(1), true))), new Item_Option(5, singleton(["style", {
            flexGrow: 1,
        }]))]), singleton(level(ofArray([new Level_Option(0, singleton(["style", {
            flexGrow: 1,
        }])), new Level_Option(1)]), ofArray([item(singleton(new Item_Option_1(0, singleton(["style", {
            flexGrow: 1,
        }]))), empty()), right(empty(), singleton(item(empty(), singleton(view_13(model.authModel, (arg_15) => {
            dispatch(new Msg(2, arg_15));
        })))))]))))])), menu_1(empty(), singleton(End_div(empty(), ofArray([Item_div(singleton(new Item_Option(6, "banner-profile")), singleton(image(singleton(new Option_4(25, ofArray([new DOMAttr(40, (_arg3) => {
            dispatch(new Msg(1, new Page(5)));
        }), ["style", {
            cursor: "pointer",
        }]]))), singleton(react.createElement("img", {
            className: "is-rounded",
            src: "blank-profile-picture.png",
        }))))), defaultArg(map_1((ui_2) => Item_div(empty(), singleton(describeUser)), model.authModel.userInfo), react.createElement("div", {})), Item_div(empty(), singleton(view_13(model.authModel, (arg_16) => {
            dispatch(new Msg(2, arg_16));
        })))]))))]));
    }
    const footerContents = Brand_div(singleton(new Common_GenericOption(1, singleton(["style", {
        flexGrow: "1",
        height: "inherit",
        minHeight: "inherit",
    }]))), singleton(level(ofArray([new Level_Option(0, singleton(new HTMLAttr(99, "footer-level"))), new Level_Option(1)]), singleton(item(empty(), singleton(content_18(singleton(new Option_2(3, singleton(new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(0))))), singleton((children_18 = ["Version ", react.createElement("strong", {}, clientVersion)], react.createElement("p", {}, ...children_18))))))))));
    const children_22 = [navbar, (children_20 = [columns(ofArray([new Option_5(3), new Option_5(9, singleton(["style", {
        marginBottom: "0px",
    }]))]), ofArray([menu, column(empty(), singleton(content_16))]))], react.createElement("div", {}, ...children_20)), sessionExpiryModal(model.authModel.inactivity, (arg_17) => {
        dispatch(new Msg(2, arg_17));
    }), navbar_1(ofArray([new Option_3(6, singleton(new HTMLAttr(99, "footer-desktop"))), new Option_3(4), new Option_3(2), new Option_3(8, singleton(new Modifier_IModifier(24, new Screen(5), true)))]), singleton(footerContents)), navbar_1(ofArray([new Option_3(6, singleton(new HTMLAttr(99, "footer-touch"))), new Option_3(8, singleton(new Modifier_IModifier(24, new Screen(1), true)))]), singleton(footerContents))];
    return react.createElement("div", {}, ...children_22);
}

