import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, option_type, string_type, list_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { Specialty$reflection } from "../Domain/Specialty.js";
import { empty } from "../../fable_modules/fable-library.3.7.12/List.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, Result_mapError, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { FormValidationError, Validate_required, Validate_String_standardValidate, Operators_op_GreaterEqualsGreater, Validate_Guid_notNullEmpty } from "../../ThreeDocs.SharedUI/FormValidation.js";
import { uncurry } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { Section } from "../Domain/Section.js";

export class SectionViewModel extends Record {
    constructor(sectionId, mediaId, mediaSpecialtyFilter, sectionHeading, sectionContents) {
        super();
        this.sectionId = sectionId;
        this.mediaId = mediaId;
        this.mediaSpecialtyFilter = mediaSpecialtyFilter;
        this.sectionHeading = sectionHeading;
        this.sectionContents = sectionContents;
    }
}

export function SectionViewModel$reflection() {
    return record_type("ThreeDocs.ViewModels.SectionViewModel.SectionViewModel", [], SectionViewModel, () => [["sectionId", class_type("System.Guid")], ["mediaId", class_type("System.Guid")], ["mediaSpecialtyFilter", list_type(Specialty$reflection())], ["sectionHeading", option_type(string_type)], ["sectionContents", option_type(string_type)]]);
}

export function SectionViewModel_get_empty() {
    return new SectionViewModel("00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", empty(), void 0, void 0);
}

export function SectionViewModel_get_sectionId_() {
    return [(m) => m.sectionId, (v) => ((m_1) => (new SectionViewModel(v, m_1.mediaId, m_1.mediaSpecialtyFilter, m_1.sectionHeading, m_1.sectionContents)))];
}

export function SectionViewModel_get_mediaId_() {
    return [(m) => m.mediaId, (v) => ((m_1) => (new SectionViewModel(m_1.sectionId, v, m_1.mediaSpecialtyFilter, m_1.sectionHeading, m_1.sectionContents)))];
}

export function SectionViewModel_get_sectionHeading_() {
    return [(m) => m.sectionHeading, (v) => ((m_1) => (new SectionViewModel(m_1.sectionId, m_1.mediaId, m_1.mediaSpecialtyFilter, v, m_1.sectionContents)))];
}

export function SectionViewModel_get_sectionContents_() {
    return [(m) => m.sectionContents, (v) => ((m_1) => (new SectionViewModel(m_1.sectionId, m_1.mediaId, m_1.mediaSpecialtyFilter, m_1.sectionHeading, v)))];
}

export function SectionViewModel_toDomain_4C64BA8F(vm) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Validate_Guid_notNullEmpty("Section Id", vm.sectionId), (_arg1) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Operators_op_GreaterEqualsGreater(uncurry(2, Validate_String_standardValidate), Validate_required)("Media Title")(vm.sectionHeading), (_arg2) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Operators_op_GreaterEqualsGreater(uncurry(2, Validate_String_standardValidate), Validate_required)("Media Description")(vm.sectionContents), (_arg3) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Result_mapError()((_arg1_1) => (new FormValidationError(0, "Media", "be selected")))(Validate_Guid_notNullEmpty("Media Id", vm.mediaId)), (_arg4) => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, new Section(_arg1, _arg4, _arg2, _arg3))))))));
}

export function SectionViewModel_ofDomain_4158CEC(section) {
    return new SectionViewModel(section.SectionId, section.MediaId, empty(), section.SectionHeading, section.SectionContents);
}

