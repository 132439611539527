import { Union } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { union_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { ofArray } from "../../fable_modules/fable-library.3.7.12/List.js";
import { FormData_tryParse } from "../Shared.js";

export class MediaType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Image", "Video", "Pdf"];
    }
}

export function MediaType$reflection() {
    return union_type("ThreeDocs.Models.Domain.MediaType.MediaType", [], MediaType, () => [[], [], []]);
}

export function MediaType_get_describe() {
    return (_arg1) => ((_arg1.tag === 1) ? "Video" : ((_arg1.tag === 2) ? "PDF" : "Image"));
}

export function MediaType_get_all() {
    return ofArray([new MediaType(0), new MediaType(1), new MediaType(2)]);
}

export function MediaType_get_tryParse() {
    const all = MediaType_get_all();
    const toString = MediaType_get_describe();
    return (v) => FormData_tryParse(toString, all, v);
}

export function MediaType_tryParseFromExtension_Z721C83C5(extension) {
    const matchValue = extension.toLocaleLowerCase();
    switch (matchValue) {
        case "png":
        case "jpg":
        case "jpeg": {
            return new MediaType(0);
        }
        case "mkv":
        case "mov":
        case "mp4": {
            return new MediaType(1);
        }
        case "pdf": {
            return new MediaType(2);
        }
        default: {
            return void 0;
        }
    }
}

