import { Toastr_info, Toastr_success, Toastr_message, Toastr_title, Toastr_position, Toastr_timeout, Toastr_hideEasing, Toastr_showCloseButton, Toastr_error } from "../fable_modules/Elmish.Toastr.2.1.0/Elmish.Toastr.fs.js";

export function error(text) {
    return Toastr_error(Toastr_showCloseButton(Toastr_hideEasing("swing", Toastr_timeout(3000, Toastr_position("toast-top-full-width", Toastr_title("Error", Toastr_message(text)))))));
}

export function success(text) {
    return Toastr_success(Toastr_showCloseButton(Toastr_hideEasing("swing", Toastr_timeout(3000, Toastr_position("toast-top-full-width", Toastr_title("Success", Toastr_message(text)))))));
}

export function info(text) {
    return Toastr_info(Toastr_showCloseButton(Toastr_hideEasing("swing", Toastr_timeout(3000, Toastr_position("toast-top-full-width", Toastr_title("Info", Toastr_message(text)))))));
}

