import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, unit_type, union_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { CrudControls_mkId } from "../ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { Label_Option, label as label_3, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { map as map_1, ofArray, singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Naming_UIElement } from "../fable_modules/Webbler.Models.1.1.0/Common.fs.js";
import { IInputType, Option, input } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Option as Option_1, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { delay, toList, map } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { SelectPropsMulti$1, SelectOption$1 } from "../ThreeDocs.SharedUI/bindings/ReactSelectBind.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import * as react from "react";
import react_select from "react-select";
import { FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_single } from "../ThreeDocs.SharedUI/Forms.js";
import { TableOption, table } from "../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError"];
    }
}

export function Msg$reflection() {
    return union_type("Client.DemonstrationPage.DemonstrationRef.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]]]);
}

export class Model extends Record {
    constructor(none) {
        super();
        this.none = none;
    }
}

export function Model$reflection() {
    return record_type("Client.DemonstrationPage.DemonstrationRef.Model", [], Model, () => [["none", unit_type]]);
}

export function init() {
    return [new Model(void 0), Cmd_none()];
}

export function update(token, msg, model) {
    console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
    return [model, Cmd_none()];
}

export const labeledUiElementsDemo = (() => {
    let columns, children_9, children_7, children_11;
    const mkDemoId = (view_1, label, element) => CrudControls_mkId("demo", view_1, label, element);
    const mkInputField = (label_1, inputType, isDisabled) => div(empty(), ofArray([label_3(singleton(new Label_Option(3, singleton(mkDemoId("", label_1, new Naming_UIElement(1, new Naming_UIElement(0)))))), singleton(label_1)), input(ofArray([new Option(1, inputType), new Option(15, ofArray([mkDemoId("", label_1, new Naming_UIElement(0)), new HTMLAttr(79, isDisabled)]))]))]));
    const children_13 = [button(singleton(new Option_1(17, ofArray([mkDemoId("", "Enabled", new Naming_UIElement(2)), new HTMLAttr(79, false)]))), singleton("Enabled Button")), button(singleton(new Option_1(17, ofArray([mkDemoId("", "Disabled", new Naming_UIElement(2)), new HTMLAttr(79, true)]))), singleton("Disabled Button")), mkInputField("EnabledTextField", new IInputType(0), false), mkInputField("DisabledTextField", new IInputType(0), true), mkInputField("EnabledNumberField", new IInputType(7), false), mkInputField("DisabledNumberField", new IInputType(7), true), ((values) => {
        let reactSelect;
        const props = ofArray([new SelectPropsMulti$1(1, Array.from(map((selection) => (new SelectOption$1(selection, selection)), values))), new SelectPropsMulti$1(8, false), new SelectPropsMulti$1(9, false)]);
        const props_1 = keyValueList(props, 1);
        reactSelect = react.createElement(react_select, props_1);
        return FormField_ReactSelect_single(values, [], (x) => x, void 0, new FormField_ReactSelect_CallbackSingle$1(0, (_arg2) => {
        }), "Options", "Test", []);
    })(["Option 1", "Option 2", "Option 3", "Bonus Option"]), (columns = ofArray(["Name", "Age", "Status"]), table(ofArray([new TableOption(6, singleton(new HTMLAttr(99, "Table"))), new TableOption(1), new TableOption(2), new TableOption(4)]), ofArray([(children_9 = [(children_7 = toList(delay(() => map_1((column) => react.createElement("td", {}, column), columns))), react.createElement("tr", {}, ...children_7))], react.createElement("thead", {
        id: "TableHeader",
    }, ...children_9)), (children_11 = map_1((item) => {
        const children_3 = toList(delay(() => map_1((col) => {
            const children_1 = [toText(printf("%s%s"))(item)(col)];
            return react.createElement("td", {}, ...children_1);
        }, columns)));
        return react.createElement("tr", {}, ...children_3);
    }, ofArray(["Cat", "Dog", "Fish"])), react.createElement("tbody", {
        id: "TableBody",
    }, ...children_11))])))];
    return react.createElement("div", {}, ...children_13);
})();

export function view(model, dispatch) {
    return content(empty(), singleton(react.createElement("p", {}, "For demonstration")));
}

