import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, lambda_type, unit_type, float64_type, record_type, class_type, string_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { ErrorMessage$reflection } from "./Api.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.12/Choice.js";

export class DeveloperIdentity extends Record {
    constructor(IdentityId, Token, Expiry) {
        super();
        this.IdentityId = IdentityId;
        this.Token = Token;
        this.Expiry = Expiry;
    }
}

export function DeveloperIdentity$reflection() {
    return record_type("ThreeDocs.Models.Api.Aws.DeveloperIdentity", [], DeveloperIdentity, () => [["IdentityId", string_type], ["Token", string_type], ["Expiry", class_type("System.DateTimeOffset")]]);
}

export class S3Configuration extends Record {
    constructor(Authority, IdentityPoolId, Region, Bucket) {
        super();
        this.Authority = Authority;
        this.IdentityPoolId = IdentityPoolId;
        this.Region = Region;
        this.Bucket = Bucket;
    }
}

export function S3Configuration$reflection() {
    return record_type("ThreeDocs.Models.Api.Aws.S3Configuration", [], S3Configuration, () => [["Authority", string_type], ["IdentityPoolId", string_type], ["Region", string_type], ["Bucket", string_type]]);
}

export class CognitoClientConfiguration extends Record {
    constructor(AccessKey, SecretKey) {
        super();
        this.AccessKey = AccessKey;
        this.SecretKey = SecretKey;
    }
}

export function CognitoClientConfiguration$reflection() {
    return record_type("ThreeDocs.Models.Api.Aws.CognitoClientConfiguration", [], CognitoClientConfiguration, () => [["AccessKey", string_type], ["SecretKey", string_type]]);
}

export class IdentityConfiguration extends Record {
    constructor(PoolId, IdentityProvider) {
        super();
        this.PoolId = PoolId;
        this.IdentityProvider = IdentityProvider;
    }
}

export function IdentityConfiguration$reflection() {
    return record_type("ThreeDocs.Models.Api.Aws.IdentityConfiguration", [], IdentityConfiguration, () => [["PoolId", string_type], ["IdentityProvider", string_type]]);
}

export class S3ObjectViewModel extends Record {
    constructor(FileName, Size, UploadDate, FilePath) {
        super();
        this.FileName = FileName;
        this.Size = Size;
        this.UploadDate = UploadDate;
        this.FilePath = FilePath;
    }
}

export function S3ObjectViewModel$reflection() {
    return record_type("ThreeDocs.Models.Api.Aws.S3ObjectViewModel", [], S3ObjectViewModel, () => [["FileName", string_type], ["Size", float64_type], ["UploadDate", class_type("System.DateTimeOffset")], ["FilePath", string_type]]);
}

export class SecuredAwsApi extends Record {
    constructor(getS3Configuration, getIdentity) {
        super();
        this.getS3Configuration = getS3Configuration;
        this.getIdentity = getIdentity;
    }
}

export function SecuredAwsApi$reflection() {
    return record_type("ThreeDocs.Models.Api.Aws.SecuredAwsApi", [], SecuredAwsApi, () => [["getS3Configuration", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [S3Configuration$reflection()]))], ["getIdentity", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [DeveloperIdentity$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", DeveloperIdentity$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]))]]);
}

