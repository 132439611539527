import { content } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { singleton as singleton_1, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { View_view } from "../ThreeDocs.Client.StateMachine.Crud/CrudComponent.js";
import { childAbsentStrategy, Msg } from "./UsersTypes.js";
import { emailChangeModal } from "../EmailChange/EmailChangeView.js";
import { ChildMsg_ofMsg } from "../Common/Terminate.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import * as react from "react";

export function view(model, dispatch) {
    let children;
    return content(empty(), singleton_1((children = toList(delay(() => append(singleton(View_view(model.UserCrudModel, (arg) => {
        dispatch(new Msg(8, arg));
    })), delay(() => {
        const matchValue = model.emailChange.child;
        return (matchValue.tag === 0) ? singleton(emailChangeModal(matchValue.fields[0], (arg_2) => {
            dispatch(new Msg(9, ChildMsg_ofMsg(childAbsentStrategy, arg_2)));
        }, (_arg1) => {
            dispatch(new Msg(6));
        }, (_arg2) => {
            dispatch(new Msg(10));
        })) : singleton(Helpers_nothing);
    })))), react.createElement("div", {}, ...children))));
}

