import { Union } from "../fable-library.3.7.12/Types.js";
import { union_type } from "../fable-library.3.7.12/Reflection.js";
import { some, bind, value as value_2, map } from "../fable-library.3.7.12/Option.js";
import { curry } from "../fable-library.3.7.12/Util.js";
import { empty, mapIndexed, item, length, cons, tail, head, isEmpty, toArray, ofArray } from "../fable-library.3.7.12/List.js";
import { FSharpResult$2, FSharpChoice$2 } from "../fable-library.3.7.12/Choice.js";
import { ofList, toList, ofArray as ofArray_1, toArray as toArray_1, remove, add, containsKey, tryFind } from "../fable-library.3.7.12/Map.js";

export class Compose_Lens extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Lens"];
    }
}

export function Compose_Lens$reflection() {
    return union_type("Aether.Compose.Lens", [], Compose_Lens, () => [[]]);
}

export function Compose_Lens_op_GreaterMinusGreater_31E453EA(_arg1, _arg2) {
    return (_arg1_1) => {
        const g1 = _arg1_1[0];
        return [(a) => _arg2[0](g1(a)), (c) => ((a_1) => _arg1_1[1](_arg2[1](c)(g1(a_1)))(a_1))];
    };
}

export function Compose_Lens_op_GreaterMinusGreater_206F9379(_arg3, _arg4) {
    return (_arg2) => {
        const g1 = _arg2[0];
        return [(a) => _arg4[0](g1(a)), (c) => ((a_1) => _arg2[1](_arg4[1](c)(g1(a_1)))(a_1))];
    };
}

export function Compose_Lens_op_GreaterMinusGreater_2536FC39(_arg5, _arg6) {
    return (_arg3) => [(a) => _arg6[0](_arg3[0](a)), (c) => ((a_1) => _arg3[1](_arg6[1](c))(a_1))];
}

export function Compose_Lens_op_GreaterMinusGreater_1BCE002A(_arg7, _arg8) {
    return (_arg4) => [(a) => _arg8[0](_arg4[0](a)), (c) => ((a_1) => _arg4[1](_arg8[1](c))(a_1))];
}

export class Compose_Prism extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Prism"];
    }
}

export function Compose_Prism$reflection() {
    return union_type("Aether.Compose.Prism", [], Compose_Prism, () => [[]]);
}

export function Compose_Prism_op_GreaterQmarkGreater_4C84C7CB(_arg1, _arg2) {
    return (_arg1_1) => {
        const g1 = _arg1_1[0];
        return [(a) => map(_arg2[0], g1(a)), (c) => ((a_1) => {
            const _arg9 = map(_arg2[1](c), g1(a_1));
            return (_arg9 != null) ? _arg1_1[1](value_2(_arg9))(a_1) : a_1;
        })];
    };
}

export function Compose_Prism_op_GreaterQmarkGreater_5D0F0758(_arg3, _arg4) {
    return (_arg2) => {
        const g1 = _arg2[0];
        return [(a) => bind(_arg4[0], g1(a)), (c) => ((a_1) => {
            const _arg10 = map(_arg4[1](c), g1(a_1));
            return (_arg10 != null) ? _arg2[1](value_2(_arg10))(a_1) : a_1;
        })];
    };
}

export function Compose_Prism_op_GreaterQmarkGreater_58566818(_arg5, _arg6) {
    return (_arg3) => [(a) => map(_arg6[0], _arg3[0](a)), (c) => ((a_1) => _arg3[1](_arg6[1](c))(a_1))];
}

export function Compose_Prism_op_GreaterQmarkGreater_66AE940B(_arg7, _arg8) {
    return (_arg4) => [(a) => bind(_arg8[0], _arg4[0](a)), (c) => ((a_1) => _arg4[1](_arg8[1](c))(a_1))];
}

export function Compose_totalLensTotalLens(l1_0, l1_1, l2_0, l2_1) {
    const l1 = [l1_0, curry(2, l1_1)];
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), [l2_0, curry(2, l2_1)])(l1);
}

export function Compose_totalLensPartialLens(l1_0, l1_1, p1_0, p1_1) {
    const l1 = [l1_0, curry(2, l1_1)];
    return Compose_Lens_op_GreaterMinusGreater_206F9379(new Compose_Lens(0), [p1_0, curry(2, p1_1)])(l1);
}

export function Compose_partialLensTotalLens(p1_0, p1_1, l1_0, l1_1) {
    const p1 = [p1_0, curry(2, p1_1)];
    return Compose_Prism_op_GreaterQmarkGreater_4C84C7CB(new Compose_Prism(0), [l1_0, curry(2, l1_1)])(p1);
}

export function Compose_partialLensPartialLens(p1_0, p1_1, p2_0, p2_1) {
    const p1 = [p1_0, curry(2, p1_1)];
    return Compose_Prism_op_GreaterQmarkGreater_5D0F0758(new Compose_Prism(0), [p2_0, curry(2, p2_1)])(p1);
}

export function Compose_totalLensTotalIsomorphism(l1_0, l1_1, i1_0, i1_1) {
    const l1 = [l1_0, curry(2, l1_1)];
    return Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), [i1_0, i1_1])(l1);
}

export function Compose_totalLensPartialIsomorphism(l1_0, l1_1, p1_0, p1_1) {
    const l1 = [l1_0, curry(2, l1_1)];
    return Compose_Lens_op_GreaterMinusGreater_1BCE002A(new Compose_Lens(0), [p1_0, p1_1])(l1);
}

export function Compose_partialLensTotalIsomorphism(p1_0, p1_1, i1_0, i1_1) {
    const p1 = [p1_0, curry(2, p1_1)];
    return Compose_Prism_op_GreaterQmarkGreater_58566818(new Compose_Prism(0), [i1_0, i1_1])(p1);
}

export function Compose_partialLensPartialIsomorphism(p1_0, p1_1, e1_0, e1_1) {
    const p1 = [p1_0, curry(2, p1_1)];
    return Compose_Prism_op_GreaterQmarkGreater_66AE940B(new Compose_Prism(0), [e1_0, e1_1])(p1);
}

export class Optic_Get extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Get"];
    }
}

export function Optic_Get$reflection() {
    return union_type("Aether.Optic.Get", [], Optic_Get, () => [[]]);
}

export function Optic_Get_op_HatDot_21762A61(_arg1, _arg2) {
    return (a) => _arg2[0](a);
}

export function Optic_Get_op_HatDot_30FDEAF2(_arg3, _arg4) {
    return (a) => _arg4[0](a);
}

export class Optic_Set extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Set"];
    }
}

export function Optic_Set$reflection() {
    return union_type("Aether.Optic.Set", [], Optic_Set, () => [[]]);
}

export function Optic_Set_op_HatEquals_2170E4F5(_arg1, _arg2) {
    return (b) => _arg2[1](b);
}

export function Optic_Set_op_HatEquals_30FB2466(_arg3, _arg4) {
    return (b) => _arg4[1](b);
}

export class Optic_Map extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Map"];
    }
}

export function Optic_Map$reflection() {
    return union_type("Aether.Optic.Map", [], Optic_Map, () => [[]]);
}

export function Optic_Map_op_HatPercent_216CF0AB(_arg1, _arg2) {
    return (f) => ((a) => _arg2[1](f(_arg2[0](a)))(a));
}

export function Optic_Map_op_HatPercent_30E73038(_arg3, _arg4) {
    return (f) => ((a) => {
        const _arg5 = map(f, _arg4[0](a));
        return (_arg5 != null) ? _arg4[1](value_2(_arg5))(a) : a;
    });
}

export function Lens_ofIsomorphism(_arg1_0, _arg1_1) {
    const _arg1 = [_arg1_0, _arg1_1];
    return [_arg1[0], (b) => ((_arg1_2) => _arg1[1](b))];
}

export function Lens_getPartial(p_0, p_1) {
    const p = [p_0, curry(2, p_1)];
    return (target) => Optic_Get_op_HatDot_30FDEAF2(new Optic_Get(0), p)(target);
}

export function Lens_getPartialOrElse(p_0, p_1, b) {
    const p = [p_0, curry(2, p_1)];
    return (arg) => {
        const _arg1 = Optic_Get_op_HatDot_30FDEAF2(new Optic_Get(0), p)(arg);
        return (_arg1 != null) ? value_2(_arg1) : b;
    };
}

export function Lens_setPartial(p_0, p_1) {
    const p = [p_0, curry(2, p_1)];
    return (value) => Optic_Set_op_HatEquals_30FB2466(new Optic_Set(0), p)(value);
}

export function Lens_mapPartial(p_0, p_1) {
    const p = [p_0, curry(2, p_1)];
    return (f) => Optic_Map_op_HatPercent_30E73038(new Optic_Map(0), p)(f);
}

export function Prism_ofEpimorphism(_arg1_0, _arg1_1) {
    const _arg1 = [_arg1_0, _arg1_1];
    return [_arg1[0], (b) => ((_arg1_2) => _arg1[1](b))];
}

export function Optics_id_() {
    return [(x) => x, (x_1) => ((_arg1) => x_1)];
}

export function Optics_box_() {
    return [(value) => value, (value_1) => value_1];
}

export function Optics_fst_() {
    return [(tuple) => tuple[0], (a) => ((t) => [a, t[1]])];
}

export function Optics_snd_() {
    return [(tuple) => tuple[1], (b) => ((t) => [t[0], b])];
}

export function Optics_Array_list_() {
    return [ofArray, toArray];
}

export function Optics_Choice_choice1Of2_() {
    return [(x) => ((x.tag === 0) ? some(x.fields[0]) : (void 0)), (v_1) => ((x_1) => ((x_1.tag === 0) ? (new FSharpChoice$2(0, v_1)) : x_1))];
}

export function Optics_Choice_choice2Of2_() {
    return [(x) => ((x.tag === 1) ? some(x.fields[0]) : (void 0)), (v_1) => ((x_1) => ((x_1.tag === 1) ? (new FSharpChoice$2(1, v_1)) : x_1))];
}

export function Optics_Result_ok_() {
    return [(x) => ((x.tag === 0) ? some(x.fields[0]) : (void 0)), (v_1) => ((x_1) => ((x_1.tag === 0) ? (new FSharpResult$2(0, v_1)) : x_1))];
}

export function Optics_Result_error_() {
    return [(x) => ((x.tag === 1) ? some(x.fields[0]) : (void 0)), (v_1) => ((x_1) => ((x_1.tag === 1) ? (new FSharpResult$2(1, v_1)) : x_1))];
}

export function Optics_List_head_() {
    return [(_arg1) => ((!isEmpty(_arg1)) ? some(head(_arg1)) : (void 0)), (v) => ((_arg2) => ((!isEmpty(_arg2)) ? cons(v, tail(_arg2)) : _arg2))];
}

export function Optics_List_pos_(i) {
    return [(_arg1) => ((length(_arg1) > i) ? some(item(i, _arg1)) : (void 0)), (v) => ((l_2) => mapIndexed((i$0027, x) => ((i === i$0027) ? v : x), l_2))];
}

export function Optics_List_tail_() {
    return [(_arg1) => ((!isEmpty(_arg1)) ? tail(_arg1) : (void 0)), (t_1) => ((_arg2) => (isEmpty(_arg2) ? empty() : cons(head(_arg2), t_1)))];
}

export function Optics_List_array_() {
    return [toArray, ofArray];
}

export function Optics_Map_key_(k) {
    return [(table) => tryFind(k, table), (v) => ((x) => (containsKey(k, x) ? add(k, v, x) : x))];
}

export function Optics_Map_value_(k) {
    return [(table) => tryFind(k, table), (v) => ((x) => ((v != null) ? add(k, value_2(v), x) : remove(k, x)))];
}

export function Optics_Map_array_() {
    return [toArray_1, ofArray_1];
}

export function Optics_Map_list_() {
    return [toList, ofList];
}

export function Optics_Option_value_() {
    return [(x) => x, (v) => ((_arg1) => ((_arg1 == null) ? (void 0) : some(v)))];
}

export function id_() {
    return Optics_id_();
}

export function fst_() {
    return Optics_fst_();
}

export function snd_() {
    return Optics_snd_();
}

export function head_() {
    return Optics_List_head_();
}

export function pos_(i) {
    return Optics_List_pos_(i);
}

export function tail_() {
    return Optics_List_tail_();
}

export function key_(k) {
    return Optics_Map_key_(k);
}

export function Operators_op_HatPercentEquals(f, l_0, l_1) {
    return Optic_Map_op_HatPercent_216CF0AB(new Optic_Map(0), [l_0, curry(2, l_1)])(f);
}

