import { Union, Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { class_type, tuple_type, list_type, lambda_type, unit_type, union_type, string_type, record_type, option_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { ListDisplayModification_ListDisplayModification$2_applyListModifications, Sort_SortableColumn$1, ListDisplayModification_ListDisplayModification$2$reflection, ListDisplayModification_ListDisplayModification$2, Sort_SortInfo$1_getSortComparison_4155F936, Sort_SortInfo$1$reflection } from "../../ThreeDocs.SharedUI/Sort.js";
import { Shared_InboundModificationUpdate$2, CrudControls_ButtonClick, Shared_SharedCrudModel$4$reflection, CrudControls_ButtonClick$reflection, CrudControls_ListColumn$2$reflection, Shared_CrudListModifications, Shared_updateInboundModification, Shared_SharedCrudModel$4, Shared_InboundModificationUpdate$2$reflection } from "../SharedCrud.js";
import { makeStateTransition, StateResultMsg$6, StateMachineMsg$5$reflection } from "../../fable_modules/Webbler.StateMachine.Core.1.1.0/StateMachine.fs.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { map, defaultArg, some, value as value_1 } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library.3.7.12/Seq.js";
import { map as map_1, exists, sortWith, filter } from "../../fable_modules/fable-library.3.7.12/List.js";
import { uncurry, equals } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { TableSearch$reflection, TableSearch } from "../../ThreeDocs.SharedUI/Search.js";
import { isNullOrWhiteSpace } from "../../fable_modules/fable-library.3.7.12/String.js";
import { HTMLAttr } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import * as react from "react";

export class Model$4 extends Record {
    constructor(selected) {
        super();
        this.selected = selected;
    }
}

export function Model$4$reflection(gen0, gen1, gen2, gen3) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudList.Model`4", [gen0, gen1, gen2, gen3], Model$4, () => [["selected", option_type(gen0)]]);
}

export class InternalMsg$4 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SelectItem", "UpdateSort", "StartCreateItem", "UpdateSearch", "InternalModificationUpdated", "SendOutbound"];
    }
}

export function InternalMsg$4$reflection(gen0, gen1, gen2, gen3) {
    return union_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudList.InternalMsg`4", [gen0, gen1, gen2, gen3], InternalMsg$4, () => [[["Item", gen0]], [["Item", Sort_SortInfo$1$reflection(gen0)]], [], [["Item", option_type(string_type)]], [["Item", Shared_InboundModificationUpdate$2$reflection(gen0, gen3)]], [["Item", gen1]]]);
}

export class Transitions$8 extends Record {
    constructor(SelectItem, CreateItem) {
        super();
        this.SelectItem = SelectItem;
        this.CreateItem = CreateItem;
    }
}

export function Transitions$8$reflection(gen0, gen1, gen2, gen3, gen4, gen5, gen6, gen7) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudList.Transitions`8", [gen0, gen1, gen2, gen3, gen4, gen5, gen6, gen7], Transitions$8, () => [["SelectItem", lambda_type(gen1, lambda_type(Model$4$reflection(gen7, gen4, gen5, gen6), tuple_type(gen0, list_type(lambda_type(lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), unit_type), unit_type)), gen1)))], ["CreateItem", lambda_type(gen1, lambda_type(Model$4$reflection(gen7, gen4, gen5, gen6), tuple_type(gen0, list_type(lambda_type(lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), unit_type), unit_type)), gen1)))]]);
}

export function init(shared, selected) {
    return [new Model$4(selected), Cmd_none(), shared];
}

export function updateInbound(transitions, token, msg, sharedModel, model) {
    switch (msg.tag) {
        case 3: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(sharedModel.ItemList, sharedModel.Search, sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, msg.fields[0])];
        }
        case 4: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(sharedModel.ItemList, sharedModel.Search, sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, msg.fields[0](sharedModel.CustomStorage))];
        }
        case 1: {
            return [model, Cmd_none(), Shared_updateInboundModification(msg.fields[0], sharedModel)];
        }
        case 2: {
            const maybeSelectedItem = msg.fields[0](sharedModel.ItemList);
            if (maybeSelectedItem == null) {
                return [model, Cmd_none(), sharedModel];
            }
            else {
                return [model, Cmd_OfFunc_result(new StateResultMsg$6(0, new InternalMsg$4(0, value_1(maybeSelectedItem)))), sharedModel];
            }
        }
        default: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(msg.fields[0], sharedModel.Search, sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, sharedModel.CustomStorage)];
        }
    }
}

export function update(transitions, token, msg, sharedModel, model) {
    switch (msg.tag) {
        case 2: {
            return [model, Cmd_OfFunc_result(new StateResultMsg$6(1, transitions.CreateItem(sharedModel, model))), sharedModel];
        }
        case 5: {
            return [model, Cmd_OfFunc_result(new StateResultMsg$6(2, msg.fields[0])), sharedModel];
        }
        case 1: {
            const newSort = msg.fields[0];
            const sharedModel$0027 = new Shared_SharedCrudModel$4(sharedModel.ItemList, sharedModel.Search, sharedModel.ItemConfig, newSort, toList(delay(() => append(filter((modification) => (!equals(modification.Source, new Shared_CrudListModifications(0))), sharedModel.ListModifications), delay(() => {
                let comparer;
                return singleton(new ListDisplayModification_ListDisplayModification$2(new Shared_CrudListModifications(0), 0, (comparer = Sort_SortInfo$1_getSortComparison_4155F936(newSort), (list_1) => sortWith(uncurry(2, comparer), list_1))));
            })))), sharedModel.CustomStorage);
            return [model, Cmd_none(), sharedModel$0027];
        }
        case 4: {
            return [model, Cmd_none(), Shared_updateInboundModification(msg.fields[0], sharedModel)];
        }
        case 3: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(sharedModel.ItemList, new TableSearch(msg.fields[0], sharedModel.Search.identifier), sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, sharedModel.CustomStorage)];
        }
        default: {
            return makeStateTransition(transitions.SelectItem, sharedModel, new Model$4(some(msg.fields[0])));
        }
    }
}

export function SearchModule_allColumnSearch(tableSearch, fields, storage) {
    return new ListDisplayModification_ListDisplayModification$2(tableSearch.identifier, 0, (crudItems) => filter((item_1) => {
        let _arg1, search_2;
        return defaultArg(map((search) => exists((column) => {
            let cellContents;
            const matchValue = column.mkCellContent(item_1, storage);
            cellContents = ((matchValue === null) ? "" : matchValue.toLowerCase().trim());
            return cellContents.indexOf(search.toLowerCase().trim()) >= 0;
        }, fields), (_arg1 = tableSearch.search, (_arg1 != null) ? (isNullOrWhiteSpace(_arg1) ? ((search_2 = _arg1, void 0)) : ((_arg1 != null) ? _arg1 : (void 0))) : ((_arg1 != null) ? _arg1 : (void 0)))), true);
    }, crudItems));
}

export class ViewConfig$2 extends Record {
    constructor(listColumns, makeSearch) {
        super();
        this.listColumns = listColumns;
        this.makeSearch = makeSearch;
    }
}

export function ViewConfig$2$reflection(gen0, gen1) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudList.ViewConfig`2", [gen0, gen1], ViewConfig$2, () => [["listColumns", list_type(CrudControls_ListColumn$2$reflection(gen0, gen1))], ["makeSearch", lambda_type(TableSearch$reflection(), lambda_type(list_type(CrudControls_ListColumn$2$reflection(gen0, gen1)), lambda_type(gen1, ListDisplayModification_ListDisplayModification$2$reflection(gen0, string_type))))]]);
}

export class Search extends Record {
    constructor(updateSearch, triggerSearch) {
        super();
        this.updateSearch = updateSearch;
        this.triggerSearch = triggerSearch;
    }
}

export function Search$reflection() {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudList.Search", [], Search, () => [["updateSearch", lambda_type(option_type(string_type), unit_type)], ["triggerSearch", lambda_type(option_type(string_type), unit_type)]]);
}

export class Buttons extends Record {
    constructor(createItem, searchTable) {
        super();
        this.createItem = createItem;
        this.searchTable = searchTable;
    }
}

export function Buttons$reflection() {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudList.Buttons", [], Buttons, () => [["createItem", CrudControls_ButtonClick$reflection()], ["searchTable", Search$reflection()]]);
}

export class ViewLayoutConfig$4 extends Record {
    constructor(listViewParentElement, columnLayoutFormat) {
        super();
        this.listViewParentElement = listViewParentElement;
        this.columnLayoutFormat = columnLayoutFormat;
    }
}

export function ViewLayoutConfig$4$reflection(gen0, gen1, gen2, gen3) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudList.ViewLayoutConfig`4", [gen0, gen1, gen2, gen3], ViewLayoutConfig$4, () => [["listViewParentElement", lambda_type(ViewConfig$2$reflection(gen0, gen3), lambda_type(Shared_SharedCrudModel$4$reflection(gen0, gen1, gen2, gen3), lambda_type(Model$4$reflection(gen0, gen1, gen2, gen3), lambda_type(class_type("Fable.React.ReactElement"), lambda_type(Buttons$reflection(), lambda_type(lambda_type(gen1, unit_type), class_type("Fable.React.ReactElement")))))))], ["columnLayoutFormat", lambda_type(Shared_SharedCrudModel$4$reflection(gen0, gen1, gen2, gen3), lambda_type(list_type(CrudControls_ListColumn$2$reflection(gen0, gen3)), lambda_type(option_type(gen0), lambda_type(lambda_type(InternalMsg$4$reflection(gen0, gen1, gen2, gen3), unit_type), class_type("Fable.React.ReactElement")))))]]);
}

export function columnListTable(itemListLayoutArranger, _arg1, columnConfig, selectedItem, dispatch) {
    const children = [itemListLayoutArranger(map_1((listColumn) => (new Sort_SortableColumn$1(listColumn.label, (itemVm) => listColumn.mkCellContent(itemVm, _arg1.CustomStorage), listColumn.compare)), columnConfig), (item_1) => ((_arg1_1) => dispatch(new InternalMsg$4(0, item_1))), ListDisplayModification_ListDisplayModification$2_applyListModifications(_arg1.ListModifications, _arg1.ItemList), (item) => {
        let pattern_matching_result;
        if (selectedItem != null) {
            if (_arg1.ItemConfig.isSameItem(value_1(selectedItem), item)) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return delay(() => singleton(new HTMLAttr(64, "is-selected")));
            }
            case 1: {
                return empty();
            }
        }
    }, _arg1.SortInfo, (sort) => ((_arg2) => dispatch(new InternalMsg$4(1, sort))))];
    return react.createElement("div", {
        className: "table-container",
    }, ...children);
}

export function view(config, viewSurroundConfig, sharedModel, model, dispatch) {
    const children = [viewSurroundConfig.listViewParentElement(config, sharedModel, model, viewSurroundConfig.columnLayoutFormat(sharedModel, config.listColumns, model.selected, dispatch), new Buttons(new CrudControls_ButtonClick("Create", (_arg1) => {
        dispatch(new InternalMsg$4(2));
    }), new Search((arg) => {
        dispatch(new InternalMsg$4(3, arg));
    }, (basicSearch) => {
        const newTableSearch = new TableSearch(basicSearch, sharedModel.Search.identifier);
        dispatch(new InternalMsg$4(3, basicSearch));
        dispatch(new InternalMsg$4(4, new Shared_InboundModificationUpdate$2(0, config.makeSearch(newTableSearch, config.listColumns, sharedModel.CustomStorage))));
    })), (arg_1) => {
        dispatch(new InternalMsg$4(5, arg_1));
    })];
    return react.createElement("div", {}, ...children);
}

