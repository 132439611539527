import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { CrudStateMachine_forceCreate, CrudStateMachine_states, CrudStateMachine_CrudTaggedStateMessages$4$reflection, Crud_CrudTagModel$4$reflection } from "./CrudMachine.js";
import { Shared_CrudInbound$2$reflection, Shared_SharedCrudModel$4$reflection } from "./SharedCrud.js";
import { view, update, StateMachineMsg$5, StateMachineModel$6$reflection, StateMachineMsg$5$reflection } from "../fable_modules/Webbler.StateMachine.Core.1.1.0/StateMachine.fs.js";
import { record_type, union_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { Cmd_map, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { error } from "../ThreeDocs.SharedUI/Toast.js";
import * as react from "react";

export class Types_Msg$4 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CrudMsg", "SafeOutbound", "SafeInbound"];
    }
}

export function Types_Msg$4$reflection(gen0, gen1, gen2, gen3) {
    return union_type("ThreeDocs.Client.StateMachine.Crud.CrudComponent.Types.Msg`4", [gen0, gen1, gen2, gen3], Types_Msg$4, () => [[["Item", StateMachineMsg$5$reflection(Crud_CrudTagModel$4$reflection(gen0, gen1, gen2, gen3), Shared_SharedCrudModel$4$reflection(gen0, gen1, gen2, gen3), CrudStateMachine_CrudTaggedStateMessages$4$reflection(gen0, gen1, gen2, gen3), Shared_CrudInbound$2$reflection(gen0, gen3), gen1)]], [["Item", gen1]], [["Item", Shared_CrudInbound$2$reflection(gen0, gen3)]]]);
}

export class Types_Model$4 extends Record {
    constructor(CrudMachineModel) {
        super();
        this.CrudMachineModel = CrudMachineModel;
    }
}

export function Types_Model$4$reflection(gen0, gen1, gen2, gen3) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.CrudComponent.Types.Model`4", [gen0, gen1, gen2, gen3], Types_Model$4, () => [["CrudMachineModel", StateMachineModel$6$reflection(Crud_CrudTagModel$4$reflection(gen0, gen1, gen2, gen3), Shared_SharedCrudModel$4$reflection(gen0, gen1, gen2, gen3), CrudStateMachine_CrudTaggedStateMessages$4$reflection(gen0, gen1, gen2, gen3), Shared_CrudInbound$2$reflection(gen0, gen3), gen1, gen2)]]);
}

export function State_init(crudMachineLayoutConfig, crudMachineViewItemConfig, crudMachineItemConfig, initialCustomStorage) {
    return [new Types_Model$4(CrudStateMachine_states(crudMachineLayoutConfig, crudMachineViewItemConfig, crudMachineItemConfig, initialCustomStorage)), Cmd_none()];
}

export function State_update(token, msg, model) {
    if (msg.tag === 2) {
        return [model, Cmd_OfFunc_result(new Types_Msg$4(0, new StateMachineMsg$5(2, msg.fields[0])))];
    }
    else if (msg.tag === 0) {
        if (msg.fields[0].tag === 3) {
            return [model, Cmd_OfFunc_result(new Types_Msg$4(1, msg.fields[0].fields[0]))];
        }
        else {
            const patternInput = update(token, msg.fields[0], model.CrudMachineModel);
            return [new Types_Model$4(patternInput[0]), Cmd_map((arg0_2) => (new Types_Msg$4(0, arg0_2)), patternInput[1])];
        }
    }
    else {
        return [model, error("Outbound command not handled.")];
    }
}

export function State_forceCreate(model) {
    return new Types_Model$4(CrudStateMachine_forceCreate(model.CrudMachineModel));
}

export function View_view(model, dispatch) {
    const children = [view(model.CrudMachineModel, (arg) => {
        dispatch(new Types_Msg$4(0, arg));
    })];
    return react.createElement("div", {}, ...children);
}

