import { Union, Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, bool_type, tuple_type, lambda_type, unit_type, array_type, record_type, string_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class SelectOption$1 extends Record {
    constructor(label, value) {
        super();
        this.label = label;
        this.value = value;
    }
}

export function SelectOption$1$reflection(gen0) {
    return record_type("ThreeDocs.SharedUI.Bindings.ReactSelectBind.SelectOption`1", [gen0], SelectOption$1, () => [["label", string_type], ["value", gen0]]);
}

export class GroupType$1 extends Record {
    constructor(label, options) {
        super();
        this.label = label;
        this.options = options;
    }
}

export function GroupType$1$reflection(gen0) {
    return record_type("ThreeDocs.SharedUI.Bindings.ReactSelectBind.GroupType`1", [gen0], GroupType$1, () => [["label", string_type], ["options", array_type(SelectOption$1$reflection(gen0))]]);
}

export function SelectOptions_label(_arg1) {
    return _arg1.label;
}

export function SelectOptions_value(_arg1) {
    return _arg1.value;
}

export function GroupType_groupLabel(_arg1) {
    return _arg1.label;
}

export function GroupType_options(_arg1) {
    return _arg1.options;
}

export class SelectPropsMulti$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DefaultValue", "Options", "options", "value", "onChange", "IsOptionSelected", "MatchPos", "MatchProps", "IsMulti", "IsClearable", "IsDisabled", "Id"];
    }
}

export function SelectPropsMulti$1$reflection(gen0) {
    return union_type("ThreeDocs.SharedUI.Bindings.ReactSelectBind.SelectPropsMulti`1", [gen0], SelectPropsMulti$1, () => [[["Item", array_type(SelectOption$1$reflection(gen0))]], [["Item", array_type(SelectOption$1$reflection(gen0))]], [["Item", array_type(GroupType$1$reflection(gen0))]], [["Item", array_type(SelectOption$1$reflection(gen0))]], [["Item", lambda_type(array_type(SelectOption$1$reflection(gen0)), unit_type)]], [["Item", lambda_type(tuple_type(SelectOption$1$reflection(gen0), array_type(SelectOption$1$reflection(gen0))), bool_type)]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]]]);
}

