import { Union, Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { class_type, tuple_type, list_type, lambda_type, unit_type, union_type, record_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { StateResultMsg$6, StateMachineMsg$5$reflection } from "../../fable_modules/Webbler.StateMachine.Core.1.1.0/StateMachine.fs.js";
import { Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { value } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { CrudControls_ButtonClick, CrudControls_itemWithBoldLabel, Shared_SharedCrudModel$4$reflection, CrudControls_DetailField$2$reflection, CrudControls_ButtonClick$reflection, Shared_updateInboundModification, Shared_SharedCrudModel$4 } from "../SharedCrud.js";
import { content } from "../../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { map, empty } from "../../fable_modules/fable-library.3.7.12/List.js";
import * as react from "react";

export class Model$4 extends Record {
    constructor(selected) {
        super();
        this.selected = selected;
    }
}

export function Model$4$reflection(gen0, gen1, gen2, gen3) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDetail.Model`4", [gen0, gen1, gen2, gen3], Model$4, () => [["selected", gen0]]);
}

export class InternalMsg$4 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Cancel", "Edit", "Delete", "SendOutbound"];
    }
}

export function InternalMsg$4$reflection(gen0, gen1, gen2, gen3) {
    return union_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDetail.InternalMsg`4", [gen0, gen1, gen2, gen3], InternalMsg$4, () => [[], [], [], [["Item", gen1]]]);
}

export class Transitions$8 extends Record {
    constructor(Cancel, EditItem, DeleteItem) {
        super();
        this.Cancel = Cancel;
        this.EditItem = EditItem;
        this.DeleteItem = DeleteItem;
    }
}

export function Transitions$8$reflection(gen0, gen1, gen2, gen3, gen4, gen5, gen6, gen7) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDetail.Transitions`8", [gen0, gen1, gen2, gen3, gen4, gen5, gen6, gen7], Transitions$8, () => [["Cancel", lambda_type(gen1, lambda_type(Model$4$reflection(gen7, gen4, gen5, gen6), tuple_type(gen0, list_type(lambda_type(lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), unit_type), unit_type)), gen1)))], ["EditItem", lambda_type(gen1, lambda_type(Model$4$reflection(gen7, gen4, gen5, gen6), tuple_type(gen0, list_type(lambda_type(lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), unit_type), unit_type)), gen1)))], ["DeleteItem", lambda_type(gen1, lambda_type(Model$4$reflection(gen7, gen4, gen5, gen6), tuple_type(gen0, list_type(lambda_type(lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), unit_type), unit_type)), gen1)))]]);
}

export function init(emptyItem, shared, maybeSelected) {
    if (maybeSelected == null) {
        return [new Model$4(emptyItem), Cmd_OfFunc_result(new StateResultMsg$6(0, new InternalMsg$4(0))), shared];
    }
    else {
        return [new Model$4(value(maybeSelected)), Cmd_none(), shared];
    }
}

export function updateInbound(transitions, token, msg, sharedModel, model) {
    switch (msg.tag) {
        case 3: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(sharedModel.ItemList, sharedModel.Search, sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, msg.fields[0])];
        }
        case 4: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(sharedModel.ItemList, sharedModel.Search, sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, msg.fields[0](sharedModel.CustomStorage))];
        }
        case 1: {
            return [model, Cmd_none(), Shared_updateInboundModification(msg.fields[0], sharedModel)];
        }
        case 2: {
            const maybeSelectedItem = msg.fields[0](sharedModel.ItemList);
            if (maybeSelectedItem == null) {
                return [model, Cmd_OfFunc_result(new StateResultMsg$6(0, new InternalMsg$4(0))), sharedModel];
            }
            else {
                return [new Model$4(value(maybeSelectedItem)), Cmd_none(), sharedModel];
            }
        }
        default: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(msg.fields[0], sharedModel.Search, sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, sharedModel.CustomStorage)];
        }
    }
}

export function update(transitions, token, msg, sharedModel, model) {
    switch (msg.tag) {
        case 1: {
            return [model, Cmd_OfFunc_result(new StateResultMsg$6(1, transitions.EditItem(sharedModel, model))), sharedModel];
        }
        case 2: {
            return [model, Cmd_OfFunc_result(new StateResultMsg$6(1, transitions.DeleteItem(sharedModel, model))), sharedModel];
        }
        case 3: {
            return [model, Cmd_OfFunc_result(new StateResultMsg$6(2, msg.fields[0])), sharedModel];
        }
        default: {
            return [model, Cmd_OfFunc_result(new StateResultMsg$6(1, transitions.Cancel(sharedModel, model))), sharedModel];
        }
    }
}

export class Buttons extends Record {
    constructor(edit, cancel, delete$) {
        super();
        this.edit = edit;
        this.cancel = cancel;
        this.delete = delete$;
    }
}

export function Buttons$reflection() {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDetail.Buttons", [], Buttons, () => [["edit", CrudControls_ButtonClick$reflection()], ["cancel", CrudControls_ButtonClick$reflection()], ["delete", CrudControls_ButtonClick$reflection()]]);
}

export class FormViewConfig$2 extends Record {
    constructor(detailFields) {
        super();
        this.detailFields = detailFields;
    }
}

export function FormViewConfig$2$reflection(gen0, gen1) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDetail.FormViewConfig`2", [gen0, gen1], FormViewConfig$2, () => [["detailFields", list_type(CrudControls_DetailField$2$reflection(gen0, gen1))]]);
}

export class ViewLayoutConfig$4 extends Record {
    constructor(detailViewParentElement) {
        super();
        this.detailViewParentElement = detailViewParentElement;
    }
}

export function ViewLayoutConfig$4$reflection(gen0, gen1, gen2, gen3) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDetail.ViewLayoutConfig`4", [gen0, gen1, gen2, gen3], ViewLayoutConfig$4, () => [["detailViewParentElement", lambda_type(FormViewConfig$2$reflection(gen0, gen3), lambda_type(Shared_SharedCrudModel$4$reflection(gen0, gen1, gen2, gen3), lambda_type(Model$4$reflection(gen0, gen1, gen2, gen3), lambda_type(class_type("Fable.React.ReactElement"), lambda_type(Buttons$reflection(), lambda_type(lambda_type(gen1, unit_type), class_type("Fable.React.ReactElement")))))))]]);
}

export function makeItemDetailElement(config, activeItem, customStorage) {
    return content(empty(), map((field) => CrudControls_itemWithBoldLabel(field.label + ": ", field.mkDetailField(activeItem, customStorage)), config.detailFields));
}

export function view(formConfig, viewLayoutConfig, sharedModel, model, dispatch) {
    const children = [viewLayoutConfig.detailViewParentElement(formConfig, sharedModel, model, makeItemDetailElement(formConfig, model.selected, sharedModel.CustomStorage), new Buttons(new CrudControls_ButtonClick("Edit", (_arg1) => {
        dispatch(new InternalMsg$4(1));
    }), new CrudControls_ButtonClick("Cancel", (_arg2) => {
        dispatch(new InternalMsg$4(0));
    }), new CrudControls_ButtonClick("Delete", (_arg3) => {
        dispatch(new InternalMsg$4(2));
    })), (arg) => {
        dispatch(new InternalMsg$4(3, arg));
    })];
    return react.createElement("div", {}, ...children);
}

