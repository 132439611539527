import { toString as toString_1, Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { list_type, bool_type, option_type, record_type, unit_type, lambda_type, class_type, union_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { TableSearch$reflection } from "../ThreeDocs.SharedUI/Search.js";
import { Sort_Compare$1, Sort_Compare$1$reflection, ListDisplayModification_ListDisplayModification$2, ListDisplayModification_ListDisplayModification$2$reflection, Sort_SortInfo$1$reflection } from "../ThreeDocs.SharedUI/Sort.js";
import { map, head, tail, isEmpty, ofArray, filter, singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { comparePrimitives, uncurry, compare, curry, equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Naming_UIElement, Naming_UIElement__get_asString, Naming_mkIdFromStrings } from "../fable_modules/Webbler.Models.1.1.0/Common.fs.js";
import { HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import * as react from "react";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_2536FC39, Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { split } from "../fable_modules/fable-library.3.7.12/RegExp.js";
import { fromDateTimeOffset, toString } from "../fable_modules/fable-library.3.7.12/Date.js";
import { List_toSeq_, Option_mapString, String_fromOption, String_fromOption_, String_toOption_ } from "../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import { FormField_ReactSelect_onChangeMulti, FormField_number, FormField_onChangeNumberOptic, FormField_date, FormField_onChangeDateOptic, FormField_ReactSelect_onChangeSingle, FormField_text, FormField_onChangeStringOptic } from "../ThreeDocs.SharedUI/Forms.js";
import { Option } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { join } from "../fable_modules/fable-library.3.7.12/String.js";
import { mapVis } from "../ThreeDocs.SharedUI/ComponentVisibility.js";
import { div, Option as Option_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";

export class Shared_CrudListModifications extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Sort", "InboundModification"];
    }
}

export function Shared_CrudListModifications$reflection() {
    return union_type("ThreeDocs.Client.StateMachine.Crud.SharedCrud.Shared.CrudListModifications", [], Shared_CrudListModifications, () => [[], [["Item", string_type]]]);
}

export class Shared_CrudMachineServerCalls$4 extends Record {
    constructor(createItem, editItem, deleteItem) {
        super();
        this.createItem = createItem;
        this.editItem = editItem;
        this.deleteItem = deleteItem;
    }
}

export function Shared_CrudMachineServerCalls$4$reflection(gen0, gen1, gen2, gen3) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.SharedCrud.Shared.CrudMachineServerCalls`4", [gen0, gen1, gen2, gen3], Shared_CrudMachineServerCalls$4, () => [["createItem", lambda_type(gen2, lambda_type(gen0, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [gen0, gen1], FSharpResult$2, () => [[["ResultValue", gen0]], [["ErrorValue", gen1]]])])))], ["editItem", lambda_type(gen2, lambda_type(gen0, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [gen0, gen1], FSharpResult$2, () => [[["ResultValue", gen0]], [["ErrorValue", gen1]]])])))], ["deleteItem", lambda_type(gen2, lambda_type(gen0, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, gen1], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", gen1]]])])))]]);
}

export class Shared_CrudMachineItemConfig$4 extends Record {
    constructor(makeEmptyItem, validateItem, isSameItem, serverCalls, exceptionToOutbound) {
        super();
        this.makeEmptyItem = makeEmptyItem;
        this.validateItem = validateItem;
        this.isSameItem = isSameItem;
        this.serverCalls = serverCalls;
        this.exceptionToOutbound = exceptionToOutbound;
    }
}

export function Shared_CrudMachineItemConfig$4$reflection(gen0, gen1, gen2, gen3) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.SharedCrud.Shared.CrudMachineItemConfig`4", [gen0, gen1, gen2, gen3], Shared_CrudMachineItemConfig$4, () => [["makeEmptyItem", lambda_type(gen3, gen0)], ["validateItem", lambda_type(gen2, lambda_type(gen3, lambda_type(gen0, union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, option_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", option_type(string_type)]]]))))], ["isSameItem", lambda_type(gen0, lambda_type(gen0, bool_type))], ["serverCalls", Shared_CrudMachineServerCalls$4$reflection(gen0, gen1, gen2, gen3)], ["exceptionToOutbound", lambda_type(class_type("System.Exception"), gen1)]]);
}

export class Shared_SharedCrudModel$4 extends Record {
    constructor(ItemList, Search, ItemConfig, SortInfo, ListModifications, CustomStorage) {
        super();
        this.ItemList = ItemList;
        this.Search = Search;
        this.ItemConfig = ItemConfig;
        this.SortInfo = SortInfo;
        this.ListModifications = ListModifications;
        this.CustomStorage = CustomStorage;
    }
}

export function Shared_SharedCrudModel$4$reflection(gen0, gen1, gen2, gen3) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.SharedCrud.Shared.SharedCrudModel`4", [gen0, gen1, gen2, gen3], Shared_SharedCrudModel$4, () => [["ItemList", list_type(gen0)], ["Search", TableSearch$reflection()], ["ItemConfig", Shared_CrudMachineItemConfig$4$reflection(gen0, gen1, gen2, gen3)], ["SortInfo", Sort_SortInfo$1$reflection(gen0)], ["ListModifications", list_type(ListDisplayModification_ListDisplayModification$2$reflection(gen0, Shared_CrudListModifications$reflection()))], ["CustomStorage", gen3]]);
}

export class Shared_InboundModificationUpdate$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AddOrUpdate", "RemoveSpecific", "RemoveAll"];
    }
}

export function Shared_InboundModificationUpdate$2$reflection(gen0, gen1) {
    return union_type("ThreeDocs.Client.StateMachine.Crud.SharedCrud.Shared.InboundModificationUpdate`2", [gen0, gen1], Shared_InboundModificationUpdate$2, () => [[["Item", ListDisplayModification_ListDisplayModification$2$reflection(gen0, string_type)]], [["Item", string_type]], []]);
}

export class Shared_CrudInbound$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ItemListUpdated", "InboundModificationUpdated", "TryForceSelect", "ReplaceSharedStorage", "UpdateSharedStorage"];
    }
}

export function Shared_CrudInbound$2$reflection(gen0, gen1) {
    return union_type("ThreeDocs.Client.StateMachine.Crud.SharedCrud.Shared.CrudInbound`2", [gen0, gen1], Shared_CrudInbound$2, () => [[["Item", list_type(gen0)]], [["Item", Shared_InboundModificationUpdate$2$reflection(gen0, gen1)]], [["Item", lambda_type(list_type(gen0), option_type(gen0))]], [["Item", gen1]], [["Item", lambda_type(gen1, gen1)]]]);
}

export function Shared_updateInboundModification(modification, shared) {
    const shared_1 = shared;
    let additionalMod;
    switch (modification.tag) {
        case 1:
        case 2: {
            additionalMod = empty();
            break;
        }
        default: {
            const newMod = modification.fields[0];
            additionalMod = singleton(new ListDisplayModification_ListDisplayModification$2(new Shared_CrudListModifications(1, newMod.Source), newMod.ModificationOrderIndex, newMod.Modifier));
        }
    }
    const existingMods = (modification.tag === 1) ? filter((modification_1) => (!equals(modification_1.Source, new Shared_CrudListModifications(1, modification.fields[0]))), shared_1.ListModifications) : ((modification.tag === 2) ? empty() : filter((modification_1) => (!equals(modification_1.Source, new Shared_CrudListModifications(1, modification.fields[0].Source))), shared_1.ListModifications));
    return new Shared_SharedCrudModel$4(shared_1.ItemList, shared_1.Search, shared_1.ItemConfig, shared_1.SortInfo, toList(delay(() => append(existingMods, delay(() => additionalMod)))), shared_1.CustomStorage);
}

export const Shared_updateSharedStorage = void 0;

export class CrudControls_ListColumn$2 extends Record {
    constructor(label, mkCellContent, compare) {
        super();
        this.label = label;
        this.mkCellContent = mkCellContent;
        this.compare = compare;
    }
}

export function CrudControls_ListColumn$2$reflection(gen0, gen1) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.SharedCrud.CrudControls.ListColumn`2", [gen0, gen1], CrudControls_ListColumn$2, () => [["label", string_type], ["mkCellContent", lambda_type(gen0, lambda_type(gen1, string_type))], ["compare", Sort_Compare$1$reflection(gen0)]]);
}

export class CrudControls_DetailField$2 extends Record {
    constructor(label, mkDetailField) {
        super();
        this.label = label;
        this.mkDetailField = mkDetailField;
    }
}

export function CrudControls_DetailField$2$reflection(gen0, gen1) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.SharedCrud.CrudControls.DetailField`2", [gen0, gen1], CrudControls_DetailField$2, () => [["label", string_type], ["mkDetailField", lambda_type(gen0, lambda_type(gen1, list_type(class_type("Fable.React.ReactElement"))))]]);
}

export class CrudControls_ButtonClick extends Record {
    constructor(label, action) {
        super();
        this.label = label;
        this.action = action;
    }
}

export function CrudControls_ButtonClick$reflection() {
    return record_type("ThreeDocs.Client.StateMachine.Crud.SharedCrud.CrudControls.ButtonClick", [], CrudControls_ButtonClick, () => [["label", string_type], ["action", lambda_type(class_type("Browser.Types.MouseEvent", void 0, MouseEvent), unit_type)]]);
}

export class CrudControls_CrudFormField$2 extends Record {
    constructor(label, mkFormField) {
        super();
        this.label = label;
        this.mkFormField = mkFormField;
    }
}

export function CrudControls_CrudFormField$2$reflection(gen0, gen1) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.SharedCrud.CrudControls.CrudFormField`2", [gen0, gen1], CrudControls_CrudFormField$2, () => [["label", string_type], ["mkFormField", lambda_type(gen0, lambda_type(gen1, lambda_type(lambda_type(lambda_type(gen0, gen0), unit_type), class_type("Fable.React.ReactElement"))))]]);
}

export function CrudControls_mkId(model, view, label, element) {
    return new HTMLAttr(99, Naming_mkIdFromStrings(ofArray([model, view, label, Naming_UIElement__get_asString(element)])));
}

export function CrudControls_itemWithBoldLabel(label, item) {
    if (!isEmpty(item)) {
        if (isEmpty(tail(item))) {
            const children_6 = [react.createElement("b", {}, label), head(item)];
            return react.createElement("p", {}, ...children_6);
        }
        else {
            const children_15 = toList(delay(() => {
                let children_10;
                return append(singleton_1((children_10 = [react.createElement("b", {}, label)], react.createElement("dt", {}, ...children_10))), delay(() => map((arg) => react.createElement("dd", {}, arg), item)));
            }));
            return react.createElement("dl", {}, ...children_15);
        }
    }
    else {
        const children_2 = [react.createElement("b", {}, label)];
        return react.createElement("p", {}, ...children_2);
    }
}

export class CrudControls_ItemElement$2 extends Record {
    constructor(maybeList, maybeDetail, maybeForm) {
        super();
        this.maybeList = maybeList;
        this.maybeDetail = maybeDetail;
        this.maybeForm = maybeForm;
    }
}

export function CrudControls_ItemElement$2$reflection(gen0, gen1) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.SharedCrud.CrudControls.ItemElement`2", [gen0, gen1], CrudControls_ItemElement$2, () => [["maybeList", option_type(CrudControls_ListColumn$2$reflection(gen0, gen1))], ["maybeDetail", option_type(CrudControls_DetailField$2$reflection(gen0, gen1))], ["maybeForm", option_type(CrudControls_CrudFormField$2$reflection(gen0, gen1))]]);
}

export function CrudControls_makeItemElement(label, maybeList, maybeDetail, maybeForm, maybeCustomCompare) {
    let optic, cellDisplay;
    return new CrudControls_ItemElement$2((maybeList == null) ? (void 0) : ((optic = maybeList[0], (cellDisplay = maybeList[1], new CrudControls_ListColumn$2(label, (item, _arg1) => cellDisplay(item), new Sort_Compare$1(0, (itemA, itemB) => {
        const valueA = Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(itemA);
        const valueB = Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(itemB);
        return defaultArg(curry(2, maybeCustomCompare), (e1) => ((e2) => compare(e1, e2)))(valueA)(valueB) | 0;
    }))))), map_1((mkField) => (new CrudControls_DetailField$2(label, (item_1, _arg2) => mkField(item_1))), maybeDetail), map_1((edit) => (new CrudControls_CrudFormField$2(label, uncurry(3, edit))), curry(3, maybeForm)));
}

export function CrudControls_stringDetail(contents) {
    if (contents === null) {
        return empty();
    }
    else {
        return map((s) => s, ofArray(split(contents, "\r?\n")));
    }
}

export function CrudControls_dateDetail(dateTime) {
    if (dateTime == null) {
        return "";
    }
    else {
        return toString(fromDateTimeOffset(dateTime, 0), "yyyy-MM-dd");
    }
}

export function CrudControls_makeItemElementString(view, modelName, label, lens_0, lens_1) {
    const lens = [lens_0, curry(2, lens_1)];
    return CrudControls_makeItemElement(label, [lens, (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(target)], (model) => CrudControls_stringDetail(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(model)), (model_1, _customStorage, dispatchChange) => {
        let onChange;
        const tupledArg = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), String_toOption_)(lens);
        onChange = FormField_onChangeStringOptic(dispatchChange, tupledArg[0], uncurry(2, tupledArg[1]));
        return FormField_text(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(model_1), empty(), [new Option(15, singleton(CrudControls_mkId(view, modelName, label, new Naming_UIElement(0))))], onChange, modelName, label)([]);
    }, (a, b) => comparePrimitives(a.toLocaleLowerCase(), b.toLocaleLowerCase()));
}

export function CrudControls_makeItemElementOptionString(modelName, label, lens_0, lens_1, mkFormElement) {
    let optic;
    const lens = [lens_0, curry(2, lens_1)];
    return CrudControls_makeItemElement(label, [Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), String_fromOption_)(lens), (optic = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), String_fromOption_)(lens), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(target))], (model) => CrudControls_stringDetail(String_fromOption(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(model))), (model_1, _customStorage, dispatchChange) => {
        const onChange = FormField_onChangeStringOptic(dispatchChange, lens[0], uncurry(2, lens[1]));
        return mkFormElement(String_fromOption(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(model_1)), empty(), empty(), onChange, modelName, label, empty());
    }, (str1, str2) => comparePrimitives((str1 !== null) ? str1.toLocaleLowerCase() : "", (str2 !== null) ? str2.toLocaleLowerCase() : ""));
}

export function CrudControls_makeItemElementOptionUnion(modelName, label, lens_0, lens_1, availableToSelect, describe, mkFormElement) {
    let f2;
    const lens = [lens_0, curry(2, lens_1)];
    return CrudControls_makeItemElement(label, [lens, (f2 = Option_mapString(describe), (arg) => f2(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(arg)))], (model) => CrudControls_stringDetail(Option_mapString(describe)(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(model))), (model_1, _customStorage, dispatchChange) => {
        const onChange = FormField_ReactSelect_onChangeSingle(dispatchChange, lens[0], uncurry(2, lens[1]));
        return mkFormElement(availableToSelect, empty(), describe, Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(model_1), onChange, label, modelName, empty());
    }, (a, b) => comparePrimitives(Option_mapString(describe)(a).toLocaleLowerCase(), Option_mapString(describe)(b).toLocaleLowerCase()));
}

export function CrudControls_makeItemElementOptionDate(modelName, label, lens_0, lens_1) {
    const lens = [lens_0, curry(2, lens_1)];
    return CrudControls_makeItemElement(label, [lens, (arg) => CrudControls_dateDetail(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(arg))], (model) => singleton(CrudControls_dateDetail(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(model))), (model_1, _customStorage, dispatchChange) => {
        const onChange = FormField_onChangeDateOptic(dispatchChange, lens[0], uncurry(2, lens[1]));
        return FormField_date(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(model_1), [], [], onChange, modelName, label)([]);
    }, uncurry(2, void 0));
}

export function CrudControls_makeItemElementOptionInt(modelName, label, lens_0, lens_1) {
    const lens = [lens_0, curry(2, lens_1)];
    return CrudControls_makeItemElement(label, [lens, (arg) => toString_1(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(arg))], (model) => singleton(toString_1(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(model))), (model_1, _customStorage, dispatchChange) => {
        const onChange = FormField_onChangeNumberOptic(dispatchChange, lens[0], uncurry(2, lens[1]));
        return FormField_number(toString_1(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(model_1)), empty(), [], onChange, modelName, label)([]);
    }, uncurry(2, void 0));
}

export function CrudControls_makeItemElementUnionList(modelName, label, lens_0, lens_1, availableToSelect, describe, mkFormElement) {
    const lens = [lens_0, curry(2, lens_1)];
    return CrudControls_makeItemElement(label, [lens, (arg_1) => join(", ", map(describe, Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(arg_1)))], (model) => CrudControls_stringDetail(join(",", map(describe, Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(model)))), (model_1, _customStorage, dispatchChange) => {
        let onChange;
        const tupledArg = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), List_toSeq_())(lens);
        onChange = FormField_ReactSelect_onChangeMulti(dispatchChange, tupledArg[0], uncurry(2, tupledArg[1]));
        return mkFormElement(availableToSelect, empty(), describe, Optic_Get_op_HatDot_21762A61(new Optic_Get(0), lens)(model_1), onChange, label, modelName, empty());
    }, (a, b) => comparePrimitives(join(",", map(describe, a)).toLocaleLowerCase(), join(",", map(describe, b)).toLocaleLowerCase()));
}

export function CrudControls_makeItemElementOptionOtherSelect(modelName, label, vmLens_0, vmLens_1, otherIso_0, otherIso_1, availableToSelect, viewDescribe, dropDownDescribe, otherSelected, mkFormElement) {
    let f2;
    const vmLens = [vmLens_0, curry(2, vmLens_1)];
    const otherIso = [otherIso_0, otherIso_1];
    return CrudControls_makeItemElement(label, [vmLens, (f2 = Option_mapString(viewDescribe), (arg) => f2(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), vmLens)(arg)))], (model) => CrudControls_stringDetail(Option_mapString(viewDescribe)(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), vmLens)(model))), (model_1, _customStorage, dispatchChange) => {
        let props, props_2, children_2;
        const onChangeSelect = FormField_ReactSelect_onChangeSingle(dispatchChange, vmLens[0], uncurry(2, vmLens[1]));
        let onChangeOther;
        const tupledArg = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), otherIso)(vmLens);
        onChangeOther = FormField_onChangeStringOptic(dispatchChange, tupledArg[0], uncurry(2, tupledArg[1]));
        const baseSelect = mkFormElement(availableToSelect, empty(), dropDownDescribe, Optic_Get_op_HatDot_21762A61(new Optic_Get(0), vmLens)(model_1), onChangeSelect, label, modelName, empty());
        const fieldProp = singleton(new Option_1(11, mapVis(otherSelected(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), vmLens)(model_1)))));
        const otherSpecify = FormField_text(String_fromOption(Optic_Get_op_HatDot_21762A61(new Optic_Get(0), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), otherIso)(vmLens))(model_1)), empty(), [], onChangeOther, modelName, "Other")([]);
        return div(empty(), ofArray([(props = [["style", {
            marginBottom: "6px",
        }]], react.createElement("div", keyValueList(props, 1), baseSelect)), (props_2 = [["style", {
            paddingLeft: "30px",
        }]], (children_2 = [div(fieldProp, singleton(otherSpecify))], react.createElement("div", keyValueList(props_2, 1), ...children_2)))]));
    }, uncurry(2, void 0));
}

