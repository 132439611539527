import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { ErrorMessage, ErrorMessage$reflection } from "../ThreeDocs.Models/Api/Api.js";
import { list_type, record_type, string_type, union_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { Permission, isAllowed, UserRole$reflection } from "../ThreeDocs.Models/Security.js";
import { PracticeViewModel, PracticeViewModel_toDomain_Z14B1B391, PracticeViewModel_get_empty, PracticeViewModel_get_practiceType_, PracticeViewModel_get_phoneNumber_, PracticeViewModel_get_website_, PracticeViewModel_get_subdivision_, PracticeViewModel_get_country_, PracticeViewModel_get_city_, PracticeViewModel_get_address_, PracticeViewModel_get_generalEnquiryEmail_, PracticeViewModel_get_name_ } from "../ThreeDocs.Models/View/PracticeViewModel.js";
import { Shared_CrudMachineItemConfig$4, Shared_CrudMachineServerCalls$4, CrudControls_makeItemElementOptionOtherSelect, CrudControls_makeItemElementOptionString } from "../ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { FormField_ReactSelect_single, FormField_text } from "../ThreeDocs.SharedUI/Forms.js";
import { PracticeType_get_otherSelected, PracticeType_get_dropdownDisplay, PracticeType_get_viewDisplay, PracticeType_get_all, PracticeType_get_other_ } from "../ThreeDocs.Models/Domain/PracticeType.js";
import { choose, map as map_1, empty, singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { deleteConfirmationViewLayoutConfig, detailViewLayoutConfig, listViewLayoutConfig, dataEntryViewLayoutConfig, makeList, defaultSearchComponent, ListViewHeaderInfo } from "../ModalCrudConfigLayout.js";
import { ComponentVisibility, Button_visibilityControlled } from "../ThreeDocs.SharedUI/ComponentVisibility.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { button as button_1, Option } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { map, toArray } from "../fable_modules/fable-library.3.7.12/Option.js";
import { DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { ViewConfig$2, SearchModule_allColumnSearch, columnListTable } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudListState.js";
import { Sort_itemListSortable } from "../ThreeDocs.SharedUI/Sort.js";
import { CrudStateMachine_CrudMachineItemViewConfig$4, CrudStateMachine_CrudMachineViewLayoutConfig$4 } from "../ThreeDocs.Client.StateMachine.Crud/CrudMachine.js";
import { FormViewConfig$2 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDataEntryState.js";
import { FormViewConfig$2 as FormViewConfig$2_1 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDetailState.js";
import { FormViewConfig$2 as FormViewConfig$2_2 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDeleteConfirmationState.js";
import { AsyncResult_ofError, AsyncResult_mapError, Result_map, Result_mapError } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { FormValidationError_get_describe } from "../ThreeDocs.SharedUI/FormValidation.js";
import { newGuid } from "../fable_modules/fable-library.3.7.12/Guid.js";
import { securedApi } from "../Api.js";

export class PracticesCrudOutbound extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ApiError", "CrudExceptionError", "ViewPackages", "ViewMedia"];
    }
}

export function PracticesCrudOutbound$reflection() {
    return union_type("Client.Practices.PracticesCrudOutbound", [], PracticesCrudOutbound, () => [[["Item", ErrorMessage$reflection()]], [["Item", class_type("System.Exception")]], [["practiceId", class_type("System.Guid")]], [["practiceId", class_type("System.Guid")]]]);
}

export class PracticesUpdateArgs extends Record {
    constructor(token) {
        super();
        this.token = token;
    }
}

export function PracticesUpdateArgs$reflection() {
    return record_type("Client.Practices.PracticesUpdateArgs", [], PracticesUpdateArgs, () => [["token", string_type]]);
}

export class CustomShared extends Record {
    constructor(currentRoles) {
        super();
        this.currentRoles = currentRoles;
    }
}

export function CustomShared$reflection() {
    return record_type("Client.Practices.CustomShared", [], CustomShared, () => [["currentRoles", list_type(UserRole$reflection())]]);
}

export function CustomShared_get_currentRoles_() {
    return [(m) => m.currentRoles, (v) => ((m_1) => (new CustomShared(v)))];
}

export const PracticesCrudConfig_itemFields = ofArray([(() => {
    const tupledArg = PracticeViewModel_get_name_();
    return CrudControls_makeItemElementOptionString("Practice", "Name", tupledArg[0], uncurry(2, tupledArg[1]), uncurry(7, (initialValue) => ((extraProps) => ((extraInputProps) => ((arg30$0040) => ((modelName_1) => ((label_1) => {
        const clo6 = FormField_text(initialValue, extraProps, extraInputProps, arg30$0040, modelName_1, label_1);
        return clo6;
    })))))));
})(), (() => {
    const tupledArg_1 = PracticeViewModel_get_generalEnquiryEmail_();
    return CrudControls_makeItemElementOptionString("Practice", "General Enquiry Email Address", tupledArg_1[0], uncurry(2, tupledArg_1[1]), uncurry(7, (initialValue_1) => ((extraProps_1) => ((extraInputProps_1) => ((arg30$0040_1) => ((modelName_3) => ((label_3) => {
        const clo6_1 = FormField_text(initialValue_1, extraProps_1, extraInputProps_1, arg30$0040_1, modelName_3, label_3);
        return clo6_1;
    })))))));
})(), (() => {
    const tupledArg_2 = PracticeViewModel_get_address_();
    return CrudControls_makeItemElementOptionString("Practice", "Address", tupledArg_2[0], uncurry(2, tupledArg_2[1]), uncurry(7, (initialValue_2) => ((extraProps_2) => ((extraInputProps_2) => ((arg30$0040_2) => ((modelName_5) => ((label_5) => {
        const clo6_2 = FormField_text(initialValue_2, extraProps_2, extraInputProps_2, arg30$0040_2, modelName_5, label_5);
        return clo6_2;
    })))))));
})(), (() => {
    const tupledArg_3 = PracticeViewModel_get_city_();
    return CrudControls_makeItemElementOptionString("Practice", "City", tupledArg_3[0], uncurry(2, tupledArg_3[1]), uncurry(7, (initialValue_3) => ((extraProps_3) => ((extraInputProps_3) => ((arg30$0040_3) => ((modelName_7) => ((label_7) => {
        const clo6_3 = FormField_text(initialValue_3, extraProps_3, extraInputProps_3, arg30$0040_3, modelName_7, label_7);
        return clo6_3;
    })))))));
})(), (() => {
    const tupledArg_4 = PracticeViewModel_get_country_();
    return CrudControls_makeItemElementOptionString("Practice", "Country", tupledArg_4[0], uncurry(2, tupledArg_4[1]), uncurry(7, (initialValue_4) => ((extraProps_4) => ((extraInputProps_4) => ((arg30$0040_4) => ((modelName_9) => ((label_9) => {
        const clo6_4 = FormField_text(initialValue_4, extraProps_4, extraInputProps_4, arg30$0040_4, modelName_9, label_9);
        return clo6_4;
    })))))));
})(), (() => {
    const tupledArg_5 = PracticeViewModel_get_subdivision_();
    return CrudControls_makeItemElementOptionString("Practice", "State/Province/Territory", tupledArg_5[0], uncurry(2, tupledArg_5[1]), uncurry(7, (initialValue_5) => ((extraProps_5) => ((extraInputProps_5) => ((arg30$0040_5) => ((modelName_11) => ((label_11) => {
        const clo6_5 = FormField_text(initialValue_5, extraProps_5, extraInputProps_5, arg30$0040_5, modelName_11, label_11);
        return clo6_5;
    })))))));
})(), (() => {
    const tupledArg_6 = PracticeViewModel_get_website_();
    return CrudControls_makeItemElementOptionString("Practice", "Website", tupledArg_6[0], uncurry(2, tupledArg_6[1]), uncurry(7, (initialValue_6) => ((extraProps_6) => ((extraInputProps_6) => ((arg30$0040_6) => ((modelName_13) => ((label_13) => {
        const clo6_6 = FormField_text(initialValue_6, extraProps_6, extraInputProps_6, arg30$0040_6, modelName_13, label_13);
        return clo6_6;
    })))))));
})(), (() => {
    const tupledArg_7 = PracticeViewModel_get_phoneNumber_();
    return CrudControls_makeItemElementOptionString("Practice", "Phone Number", tupledArg_7[0], uncurry(2, tupledArg_7[1]), uncurry(7, (initialValue_7) => ((extraProps_7) => ((extraInputProps_7) => ((arg30$0040_7) => ((modelName_15) => ((label_15) => {
        const clo6_7 = FormField_text(initialValue_7, extraProps_7, extraInputProps_7, arg30$0040_7, modelName_15, label_15);
        return clo6_7;
    })))))));
})(), (() => {
    const tupledArg_8 = PracticeViewModel_get_practiceType_();
    const tupledArg_9 = PracticeType_get_other_();
    return CrudControls_makeItemElementOptionOtherSelect("Practice", "Practice Type", tupledArg_8[0], uncurry(2, tupledArg_8[1]), tupledArg_9[0], tupledArg_9[1], PracticeType_get_all(), PracticeType_get_viewDisplay(), PracticeType_get_dropdownDisplay(), PracticeType_get_otherSelected(), FormField_ReactSelect_single);
})()]);

export function PracticesCrudConfig_generalViewLayoutConfig(columns, isSameItem) {
    const listViewHeaderInfo = new ListViewHeaderInfo("Practices", "Create Practice");
    const createPracticeVisibilityButton = (sharedModel, listViewHeaderInfo_1, buttons) => ofArray([defaultSearchComponent(sharedModel, listViewHeaderInfo_1, buttons), Button_visibilityControlled((buttons == null) ? (new ComponentVisibility(2, void 0)) : (isAllowed(new Permission(7))(sharedModel.CustomStorage.currentRoles) ? (new ComponentVisibility(0)) : (new ComponentVisibility(1))), toList(delay(() => append(singleton(new Option(4)), delay(() => ofArray(toArray(map((button) => (new Option(17, singleton_1(new DOMAttr(40, button.createItem.action)))), buttons))))))), singleton_1(listViewHeaderInfo_1.createButtonText))]);
    const extraListButtons = empty();
    const listView = (selected_1, shared_1) => makeList(createPracticeVisibilityButton, shared_1, columnListTable((columns_1, selectItem, items, extraItem, sortInfo, updateSortInfo) => Sort_itemListSortable(listViewHeaderInfo.title, columns_1, uncurry(2, selectItem), items, extraItem, sortInfo, uncurry(2, updateSortInfo)), shared_1, columns, selected_1, (value) => {
    }), listViewHeaderInfo, void 0, map_1((func) => func((value_1) => {
    }), extraListButtons));
    return new CrudStateMachine_CrudMachineViewLayoutConfig$4(dataEntryViewLayoutConfig(empty(), listView, void 0, listViewHeaderInfo.title, "Practice", "Edit"), dataEntryViewLayoutConfig(empty(), listView, void 0, listViewHeaderInfo.title, "Practice", "Create"), listViewLayoutConfig(createPracticeVisibilityButton, listViewHeaderInfo, extraListButtons), detailViewLayoutConfig((_arg2_1, sharedModel_2) => isAllowed(new Permission(9))(sharedModel_2.CustomStorage.currentRoles), (_arg1_1, sharedModel_1) => isAllowed(new Permission(8))(sharedModel_1.CustomStorage.currentRoles), ofArray([(model) => ((_arg2) => ((dispatch) => button_1(singleton_1(new Option(18, (_arg1) => {
        dispatch(new PracticesCrudOutbound(2, model.selected.id));
    })), singleton_1("View Packages")))), (model_1) => ((_arg4) => ((dispatch_1) => button_1(singleton_1(new Option(18, (_arg3) => {
        dispatch_1(new PracticesCrudOutbound(3, model_1.selected.id));
    })), singleton_1("View Media"))))]), listView, listViewHeaderInfo.title), deleteConfirmationViewLayoutConfig(empty(), listView, void 0, listViewHeaderInfo.title));
}

export const PracticesCrudConfig_practiceVmViewConfig = (() => {
    const listViewConfig = new ViewConfig$2(choose((_arg1) => _arg1.maybeList, PracticesCrudConfig_itemFields), SearchModule_allColumnSearch);
    const editFormViewConfig = new FormViewConfig$2(choose((_arg2) => _arg2.maybeForm, PracticesCrudConfig_itemFields));
    return new CrudStateMachine_CrudMachineItemViewConfig$4(listViewConfig, editFormViewConfig, editFormViewConfig, new FormViewConfig$2_1(choose((_arg3) => _arg3.maybeDetail, PracticesCrudConfig_itemFields)), new FormViewConfig$2_2(empty()));
})();

export const PracticesCrudConfig_practiceVmModelConfig = new Shared_CrudMachineItemConfig$4((_arg1) => PracticeViewModel_get_empty(), (_arg3, _arg2, item_3) => {
    let f1;
    return Result_mapError()((f1 = FormValidationError_get_describe(), f1))(Result_map()((value) => {
    })(PracticeViewModel_toDomain_Z14B1B391(new PracticeViewModel(newGuid(), item_3.name, item_3.address, item_3.city, item_3.country, item_3.subdivision, item_3.generalEnquiryEmail, item_3.website, item_3.phoneNumber, item_3.practiceType))));
}, (practiceVm1, practiceVm2) => (practiceVm1.id === practiceVm2.id), new Shared_CrudMachineServerCalls$4((updateArgs, item) => AsyncResult_mapError((arg0) => (new PracticesCrudOutbound(0, arg0)), securedApi(updateArgs.token).createPractice(item)), (updateArgs_1, item_1) => AsyncResult_mapError((arg0_1) => (new PracticesCrudOutbound(0, arg0_1)), securedApi(updateArgs_1.token).editPractice(item_1)), (updateArgs_2, item_2) => AsyncResult_ofError(new PracticesCrudOutbound(0, new ErrorMessage(0)))), (arg0_4) => (new PracticesCrudOutbound(1, arg0_4)));

