import { update as update_9, init as init_1 } from "./Notifications/State.js";
import { update as update_1, init as init_2 } from "./Auth/AuthState.js";
import { update as update_2, init as init_3 } from "./Users/UsersState.js";
import { update as update_4, init as init_4 } from "./Invitations/InvitationsState.js";
import { update as update_3, init as init_5 } from "./Practices/PracticesState.js";
import { update as update_8, init as init_6 } from "./Demonstration/DemonstrationPage.js";
import { init as init_7 } from "./Landing/LandingPage.js";
import { update as update_5, init as init_8 } from "./UserProfile/UserProfileState.js";
import { update as update_6, init as init_9 } from "./Package/PackageState.js";
import { singleton, ofArray, empty } from "./fable_modules/fable-library.3.7.12/List.js";
import { update as update_7, init as init_10 } from "./Media/MediaState.js";
import { update as update_10, init as init_11 } from "./PatientPackages/PatientPackagesState.js";
import { toArray, map as map_1, defaultArg, value as value_1, some, bind } from "./fable_modules/fable-library.3.7.12/Option.js";
import { handleUpdatedUrl } from "./Routing.js";
import { pageHash, Page, AuthPage } from "./Pages.js";
import { Msg, Model } from "./Types.js";
import { Cmd_OfPromise_either, Cmd_ofSub, Cmd_none, Cmd_map, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { ProxyRequestException__get_StatusCode, ProxyRequestException } from "./fable_modules/Fable.Remoting.Client.7.21.0/Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Msg as Msg_1 } from "./Auth/AuthTypes.js";
import { error } from "./ThreeDocs.SharedUI/Toast.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.12/String.js";
import { Msg as Msg_2 } from "./Users/UsersTypes.js";
import { Msg as Msg_8, MediaUpdateArgs } from "./Media/MediaTypes.js";
import { Navigation_newUrl } from "./fable_modules/Fable.Elmish.Browser.3.0.5/navigation.fs.js";
import { subscribe } from "./Users/UsersApi.js";
import { subscribe as subscribe_1 } from "./Practices/PracticesApi.js";
import { subscribe as subscribe_2 } from "./UserProfile/UserProfileApi.js";
import { subscribe as subscribe_3 } from "./Invitations/InvitationsApi.js";
import { subscribe as subscribe_4 } from "./Package/PackagesApi.js";
import { subscribe as subscribe_5 } from "./Media/MediaApi.js";
import { disconnect, subscribe as subscribe_6 } from "./Notifications/Api.js";
import { securedAwsApi } from "./Api.js";
import { S3Context, mkS3Client, updateGlobalConfig, mkCredentials } from "./Common/AwsCommon.js";
import { ofList } from "./fable_modules/fable-library.3.7.12/Map.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { SessionManagement_SessionManagementModel__get_token } from "./Session.js";
import { equals } from "./fable_modules/fable-library.3.7.12/Util.js";
import { iterate, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.12/Seq.js";
import { Msg as Msg_3 } from "./Notifications/Types.js";
import { Msg as Msg_4 } from "./Practices/PracticesTypes.js";
import { Msg as Msg_5 } from "./Invitations/InvitationsTypes.js";
import { Msg as Msg_6 } from "./UserProfile/UserProfileTypes.js";
import { Msg as Msg_7 } from "./Package/PackageTypes.js";

export function init(result) {
    const patternInput = init_1();
    const patternInput_1 = init_2();
    const patternInput_2 = init_3();
    const patternInput_3 = init_4();
    const patternInput_4 = init_5();
    const patternInput_5 = init_6();
    const patternInput_6 = init_7();
    const patternInput_7 = init_8();
    const patternInput_8 = init_9(void 0, empty());
    const patternInput_9 = init_10(void 0, empty());
    const patternInput_10 = init_11(bind((_arg1) => {
        if (_arg1.tag === 7) {
            return _arg1.fields[0];
        }
        else {
            return void 0;
        }
    }, result));
    const patternInput_11 = handleUpdatedUrl(result, new Model(patternInput[0], new Page(0, new AuthPage(0)), void 0, patternInput_1[0], patternInput_2[0], patternInput_3[0], patternInput_4[0], patternInput_5[0], patternInput_6[0], patternInput_7[0], patternInput_8[0], patternInput_10[0], patternInput_9[0], false, void 0, void 0, void 0, void 0));
    return [patternInput_11[0], Cmd_batch(ofArray([patternInput_11[1], Cmd_map((arg0) => (new Msg(8, arg0)), patternInput[1]), Cmd_map((arg0_1) => (new Msg(2, arg0_1)), patternInput_1[1]), Cmd_map((arg0_2) => (new Msg(4, arg0_2)), patternInput_2[1]), Cmd_map((arg0_3) => (new Msg(3, arg0_3)), patternInput_4[1]), Cmd_map((arg0_4) => (new Msg(6, arg0_4)), patternInput_5[1]), Cmd_map((arg0_5) => (new Msg(7, arg0_5)), patternInput_6[1]), Cmd_map((arg0_6) => (new Msg(9, arg0_6)), patternInput_7[1]), Cmd_map((arg0_7) => (new Msg(5, arg0_7)), patternInput_3[1]), Cmd_map((arg0_8) => (new Msg(10, arg0_8)), patternInput_8[1]), Cmd_map((arg0_9) => (new Msg(12, arg0_9)), patternInput_9[1]), Cmd_map((arg0_10) => (new Msg(11, arg0_10)), patternInput_10[1])]))];
}

export function authenticatedUpdate(token, msg, model) {
    let rawTopics;
    let pattern_matching_result, ex, msg_4, msg_5;
    if (msg.tag === 2) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
            msg_4 = msg.fields[0];
        }
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else if (model.authModel.userInfo != null) {
            pattern_matching_result = 2;
            msg_5 = msg.fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (msg.tag === 6) {
        pattern_matching_result = 0;
        ex = msg.fields[0].fields[0];
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (msg.tag === 10) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (msg.tag === 12) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 0;
            ex = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (msg.tag === 0) {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    else {
        pattern_matching_result = 3;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [model, (ex instanceof ProxyRequestException) ? ((ProxyRequestException__get_StatusCode(ex) === 401) ? Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(2, new Msg_1(6))), error("You have been logged out")])) : Cmd_none()) : ((console.error(some(toText(printf("Error: %O"))(ex))), Cmd_none()))];
        }
        case 1: {
            const patternInput = update_1(msg_4, model.authModel);
            return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, patternInput[0], model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0) => (new Msg(2, arg0)), patternInput[1])];
        }
        case 2: {
            const patternInput_1 = update_2(token, value_1(model.authModel.userInfo), msg_5, model.usersModel);
            return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, patternInput_1[0], model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0_1) => (new Msg(4, arg0_1)), patternInput_1[1])];
        }
        case 3: {
            let pattern_matching_result_1, msg_7, practices_1;
            if (msg.tag === 3) {
                if (msg.fields[0].tag === 3) {
                    if (msg.fields[0].fields[0].tag === 0) {
                        if (model.authModel.userInfo != null) {
                            pattern_matching_result_1 = 0;
                            msg_7 = msg.fields[0];
                            practices_1 = msg.fields[0].fields[0].fields[0];
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const patternInput_2 = update_3(token, value_1(model.authModel.userInfo), msg_7, model.practicesModel);
                    return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, patternInput_2[0], model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_batch(ofArray([Cmd_map((arg0_2) => (new Msg(3, arg0_2)), patternInput_2[1]), Cmd_OfFunc_result(new Msg(4, new Msg_2(4, practices_1)))]))];
                }
                case 1: {
                    let pattern_matching_result_2, msg_11;
                    if (msg.tag === 3) {
                        if (model.authModel.userInfo != null) {
                            pattern_matching_result_2 = 0;
                            msg_11 = msg.fields[0];
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            const patternInput_3 = update_3(token, value_1(model.authModel.userInfo), msg_11, model.practicesModel);
                            return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, patternInput_3[0], model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0_5) => (new Msg(3, arg0_5)), patternInput_3[1])];
                        }
                        case 1: {
                            let pattern_matching_result_3, msg_13;
                            if (msg.tag === 5) {
                                if (model.authModel.userInfo != null) {
                                    pattern_matching_result_3 = 0;
                                    msg_13 = msg.fields[0];
                                }
                                else {
                                    pattern_matching_result_3 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_3 = 1;
                            }
                            switch (pattern_matching_result_3) {
                                case 0: {
                                    const patternInput_4 = update_4(token, value_1(model.authModel.userInfo), msg_13, model.invitationsModel);
                                    return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, patternInput_4[0], model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0_6) => (new Msg(5, arg0_6)), patternInput_4[1])];
                                }
                                case 1: {
                                    let pattern_matching_result_4, msg_15;
                                    if (msg.tag === 9) {
                                        if (model.authModel.userInfo != null) {
                                            pattern_matching_result_4 = 0;
                                            msg_15 = msg.fields[0];
                                        }
                                        else {
                                            pattern_matching_result_4 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_4 = 1;
                                    }
                                    switch (pattern_matching_result_4) {
                                        case 0: {
                                            const patternInput_5 = update_5(token, value_1(model.authModel.userInfo), msg_15, model.userProfileModel);
                                            return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, patternInput_5[0], model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0_7) => (new Msg(9, arg0_7)), patternInput_5[1])];
                                        }
                                        case 1: {
                                            let pattern_matching_result_5, msg_17;
                                            if (msg.tag === 10) {
                                                if (model.authModel.userInfo != null) {
                                                    pattern_matching_result_5 = 0;
                                                    msg_17 = msg.fields[0];
                                                }
                                                else {
                                                    pattern_matching_result_5 = 1;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_5 = 1;
                                            }
                                            switch (pattern_matching_result_5) {
                                                case 0: {
                                                    const matchValue_1 = model.currentPage;
                                                    if (matchValue_1.tag === 6) {
                                                        const patternInput_6 = update_6(token, value_1(model.authModel.userInfo), matchValue_1.fields[0], msg_17, model.packagesModel);
                                                        return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, patternInput_6[0], model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0_8) => (new Msg(10, arg0_8)), patternInput_6[1])];
                                                    }
                                                    else {
                                                        return [model, Cmd_none()];
                                                    }
                                                }
                                                case 1: {
                                                    let pattern_matching_result_6, msg_19;
                                                    if (msg.tag === 12) {
                                                        if (model.authModel.userInfo != null) {
                                                            pattern_matching_result_6 = 0;
                                                            msg_19 = msg.fields[0];
                                                        }
                                                        else {
                                                            pattern_matching_result_6 = 1;
                                                        }
                                                    }
                                                    else {
                                                        pattern_matching_result_6 = 1;
                                                    }
                                                    switch (pattern_matching_result_6) {
                                                        case 0: {
                                                            const args = new MediaUpdateArgs(token, model.credentials, model.s3Context);
                                                            const matchValue_2 = model.currentPage;
                                                            if (matchValue_2.tag === 8) {
                                                                const patternInput_7 = update_7(args, value_1(model.authModel.userInfo), matchValue_2.fields[0], msg_19, model.mediaModel);
                                                                return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, patternInput_7[0], model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0_9) => (new Msg(12, arg0_9)), patternInput_7[1])];
                                                            }
                                                            else {
                                                                const patternInput_8 = update_7(args, value_1(model.authModel.userInfo), void 0, msg_19, model.mediaModel);
                                                                return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, patternInput_8[0], model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0_10) => (new Msg(12, arg0_10)), patternInput_8[1])];
                                                            }
                                                        }
                                                        case 1: {
                                                            let pattern_matching_result_7, msg_20, page, client, msg_21, msg_23, active, config, r, credentials_3, s3Context_2;
                                                            if (msg.tag === 4) {
                                                                pattern_matching_result_7 = 0;
                                                            }
                                                            else if (msg.tag === 3) {
                                                                pattern_matching_result_7 = 0;
                                                            }
                                                            else if (msg.tag === 5) {
                                                                pattern_matching_result_7 = 0;
                                                            }
                                                            else if (msg.tag === 10) {
                                                                pattern_matching_result_7 = 0;
                                                            }
                                                            else if (msg.tag === 11) {
                                                                pattern_matching_result_7 = 0;
                                                            }
                                                            else if (msg.tag === 12) {
                                                                pattern_matching_result_7 = 0;
                                                            }
                                                            else if (msg.tag === 9) {
                                                                pattern_matching_result_7 = 0;
                                                            }
                                                            else if (msg.tag === 6) {
                                                                pattern_matching_result_7 = 1;
                                                                msg_20 = msg.fields[0];
                                                            }
                                                            else if (msg.tag === 1) {
                                                                pattern_matching_result_7 = 2;
                                                                page = msg.fields[0];
                                                            }
                                                            else if (msg.tag === 8) {
                                                                if (msg.fields[0].tag === 3) {
                                                                    pattern_matching_result_7 = 3;
                                                                    client = msg.fields[0].fields[0];
                                                                    msg_21 = msg.fields[0];
                                                                }
                                                                else {
                                                                    pattern_matching_result_7 = 4;
                                                                    msg_23 = msg.fields[0];
                                                                }
                                                            }
                                                            else if (msg.tag === 13) {
                                                                pattern_matching_result_7 = 5;
                                                                active = msg.fields[0];
                                                            }
                                                            else if (msg.tag === 14) {
                                                                pattern_matching_result_7 = 6;
                                                            }
                                                            else if (msg.tag === 15) {
                                                                pattern_matching_result_7 = 7;
                                                                config = msg.fields[0];
                                                            }
                                                            else if (msg.tag === 16) {
                                                                pattern_matching_result_7 = 8;
                                                                r = msg.fields[0];
                                                            }
                                                            else if (msg.tag === 17) {
                                                                pattern_matching_result_7 = 9;
                                                                credentials_3 = msg.fields[0];
                                                                s3Context_2 = msg.fields[1];
                                                            }
                                                            else {
                                                                pattern_matching_result_7 = 10;
                                                            }
                                                            switch (pattern_matching_result_7) {
                                                                case 0: {
                                                                    return [model, error("Unable to find active user.")];
                                                                }
                                                                case 1: {
                                                                    const patternInput_9 = update_8(token, msg_20, model.demonstrationModel);
                                                                    return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, model.practicesModel, patternInput_9[0], model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0_11) => (new Msg(6, arg0_11)), patternInput_9[1])];
                                                                }
                                                                case 2: {
                                                                    return [model, Cmd_batch(singleton(Navigation_newUrl(pageHash(page))))];
                                                                }
                                                                case 3: {
                                                                    const patternInput_10 = update_9(token, msg_21, model.notificationsModel);
                                                                    const toSubs = (tupledArg, map) => [tupledArg[0], (arg) => map(tupledArg[1](arg))];
                                                                    const subs_1 = Cmd_ofSub((rawTopics = defaultArg(map_1((ui) => ofArray([toSubs(subscribe(ui.id), (arg0_12) => (new Msg(4, arg0_12))), toSubs(subscribe_1(ui.id), (arg0_13) => (new Msg(3, arg0_13))), toSubs(subscribe_2(ui.id), (arg0_14) => (new Msg(9, arg0_14))), toSubs(subscribe_3(ui.id), (arg0_15) => (new Msg(5, arg0_15))), toSubs(subscribe_4(ui.id), (arg0_16) => (new Msg(10, arg0_16))), toSubs(subscribe_5(ui.id), (arg0_17) => (new Msg(12, arg0_17)))]), model.authModel.userInfo), empty()), (dispatch) => {
                                                                        subscribe_6(client, rawTopics, dispatch);
                                                                    }));
                                                                    return [new Model(patternInput_10[0], model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_batch(ofArray([Cmd_map((arg0_18) => (new Msg(8, arg0_18)), patternInput_10[1]), subs_1]))];
                                                                }
                                                                case 4: {
                                                                    const patternInput_11 = update_9(token, msg_23, model.notificationsModel);
                                                                    return [new Model(patternInput_11[0], model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0_19) => (new Msg(8, arg0_19)), patternInput_11[1])];
                                                                }
                                                                case 5: {
                                                                    return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, active, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_none()];
                                                                }
                                                                case 6: {
                                                                    return [model, Cmd_OfAsyncWith_either((x) => {
                                                                        Cmd_OfAsync_start(x);
                                                                    }, securedAwsApi(token).getS3Configuration, void 0, (arg0_20) => (new Msg(15, arg0_20)), (arg0_21) => (new Msg(0, arg0_21)))];
                                                                }
                                                                case 7: {
                                                                    return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, config, model.credentials), Cmd_OfAsyncWith_either((x_1) => {
                                                                        Cmd_OfAsync_start(x_1);
                                                                    }, securedAwsApi(token).getIdentity, void 0, (arg0_22) => (new Msg(16, arg0_22)), (arg0_23) => (new Msg(0, arg0_23)))];
                                                                }
                                                                case 8: {
                                                                    const matchValue_3 = model.s3Configuration;
                                                                    if (matchValue_3 != null) {
                                                                        const config_1 = matchValue_3;
                                                                        if (r.tag === 1) {
                                                                            return [model, Cmd_none()];
                                                                        }
                                                                        else {
                                                                            const result = r.fields[0];
                                                                            const credentials = mkCredentials(config_1.IdentityPoolId, result.IdentityId, config_1.Region, ofList(singleton([config_1.Authority, result.Token])));
                                                                            updateGlobalConfig(config_1.Region, credentials);
                                                                            const s3Context = new S3Context(config_1, mkS3Client(config_1.Bucket), void 0, config_1.Bucket);
                                                                            return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, s3Context, result, model.s3Configuration, credentials), Cmd_OfPromise_either((tupledArg_1) => {
                                                                                const credentials_1 = tupledArg_1[0];
                                                                                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (credentials_1.getPromise().then(() => (Promise.resolve([credentials_1, tupledArg_1[1]]))))));
                                                                            }, [credentials, s3Context], (tupledArg_2) => (new Msg(17, tupledArg_2[0], tupledArg_2[1])), (arg0_25) => (new Msg(0, arg0_25)))];
                                                                        }
                                                                    }
                                                                    else {
                                                                        return [model, Cmd_none()];
                                                                    }
                                                                }
                                                                case 9: {
                                                                    return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, new S3Context(s3Context_2.S3Config, s3Context_2.S3Client, credentials_3.expireTime, s3Context_2.ActiveBucket), model.awsToken, model.s3Configuration, model.credentials), Cmd_none()];
                                                                }
                                                                case 10: {
                                                                    throw (new Error("Match failure: Client.Types.Msg"));
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

export function update(msg, model) {
    let tupledArg;
    const matchValue = [SessionManagement_SessionManagementModel__get_token(model.authModel.sessionManagement), msg];
    let pattern_matching_result, msg_1, token, msg_2, msg_11, msg_12, msg_13, msg_14, token_1, msg_15;
    if (matchValue[0] != null) {
        if (matchValue[1].tag === 2) {
            if (matchValue[1].fields[0].tag === 0) {
                pattern_matching_result = 0;
                msg_1 = matchValue[1];
                token = matchValue[0];
            }
            else if (matchValue[1].fields[0].tag === 18) {
                pattern_matching_result = 1;
                msg_2 = matchValue[1].fields[0];
            }
            else if (matchValue[1].fields[0].tag === 6) {
                pattern_matching_result = 2;
                msg_11 = matchValue[1].fields[0];
            }
            else {
                pattern_matching_result = 3;
                msg_12 = matchValue[1].fields[0];
            }
        }
        else if (matchValue[1].tag === 11) {
            pattern_matching_result = 4;
            msg_13 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 5;
            msg_14 = matchValue[1];
            token_1 = matchValue[0];
        }
    }
    else if (matchValue[1].tag === 2) {
        if (matchValue[1].fields[0].tag === 18) {
            pattern_matching_result = 1;
            msg_2 = matchValue[1].fields[0];
        }
        else if (matchValue[1].fields[0].tag === 6) {
            pattern_matching_result = 2;
            msg_11 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 3;
            msg_12 = matchValue[1].fields[0];
        }
    }
    else if (matchValue[1].tag === 11) {
        pattern_matching_result = 4;
        msg_13 = matchValue[1].fields[0];
    }
    else {
        pattern_matching_result = 6;
        msg_15 = matchValue;
    }
    switch (pattern_matching_result) {
        case 0: {
            return authenticatedUpdate(token.rawToken, msg_1, model);
        }
        case 1: {
            const patternInput = update_1(msg_2, model.authModel);
            return [new Model(model.notificationsModel, model.currentPage, equals(model.currentPage, new Page(0, new AuthPage(0))) ? (void 0) : model.redirectPage, patternInput[0], model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_batch(toList(delay(() => append(singleton_1(Cmd_map((arg0) => (new Msg(2, arg0)), patternInput[1])), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(8, new Msg_3(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(4, new Msg_2(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(3, new Msg_4(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(5, new Msg_5(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(9, new Msg_6(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(10, new Msg_7(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(12, new Msg_8(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(14))), delay(() => {
                const matchValue_1 = model.currentPage;
                let pattern_matching_result_1;
                if (matchValue_1.tag === 0) {
                    if (matchValue_1.fields[0].tag === 0) {
                        pattern_matching_result_1 = 0;
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        const matchValue_2 = [model.redirectPage, model.authModel.userInfo];
                        return (matchValue_2[0] != null) ? singleton_1(Navigation_newUrl(pageHash(matchValue_2[0]))) : singleton_1(Navigation_newUrl(pageHash(new Page(10))));
                    }
                    case 1: {
                        return singleton_1(Cmd_none());
                    }
                }
            })))))))))))))))))))))];
        }
        case 2: {
            const patternInput_1 = update_1(msg_11, model.authModel);
            iterate((arg) => {
                disconnect(arg);
            }, toArray(model.notificationsModel.Client));
            return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, patternInput_1[0], model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0_1) => (new Msg(2, arg0_1)), patternInput_1[1])];
        }
        case 3: {
            const patternInput_2 = update_1(msg_12, model.authModel);
            return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, patternInput_2[0], model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, model.patientPackagesModel, model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0_2) => (new Msg(2, arg0_2)), patternInput_2[1])];
        }
        case 4: {
            const patternInput_3 = update_10(msg_13, model.patientPackagesModel);
            return [new Model(model.notificationsModel, model.currentPage, model.redirectPage, model.authModel, model.usersModel, model.invitationsModel, model.practicesModel, model.demonstrationModel, model.landingModel, model.userProfileModel, model.packagesModel, patternInput_3[0], model.mediaModel, model.menuIsExpanded, model.s3Context, model.awsToken, model.s3Configuration, model.credentials), Cmd_map((arg0_3) => (new Msg(11, arg0_3)), patternInput_3[1])];
        }
        case 5: {
            return authenticatedUpdate(token_1.rawToken, msg_14, model);
        }
        case 6: {
            console.log(some((tupledArg = msg_15, toText(printf("Ignoring unauthenticated msg: %O"))([tupledArg[0], tupledArg[1]]))));
            return [model, Cmd_none()];
        }
    }
}

