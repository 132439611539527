import { Union } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, string_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { ofArray } from "../../fable_modules/fable-library.3.7.12/List.js";
import { FormData_tryParse, Extensions_String_$007CPrefix$007C_$007C } from "../Shared.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Validate_String_standardValidate } from "../../ThreeDocs.SharedUI/FormValidation.js";
import { map } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { ComponentVisibility } from "../../ThreeDocs.SharedUI/ComponentVisibility.js";

export class PracticeType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Individual", "Group", "IndependentHealthFacility", "Hospital", "Other_specify_string"];
    }
}

export function PracticeType$reflection() {
    return union_type("ThreeDocs.Models.Domain.PracticeType.PracticeType", [], PracticeType, () => [[], [], [], [], [["Item", string_type]]]);
}

export function PracticeType_get_viewDisplay() {
    return (_arg1) => ((_arg1.tag === 1) ? "Group" : ((_arg1.tag === 2) ? "Independent Health Facility" : ((_arg1.tag === 3) ? "Hospital" : ((_arg1.tag === 4) ? (`Other (${_arg1.fields[0]})`) : "Individual"))));
}

export function PracticeType_get_dropdownDisplay() {
    return (_arg2) => ((_arg2.tag === 4) ? "Other (Specify)" : PracticeType_get_viewDisplay()(_arg2));
}

export function PracticeType_get_internalDescribe() {
    return (_arg3) => ((_arg3.tag === 1) ? "Group" : ((_arg3.tag === 2) ? "IndependentHealthFacility" : ((_arg3.tag === 3) ? "Hospital" : ((_arg3.tag === 4) ? (`Other_specify_string ${_arg3.fields[0]}`) : "Individual"))));
}

export function PracticeType_get_all() {
    return ofArray([new PracticeType(0), new PracticeType(1), new PracticeType(2), new PracticeType(3), new PracticeType(4, "")]);
}

export function PracticeType_get_tryParse() {
    return (_arg4) => {
        let activePatternResult17928, other;
        return (activePatternResult17928 = Extensions_String_$007CPrefix$007C_$007C(PracticeType_get_internalDescribe()(new PracticeType(4, "")), _arg4), (activePatternResult17928 != null) ? ((other = activePatternResult17928, new PracticeType(4, other))) : FormData_tryParse(PracticeType_get_internalDescribe(), PracticeType_get_all(), _arg4));
    };
}

export function PracticeType_validate_475A7DF9(maybePracticeType) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => {
        let other;
        return (maybePracticeType != null) ? ((maybePracticeType.tag === 4) ? ((other = maybePracticeType.fields[0], ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, Validate_String_standardValidate("Practice Type Other (Specify)")(other), (_arg5) => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, maybePracticeType)))) : ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, maybePracticeType)) : ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, maybePracticeType);
    }));
}

export function PracticeType_get_other_() {
    return [(_arg6) => {
        let other;
        return (_arg6 != null) ? ((_arg6.tag === 4) ? ((other = _arg6.fields[0], other)) : (void 0)) : (void 0);
    }, (option) => map((arg0) => (new PracticeType(4, arg0)), option)];
}

export function PracticeType_get_otherSelected() {
    return (_arg7) => ((_arg7 != null) ? ((_arg7.tag === 4) ? (new ComponentVisibility(0)) : (new ComponentVisibility(1))) : (new ComponentVisibility(1)));
}

