import { Union, Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { tuple_type, list_type, record_type, lambda_type, class_type, union_type, option_type, string_type, unit_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.12/Choice.js";
import { StateResultMsg$6, StateMachineMsg$5$reflection } from "../../fable_modules/Webbler.StateMachine.Core.1.1.0/StateMachine.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_map, Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { CrudControls_ButtonClick, Shared_SharedCrudModel$4$reflection, CrudControls_CrudFormField$2$reflection, CrudControls_ButtonClick$reflection, Shared_updateInboundModification, Shared_SharedCrudModel$4 } from "../SharedCrud.js";
import { partialApply } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { printf, toText } from "../../fable_modules/fable-library.3.7.12/String.js";
import { some } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { map } from "../../fable_modules/fable-library.3.7.12/List.js";
import * as react from "react";

export class Model$4 extends Record {
    constructor(activeItem, isValid, save) {
        super();
        this.activeItem = activeItem;
        this.isValid = isValid;
        this.save = save;
    }
}

export function Model$4$reflection(gen0, gen1, gen2, gen3) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDataEntry.Model`4", [gen0, gen1, gen2, gen3], Model$4, () => [["activeItem", gen0], ["isValid", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, option_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", option_type(string_type)]]])], ["save", lambda_type(gen2, lambda_type(gen0, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [gen0, gen1], FSharpResult$2, () => [[["ResultValue", gen0]], [["ErrorValue", gen1]]])])))]]);
}

export class InternalMsg$4 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetItemVm", "Save", "Validate", "SaveResult", "ExceptionError", "Cancel", "SendOutbound"];
    }
}

export function InternalMsg$4$reflection(gen0, gen1, gen2, gen3) {
    return union_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDataEntry.InternalMsg`4", [gen0, gen1, gen2, gen3], InternalMsg$4, () => [[["Item", lambda_type(gen0, gen0)]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [gen0, gen1], FSharpResult$2, () => [[["ResultValue", gen0]], [["ErrorValue", gen1]]])]], [["Item", class_type("System.Exception")]], [], [["Item", gen1]]]);
}

export class Transitions$8 extends Record {
    constructor(ItemSaved, Cancel) {
        super();
        this.ItemSaved = ItemSaved;
        this.Cancel = Cancel;
    }
}

export function Transitions$8$reflection(gen0, gen1, gen2, gen3, gen4, gen5, gen6, gen7) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDataEntry.Transitions`8", [gen0, gen1, gen2, gen3, gen4, gen5, gen6, gen7], Transitions$8, () => [["ItemSaved", lambda_type(gen1, lambda_type(Model$4$reflection(gen7, gen4, gen5, gen6), tuple_type(gen0, list_type(lambda_type(lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), unit_type), unit_type)), gen1)))], ["Cancel", lambda_type(gen1, lambda_type(Model$4$reflection(gen7, gen4, gen5, gen6), tuple_type(gen0, list_type(lambda_type(lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), unit_type), unit_type)), gen1)))]]);
}

export function init(shared, activeItem, saveAction) {
    return [new Model$4(activeItem, new FSharpResult$2(1, "Invalid"), saveAction), Cmd_OfFunc_result(new StateResultMsg$6(0, new InternalMsg$4(2))), shared];
}

export function updateInbound(transitions, token, msg, sharedModel, model) {
    switch (msg.tag) {
        case 3: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(sharedModel.ItemList, sharedModel.Search, sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, msg.fields[0])];
        }
        case 4: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(sharedModel.ItemList, sharedModel.Search, sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, msg.fields[0](sharedModel.CustomStorage))];
        }
        case 1: {
            return [model, Cmd_none(), Shared_updateInboundModification(msg.fields[0], sharedModel)];
        }
        case 2: {
            return [model, Cmd_none(), sharedModel];
        }
        default: {
            return [model, Cmd_none(), new Shared_SharedCrudModel$4(msg.fields[0], sharedModel.Search, sharedModel.ItemConfig, sharedModel.SortInfo, sharedModel.ListModifications, sharedModel.CustomStorage)];
        }
    }
}

export function update(transitions, updateArgs, msg, sharedModel, model) {
    if (msg.tag === 0) {
        return [new Model$4(msg.fields[0](model.activeItem), model.isValid, model.save), Cmd_OfFunc_result(new StateResultMsg$6(0, new InternalMsg$4(2))), sharedModel];
    }
    else if (msg.tag === 2) {
        return [new Model$4(model.activeItem, sharedModel.ItemConfig.validateItem(updateArgs, sharedModel.CustomStorage, model.activeItem), model.save), Cmd_none(), sharedModel];
    }
    else if (msg.tag === 1) {
        return [model, Cmd_map((arg0_4) => (new StateResultMsg$6(0, arg0_4)), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, partialApply(1, model.save, [updateArgs]), model.activeItem, (arg0_2) => (new InternalMsg$4(3, arg0_2)), (arg0_3) => (new InternalMsg$4(4, arg0_3)))), sharedModel];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            return [model, Cmd_OfFunc_result(new StateResultMsg$6(2, msg.fields[0].fields[0])), sharedModel];
        }
        else {
            const updatedModel = new Model$4(msg.fields[0].fields[0], model.isValid, model.save);
            return [updatedModel, Cmd_OfFunc_result(new StateResultMsg$6(1, transitions.ItemSaved(sharedModel, updatedModel))), sharedModel];
        }
    }
    else if (msg.tag === 5) {
        return [model, Cmd_OfFunc_result(new StateResultMsg$6(1, transitions.Cancel(sharedModel, model))), sharedModel];
    }
    else if (msg.tag === 6) {
        return [model, Cmd_OfFunc_result(new StateResultMsg$6(2, msg.fields[0])), sharedModel];
    }
    else {
        console.error(some(toText(printf("Error: %O"))(msg.fields[0])));
        return [model, Cmd_OfFunc_result(new StateResultMsg$6(2, sharedModel.ItemConfig.exceptionToOutbound(msg.fields[0]))), sharedModel];
    }
}

export class Buttons extends Record {
    constructor(save, cancel) {
        super();
        this.save = save;
        this.cancel = cancel;
    }
}

export function Buttons$reflection() {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDataEntry.Buttons", [], Buttons, () => [["save", CrudControls_ButtonClick$reflection()], ["cancel", CrudControls_ButtonClick$reflection()]]);
}

export class FormViewConfig$2 extends Record {
    constructor(formFields) {
        super();
        this.formFields = formFields;
    }
}

export function FormViewConfig$2$reflection(gen0, gen1) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDataEntry.FormViewConfig`2", [gen0, gen1], FormViewConfig$2, () => [["formFields", list_type(CrudControls_CrudFormField$2$reflection(gen0, gen1))]]);
}

export class ViewLayoutConfig$4 extends Record {
    constructor(fieldViewParentElement) {
        super();
        this.fieldViewParentElement = fieldViewParentElement;
    }
}

export function ViewLayoutConfig$4$reflection(gen0, gen1, gen2, gen3) {
    return record_type("ThreeDocs.Client.StateMachine.Crud.Substates.CrudDataEntry.ViewLayoutConfig`4", [gen0, gen1, gen2, gen3], ViewLayoutConfig$4, () => [["fieldViewParentElement", lambda_type(FormViewConfig$2$reflection(gen0, gen3), lambda_type(Shared_SharedCrudModel$4$reflection(gen0, gen1, gen2, gen3), lambda_type(Model$4$reflection(gen0, gen1, gen2, gen3), lambda_type(class_type("Fable.React.ReactElement"), lambda_type(Buttons$reflection(), lambda_type(lambda_type(gen1, unit_type), class_type("Fable.React.ReactElement")))))))]]);
}

export function makeDataEntryFormElement(config, activeItem, customStorage, dispatch) {
    const children = map((field) => field.mkFormField(activeItem, customStorage, (arg) => {
        dispatch(new InternalMsg$4(0, arg));
    }), config.formFields);
    return react.createElement("form", {}, ...children);
}

export function view(formConfig, viewLayoutConfig, sharedModel, model, dispatch) {
    const children = [viewLayoutConfig.fieldViewParentElement(formConfig, sharedModel, model, makeDataEntryFormElement(formConfig, model.activeItem, sharedModel.CustomStorage, dispatch), new Buttons(new CrudControls_ButtonClick("Save", (_arg1) => {
        dispatch(new InternalMsg$4(1));
    }), new CrudControls_ButtonClick("Cancel", (_arg2) => {
        dispatch(new InternalMsg$4(5));
    })), (arg) => {
        dispatch(new InternalMsg$4(6, arg));
    })];
    return react.createElement("div", {}, ...children);
}

