import { Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { lambda_type, union_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { isNullOrWhiteSpace, printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Result_map, Result_bind } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { uncurry, partialApply } from "../fable_modules/fable-library.3.7.12/Util.js";
import { map, some, value } from "../fable_modules/fable-library.3.7.12/Option.js";
import { DateTimeOffset_truncateToMicrosecond } from "../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import { equals, compare } from "../fable_modules/fable-library.3.7.12/Date.js";
import { date, utcNow } from "../fable_modules/fable-library.3.7.12/DateOffset.js";
import { isEmpty } from "../fable_modules/fable-library.3.7.12/List.js";

export class FormValidationError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InvalidValue", "NotNullOrEmpty", "MandatoryNotSet"];
    }
}

export function FormValidationError$reflection() {
    return union_type("ThreeDocs.SharedUI.FormValidation.FormValidationError", [], FormValidationError, () => [[["fieldName", string_type], ["must", string_type]], [["fieldName", string_type]], [["fieldName", string_type]]]);
}

export function FormValidationError_get_describe() {
    return (_arg1) => ((_arg1.tag === 1) ? toText(printf("%s must not be null or empty"))(_arg1.fields[0]) : ((_arg1.tag === 2) ? toText(printf("%s is a required value."))(_arg1.fields[0]) : toText(printf("%s must %s"))(_arg1.fields[0])(_arg1.fields[1])));
}

export class FieldValidity extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Valid", "Invalid"];
    }
}

export function FieldValidity$reflection() {
    return union_type("ThreeDocs.SharedUI.FormValidation.FieldValidity", [], FieldValidity, () => [[], [["Item", FormValidationError$reflection()]]]);
}

export function FieldValidity_ofResult_Z22DD7A26(result) {
    if (result.tag === 1) {
        return new FieldValidity(1, result.fields[0]);
    }
    else {
        return new FieldValidity(0);
    }
}

export function FieldValidity_get_describe() {
    return (_arg1) => ((_arg1.tag === 1) ? FormValidationError_get_describe()(_arg1.fields[0]) : "Valid");
}

export class ValidatedSetter$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ValidatedSetter"];
    }
}

export function ValidatedSetter$2$reflection(gen0, gen1) {
    return union_type("ThreeDocs.SharedUI.FormValidation.ValidatedSetter`2", [gen0, gen1], ValidatedSetter$2, () => [[["Item", lambda_type(gen0, union_type("Microsoft.FSharp.Core.FSharpResult`2", [lambda_type(gen1, gen1), FormValidationError$reflection()], FSharpResult$2, () => [[["ResultValue", lambda_type(gen1, gen1)]], [["ErrorValue", FormValidationError$reflection()]]]))]]]);
}

export function Operators_op_GreaterEqualsGreater(val1, val2) {
    return (fieldName) => ((field) => {
        const val2_1 = val2;
        const fieldName_1 = fieldName;
        const result1 = val1(fieldName_1, field);
        return Result_bind()(partialApply(1, val2_1, [fieldName_1]))(result1);
    });
}

export function Operators_op_QmarkEqualsQmark(val1, val2) {
    return (fieldName) => ((field) => {
        const val1_1 = val1;
        const val2_1 = val2;
        const fieldName_1 = fieldName;
        const field_1 = field;
        if (field_1 == null) {
            return new FSharpResult$2(0, void 0);
        }
        else {
            const result1 = val1_1(fieldName_1, value(field_1));
            return Result_map()(some)(Result_bind()(partialApply(1, val2_1, [fieldName_1]))(result1));
        }
    });
}

export function Validate_required(fieldName, field) {
    if (field == null) {
        return new FSharpResult$2(1, new FormValidationError(2, fieldName));
    }
    else {
        return new FSharpResult$2(0, value(field));
    }
}

export function Validate_String_trim(_fieldName, field) {
    return new FSharpResult$2(0, map((str) => str.trim(), field));
}

export function Validate_String_notNullOrWhiteSpace(fieldName, field) {
    let pattern_matching_result, str_1;
    if (field != null) {
        if (!isNullOrWhiteSpace(field)) {
            pattern_matching_result = 0;
            str_1 = field;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new FSharpResult$2(0, str_1);
        }
        case 1: {
            if (field != null) {
                return new FSharpResult$2(1, new FormValidationError(1, fieldName));
            }
            else {
                return new FSharpResult$2(0, void 0);
            }
        }
    }
}

export const Validate_String_standardValidate = Operators_op_GreaterEqualsGreater(Validate_String_notNullOrWhiteSpace, Validate_String_trim);

export function Validate_String_notLongerThan(maxLength, fieldName, field) {
    let tupledArg, str;
    let pattern_matching_result;
    if (field != null) {
        if ((str = field, str.length > maxLength)) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new FSharpResult$2(1, (tupledArg = [fieldName, `not be longer than ${maxLength} characters`], new FormValidationError(0, tupledArg[0], tupledArg[1])));
        }
        case 1: {
            return new FSharpResult$2(0, field);
        }
    }
}

export function Validate_Guid_notNullEmpty(fieldName, field) {
    if (field === "00000000-0000-0000-0000-000000000000") {
        return new FSharpResult$2(1, new FormValidationError(1, fieldName));
    }
    else {
        return new FSharpResult$2(0, field);
    }
}

export function Validate_DateTimeOffset_truncate(_fieldName, field) {
    return new FSharpResult$2(0, map(DateTimeOffset_truncateToMicrosecond, field));
}

export function Validate_DateTimeOffset_notFutureDate(fieldName, field) {
    let pattern_matching_result;
    if (field != null) {
        if (compare(field, utcNow()) > 0) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new FSharpResult$2(1, new FormValidationError(0, fieldName, "not be in the future"));
        }
        case 1: {
            let pattern_matching_result_1;
            if (field == null) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 0;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return new FSharpResult$2(0, field);
                }
            }
        }
    }
}

export const Validate_DateTimeOffset_isToday_ = (() => {
    const nowTime = utcNow();
    return (fieldName) => ((field) => {
        let dateTime_1;
        return (field != null) ? ((!equals(date(field), date(nowTime))) ? ((dateTime_1 = field, new FSharpResult$2(1, new FormValidationError(0, fieldName, "be the current date")))) : ((field == null) ? (new FSharpResult$2(0, field)) : (new FSharpResult$2(0, field)))) : ((field == null) ? (new FSharpResult$2(0, field)) : (new FSharpResult$2(0, field)));
    });
})();

export function Validate_DateTimeOffset_notPastDate_(fieldName, field) {
    let pattern_matching_result;
    if (field != null) {
        if (compare(field, utcNow()) < 0) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new FSharpResult$2(1, new FormValidationError(0, fieldName, "not be in the past"));
        }
        case 1: {
            let pattern_matching_result_1;
            if (field == null) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 0;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return new FSharpResult$2(0, field);
                }
            }
        }
    }
}

export const Validate_DateTimeOffset_notPastDate = Operators_op_GreaterEqualsGreater(Validate_DateTimeOffset_truncate, Validate_DateTimeOffset_notPastDate_);

export const Validate_DateTimeOffset_isToday = Operators_op_GreaterEqualsGreater(Validate_DateTimeOffset_truncate, uncurry(2, Validate_DateTimeOffset_isToday_));

export const Validate_DateTimeOffset_standardValidate = Operators_op_GreaterEqualsGreater(Validate_DateTimeOffset_truncate, Validate_DateTimeOffset_notFutureDate);

export function Validate_List_required(fieldName, field) {
    if (isEmpty(field)) {
        return new FSharpResult$2(1, new FormValidationError(2, fieldName));
    }
    else {
        return new FSharpResult$2(0, field);
    }
}

