import { Union } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, list_type, class_type, bool_type, lambda_type, unit_type, string_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { HTMLAttr$reflection } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";

export class EasyEditProps$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Type", "Placeholder", "Value", "SaveButtonLabel", "SaveButtonStyle", "CancelButtonLabel", "CancelButtonStyle", "OnSave", "EditMode", "DisplayComponent", "CssClassPrefix", "OnValidate", "Attributes", "AllowEdit"];
    }
}

export function EasyEditProps$1$reflection(gen0) {
    return union_type("ThreeDocs.SharedUI.Bindings.ReactEasyEditBind.EasyEditProps`1", [gen0], EasyEditProps$1, () => [[["Item", string_type]], [["Item", string_type]], [["Item", gen0]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", lambda_type(gen0, unit_type)]], [["Item", bool_type]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", string_type]], [["Item", lambda_type(gen0, bool_type)]], [["Item", list_type(HTMLAttr$reflection())]], [["Item", bool_type]]]);
}

