import { Common_mkId } from "../Common/Common.js";
import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Naming_UIElement } from "../fable_modules/Webbler.Models.1.1.0/Common.fs.js";
import { Page, AuthPage, pageHash } from "../Pages.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { Msg } from "./AuthTypes.js";
import { Card_foot, Card_body, Card_head, Card_card, background, Option, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { ofArray, empty, singleton } from "../fable_modules/fable-library.3.7.12/List.js";
import { Common_GenericOption } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option as Option_1, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";

export function mkAuthViewId(label, element) {
    return new HTMLAttr(99, Common_mkId("Auth", label, element));
}

export function view(model, dispatch) {
    let props_4, props;
    const matchValue = model.userInfo;
    if (matchValue == null) {
        const children_6 = [(props_4 = [mkAuthViewId("login", new Naming_UIElement(2)), new HTMLAttr(64, "bd-tw-button button"), new HTMLAttr(94, pageHash(new Page(0, new AuthPage(0))))], react.createElement("a", keyValueList(props_4, 1), "Login"))];
        return react.createElement("div", {}, ...children_6);
    }
    else {
        const userInfo = matchValue;
        const children_2 = [(props = [mkAuthViewId("logout", new Naming_UIElement(2)), new HTMLAttr(64, "bd-tw-button button"), new DOMAttr(40, (_arg1) => {
            dispatch(new Msg(6));
        })], react.createElement("button", keyValueList(props, 1), "Log out"))];
        return react.createElement("div", {}, ...children_2);
    }
}

export function sessionExpiryModal(im, dispatch) {
    const closeMsg = (_arg1) => {
        dispatch(new Msg(20, false));
    };
    return modal(singleton(new Option(1, im.isActive)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, closeMsg)))), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton("Session timeout")), Card_body(empty(), singleton(im.message)), Card_foot(empty(), singleton(button(singleton(new Option_1(17, ofArray([mkAuthViewId("SessionExpiryModel", new Naming_UIElement(2)), new DOMAttr(40, closeMsg)]))), singleton(im.button))))]))]));
}

