import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, bool_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { Common_EmailAddress$reflection } from "../../fable_modules/Webbler.Models.1.1.0/Api.fs.js";

export class Invitation extends Record {
    constructor(InvitationId, PatientName, PatientEmail, ReplyToEmail, PackageId, PracticeId, CreatedAt, Viewed) {
        super();
        this.InvitationId = InvitationId;
        this.PatientName = PatientName;
        this.PatientEmail = PatientEmail;
        this.ReplyToEmail = ReplyToEmail;
        this.PackageId = PackageId;
        this.PracticeId = PracticeId;
        this.CreatedAt = CreatedAt;
        this.Viewed = Viewed;
    }
}

export function Invitation$reflection() {
    return record_type("ThreeDocs.Models.Domain.Invitation.Invitation", [], Invitation, () => [["InvitationId", class_type("System.Guid")], ["PatientName", string_type], ["PatientEmail", Common_EmailAddress$reflection()], ["ReplyToEmail", Common_EmailAddress$reflection()], ["PackageId", class_type("System.Guid")], ["PracticeId", class_type("System.Guid")], ["CreatedAt", class_type("System.DateTimeOffset")], ["Viewed", bool_type]]);
}

