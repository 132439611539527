import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, union_type, list_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { InvitationViewModel$reflection } from "../ThreeDocs.Models/View/InvitationViewModel.js";
import { Notification$reflection, ErrorMessage$reflection } from "../ThreeDocs.Models/Api/Api.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { PackageViewModel$reflection } from "../ThreeDocs.Models/View/PackageViewModel.js";
import { UserData$reflection, UserRole$reflection } from "../ThreeDocs.Models/Security.js";
import { Security_AuthErrorMessage$reflection, Security_UserInfo$2$reflection } from "../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { CustomShared$reflection, InvitationsUpdateArgs$reflection, InvitationsCrudOutbound$reflection } from "./InvitationsCrudConfig.js";
import { Types_Model$4$reflection, Types_Msg$4$reflection } from "../ThreeDocs.Client.StateMachine.Crud/CrudComponent.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "GetInvitations", "GetInvitationsResult", "GetPackagesForPractice", "GetPackagesForPracticeResult", "Notification", "GetUserInfoResult", "InvitationCrudMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Invitations.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(InvitationViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(InvitationViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["practiceId", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(PackageViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(PackageViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", Notification$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection()), Security_AuthErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection())]], [["ErrorValue", Security_AuthErrorMessage$reflection()]]])]], [["Item", Types_Msg$4$reflection(InvitationViewModel$reflection(), InvitationsCrudOutbound$reflection(), InvitationsUpdateArgs$reflection(), CustomShared$reflection())]]]);
}

export class Model extends Record {
    constructor(InvitationCrudModel) {
        super();
        this.InvitationCrudModel = InvitationCrudModel;
    }
}

export function Model$reflection() {
    return record_type("Client.Invitations.Types.Model", [], Model, () => [["InvitationCrudModel", Types_Model$4$reflection(InvitationViewModel$reflection(), InvitationsCrudOutbound$reflection(), InvitationsUpdateArgs$reflection(), CustomShared$reflection())]]);
}

