import { Union, Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { tuple_type, list_type, unit_type, string_type, lambda_type, union_type, record_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { Cmd_none, Cmd_map } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { error } from "../ThreeDocs.SharedUI/Toast.js";

export class ChainedChildMsg$2 extends Record {
    constructor(chainedMsg, heldData) {
        super();
        this.chainedMsg = chainedMsg;
        this.heldData = heldData;
    }
}

export function ChainedChildMsg$2$reflection(gen0, gen1) {
    return record_type("ThreeDocs.Client.Common.Terminate.Terminatable.ChainedChildMsg`2", [gen0, gen1], ChainedChildMsg$2, () => [["chainedMsg", gen0], ["heldData", gen1]]);
}

export class ChildAbsentBehaviour extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Alert", "Ignore"];
    }
}

export function ChildAbsentBehaviour$reflection() {
    return union_type("ThreeDocs.Client.Common.Terminate.Terminatable.ChildAbsentBehaviour", [], ChildAbsentBehaviour, () => [[], []]);
}

export class ChildAbsentStrategy$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Dynamic", "Fixed"];
    }
}

export function ChildAbsentStrategy$1$reflection(gen0) {
    return union_type("ThreeDocs.Client.Common.Terminate.Terminatable.ChildAbsentStrategy`1", [gen0], ChildAbsentStrategy$1, () => [[["Item", lambda_type(gen0, ChildAbsentBehaviour$reflection())]], [["Item", ChildAbsentBehaviour$reflection()]]]);
}

export class Child$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Active", "NeverCreated", "Terminated"];
    }
}

export function Child$1$reflection(gen0) {
    return union_type("ThreeDocs.Client.Common.Terminate.Terminatable.Child`1", [gen0], Child$1, () => [[["Item", gen0]], [], []]);
}

export function Child_absentChildCheck(terminatable) {
    switch (terminatable.tag) {
        case 1: {
            return new ChildAbsentBehaviour(0);
        }
        case 2: {
            return new ChildAbsentBehaviour(1);
        }
        default: {
            return new ChildAbsentBehaviour(0);
        }
    }
}

export function ChildMsg_evaluateStrategy(strategy, parentModel) {
    if (strategy.tag === 1) {
        return strategy.fields[0];
    }
    else {
        return strategy.fields[0](parentModel);
    }
}

export function ChildMsg_mapMessage(strategy, childCmd) {
    return Cmd_map((childMsg) => (new ChainedChildMsg$2(childMsg, strategy)), childCmd);
}

export function ChildMsg_mapParentMessage(strategy, parentMessage, childCmd) {
    return Cmd_map(parentMessage, ChildMsg_mapMessage(strategy, childCmd));
}

export function ChildMsg_ofMsg(strategy, msg) {
    return new ChainedChildMsg$2(msg, strategy);
}

export class DefaultActions_Types_ChildModel$2 extends Record {
    constructor(child, childAbsentStrategy, childComponentName) {
        super();
        this.child = child;
        this.childAbsentStrategy = childAbsentStrategy;
        this.childComponentName = childComponentName;
    }
}

export function DefaultActions_Types_ChildModel$2$reflection(gen0, gen1) {
    return record_type("ThreeDocs.Client.Common.Terminate.Terminatable.DefaultActions.Types.ChildModel`2", [gen0, gen1], DefaultActions_Types_ChildModel$2, () => [["child", Child$1$reflection(gen0)], ["childAbsentStrategy", ChildAbsentStrategy$1$reflection(gen1)], ["childComponentName", string_type]]);
}

export class DefaultActions_Types_UpdateArgs$3 extends Record {
    constructor(runUpdate, parentModel) {
        super();
        this.runUpdate = runUpdate;
        this.parentModel = parentModel;
    }
}

export function DefaultActions_Types_UpdateArgs$3$reflection(gen0, gen1, gen2) {
    return record_type("ThreeDocs.Client.Common.Terminate.Terminatable.DefaultActions.Types.UpdateArgs`3", [gen0, gen1, gen2], DefaultActions_Types_UpdateArgs$3, () => [["runUpdate", lambda_type(gen0, lambda_type(gen1, tuple_type(gen1, list_type(lambda_type(lambda_type(gen0, unit_type), unit_type)))))], ["parentModel", gen2]]);
}

export function DefaultActions_runUpdate(args, model, msg) {
    const matchValue = [msg, model.child];
    let pattern_matching_result, strategy;
    if (matchValue[1].tag === 2) {
        pattern_matching_result = 0;
        strategy = matchValue[0].heldData;
    }
    else if (matchValue[1].tag === 0) {
        pattern_matching_result = 1;
    }
    else {
        pattern_matching_result = 0;
        strategy = matchValue[0].heldData;
    }
    switch (pattern_matching_result) {
        case 0: {
            const _arg1 = ChildMsg_evaluateStrategy(strategy, args.parentModel);
            if (_arg1.tag === 1) {
                return [model, Cmd_none()];
            }
            else {
                return [model, error(`${model.childComponentName} does not exist.`)];
            }
        }
        case 1: {
            const patternInput = args.runUpdate(matchValue[0].chainedMsg, matchValue[1].fields[0]);
            return [new DefaultActions_Types_ChildModel$2(new Child$1(0, patternInput[0]), model.childAbsentStrategy, model.childComponentName), ChildMsg_mapMessage(model.childAbsentStrategy, patternInput[1])];
        }
    }
}

export function DefaultActions_terminate(terminate, model) {
    const matchValue = model.child;
    switch (matchValue.tag) {
        case 1:
        case 2: {
            return [model, error(`${model.childComponentName} component does not exist.`)];
        }
        default: {
            const termination = terminate(matchValue.fields[0]);
            if (termination.tag === 1) {
                return [model, Cmd_none()];
            }
            else {
                termination.fields[0]();
                return [new DefaultActions_Types_ChildModel$2(new Child$1(2), model.childAbsentStrategy, model.childComponentName), Cmd_none()];
            }
        }
    }
}

export function DefaultActions_create(childModel, childCommand, model) {
    const matchValue = model.child;
    switch (matchValue.tag) {
        case 2: {
            return [new DefaultActions_Types_ChildModel$2(new Child$1(0, childModel), model.childAbsentStrategy, model.childComponentName), ChildMsg_mapMessage(model.childAbsentStrategy, childCommand)];
        }
        case 0: {
            return [model, error(`${model.childComponentName} already exists.`)];
        }
        default: {
            return [new DefaultActions_Types_ChildModel$2(new Child$1(0, childModel), model.childAbsentStrategy, model.childComponentName), ChildMsg_mapMessage(model.childAbsentStrategy, childCommand)];
        }
    }
}

