import { Record, Union } from "../fable-library.3.7.12/Types.js";
import { record_type, class_type, union_type, tuple_type, list_type, lambda_type, unit_type } from "../fable-library.3.7.12/Reflection.js";
import { Cmd_none } from "../Fable.Elmish.3.1.0/cmd.fs.js";
import * as react from "react";
import { Cmd_OfFunc_result } from "../Fable.Elmish.3.1.0/cmd.fs.js";

export class StateMachineMsg$5 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UnsafeStateUpdate", "UnsafeSwitchState", "SafeInboundUpdate", "SafeOutboundMsg"];
    }
}

export function StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4) {
    return union_type("Webbler.StateMachine.Core.StateMachine.StateMachineMsg`5", [gen0, gen1, gen2, gen3, gen4], StateMachineMsg$5, () => [[["Item", gen2]], [["Item", tuple_type(gen0, list_type(lambda_type(lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), unit_type), unit_type)), gen1)]], [["Item", gen3]], [["Item", gen4]]]);
}

export class StateResultMsg$6 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Internal", "TransitionMsg", "Outbound"];
    }
}

export function StateResultMsg$6$reflection(gen0, gen1, gen2, gen3, gen4, gen5) {
    return union_type("Webbler.StateMachine.Core.StateMachine.StateResultMsg`6", [gen0, gen1, gen2, gen3, gen4, gen5], StateResultMsg$6, () => [[["Item", gen5]], [["Item", tuple_type(gen0, list_type(lambda_type(lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), unit_type), unit_type)), gen1)]], [["Item", gen4]]]);
}

export class StateMachineModel$6 extends Record {
    constructor(ActiveState, SharedModel, SendUpdateMessages, MakeViewElement) {
        super();
        this.ActiveState = ActiveState;
        this.SharedModel = SharedModel;
        this.SendUpdateMessages = SendUpdateMessages;
        this.MakeViewElement = MakeViewElement;
    }
}

export function StateMachineModel$6$reflection(gen0, gen1, gen2, gen3, gen4, gen5) {
    return record_type("Webbler.StateMachine.Core.StateMachine.StateMachineModel`6", [gen0, gen1, gen2, gen3, gen4, gen5], StateMachineModel$6, () => [["ActiveState", gen0], ["SharedModel", gen1], ["SendUpdateMessages", lambda_type(gen5, lambda_type(gen1, lambda_type(gen0, lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), tuple_type(gen0, list_type(lambda_type(lambda_type(StateMachineMsg$5$reflection(gen0, gen1, gen2, gen3, gen4), unit_type), unit_type)), gen1)))))], ["MakeViewElement", lambda_type(gen1, lambda_type(gen0, lambda_type(lambda_type(gen2, unit_type), class_type("Fable.React.ReactElement"))))]]);
}

export function update(updateArgs, msg, model) {
    switch (msg.tag) {
        case 0: {
            const patternInput = model.SendUpdateMessages(updateArgs, model.SharedModel, model.ActiveState, msg);
            return [new StateMachineModel$6(patternInput[0], patternInput[2], model.SendUpdateMessages, model.MakeViewElement), patternInput[1]];
        }
        case 3: {
            return [model, Cmd_none()];
        }
        case 2: {
            const patternInput_1 = model.SendUpdateMessages(updateArgs, model.SharedModel, model.ActiveState, msg);
            return [new StateMachineModel$6(patternInput_1[0], patternInput_1[2], model.SendUpdateMessages, model.MakeViewElement), patternInput_1[1]];
        }
        default: {
            return [new StateMachineModel$6(msg.fields[0][0], msg.fields[0][2], model.SendUpdateMessages, model.MakeViewElement), msg.fields[0][1]];
        }
    }
}

export function view(model, dispatch) {
    const children = [model.MakeViewElement(model.SharedModel, model.ActiveState, (arg) => {
        dispatch(new StateMachineMsg$5(0, arg));
    })];
    return react.createElement("div", {}, ...children);
}

export function makeStateTransition(transition, sharedModel, model) {
    return [model, Cmd_OfFunc_result(new StateResultMsg$6(1, transition(sharedModel, model))), sharedModel];
}

