import { Union } from "../../fable-library.3.7.12/Types.js";
import { Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddProps_416C4D0B, Color_ofColor, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProp_7BFEDA81, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection, Color_IColor$reflection, Size_ISize$reflection } from "../Common.fs.js";
import { union_type, string_type, int32_type, list_type, class_type } from "../../fable-library.3.7.12/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.7.12/MapUtil.js";
import { HTMLAttr } from "../../Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Reflection_getCaseName } from "../Common.fs.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Size", "Color", "Props", "Value", "Max", "CustomClass", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Progress.Option", [], Option, () => [[["Item", Size_ISize$reflection()]], [["Item", Color_IColor$reflection()]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", int32_type]], [["Item", int32_type]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function progress(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 4: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(114, option.fields[0]));
            }
            case 0: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            }
            case 1: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
            }
            case 2: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 5: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 6: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(161, option.fields[0]));
            }
        }
    }, "progress"), (props_1, children_1) => react.createElement("progress", keyValueList(props_1, 1), ...children_1), children);
}

