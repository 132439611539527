import { Union } from "../../fable-library.3.7.12/Types.js";
import { union_type, list_type, class_type, string_type } from "../../fable-library.3.7.12/Reflection.js";
import { Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.7.12/MapUtil.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-16x16", "is-24x24", "is-32x32", "is-48x48", "is-64x64", "is-96x96", "is-128x128", "is-square", "is-1by1", "is-5by4", "is-4by3", "is-3by2", "is-5by3", "is-16by9", "is-2by1", "is-3by1", "is-4by5", "is-3by4", "is-2by3", "is-3by5", "is-9by16", "is-1by2", "is-1by3", "is-fullwidth", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Image.Option", [], Option, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function image(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 23: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 25: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 24: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 26: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }, "image"), (props_1, children_1) => react.createElement("figure", keyValueList(props_1, 1), ...children_1), children);
}

