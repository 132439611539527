import { record_type, list_type, class_type, bool_type, string_type, union_type, getCaseTag, getCaseName } from "../fable-library.3.7.12/Reflection.js";
import { Record, toString, Union } from "../fable-library.3.7.12/Types.js";
import { stringHash, int32ToString } from "../fable-library.3.7.12/Util.js";
import { join, isNullOrEmpty, printf, toText } from "../fable-library.3.7.12/String.js";
import { defaultArg, some } from "../fable-library.3.7.12/Option.js";
import { isEmpty, filter, append, empty, ofArrayWithTail, contains, cons, fold } from "../fable-library.3.7.12/List.js";
import { HTMLAttr } from "../Fable.React.7.4.3/Fable.React.Props.fs.js";
import * as react from "react";
import { keyValueList } from "../fable-library.3.7.12/MapUtil.js";

export function Reflection_getCaseName(case$) {
    return getCaseName(case$);
}

export function Reflection_getCaseTag(case$) {
    return getCaseTag(case$);
}

export class Screen extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["All", "desktop", "tablet", "mobile", "widescreen", "touch", "fullhd"];
    }
}

export function Screen$reflection() {
    return union_type("Fulma.Screen", [], Screen, () => [[], [], [], [], [], [], []]);
}

export function Screen_ToString_2D2414B4(screen) {
    switch (screen.tag) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6: {
            return "-" + Reflection_getCaseName(screen);
        }
        default: {
            return "";
        }
    }
}

export class Color_IColor extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-black", "is-dark", "is-light", "is-white", "is-primary", "is-info", "is-success", "is-warning", "is-danger", "is-link", "is-black-bis", "is-black-ter", "is-grey-darker", "is-grey-dark", "is-grey", "is-grey-light", "is-grey-lighter", "is-white-ter", "is-white-bis", "IsCustomColor", "NoColor"];
    }
}

export function Color_IColor$reflection() {
    return union_type("Fulma.Color.IColor", [], Color_IColor, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [["Item", string_type]], []]);
}

export function Color_ofColor(level) {
    switch (level.tag) {
        case 19: {
            return "is-" + level.fields[0];
        }
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:
        case 18: {
            return Reflection_getCaseName(level);
        }
        default: {
            return "";
        }
    }
}

export class Size_ISize extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-small", "is-medium", "is-large"];
    }
}

export function Size_ISize$reflection() {
    return union_type("Fulma.Size.ISize", [], Size_ISize, () => [[], [], []]);
}

export class TextSize_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Is1", "Is2", "Is3", "Is4", "Is5", "Is6", "Is7"];
    }
}

export function TextSize_Option$reflection() {
    return union_type("Fulma.TextSize.Option", [], TextSize_Option, () => [[], [], [], [], [], [], []]);
}

export function TextSize_Option_ToString_Z2E0B9453(x) {
    return int32ToString(Reflection_getCaseTag(x) + 1);
}

export class TextAlignment_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["has-text-centered", "has-text-justified", "has-text-left", "has-text-right"];
    }
}

export function TextAlignment_Option$reflection() {
    return union_type("Fulma.TextAlignment.Option", [], TextAlignment_Option, () => [[], [], [], []]);
}

export class FlexDirection_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-flex-direction-row", "is-flex-direction-row-reverse", "is-flex-direction-column", "is-flex-direction-column-reverse"];
    }
}

export function FlexDirection_Option$reflection() {
    return union_type("Fulma.FlexDirection.Option", [], FlexDirection_Option, () => [[], [], [], []]);
}

export class FlexWrap_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-flex-wrap-nowrap", "is-flex-wrap-wrap", "is-flex-wrap-wrap-reverse"];
    }
}

export function FlexWrap_Option$reflection() {
    return union_type("Fulma.FlexWrap.Option", [], FlexWrap_Option, () => [[], [], []]);
}

export class FlexJustifyContent_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-justify-content-flex-start", "is-justify-content-flex-end", "is-justify-content-center", "is-justify-content-space-between", "is-justify-content-space-around", "is-justify-content-space-evenly", "is-justify-content-start", "is-justify-content-end", "is-justify-content-left", "is-justify-content-right"];
    }
}

export function FlexJustifyContent_Option$reflection() {
    return union_type("Fulma.FlexJustifyContent.Option", [], FlexJustifyContent_Option, () => [[], [], [], [], [], [], [], [], [], []]);
}

export class FlexAlignContent_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-align-content-flex-start", "is-align-content-flex-end", "is-align-content-center", "is-align-content-space-between", "is-align-content-space-around", "is-align-content-space-evenly", "is-align-content-stretch", "is-align-content-start", "is-align-content-end", "is-align-content-baseline"];
    }
}

export function FlexAlignContent_Option$reflection() {
    return union_type("Fulma.FlexAlignContent.Option", [], FlexAlignContent_Option, () => [[], [], [], [], [], [], [], [], [], []]);
}

export class FlexAlignItems_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-align-items-stretch", "is-align-items-flex-start", "is-align-items-flex-end", "is-align-items-center", "is-align-items-baseline", "is-align-items-start", "is-align-items-end", "is-align-items-self-start", "is-align-items-self-end"];
    }
}

export function FlexAlignItems_Option$reflection() {
    return union_type("Fulma.FlexAlignItems.Option", [], FlexAlignItems_Option, () => [[], [], [], [], [], [], [], [], []]);
}

export class FlexAlignSelf_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-align-self-auto", "is-align-self-flex-start", "is-align-self-flex-end", "is-align-self-center", "is-align-self-baseline", "is-align-self-stretch"];
    }
}

export function FlexAlignSelf_Option$reflection() {
    return union_type("Fulma.FlexAlignSelf.Option", [], FlexAlignSelf_Option, () => [[], [], [], [], [], []]);
}

export class FlexGrow_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-flex-grow-0", "is-flex-grow-1", "is-flex-grow-2", "is-flex-grow-3", "is-flex-grow-4", "is-flex-grow-5"];
    }
}

export function FlexGrow_Option$reflection() {
    return union_type("Fulma.FlexGrow.Option", [], FlexGrow_Option, () => [[], [], [], [], [], []]);
}

export class FlexShrink_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-flex-shrink-0", "is-flex-shrink-1", "is-flex-shrink-2", "is-flex-shrink-3", "is-flex-shrink-4", "is-flex-shrink-5"];
    }
}

export function FlexShrink_Option$reflection() {
    return union_type("Fulma.FlexShrink.Option", [], FlexShrink_Option, () => [[], [], [], [], [], []]);
}

export class TextWeight_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["has-text-weight-light", "has-text-weight-normal", "has-text-weight-semibold", "has-text-weight-bold", "has-text-weight-medium"];
    }
}

export function TextWeight_Option$reflection() {
    return union_type("Fulma.TextWeight.Option", [], TextWeight_Option, () => [[], [], [], [], []]);
}

export class TextTransform_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-capitalized", "is-lowercase", "is-uppercase", "is-italic", "is-underlined"];
    }
}

export function TextTransform_Option$reflection() {
    return union_type("Fulma.TextTransform.Option", [], TextTransform_Option, () => [[], [], [], [], []]);
}

export class Display_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["block", "flex", "inline", "inline-block", "inline-flex"];
    }
}

export function Display_Option$reflection() {
    return union_type("Fulma.Display.Option", [], Display_Option, () => [[], [], [], [], []]);
}

export function Display_toDisplayClass(screen, display) {
    return ("is-" + Reflection_getCaseName(display)) + Screen_ToString_2D2414B4(screen);
}

export function Display_toDisplayOnlyClass(screen, display) {
    let arg10;
    switch (screen.tag) {
        case 2:
        case 1:
        case 4: {
            return (("is-" + Reflection_getCaseName(display)) + Screen_ToString_2D2414B4(screen)) + "-only";
        }
        default: {
            console.warn(some((arg10 = toString(screen), toText(printf("Screen `%s` does not support display only."))(arg10))));
            return "";
        }
    }
}

export class Spacing_TypeAndDirection extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["m", "mt", "mr", "mb", "ml", "my", "mx", "p", "pt", "pr", "pb", "pl", "py", "px"];
    }
}

export function Spacing_TypeAndDirection$reflection() {
    return union_type("Fulma.Spacing.TypeAndDirection", [], Spacing_TypeAndDirection, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class Spacing_Amount extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["auto", "0", "1", "2", "3", "4", "5", "6"];
    }
}

export function Spacing_Amount$reflection() {
    return union_type("Fulma.Spacing.Amount", [], Spacing_Amount, () => [[], [], [], [], [], [], [], []]);
}

export function Spacing_toSpacingClass(typ, amount) {
    return (Reflection_getCaseName(typ) + "-") + Reflection_getCaseName(amount);
}

export function Modifier_ofBackground(level) {
    switch (level.tag) {
        case 19: {
            return "has-background-" + level.fields[0];
        }
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:
        case 18: {
            return "has-background-" + Reflection_getCaseName(level).slice(3, Reflection_getCaseName(level).length);
        }
        default: {
            return "";
        }
    }
}

export function Modifier_ofText(level) {
    switch (level.tag) {
        case 19: {
            return "has-text-" + level.fields[0];
        }
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:
        case 18: {
            return "has-text-" + Reflection_getCaseName(level).slice(3, Reflection_getCaseName(level).length);
        }
        default: {
            return "";
        }
    }
}

export function Modifier_ofInvisible(screen) {
    return "is-invisible" + Screen_ToString_2D2414B4(screen);
}

export function Modifier_ofHidden(screen) {
    return "is-hidden" + Screen_ToString_2D2414B4(screen);
}

export function Modifier_ofInvisibleOnly(screen) {
    let arg10;
    switch (screen.tag) {
        case 2:
        case 1:
        case 4: {
            return ("is-invisible" + Screen_ToString_2D2414B4(screen)) + "-only";
        }
        default: {
            console.warn(some((arg10 = toString(screen), toText(printf("Screen `%s` does not support `is-invisible-xxx-only`."))(arg10))));
            return "";
        }
    }
}

export function Modifier_ofHiddenOnly(screen) {
    let arg10;
    switch (screen.tag) {
        case 2:
        case 1:
        case 4: {
            return ("is-hidden" + Screen_ToString_2D2414B4(screen)) + "-only";
        }
        default: {
            console.warn(some((arg10 = toString(screen), toText(printf("Screen `%s` does not support `is-hidden-xxx-only`."))(arg10))));
            return "";
        }
    }
}

export class Modifier_IModifier extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["BackgroundColor", "TextColor", "TextWeight", "TextSize", "TextSizeOnly", "TextAlignment", "TextAlignmentOnly", "TextTransform", "Display", "DisplayOnly", "is-clearfix", "is-pulled-left", "is-pulled-right", "is-marginless", "is-paddingless", "is-overlay", "is-clipped", "is-radiusless", "is-shadowless", "is-unselectable", "is-clickable", "is-relative", "is-flex", "IsInvisible", "IsHidden", "IsInvisibleOnly", "IsHiddenOnly", "IsSrOnly", "IsScreenReaderOnly", "Spacing", "FlexDirection", "FlexWrap", "FlexJustifyContent", "FlexAlignContent", "FlexAlignItems", "FlexAlignSelf", "FlexGrow", "FlexShrink"];
    }
}

export function Modifier_IModifier$reflection() {
    return union_type("Fulma.Modifier.IModifier", [], Modifier_IModifier, () => [[["Item", Color_IColor$reflection()]], [["Item", Color_IColor$reflection()]], [["Item", TextWeight_Option$reflection()]], [["Item1", Screen$reflection()], ["Item2", TextSize_Option$reflection()]], [["Item1", Screen$reflection()], ["Item2", TextSize_Option$reflection()]], [["Item1", Screen$reflection()], ["Item2", TextAlignment_Option$reflection()]], [["Item1", Screen$reflection()], ["Item2", TextAlignment_Option$reflection()]], [["Item", TextTransform_Option$reflection()]], [["Item1", Screen$reflection()], ["Item2", Display_Option$reflection()]], [["Item1", Screen$reflection()], ["Item2", Display_Option$reflection()]], [], [], [], [], [], [], [], [], [], [], [], [], [], [["Item1", Screen$reflection()], ["Item2", bool_type]], [["Item1", Screen$reflection()], ["Item2", bool_type]], [["Item1", Screen$reflection()], ["Item2", bool_type]], [["Item1", Screen$reflection()], ["Item2", bool_type]], [], [], [["Item1", Spacing_TypeAndDirection$reflection()], ["Item2", Spacing_Amount$reflection()]], [["Item", FlexDirection_Option$reflection()]], [["Item", FlexWrap_Option$reflection()]], [["Item", FlexJustifyContent_Option$reflection()]], [["Item", FlexAlignContent_Option$reflection()]], [["Item", FlexAlignItems_Option$reflection()]], [["Item", FlexAlignSelf_Option$reflection()]], [["Item", FlexGrow_Option$reflection()]], [["Item", FlexShrink_Option$reflection()]]]);
}

export function Modifier_parseModifiers(options) {
    return fold((result, option) => {
        let screen_3, arg10, screen_7, arg10_1;
        switch (option.tag) {
            case 1: {
                return cons(Modifier_ofText(option.fields[0]), result);
            }
            case 2: {
                return cons(Reflection_getCaseName(option.fields[0]), result);
            }
            case 3: {
                return cons(("is-size-" + TextSize_Option_ToString_Z2E0B9453(option.fields[1])) + Screen_ToString_2D2414B4(option.fields[0]), result);
            }
            case 4: {
                return cons((screen_3 = option.fields[0], (screen_3.tag === 2) ? ((("is-size-" + TextSize_Option_ToString_Z2E0B9453(option.fields[1])) + Screen_ToString_2D2414B4(screen_3)) + "-only") : ((screen_3.tag === 1) ? ((("is-size-" + TextSize_Option_ToString_Z2E0B9453(option.fields[1])) + Screen_ToString_2D2414B4(screen_3)) + "-only") : ((screen_3.tag === 4) ? ((("is-size-" + TextSize_Option_ToString_Z2E0B9453(option.fields[1])) + Screen_ToString_2D2414B4(screen_3)) + "-only") : ((console.warn(some((arg10 = toString(screen_3), toText(printf("Screen `%s` does not support `is-size-xxx-only`."))(arg10)))), ""))))), result);
            }
            case 5: {
                return cons(Reflection_getCaseName(option.fields[1]) + Screen_ToString_2D2414B4(option.fields[0]), result);
            }
            case 6: {
                return cons((screen_7 = option.fields[0], (screen_7.tag === 2) ? ((Reflection_getCaseName(option.fields[1]) + Screen_ToString_2D2414B4(screen_7)) + "-only") : ((screen_7.tag === 1) ? ((Reflection_getCaseName(option.fields[1]) + Screen_ToString_2D2414B4(screen_7)) + "-only") : ((screen_7.tag === 4) ? ((Reflection_getCaseName(option.fields[1]) + Screen_ToString_2D2414B4(screen_7)) + "-only") : ((console.warn(some((arg10_1 = toString(screen_7), toText(printf("Screen `%s` does not support `is-size-xxx-only`."))(arg10_1)))), ""))))), result);
            }
            case 7: {
                return cons(Reflection_getCaseName(option.fields[0]), result);
            }
            case 8: {
                return cons(Display_toDisplayClass(option.fields[0], option.fields[1]), result);
            }
            case 9: {
                return cons(Display_toDisplayOnlyClass(option.fields[0], option.fields[1]), result);
            }
            case 23: {
                if (option.fields[1]) {
                    return cons(Modifier_ofInvisible(option.fields[0]), result);
                }
                else {
                    return result;
                }
            }
            case 25: {
                if (option.fields[1]) {
                    return cons(Modifier_ofInvisibleOnly(option.fields[0]), result);
                }
                else {
                    return result;
                }
            }
            case 24: {
                if (option.fields[1]) {
                    return cons(Modifier_ofHidden(option.fields[0]), result);
                }
                else {
                    return result;
                }
            }
            case 26: {
                if (option.fields[1]) {
                    return cons(Modifier_ofHiddenOnly(option.fields[0]), result);
                }
                else {
                    return result;
                }
            }
            case 27:
            case 28: {
                return cons("is-sr-only", result);
            }
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 20:
            case 19:
            case 22:
            case 21: {
                return cons(Reflection_getCaseName(option), result);
            }
            case 29: {
                return cons(Spacing_toSpacingClass(option.fields[0], option.fields[1]), result);
            }
            case 30: {
                const direction = option.fields[0];
                if (!contains("is-flex", result, {
                    Equals: (x_2, y) => (x_2 === y),
                    GetHashCode: stringHash,
                })) {
                    return ofArrayWithTail(["is-flex", Reflection_getCaseName(direction)], result);
                }
                else {
                    return cons(Reflection_getCaseName(direction), result);
                }
            }
            case 31: {
                const option_1 = option.fields[0];
                if (!contains("is-flex", result, {
                    Equals: (x_3, y_1) => (x_3 === y_1),
                    GetHashCode: stringHash,
                })) {
                    return ofArrayWithTail(["is-flex", Reflection_getCaseName(option_1)], result);
                }
                else {
                    return cons(Reflection_getCaseName(option_1), result);
                }
            }
            case 32: {
                const option_2 = option.fields[0];
                if (!contains("is-flex", result, {
                    Equals: (x_4, y_2) => (x_4 === y_2),
                    GetHashCode: stringHash,
                })) {
                    return ofArrayWithTail(["is-flex", Reflection_getCaseName(option_2)], result);
                }
                else {
                    return cons(Reflection_getCaseName(option_2), result);
                }
            }
            case 33: {
                const option_3 = option.fields[0];
                if (!contains("is-flex", result, {
                    Equals: (x_5, y_3) => (x_5 === y_3),
                    GetHashCode: stringHash,
                })) {
                    return ofArrayWithTail(["is-flex", Reflection_getCaseName(option_3)], result);
                }
                else {
                    return cons(Reflection_getCaseName(option_3), result);
                }
            }
            case 34: {
                const option_4 = option.fields[0];
                if (!contains("is-flex", result, {
                    Equals: (x_6, y_4) => (x_6 === y_4),
                    GetHashCode: stringHash,
                })) {
                    return ofArrayWithTail(["is-flex", Reflection_getCaseName(option_4)], result);
                }
                else {
                    return cons(Reflection_getCaseName(option_4), result);
                }
            }
            case 35: {
                return cons(Reflection_getCaseName(option.fields[0]), result);
            }
            case 36: {
                return cons(Reflection_getCaseName(option.fields[0]), result);
            }
            case 37: {
                return cons(Reflection_getCaseName(option.fields[0]), result);
            }
            default: {
                return cons(Modifier_ofBackground(option.fields[0]), result);
            }
        }
    }, empty(), options);
}

export class Common_GenericOption extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CustomClass", "Props", "Modifiers"];
    }
}

export function Common_GenericOption$reflection() {
    return union_type("Fulma.Common.GenericOption", [], Common_GenericOption, () => [[["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export class Common_GenericOptions extends Record {
    constructor(Props, Classes, RemovedClasses) {
        super();
        this.Props = Props;
        this.Classes = Classes;
        this.RemovedClasses = RemovedClasses;
    }
}

export function Common_GenericOptions$reflection() {
    return record_type("Fulma.Common.GenericOptions", [], Common_GenericOptions, () => [["Props", list_type(class_type("Fable.React.Props.IHTMLProp"))], ["Classes", list_type(string_type)], ["RemovedClasses", list_type(string_type)]]);
}

export function Common_GenericOptions_get_Empty() {
    return new Common_GenericOptions(empty(), empty(), empty());
}

export function Common_GenericOptions_Parse_9AE2F7C(options, parser, baseClass, baseProps) {
    const result = fold(parser, Common_GenericOptions_get_Empty(), options);
    const result_1 = (baseClass == null) ? result : Common_GenericOptions__AddClass_Z721C83C5(result, baseClass);
    if (baseProps == null) {
        return result_1;
    }
    else {
        return Common_GenericOptions__AddProps_416C4D0B(result_1, baseProps);
    }
}

export function Common_GenericOptions__AddProp_7BFEDA81(this$, prop) {
    return new Common_GenericOptions(cons(prop, this$.Props), this$.Classes, this$.RemovedClasses);
}

export function Common_GenericOptions__AddProps_416C4D0B(this$, props) {
    return new Common_GenericOptions(append(props, this$.Props), this$.Classes, this$.RemovedClasses);
}

export function Common_GenericOptions__AddClass_Z721C83C5(this$, cl) {
    return new Common_GenericOptions(this$.Props, cons(cl, this$.Classes), this$.RemovedClasses);
}

export function Common_GenericOptions__RemoveClass_Z721C83C5(this$, cl) {
    return new Common_GenericOptions(this$.Props, this$.Classes, cons(cl, this$.RemovedClasses));
}

export function Common_GenericOptions__AddCaseName_1505(this$, case$) {
    return Common_GenericOptions__AddClass_Z721C83C5(this$, Reflection_getCaseName(case$));
}

export function Common_GenericOptions__AddModifiers_5BB435D5(this$, modifiers) {
    return new Common_GenericOptions(this$.Props, append(Modifier_parseModifiers(modifiers), this$.Classes), this$.RemovedClasses);
}

export function Common_GenericOptions__ToAttributes(this$) {
    const matchValue = filter((cls) => {
        if (!isNullOrEmpty(cls)) {
            return !contains(cls, this$.RemovedClasses, {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            });
        }
        else {
            return false;
        }
    }, this$.Classes);
    if (isEmpty(matchValue)) {
        return this$.Props;
    }
    else {
        return cons(new HTMLAttr(64, join(" ", matchValue)), this$.Props);
    }
}

export function Common_GenericOptions__ToReactElement_Z6D3CD4B7(this$, el, children) {
    const children_1 = defaultArg(children, empty());
    return el(Common_GenericOptions__ToAttributes(this$), children_1);
}

export function Common_GenericOptions__ToReactElement_Z46A53D36(this$, el) {
    return el(Common_GenericOptions__ToAttributes(this$));
}

export function Common_parseOptions(result, option) {
    switch (option.tag) {
        case 0: {
            return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
        }
        case 2: {
            return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
        }
        default: {
            return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
        }
    }
}

export function Common_Helpers_classes(std, options, booleans) {
    return new HTMLAttr(64, fold((complete_1, tupledArg) => {
        if (tupledArg[1]) {
            return (complete_1 + " ") + tupledArg[0];
        }
        else {
            return complete_1;
        }
    }, fold((complete, option) => {
        if (option == null) {
            return complete;
        }
        else {
            return (complete + " ") + option;
        }
    }, std, options), booleans));
}

export function Text_p(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions), (props, children_1) => react.createElement("p", keyValueList(props, 1), ...children_1), children);
}

export function Text_div(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function Text_span(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions), (props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), children);
}

