import { printf, toText } from "./fable_modules/fable-library.3.7.12/String.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder, Remoting_buildProxy_Z15584635, RemotingModule_withAuthorizationHeader } from "./fable_modules/Fable.Remoting.Client.7.21.0/Remoting.fs.js";
import { Security_SecuredUserManagementApi$2$reflection, Security_UserViewModel$2$reflection, Security_SecuredAuthApi$1$reflection, Security_UserInfo$2$reflection, Security_PublicAuthApi$reflection, Route_builder } from "./fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { UserData$reflection, UserRole$reflection } from "./ThreeDocs.Models/Security.js";
import { PublicPatientPackagesApi$reflection, SecuredApi$reflection } from "./ThreeDocs.Models/Api/Api.js";
import { SecuredAwsApi$reflection } from "./ThreeDocs.Models/Api/AwsApi.js";

function withToken(t) {
    const token = toText(printf("Bearer %s"))(t);
    return (options) => RemotingModule_withAuthorizationHeader(token, options);
}

export const publicAuthApi = Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi()), {
    ResolveType: Security_PublicAuthApi$reflection,
});

export function securedAuthApi(token) {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder(Route_builder, withToken(token)(RemotingModule_createApi())), {
        ResolveType: () => Security_SecuredAuthApi$1$reflection(Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection())),
    });
}

export function securedApi(token) {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder(Route_builder, withToken(token)(RemotingModule_createApi())), {
        ResolveType: SecuredApi$reflection,
    });
}

export function securedUserManagementApi(token) {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder(Route_builder, withToken(token)(RemotingModule_createApi())), {
        ResolveType: () => Security_SecuredUserManagementApi$2$reflection(Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection()), Security_UserViewModel$2$reflection(UserRole$reflection(), UserData$reflection())),
    });
}

export const publicPatientPackagesApi = Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi()), {
    ResolveType: PublicPatientPackagesApi$reflection,
});

export function securedAwsApi(token) {
    let options;
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder(Route_builder, (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(`Bearer ${token}`, options))), {
        ResolveType: SecuredAwsApi$reflection,
    });
}

