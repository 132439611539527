import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { Security_AuthErrorMessage, Security_UserViewModel$2, Security_UserViewModel$2_get_roles_, Common_Name_get_family_, Common_Name_get_given_, Security_UserViewModel$2_get_name_, Security_UserViewModel$2_get_email_, Security_UserInfo$2$reflection, Security_UserViewModel$2$reflection, Security_AuthErrorMessage$reflection } from "../fable_modules/Webbler.Models.1.1.0/Api.fs.js";
import { option_type, list_type, record_type, string_type, union_type, unit_type, bool_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { UserData_get_practices_, UserData, UserRole, UserRole_get_describe, UserData_get_specialty_, UserData_get_maybeEmailString_, UserData_get_replyToEmail_, UserData$reflection, UserRole$reflection } from "../ThreeDocs.Models/Security.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { PracticeViewModel_get_name_, PracticeViewModel_get_id_, PracticeViewModel$reflection } from "../ThreeDocs.Models/View/PracticeViewModel.js";
import { Shared_CrudMachineItemConfig$4, Shared_CrudMachineServerCalls$4, CrudControls_CrudFormField$2, CrudControls_mkId, CrudControls_stringDetail, CrudControls_makeItemElement, CrudControls_ItemElement$2, CrudControls_makeItemElementString } from "../ThreeDocs.Client.StateMachine.Crud/SharedCrud.js";
import { safeHash, equals, compare, uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61, Compose_Lens_op_GreaterMinusGreater_2536FC39, Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { UserViewModelModule_ofDomain, UserViewModelModule_toDomain, UserViewModelModule_empty, UserViewModelModule_userData_ } from "../ThreeDocs.Models/View/UserViewModel.js";
import { append as append_1, choose, ofSeq, singleton, empty, ofArray, tryHead, map, tail as tail_1, head as head_1, isEmpty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Specialty_get_groupedList, Specialty as Specialty_1, Specialty_get_describe } from "../ThreeDocs.Models/Domain/Specialty.js";
import { join } from "../fable_modules/fable-library.3.7.12/String.js";
import { toArray, map as map_1, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { deleteConfirmationViewLayoutConfig, detailViewLayoutConfig, listViewLayoutConfigWithDefaultCreateButton, dataEntryViewLayoutConfig, defaultListComponents, makeList, ListViewHeaderInfo } from "../ModalCrudConfigLayout.js";
import { ViewConfig$2, SearchModule_allColumnSearch, columnListTable } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudListState.js";
import { Sort_itemListSortable } from "../ThreeDocs.SharedUI/Sort.js";
import { Button_visibilityControlled, ComponentVisibility, Button_visibilityControlledCustom } from "../ThreeDocs.SharedUI/ComponentVisibility.js";
import { CSSProp } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { String_fromOption_, List_toOption_, List_mapLens_, List_containsOneOf } from "../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import { Option } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Naming_UIElement } from "../fable_modules/Webbler.Models.1.1.0/Common.fs.js";
import { CrudStateMachine_CrudMachineItemViewConfig$4, CrudStateMachine_CrudMachineViewLayoutConfig$4 } from "../ThreeDocs.Client.StateMachine.Crud/CrudMachine.js";
import { FormField_ReactSelect_CallbackMulti$1, FormField_ReactSelect_multiGrouped, FormField_ReactSelect_onChangeSingle, FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_single } from "../ThreeDocs.SharedUI/Forms.js";
import { List_except } from "../fable_modules/fable-library.3.7.12/Seq2.js";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import * as react from "react";
import { FormViewConfig$2 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDataEntryState.js";
import { FormViewConfig$2 as FormViewConfig$2_1 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDetailState.js";
import { FormViewConfig$2 as FormViewConfig$2_2 } from "../ThreeDocs.Client.StateMachine.Crud/CrudSubstates/CrudDeleteConfirmationState.js";
import { AsyncResult_ofError, AsyncResult_mapError, AsyncResult_map, Result_map, Result_mapError } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { FormValidationError_get_describe } from "../ThreeDocs.SharedUI/FormValidation.js";
import { securedUserManagementApi } from "../Api.js";

export class UsersCrudOutbound extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ApiError", "CrudExceptionError", "StartEmailChange", "UpdateUserLockoutState", "UpdateUserLockoutStateResult"];
    }
}

export function UsersCrudOutbound$reflection() {
    return union_type("Client.Users.UsersCrudOutbound", [], UsersCrudOutbound, () => [[["Item", Security_AuthErrorMessage$reflection()]], [["Item", class_type("System.Exception")]], [["Item", Security_UserViewModel$2$reflection(UserRole$reflection(), UserData$reflection())]], [["Item1", Security_UserViewModel$2$reflection(UserRole$reflection(), UserData$reflection())], ["isLockedOut", bool_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, Security_AuthErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", Security_AuthErrorMessage$reflection()]]])]]]);
}

export class UsersUpdateArgs extends Record {
    constructor(token) {
        super();
        this.token = token;
    }
}

export function UsersUpdateArgs$reflection() {
    return record_type("Client.Users.UsersUpdateArgs", [], UsersUpdateArgs, () => [["token", string_type]]);
}

export class CustomShared extends Record {
    constructor(practices, availableRolesToSelect, canCreateUserWithoutAssignedPractices, currentRoles, currentUserInfo) {
        super();
        this.practices = practices;
        this.availableRolesToSelect = availableRolesToSelect;
        this.canCreateUserWithoutAssignedPractices = canCreateUserWithoutAssignedPractices;
        this.currentRoles = currentRoles;
        this.currentUserInfo = currentUserInfo;
    }
}

export function CustomShared$reflection() {
    return record_type("Client.Users.CustomShared", [], CustomShared, () => [["practices", list_type(PracticeViewModel$reflection())], ["availableRolesToSelect", list_type(UserRole$reflection())], ["canCreateUserWithoutAssignedPractices", bool_type], ["currentRoles", list_type(UserRole$reflection())], ["currentUserInfo", option_type(Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection()))]]);
}

export function CustomShared_get_practices_() {
    return [(m) => m.practices, (v) => ((m_1) => (new CustomShared(v, m_1.availableRolesToSelect, m_1.canCreateUserWithoutAssignedPractices, m_1.currentRoles, m_1.currentUserInfo)))];
}

export function CustomShared_get_availableRolesToSelect_() {
    return [(m) => m.availableRolesToSelect, (v) => ((m_1) => (new CustomShared(m_1.practices, v, m_1.canCreateUserWithoutAssignedPractices, m_1.currentRoles, m_1.currentUserInfo)))];
}

export function CustomShared_get_canCreateUserWithoutAssignedPractices_() {
    return [(m) => m.canCreateUserWithoutAssignedPractices, (v) => ((m_1) => (new CustomShared(m_1.practices, m_1.availableRolesToSelect, v, m_1.currentRoles, m_1.currentUserInfo)))];
}

export function CustomShared_get_currentRoles_() {
    return [(m) => m.currentRoles, (v) => ((m_1) => (new CustomShared(m_1.practices, m_1.availableRolesToSelect, m_1.canCreateUserWithoutAssignedPractices, v, m_1.currentUserInfo)))];
}

export function CustomShared_get_currentUserInfo_() {
    return [(m) => m.currentUserInfo, (v) => ((m_1) => (new CustomShared(m_1.practices, m_1.availableRolesToSelect, m_1.canCreateUserWithoutAssignedPractices, m_1.currentRoles, v)))];
}

export const UsersCrudConfig_mkUserElementString = (label) => ((tupledArg) => CrudControls_makeItemElementString("", "Users", label, tupledArg[0], uncurry(2, tupledArg[1])));

export const UsersCrudConfig_itemFields = ofArray([(() => {
    const inputRecord = UsersCrudConfig_mkUserElementString("Login Email")(Security_UserViewModel$2_get_email_());
    return new CrudControls_ItemElement$2(inputRecord.maybeList, inputRecord.maybeDetail, void 0);
})(), UsersCrudConfig_mkUserElementString("First Name")((() => {
    const l = Security_UserViewModel$2_get_name_();
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Common_Name_get_given_())(l);
})()), UsersCrudConfig_mkUserElementString("Last Name")((() => {
    const l_2 = Security_UserViewModel$2_get_name_();
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Common_Name_get_family_())(l_2);
})()), (() => {
    let l_6;
    const inputRecord_1 = UsersCrudConfig_mkUserElementString("Reply-To Email")((l_6 = Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), UserData_get_replyToEmail_())(UserViewModelModule_userData_), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), UserData_get_maybeEmailString_())(l_6)));
    return new CrudControls_ItemElement$2(void 0, inputRecord_1.maybeDetail, inputRecord_1.maybeForm);
})(), CrudControls_makeItemElement("Specialty", [Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), UserData_get_specialty_())(UserViewModelModule_userData_), (vm) => {
    const matchValue = vm.userData.Specialty;
    return (!isEmpty(matchValue)) ? (Specialty_get_describe()(head_1(matchValue)) + (isEmpty(tail_1(matchValue)) ? "" : " (...)")) : "";
}], (model) => CrudControls_stringDetail(join(", ", map(Specialty_get_describe(), model.userData.Specialty))), uncurry(3, void 0), compare), CrudControls_makeItemElement("Role", [Security_UserViewModel$2_get_roles_(), (vm_1) => {
    let option;
    return defaultArg((option = tryHead(vm_1.roles), map_1(UserRole_get_describe(), option)), "");
}], void 0, uncurry(3, void 0), compare)]);

export const UsersCrudConfig_createItemFields = ofArray([UsersCrudConfig_mkUserElementString("Login Email")(Security_UserViewModel$2_get_email_()), UsersCrudConfig_mkUserElementString("First Name")((() => {
    const l = Security_UserViewModel$2_get_name_();
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Common_Name_get_given_())(l);
})()), UsersCrudConfig_mkUserElementString("Last Name")((() => {
    const l_2 = Security_UserViewModel$2_get_name_();
    return Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), Common_Name_get_family_())(l_2);
})()), UsersCrudConfig_mkUserElementString("Reply-To Email")((() => {
    const l_6 = Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), UserData_get_replyToEmail_())(UserViewModelModule_userData_);
    return Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), UserData_get_maybeEmailString_())(l_6);
})())]);

export function UsersCrudConfig_generalViewLayoutConfig(columns, isSameItem) {
    const listViewHeaderInfo = new ListViewHeaderInfo("Users", "Create User");
    const extraListButtons = empty();
    const listView = (selected_1, shared_1) => makeList(defaultListComponents, shared_1, columnListTable((columns_1, selectItem, items, extraItem, sortInfo, updateSortInfo) => Sort_itemListSortable(listViewHeaderInfo.title, columns_1, uncurry(2, selectItem), items, extraItem, sortInfo, uncurry(2, updateSortInfo)), shared_1, columns, selected_1, (value) => {
    }), listViewHeaderInfo, void 0, map((func) => func((value_1) => {
    }), extraListButtons));
    return new CrudStateMachine_CrudMachineViewLayoutConfig$4(dataEntryViewLayoutConfig(empty(), listView, void 0, listViewHeaderInfo.title, "User", "Edit"), dataEntryViewLayoutConfig(empty(), listView, void 0, listViewHeaderInfo.title, "User", "Create"), listViewLayoutConfigWithDefaultCreateButton(listViewHeaderInfo, extraListButtons), detailViewLayoutConfig((_arg4, _arg3_1) => false, (_arg2_1, _arg1_1) => true, ofArray([(model) => ((shared_3) => ((dispatch) => {
        let matchValue, info_1;
        const buttonText = model.selected.userData.IsLockedOut ? "End User Lockout" : "Lock Out User";
        return Button_visibilityControlledCustom(ofArray([new CSSProp(280, "0.5em"), new CSSProp(221, "unset")]), (matchValue = [List_containsOneOf(ofArray([new UserRole(0), new UserRole(1)]), shared_3.CustomStorage.currentRoles), shared_3.CustomStorage.currentUserInfo], matchValue[0] ? ((matchValue[1] != null) ? ((model.selected.id !== matchValue[1].id) ? ((info_1 = matchValue[1], new ComponentVisibility(0))) : (new ComponentVisibility(1))) : (new ComponentVisibility(1))) : (new ComponentVisibility(1))), ofArray([new Option(18, (_arg2) => {
            dispatch(new UsersCrudOutbound(3, model.selected, !model.selected.userData.IsLockedOut));
        }), new Option(17, singleton(CrudControls_mkId(listViewHeaderInfo.title, "Detail", "LockOut", new Naming_UIElement(2))))]), singleton(buttonText));
    })), (model_1) => ((_shared) => ((dispatch_1) => Button_visibilityControlled(new ComponentVisibility(0), ofArray([new Option(18, (_arg3) => {
        dispatch_1(new UsersCrudOutbound(2, model_1.selected));
    }), new Option(17, singleton(CrudControls_mkId(listViewHeaderInfo.title, "Detail", "Change User Email Address", new Naming_UIElement(2))))]), singleton("Change User Email Address"))))]), listView, listViewHeaderInfo.title), deleteConfirmationViewLayoutConfig(empty(), listView, void 0, listViewHeaderInfo.title));
}

export const UsersCrudConfig_userVmViewConfig = (() => {
    const roleAndPracticeField = (userVm, sharedStorage, dispatchChange) => {
        let tupledArg, optic, l_6, role;
        const setVmDispatch = dispatchChange;
        const roleSelector = (currentRole) => FormField_ReactSelect_single(sharedStorage.availableRolesToSelect, [], UserRole_get_describe(), currentRole, new FormField_ReactSelect_CallbackSingle$1(0, (newValue_1) => {
            setVmDispatch((uvm_1) => {
                let inputRecord_1;
                const practices = (newValue_1 != null) ? ((newValue_1.tag === 3) ? empty() : ((newValue_1.tag === 0) ? empty() : ((newValue_1.tag === 1) ? uvm_1.userData.Practices : empty()))) : empty();
                const specialty = (newValue_1 != null) ? ((newValue_1.tag === 3) ? List_except([new Specialty_1(82)], uvm_1.userData.Specialty, {
                    Equals: equals,
                    GetHashCode: safeHash,
                }) : ((newValue_1.tag === 1) ? List_except([new Specialty_1(82)], uvm_1.userData.Specialty, {
                    Equals: equals,
                    GetHashCode: safeHash,
                }) : ((newValue_1.tag === 0) ? singleton(new Specialty_1(82)) : List_except([new Specialty_1(82)], uvm_1.userData.Specialty, {
                    Equals: equals,
                    GetHashCode: safeHash,
                })))) : List_except([new Specialty_1(82)], uvm_1.userData.Specialty, {
                    Equals: equals,
                    GetHashCode: safeHash,
                });
                return new Security_UserViewModel$2(uvm_1.id, uvm_1.email, uvm_1.name, ofArray(toArray(newValue_1)), (inputRecord_1 = uvm_1.userData, new UserData(practices, specialty, inputRecord_1.ReplyToEmail, inputRecord_1.Restriction, inputRecord_1.IsLockedOut)));
            });
        }), "Role", "User", []);
        let practiceLens;
        let l_4;
        const l_2 = Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), UserData_get_practices_())(UserViewModelModule_userData_);
        l_4 = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), (tupledArg = PracticeViewModel_get_id_(), List_mapLens_(tupledArg[0], uncurry(2, tupledArg[1]), sharedStorage.practices)))(l_2);
        practiceLens = Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), List_toOption_())(l_4);
        const practiceSelector = FormField_ReactSelect_single(sharedStorage.practices, [], (optic = ((l_6 = PracticeViewModel_get_name_(), Compose_Lens_op_GreaterMinusGreater_2536FC39(new Compose_Lens(0), String_fromOption_)(l_6))), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(target)), Optic_Get_op_HatDot_21762A61(new Optic_Get(0), practiceLens)(userVm), FormField_ReactSelect_onChangeSingle(setVmDispatch, practiceLens[0], uncurry(2, practiceLens[1])), "Practices", "User", []);
        let patternInput;
        const matchValue = tryHead(userVm.roles);
        patternInput = ((matchValue != null) ? ((matchValue.tag === 0) ? [roleSelector(new UserRole(0)), void 0, void 0] : ((role = matchValue, [roleSelector(role), practiceSelector, FormField_ReactSelect_multiGrouped(Specialty_get_groupedList(), [], Specialty_get_describe(), userVm.userData.Specialty, new FormField_ReactSelect_CallbackMulti$1(0, (newValue) => {
            setVmDispatch((uvm) => {
                let inputRecord;
                return new Security_UserViewModel$2(uvm.id, uvm.email, uvm.name, uvm.roles, (inputRecord = uvm.userData, new UserData(inputRecord.Practices, ofSeq(newValue), inputRecord.ReplyToEmail, inputRecord.Restriction, inputRecord.IsLockedOut)));
            });
        }), "Specialty", "User", [])]))) : [roleSelector(void 0), void 0, void 0]);
        const children = toList(delay(() => append(singleton_1(patternInput[0]), delay(() => append(ofArray(toArray(patternInput[2])), delay(() => ofArray(toArray(patternInput[1]))))))));
        return react.createElement("div", {}, ...children);
    };
    const listViewConfig = new ViewConfig$2(choose((itemField) => itemField.maybeList, UsersCrudConfig_itemFields), SearchModule_allColumnSearch);
    const editFormViewConfig = new FormViewConfig$2(append_1(choose((itemField_1) => itemField_1.maybeForm, UsersCrudConfig_itemFields), singleton(new CrudControls_CrudFormField$2("Role", roleAndPracticeField))));
    const createFormViewConfig = new FormViewConfig$2(append_1(choose((itemFields) => itemFields.maybeForm, UsersCrudConfig_createItemFields), singleton(new CrudControls_CrudFormField$2("Role", roleAndPracticeField))));
    const detailFormViewConfig = new FormViewConfig$2_1(choose((itemField_2) => itemField_2.maybeDetail, UsersCrudConfig_itemFields));
    return new CrudStateMachine_CrudMachineItemViewConfig$4(listViewConfig, createFormViewConfig, editFormViewConfig, detailFormViewConfig, new FormViewConfig$2_2(detailFormViewConfig.detailFields));
})();

export const UsersCrudConfig_userVmModelConfig = new Shared_CrudMachineItemConfig$4((_arg1) => UserViewModelModule_empty, (_arg5, _arg4, item_2) => {
    let f1;
    return Result_mapError()((f1 = FormValidationError_get_describe(), f1))(Result_map()((value) => {
    })(UserViewModelModule_toDomain(item_2)));
}, (userVm1, userVm2) => (userVm1.id === userVm2.id), new Shared_CrudMachineServerCalls$4((updateArgs, item) => AsyncResult_map(UserViewModelModule_ofDomain, AsyncResult_mapError((arg0) => (new UsersCrudOutbound(0, arg0)), securedUserManagementApi(updateArgs.token).createUser(item))), (updateArgs_1, item_1) => AsyncResult_map(() => item_1, AsyncResult_mapError((arg0_1) => (new UsersCrudOutbound(0, arg0_1)), securedUserManagementApi(updateArgs_1.token).editUser(item_1))), (_arg3, _arg2) => AsyncResult_ofError(new UsersCrudOutbound(0, new Security_AuthErrorMessage(0)))), (arg0_4) => (new UsersCrudOutbound(1, arg0_4)));

