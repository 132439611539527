import { PatientPackageViewModel_get_empty } from "../ThreeDocs.Models/View/PatientPackageViewModel.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { Msg, Model } from "./PatientPackagesTypes.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { publicPatientPackagesApi } from "../Api.js";
import { error } from "../ThreeDocs.SharedUI/Toast.js";
import { ErrorMessage_get_describe } from "../ThreeDocs.Models/Api/Api.js";
import { some } from "../fable_modules/fable-library.3.7.12/Option.js";

export function init(packageIdToken) {
    return [new Model(packageIdToken, new FSharpResult$2(0, PatientPackageViewModel_get_empty())), Cmd_none()];
}

export function update(msg, model) {
    if (msg.tag === 1) {
        return [model, Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, publicPatientPackagesApi.getPatientPackage, msg.fields[0], (arg0) => (new Msg(2, arg0)), (arg0_1) => (new Msg(0, arg0_1)))];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            return [new Model(model.token, new FSharpResult$2(1, msg.fields[0].fields[0])), error(ErrorMessage_get_describe()(msg.fields[0].fields[0]))];
        }
        else {
            return [new Model(model.token, new FSharpResult$2(0, msg.fields[0].fields[0])), Cmd_none()];
        }
    }
    else {
        console.error(some(msg.fields[0]));
        return [model, Cmd_none()];
    }
}

