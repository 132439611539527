import { columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { ofArray, map, singleton, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Option, ISize, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { content, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { String_fromOption } from "../fable_modules/Webbler.Models.1.1.0/Extensions.fs.js";
import * as react from "react";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { practiceInfo, generateSectionView } from "../Package/PackageView.js";
import { Screen } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { PatientPackageViewModel_get_empty } from "../ThreeDocs.Models/View/PatientPackageViewModel.js";

export function detailView(patientPackageVm) {
    let children;
    const package$ = patientPackageVm.packageViewModel;
    const children_6 = [columns(empty(), ofArray([column(empty(), singleton(card(empty(), ofArray([header(empty(), singleton(Header_title(empty(), singleton((children = [String_fromOption(package$.title)], react.createElement("h1", {}, ...children)))))), content(empty(), toList(delay(() => {
        let props_2, children_2;
        return append(singleton_1((props_2 = [["style", {
            whiteSpace: "pre-wrap",
        }]], (children_2 = [`Dear ${patientPackageVm.invitationViewModel.patientName},
`, String_fromOption(package$.intro)], react.createElement("p", keyValueList(props_2, 1), ...children_2)))), delay(() => append(map((section) => generateSectionView(patientPackageVm.mediaViewModels, section), package$.sections), delay(() => {
            let props_4, children_4;
            return singleton_1((props_4 = [["style", {
                whiteSpace: "pre-wrap",
            }]], (children_4 = [String_fromOption(package$.outro)], react.createElement("p", keyValueList(props_4, 1), ...children_4))));
        }))));
    })))])))), column(singleton(new Option(0, new Screen(0), new ISize(7))), singleton(practiceInfo(patientPackageVm.practiceViewModel)))]))];
    return react.createElement("div", {}, ...children_6);
}

export function view(model, _dispatch) {
    return container(empty(), toList(delay(() => {
        let children_2;
        const matchValue = model.patientPackageVm;
        let pattern_matching_result;
        if (matchValue.tag === 0) {
            if (equals(matchValue.fields[0], PatientPackageViewModel_get_empty())) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton_1((children_2 = [react.createElement("div", {
                    className: "spinner is-loading",
                }, "Please wait")], react.createElement("div", {}, ...children_2)));
            }
            case 1: {
                let pattern_matching_result_1, vm_2;
                if (matchValue.tag === 1) {
                    if (matchValue.fields[0].tag === 6) {
                        if (matchValue.fields[0].fields[0].tag === 4) {
                            pattern_matching_result_1 = 1;
                        }
                        else {
                            pattern_matching_result_1 = 2;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 2;
                    }
                }
                else {
                    pattern_matching_result_1 = 0;
                    vm_2 = matchValue.fields[0];
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        return singleton_1(detailView(vm_2));
                    }
                    case 1: {
                        return singleton_1(react.createElement("div", {}, "This Invitation has expired. Please contact your clinic if you need a new invitation."));
                    }
                    case 2: {
                        return singleton_1(react.createElement("div", {}, "Something went wrong. We are unable to retrieve your procedure package at this time. Please try again later. If this issue persists please contact your clinic or 3DOCS for assistance."));
                    }
                }
            }
        }
    })));
}

