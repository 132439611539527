import { Union } from "../../../fable-library.3.7.12/Types.js";
import { union_type, list_type, class_type, string_type } from "../../../fable-library.3.7.12/Reflection.js";
import { Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions__AddCaseName_1505, Common_parseOptions, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Size_ISize$reflection, Modifier_IModifier$reflection } from "../../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library.3.7.12/MapUtil.js";
import { Reflection_getCaseName } from "../../Common.fs.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["has-addons", "has-addons-centered", "has-addons-right", "has-addons-fullwidth", "is-grouped", "is-grouped-centered", "is-grouped-right", "is-grouped-multiline", "is-horizontal", "is-expanded", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Field.Option", [], Option, () => [[], [], [], [], [], [], [], [], [], [], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export class Label_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Size", "is-normal", "CustomClass", "Props", "Modifiers"];
    }
}

export function Label_Option$reflection() {
    return union_type("Fulma.Field.Label.Option", [], Label_Option, () => [[["Item", Size_ISize$reflection()]], [], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function body(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "field-body"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function label(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 3: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 4: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            }
        }
    }, "field-label"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

export function fieldView(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 2: {
                return Common_GenericOptions__AddCaseName_1505(Common_GenericOptions__AddClass_Z721C83C5(result, "has-addons"), option);
            }
            case 3: {
                return Common_GenericOptions__AddCaseName_1505(Common_GenericOptions__AddClass_Z721C83C5(result, "has-addons"), option);
            }
            case 5: {
                return Common_GenericOptions__AddCaseName_1505(Common_GenericOptions__AddClass_Z721C83C5(result, "is-grouped"), option);
            }
            case 6: {
                return Common_GenericOptions__AddCaseName_1505(Common_GenericOptions__AddClass_Z721C83C5(result, "is-grouped"), option);
            }
            case 7: {
                return Common_GenericOptions__AddCaseName_1505(Common_GenericOptions__AddClass_Z721C83C5(result, "is-grouped"), option);
            }
            case 0:
            case 4:
            case 8:
            case 9: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 11: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 10: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 12: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(Common_GenericOptions__AddClass_Z721C83C5(result, "has-addons"), option);
            }
        }
    }, "field"), element, children);
}

export function div(x, y) {
    return fieldView((props, children) => react.createElement("div", keyValueList(props, 1), ...children), x, y);
}

export function p(x, y) {
    return fieldView((props, children) => react.createElement("p", keyValueList(props, 1), ...children), x, y);
}

